import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps
} from '@/features/host-submissions/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldDescription,
    FieldTitle,
    Title,
    Footer,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useEffect, useRef, useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {AnswerItem} from '@components/commons/answer-item/AnswerItem.tsx'
import {z} from 'zod'
import {useFieldArray, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {Service} from '@/features/host-submissions/services/types.ts'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {PlusIcon, Trash01Icon} from '@components/ui/icon'
import {
    StyledLinkField,
    StyledInputText
} from '@/features/host-submissions/services/components/host-expertise-about-yourself-step/style.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {useUpdateStepToHostExpertiseExpertise} from '@/features/host-submissions/services/queries/useUpdateStepToHostExpertiseExpertise'
import {useUpdateStepToLocation} from '@/features/host-submissions/services/queries/useUpdateStepToLocation'
import {ConfirmSubmitModal} from '@/features/host-submissions/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {FlagsAlertText} from '../../../components/flags-alert-text/FlagsAlertText'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {ChangeProfilePhoto} from '@/features/host-submissions/components/change-profile-photo/ChangeProfilePhoto'
import {ChangePhoneNumber} from '@/features/host-submissions/components/change-phone-number/ChangePhoneNumber'

const ValidationSchema = z.object({
    links: z.array(z.object({value: z.string().url(i18n.t('errors:invalid_http_url'))})).min(1),
    hosting_modality: z.enum(['self', 'team', 'team_services']),
    co_hosts: z
        .string()
        .min(1, {message: i18n.t('errors:field_required')})
        .optional()
})
/*.superRefine((values, context) => {
        if (values.hosting_modality == 'team' && !values.co_hosts) {
            context.addIssue({
                code: 'custom',
                path: ['co_hosts'],
                message: i18n.t('errors:field_required')
            })
        }
    })*/
type ValidationSchema = z.infer<typeof ValidationSchema>

export const HostExpertiseAboutYourselfStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)

    const user = useAuthStore(store => store.user)
    const [canScroll, setCanScroll] = useState(false)
    const [changeProfilePhotoIsPending, setChangeProfilePhotoIsPending] = useState(false)

    const question3MoreInfoRef = useRef<HTMLParagraphElement>(null)
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const linksFlags = updatesRequiredSteps.aboutYourself.find(field => field.questionKeyName == 'links')?.flags
    const hostingModalityFlags = updatesRequiredSteps.aboutYourself.find(
        field => field.questionKeyName == 'hosting_modality'
    )?.flags
    const profilePhotoFlags = updatesRequiredSteps.aboutYourself.find(field => field.questionKeyName == 'profile_photo')
        ?.flags
    const flagsCount = updatesRequiredSteps.aboutYourself.flatMap(field => field.flags).length
    const updateStepToHostExpertiseExpertiseMutation = useUpdateStepToHostExpertiseExpertise<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateStepToLocationMutation = useUpdateStepToLocation<false>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })

    const onSuccessChangePhoto = (data: {url: string}) => {
        void queryClient.cancelQueries({queryKey: [QUERY_KEYS.SERVICE, service.id]})
        void queryClient.setQueriesData<Service>({queryKey: [QUERY_KEYS.SERVICE, service.id]}, prevData =>
            prevData
                ? {
                      ...prevData,
                      host: {
                          ...prevData.host,
                          profile_photo_url: data.url
                      }
                  }
                : undefined
        )
    }

    const onSuccessChangePhone = (phoneNumber: string) => {
        queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE, service.id]}, prevData => ({
            ...(prevData ?? {}),
            host: {airbnb_verified_phone: phoneNumber}
        }))
    }
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'aboutYourself', updatesRequiredSteps}).prev?.()
            : updateStepToHostExpertiseExpertiseMutation
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'aboutYourself'>({
                  service,
                  currentStep: 'aboutYourself',
                  updatesRequiredSteps
              }).next?.()
            : updateStepToLocationMutation

    const form = useForm<ValidationSchema>({
        resolver: zodResolver(ValidationSchema),
        defaultValues: service.expertise?.question_answer.reduce(
            (previousValue, currentValue) => {
                if (
                    currentValue.question_key_name == 'links' &&
                    Array.isArray(currentValue.answer) &&
                    currentValue.answer.length >= 1
                ) {
                    return {...previousValue, links: currentValue.answer.map(value => ({value}))}
                }
                if (currentValue.question_key_name == 'hosting_modality' && currentValue.answer) {
                    return {...previousValue, hosting_modality: currentValue.answer}
                }
                if (currentValue.question_key_name == 'co_hosts' && currentValue.answer) {
                    return {...previousValue, co_hosts: currentValue.answer}
                }
                return previousValue
            },
            {
                links: [{value: ''}]
            }
        )
    })
    const fieldArray = useFieldArray({control: form.control, name: 'links'})
    const hostingModality = useWatch({control: form.control, name: 'hosting_modality'})

    const onSubmit = form.handleSubmit(formValues =>
        (service.host.profile_photo_url || user?.picture_url) && (service.host.airbnb_verified_phone || user?.phone)
            ? nextStepMutation?.mutate({
                  ...formValues,
                  links: formValues.links.map(link => link.value)
              })
            : undefined
    )

    useEffect(() => {
        if (canScroll && question3MoreInfoRef.current && hostingModality == 'team_services') {
            question3MoreInfoRef.current.scrollIntoView({behavior: 'smooth', block: 'center'})
        }
    }, [canScroll, question3MoreInfoRef.current, hostingModality])

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:about_you')}</Title>
                    <Paragraph>{t('services:step_host_expertise_about_yourself:paragraph')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('services:x_items_improve', {count: flagsCount})}
                            paragraph={t('services:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={6} align="stretch">
                    <ChangeProfilePhoto
                        onSuccess={onSuccessChangePhoto}
                        photoUrl={service.host.profile_photo_url}
                        isSubmitted={form.formState.isSubmitted}
                        profilePhotoFlags={profilePhotoFlags}
                        setIsPending={setChangeProfilePhotoIsPending}
                    />
                    <Divider direction="horizontal" />

                    <ChangePhoneNumber
                        airbnbVerifiedPhone={service.host.airbnb_verified_phone}
                        userPhone={user?.phone}
                        isSubmitted={form.formState.isSubmitted}
                        onSuccess={onSuccessChangePhone}
                    />

                    <Divider direction="horizontal" />

                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5} align="stretch">
                            <FieldTitle>{t('services:step_host_expertise_about_yourself:question2_title')}</FieldTitle>

                            <FieldDescription>{t('host_review:social_link_consent_policy')}</FieldDescription>
                            <FlagsAlertText
                                flags={linksFlags}
                                title={t('services:step_host_expertise_about_yourself:question2_title')}
                            />
                        </Flexbox>
                        {fieldArray.fields.map((field, index, array) => (
                            <StyledLinkField key={field.id}>
                                <StyledInputText
                                    inputSize="sm"
                                    type="text"
                                    label={t('services:step_host_expertise_about_yourself:question2_label')}
                                    placeholder={t('services:step_host_expertise_about_yourself:question2_placeholder')}
                                    {...form.register(`links.${index}.value`)}
                                />
                                <Button
                                    variant="ghost"
                                    shape="square"
                                    onClick={() => fieldArray.remove(index)}
                                    style={{visibility: array.length == 1 ? 'hidden' : 'visible', transition: 'none'}}
                                >
                                    <Trash01Icon />
                                </Button>
                                {form.formState.errors.links?.[index]?.value && (
                                    <InputHelpText error={form.formState.errors.links[index]?.value?.message} />
                                )}
                            </StyledLinkField>
                        ))}
                        {fieldArray.fields.length < 10 && (
                            <Button
                                variant="tertiary"
                                shape="text"
                                style={{width: 'max-content'}}
                                onClick={() => fieldArray.append({value: ''})}
                            >
                                <PlusIcon />
                                {t('services:step_host_expertise_about_yourself:question2_add')}
                            </Button>
                        )}
                        {form.formState.errors.links?.root && (
                            <InputHelpText error={form.formState.errors.links.root.message} />
                        )}
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_host_expertise_about_yourself:question3_title')}</FieldTitle>

                            <FlagsAlertText
                                flags={hostingModalityFlags}
                                title={t('services:step_host_expertise_about_yourself:question3_title')}
                            />
                        </Flexbox>
                        <Flexbox direction="column" align="stretch" gap={3}>
                            <AnswerItem
                                type="radio"
                                value="self"
                                label={t('services:step_host_expertise_about_yourself:question3_option1')}
                                {...form.register('hosting_modality', {
                                    onChange: () => form.setValue('co_hosts', undefined)
                                })}
                            />
                            <AnswerItem
                                type="radio"
                                value="team"
                                label={t('services:step_host_expertise_about_yourself:question3_option2')}
                                {...form.register('hosting_modality')}
                            />
                            <AnswerItem
                                type="radio"
                                value="team_services"
                                label={t('services:step_host_expertise_about_yourself:question3_option3')}
                                onClick={() => setCanScroll(true)}
                                {...form.register('hosting_modality')}
                            />

                            {hostingModality == 'team_services' && (
                                <FieldDescription ref={question3MoreInfoRef}>
                                    {t('services:step_host_expertise_about_yourself:question3_option_description')}
                                </FieldDescription>
                            )}

                            {form.formState.errors.hosting_modality && (
                                <InputHelpText error={t('services:select_option_error')} />
                            )}
                        </Flexbox>
                        {/*{modeWatch == 'team' && (
                            <TextArea
                                rows={6}
                                label={t('services:step_host_expertise_about_yourself:question3_textarea_label')}
                                placeholder={t(
                                    'services:step_host_expertise_about_yourself:question3_textarea_placeholder'
                                )}
                                errorMessage={form.formState.errors.co_hosts?.message}
                                {...form.register('co_hosts')}
                            />
                        )}*/}
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('about_yourself')}>
                <Button
                    variant="tertiary"
                    disabled={nextStepMutation?.isPending || prevStepMutation?.isPending || changeProfilePhotoIsPending}
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button
                        disabled={
                            nextStepMutation.isPending || prevStepMutation?.isPending || changeProfilePhotoIsPending
                        }
                        onClick={onSubmit}
                    >
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button
                        disabled={prevStepMutation?.isPending}
                        onClick={() =>
                            (service.host.profile_photo_url || user?.picture_url) &&
                            (service.host.airbnb_verified_phone || user?.phone)
                                ? setIsConfirmSubmitOpen(true)
                                : undefined
                        }
                    >
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'aboutYourself'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={{
                        ...form.getValues(),
                        links: form.getValues().links.map(link => link.value)
                    }}
                />
            )}
        </>
    )
}
