import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldTitle,
    Title,
    Footer,
    AlertText
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {getPrevNextStepMutations, getUpdatesRequiredSteps} from '@/features/host-submissions/services/utils.tsx'
import {Service} from '@/features/host-submissions/services/types.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {StyledStep} from '@/features/host-submissions/services/components/suggestions-step/style.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const SuggestionsStep = ({service}: {service: Service}) => {
    const {t} = useTranslation()
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const nextStepMutation = getPrevNextStepMutations({
        service,
        currentStep: 'suggestions',
        updatesRequiredSteps
    }).next?.()
    const flagsCount = {
        hostExpertise: [
            ...updatesRequiredSteps.career,
            ...updatesRequiredSteps.expertise,
            ...updatesRequiredSteps.aboutYourself
        ].flatMap(field => field.flags).length,
        serviceLocation: updatesRequiredSteps.serviceLocation.flatMap(field => field.flags).length,
        offerings: updatesRequiredSteps.offerings.flatMap(offering => offering.flatMap(field => field.flags)).length,
        gallery:
            updatesRequiredSteps.gallery.images.flatMap(field => field.flags).length +
            updatesRequiredSteps.gallery.flags.length,
        aboutGuests: updatesRequiredSteps.aboutGuests.flatMap(field => field.flags).length,
        moreDetails: updatesRequiredSteps.moreDetails.flatMap(field => field.flags).length
    }

    return (
        <>
            <Content>
                <HeadingGroup direction="column" gap={4}>
                    <Title>{t('services:step_suggestions:title')}</Title>
                    <Paragraph>{t('services:step_suggestions:paragraph')}</Paragraph>
                </HeadingGroup>
                <Flexbox direction="column">
                    {flagsCount.hostExpertise >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('services:about_you')}</FieldTitle>
                            <AlertText text={t('services:x_items_improve', {count: flagsCount.hostExpertise})} />
                        </StyledStep>
                    )}
                    {flagsCount.serviceLocation >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('services:step_service_location:title')}</FieldTitle>
                            <AlertText text={t('services:service_location_improvement_title')} />
                        </StyledStep>
                    )}
                    {flagsCount.offerings >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('services:step_offerings:title')}</FieldTitle>
                            <AlertText text={t('services:offerings_improvement_title')} />
                        </StyledStep>
                    )}
                    {flagsCount.gallery >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('services:step_gallery:title')}</FieldTitle>
                            <AlertText text={t('services:gallery_improvement_title')} />
                        </StyledStep>
                    )}
                    {flagsCount.aboutGuests >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('services:step_other_details_about_guests:title')}</FieldTitle>
                            <AlertText text={t('services:x_items_improve', {count: flagsCount.aboutGuests})} />
                        </StyledStep>
                    )}
                    {flagsCount.moreDetails >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('services:step_other_details_more_details:title')}</FieldTitle>
                            <AlertText text={t('services:x_items_improve', {count: flagsCount.moreDetails})} />
                        </StyledStep>
                    )}
                </Flexbox>
            </Content>
            <Footer>
                <Flexbox width={'100%'} justify={'center'}>
                    <Button
                        style={{maxWidth: 800}}
                        fullWidth
                        size="lg"
                        disabled={nextStepMutation?.isPending}
                        onClick={() => nextStepMutation?.mutate(undefined)}
                    >
                        {t('services:step_suggestions:cta')}
                        {nextStepMutation?.isPending && <Spinner />}
                    </Button>
                </Flexbox>
            </Footer>
        </>
    )
}
