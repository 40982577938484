import {FC} from 'react'
import {ExperienceExtended} from '../../types'
import {LegalFood, LegalFoodDrinkFormSchema} from './LegalFood'
import {LegalEntertainment, LegalEntertainmentFormSchema} from './LegalEntertainment'
import {LegalNatureFormSchema, NaturalLegal} from './NaturalLegal'
import {Footer, HeadingGroup, Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
import {useTranslation} from '@/translations/i18n'
import {getExperiencesStepProgressPercentage} from '../../utils'
import {Button} from '@/components/ui/button/Button'
import {Spinner} from '@/components/ui/spinner/Spinner'

export type LegalStepValidationSchema = LegalNatureFormSchema | LegalFoodDrinkFormSchema | LegalEntertainmentFormSchema

export const ExperienceLegalHeader = () => {
    const {t} = useTranslation()
    return (
        <HeadingGroup>
            <Title>{t('experiences:experience_legal_step:title')}</Title>
            <Paragraph>{t('experiences:experience_legal_step:description')}</Paragraph>
        </HeadingGroup>
    )
}

export const ExperienceLegalFooter: FC<{
    onSubmit: () => void
    onBack: () => void
    isBackLoading: boolean
    isContinueLoading: boolean
}> = ({isContinueLoading, isBackLoading, onBack, onSubmit}) => {
    const {t} = useTranslation()

    return (
        <Footer progressPercentage={getExperiencesStepProgressPercentage('legal')}>
            <Button variant="tertiary" disabled={isBackLoading || isContinueLoading} onClick={onBack}>
                {t('commons:back')}
                {isBackLoading && <Spinner />}
            </Button>

            <Button disabled={isBackLoading || isContinueLoading} onClick={onSubmit}>
                {t('commons:continue')}
                {isContinueLoading && <Spinner />}
            </Button>
        </Footer>
    )
}

export const ExperienceLegalStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const category = experience.category.category?.keyName

    if (category == 'food_drink') return <LegalFood experience={experience} />
    if (category == 'entertainment') return <LegalEntertainment experience={experience} />
    return <NaturalLegal experience={experience} />
}
