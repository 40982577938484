import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Content, Title, Footer} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {AnswerItem} from '@components/commons/answer-item/AnswerItem.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FC, useRef, useState} from 'react'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {
    Category,
    CategoryFormat,
    CategoryToMap,
    ExperienceExtended,
    StepKeyName
} from '@/features/host-submissions/experiences/types'
import {
    categoryFormatToLabel,
    categoryToCategoryFormats,
    categoryToIcon,
    categoryToLabel,
    getExperiencesStepProgressPercentage
} from '@/features/host-submissions/experiences/utils'
import {StyledList, StyledSubCategoryTitle} from './style'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useChangeStep} from '../../hooks/useChangeStep'

export const ExperienceCategoryStep: FC<{
    experience: ExperienceExtended
    experienceCategory: ExperienceExtended['category']
}> = ({experienceCategory, experience}) => {
    const {t} = useTranslation()
    const [isErrorCategory, setIsErrorCategory] = useState(false)
    const [isErrorSubCategory, setIsErrorSubCategory] = useState(false)
    const errorCategoryRef = useRef<HTMLDivElement>(null)
    const errorSubCategoryRef = useRef<HTMLDivElement>(null)

    const [selectedCategory, setSelectedCategory] = useState<Category | null>(
        experienceCategory.category?.keyName == 'generic' ? null : experienceCategory.category?.keyName ?? null
    )
    const [selectedSubCategory, setSelectedSubCategory] = useState<CategoryFormat | null>(
        experienceCategory.subCategory?.keyName ?? null
    )

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.home,
        currentStep: StepKeyName.enum.category,
        nextStep: StepKeyName.enum.about_you
    })
    const categoriesToShow =
        experienceCategory.category?.keyName == Category.enum.entertainment
            ? [...CategoryToMap.options, Category.enum.entertainment]
            : CategoryToMap.options
    return (
        <>
            <Content>
                <Title>{t('experiences:experience_category_step:title')}</Title>

                <Flexbox direction="column" gap={1.5}>
                    <StyledList>
                        {categoriesToShow.map(category => {
                            const Icon = categoryToIcon[category]
                            return (
                                <AnswerItem
                                    key={category}
                                    type="radio"
                                    name="category"
                                    checked={selectedCategory == category}
                                    value={category}
                                    label={
                                        <Flexbox direction="column" gap={2}>
                                            <Icon />
                                            {t(categoryToLabel[category])}
                                        </Flexbox>
                                    }
                                    onChange={() => {
                                        setIsErrorCategory(false)
                                        setSelectedCategory(category)
                                        setSelectedSubCategory(null)
                                    }}
                                />
                            )
                        })}
                    </StyledList>
                    <div ref={errorCategoryRef}>
                        {isErrorCategory && <InputHelpText error={t('commons:select_option')} />}
                    </div>
                </Flexbox>

                {selectedCategory && (
                    <Flexbox direction="column" gap={4} width="100%">
                        <StyledSubCategoryTitle>
                            {t('experiences:experience_category_step:sub_category_title')}
                        </StyledSubCategoryTitle>
                        <Flexbox direction="column" width="100%" gap={1.5}>
                            <StyledList>
                                {categoryToCategoryFormats[selectedCategory].map(categoryFormat => (
                                    <AnswerItem
                                        key={categoryFormat}
                                        type="radio"
                                        name="subCategory"
                                        checked={selectedSubCategory == categoryFormat}
                                        value={categoryFormat}
                                        label={
                                            <Flexbox direction="column" gap={2}>
                                                {t(categoryFormatToLabel[categoryFormat])}
                                            </Flexbox>
                                        }
                                        onChange={() => {
                                            setIsErrorSubCategory(false)
                                            setSelectedSubCategory(categoryFormat)
                                        }}
                                    />
                                ))}
                            </StyledList>
                            <div ref={errorSubCategoryRef}>
                                {isErrorSubCategory && <InputHelpText error={t('commons:select_option')} />}
                            </div>
                        </Flexbox>
                    </Flexbox>
                )}
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage(StepKeyName.enum.category)}>
                <Button
                    variant="tertiary"
                    onClick={() => changeStep.handleChangeStep(true)}
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                <Button
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => {
                        if (!selectedCategory) {
                            setIsErrorCategory(true)
                            errorCategoryRef.current?.scrollIntoView()
                        } else if (!selectedSubCategory) {
                            setIsErrorSubCategory(true)
                            errorSubCategoryRef.current?.scrollIntoView()
                        } else {
                            changeStep.handleChangeStep(false, {
                                categoryKeyName: selectedCategory,
                                subCategoryKeyName: selectedSubCategory
                            })
                        }
                    }}
                >
                    {t('commons:continue')}
                    {changeStep.isContinueLoading && <Spinner />}
                </Button>
            </Footer>
        </>
    )
}
