import Container from '@components/ui/container/Container.tsx'
import {Helmet} from 'react-helmet'
import {useTranslation} from '@/translations/i18n.tsx'
import {useTheme} from 'styled-components'
import {AirbnbSSOWidget} from '@/features/sso/components/airbnb-sso-widget/AirbnbSSOWidget'
import {CategoryIsland} from 'src/components/ui/icon'
import {routes} from '@/utilities/constants'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {Navigate} from 'react-router-dom'

export const CategoriesSsoLogin = () => {
    const {palette} = useTheme()
    const {t} = useTranslation()
    const accessToken = useAuthStore(store => store.accessToken)

    if (accessToken) {
        return <Navigate to={routes.CATEGORIES.path} replace />
    }

    return (
        <Container>
            <Helmet title={t('sso_login:page_title_meta')} />

            <AirbnbSSOWidget
                serviceIcon={<CategoryIsland size={64} fill={palette.neutral.black} />}
                title={t('categories:sso:title')}
                subtitle={t('categories:sso:subtitle')}
                redirectUrl={routes.CATEGORIES.path}
            />
        </Container>
    )
}

CategoriesSsoLogin.displayName = 'CategoriesSsoLogin'
