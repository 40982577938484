import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {httpDeclineAdditionalPhotos} from '@/features/host-submissions/services/http/services.http'
import {QUERY_KEYS} from '@/queryClient.ts'

interface UseDeletePhotos {
    serviceId: number
    mutationOptions?: Omit<UseMutationOptions<unknown, unknown, {images: number[]}>, 'mutationFn'>
}

export const useDeclineAdditionalPhotos = ({serviceId, mutationOptions}: UseDeletePhotos) => {
    const queryClient = useQueryClient()
    return useMutation<unknown, unknown, {images: number[]}>({
        mutationFn: ({images}: {images: number[]}) =>
            httpDeclineAdditionalPhotos({
                urlParams: {serviceId},
                params: {
                    response_type: 'extended',
                    images
                }
            }),
        ...mutationOptions,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICES_GALLERY, serviceId]})
            mutationOptions?.onSuccess?.(data, variables, context)
        }
    })
}
