import {Button} from '@components/ui/button/Button.tsx'
import {TKey, useTranslation} from '@/translations/i18n.tsx'
import {
    StyledOtpStepBody,
    StyledOtpStepForm,
    StyledOtpStepHeader,
    StyledOtpStepWrapper,
    StyledPhoneInputWrapper
} from '@/features/otp-flow/components/style.ts'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import {AcceptanceStepsConfigByService} from '@/features/service-acceptance-flow/utils.ts'
import {Controller, SubmitHandler, useForm} from 'react-hook-form'
import {Label} from '@components/ui/label/Label.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'
import {useNotificationPreferences} from '@/features/otp-flow/queries/useNotificationPreferences'

const SmsOtpFlowSchema = z.object({
    phone: z.string().min(10, {message: 'service_accept:sms:editing:phone_error'})
})
export type PhoneValidationSchema = z.infer<typeof SmsOtpFlowSchema>

export const OtpSmsEditStep = () => {
    const {t} = useTranslation()
    //otp store
    const service = useOtpStore(state => state.service)
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    const otpChangeStep = useOtpStore(state => state.changeStep)
    const isEditing = useOtpStore(state => state.isEditing)
    const notifyToggleStepActive = useOtpStore(state => state.notifyToggleStepActive)
    const onUpdateSendTo = useOtpStore(state => state.updateSendTo)

    //request code mutation
    const {mutate: requestVerificationCode, isPending} = useNotificationPreferences({
        onSuccess: () => {
            onUpdateSendTo(getValues().phone)
            otpChangeStep('pending_confirmation', true)
        },
        onError: error => errorHandler(error)
    })

    //form structure
    const {
        control,
        handleSubmit,
        getValues,
        formState: {errors, isValid}
    } = useForm<PhoneValidationSchema>({
        mode: 'onBlur',
        shouldFocusError: true,
        resolver: zodResolver(SmsOtpFlowSchema),
        defaultValues: {
            //phone: phone ?? '-'
        }
    })

    const onSubmit: SubmitHandler<PhoneValidationSchema> = data => {
        if (coAlarmRequest && coAlarmRequest?.notification_preferences) {
            requestVerificationCode({
                urlParams: {
                    notifySettingsId: coAlarmRequest.notification_preferences.id
                },
                params: {
                    phone: data.phone
                }
            })
        } else if (service && service.notification_preferences) {
            requestVerificationCode({
                urlParams: {
                    notifySettingsId: service.notification_preferences.id
                },
                params: {
                    phone: data.phone
                }
            })
        } else {
            toast.error('errors:default')
        }
    }

    return (
        <StyledOtpStepWrapper direction={'column'} justify={'stretch'} gap={1}>
            <StyledOtpStepHeader justify={'space-between'} align={'center'}>
                <h2>{t(AcceptanceStepsConfigByService['sms'].generic.editing.title)}</h2>
                {isEditing && (
                    <Button
                        variant={'tertiary'}
                        size={'md'}
                        onClick={() => otpChangeStep(notifyToggleStepActive ? 'channel_toggle' : 'base', false)}
                    >
                        {t('commons:cancel')}
                    </Button>
                )}
            </StyledOtpStepHeader>

            <StyledOtpStepBody direction={'column'} gap={6}>
                <p>{t(AcceptanceStepsConfigByService['sms'].generic.editing.subtitle)}</p>
                <StyledOtpStepForm onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        render={({field: {onChange, value}}) => (
                            <StyledPhoneInputWrapper>
                                <Label htmlFor={'phone'}>
                                    {t(AcceptanceStepsConfigByService['sms'].generic.editing.field_label)}
                                </Label>
                                <PhoneInput
                                    countryCodeEditable={false}
                                    enableAreaCodes={false}
                                    country={'us'}
                                    onlyCountries={['us', 'br', 'fr', 'es', 'it', 'mx', 'gb', 'co', 'ca', 'tr', 'au']}
                                    placeholder={t('service_accept:sms:editing:phone_placeholder')}
                                    value={value}
                                    onChange={onChange}
                                />
                                <InputHelpText
                                    /*TODO: remove as TKey*/
                                    error={errors.phone?.message ? t(errors.phone.message as TKey) : ''}
                                />
                            </StyledPhoneInputWrapper>
                        )}
                        control={control}
                        name={'phone'}
                    />
                    <Button type={'submit'} size={'sm'} variant={'secondary'} disabled={!isValid || isPending}>
                        {t('commons:submit')}
                        {isPending && <Spinner size={16} />}
                    </Button>
                </StyledOtpStepForm>
            </StyledOtpStepBody>
        </StyledOtpStepWrapper>
    )
}

OtpSmsEditStep.displayName = 'OtpSmsEditStep'
