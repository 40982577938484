import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledInputWrapper = styled(Flexbox)`
    ${() => css`
        & > div {
            width: 100%;
        }
    `}
`

export const StyledAddressAutocompleteItem = styled(Flexbox)<{$disabled: boolean}>`
    ${({theme: {spacing, palette}, $disabled}) => css`
        padding: ${spacing * 2.5}px;
        width: 100%;
        ${!$disabled &&
        css`
            &:hover {
                background: ${palette.neutral[100]};
                border-radius: 8px;
            }
        `}

        cursor: ${$disabled ? 'unset' : 'pointer'};
    `}
`

export const StyledSearchText = styled.span`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        text-decoration: underline;
        color: ${palette.neutral[700]};
        cursor: pointer;
    `}
`
