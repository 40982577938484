import {Modal} from '@components/ui/modal/Modal.tsx'
import {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalParagraph,
    ModalTitle,
    ModalXCloseButton
} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useSubmitExperience} from '../../queries/useSubmitExperience'
import {errorHandler} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'

interface ConfirmSubmitModalProps {
    onClose: () => void
    experienceId: number
}
export const ConfirmSubmitModal = ({onClose, experienceId}: ConfirmSubmitModalProps) => {
    const {t} = useTranslation()
    const submitExperienceMutation = useSubmitExperience({
        experienceId,
        options: {onError: errorHandler}
    })
    return (
        <Modal width={360}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <ModalBody gap={8}>
                <ModalTitle>{t('host_review:confirm_submit_modal_title')}</ModalTitle>
                <ModalParagraph>
                    <Trans
                        i18nKey={'host_review:confirm_submit_modal_paragraph'}
                        components={{
                            a: (
                                <a
                                    href={'https://www.airbnb.com/help/article/2908'}
                                    target="_blank"
                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                />
                            )
                        }}
                    />
                </ModalParagraph>
            </ModalBody>
            <ModalFooter justify="space-between">
                <Button variant="tertiary" onClick={onClose}>
                    {t('commons:close')}
                </Button>
                <Button
                    variant="primary"
                    disabled={submitExperienceMutation.isPending}
                    onClick={() => submitExperienceMutation.mutate(undefined)}
                >
                    {t('experiences:confirm_submit_modal:submit_for_review')}
                    {submitExperienceMutation.isPending && <Spinner />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
