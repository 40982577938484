import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {OtpSmsBaseStep} from '@/features/otp-flow/components/otp-sms-process/OtpSmsBaseStep.tsx'
import {OtpSmsEditStep} from '@/features/otp-flow/components/otp-sms-process/OtpSmsEditStep.tsx'
import {OtpPendingConfirmStep} from '@/features/otp-flow/components/otp-pending-confirmation-step/OtpPendingConfirmStep.tsx'
import {AcceptanceStepsConfigByService, maskPhoneNumber} from '@/features/service-acceptance-flow/utils.ts'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {StyledSendAgainLink} from '@/features/otp-flow/components/style.ts'
import {errorHandler} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {OtpChannelToggleStep} from '@/features/otp-flow/components/otp-channel-toggle-step/OtpChannelToggleStep.tsx'
import {useNotificationPreferences} from '@/features/otp-flow/queries/useNotificationPreferences'

export const OtpSmsProcess = () => {
    const {t} = useTranslation()
    //OTP params store
    const service = useOtpStore(state => state.service)
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    const otpSmsActiveStep = useOtpStore(state => state.step)
    const sendTo = useOtpStore(state => state.sendTo)
    //code verification request mutation
    const {mutate: requestVerificationCode, isPending} = useNotificationPreferences({
        onSuccess: () => {
            toast.success(t('service_accept:common:code_resent_phone'), {duration: 2500})
        },
        onError: error => errorHandler(error)
    })

    const onResendOptCodeRequest = () => {
        if (coAlarmRequest && coAlarmRequest?.notification_preferences) {
            requestVerificationCode({
                urlParams: {
                    notifySettingsId: coAlarmRequest.notification_preferences.id
                },
                params: {
                    phone: sendTo
                }
            })
        } else if (service && service.notification_preferences) {
            requestVerificationCode({
                urlParams: {
                    notifySettingsId: service.notification_preferences.id
                },
                params: {
                    phone: sendTo
                }
            })
        } else {
            toast.error('errors:default')
        }
    }

    return (
        <>
            {otpSmsActiveStep == 'channel_toggle' && <OtpChannelToggleStep />}
            {otpSmsActiveStep == 'base' && <OtpSmsBaseStep />}
            {otpSmsActiveStep == 'edit' && <OtpSmsEditStep />}
            {otpSmsActiveStep == 'pending_confirmation' && (
                <OtpPendingConfirmStep
                    type={'sms'}
                    title={t(AcceptanceStepsConfigByService['sms'].generic.pending_confirm.title)}
                    subtitle={t(AcceptanceStepsConfigByService['sms'].generic.pending_confirm.subtitle, {
                        phone: maskPhoneNumber(sendTo)
                    })}
                    helpText={
                        <Flexbox align={'center'} gap={1}>
                            <Trans
                                i18nKey={AcceptanceStepsConfigByService['sms'].generic.pending_confirm.field_help}
                                components={[
                                    <StyledSendAgainLink
                                        isPending={isPending}
                                        onClick={() => onResendOptCodeRequest()}
                                    />
                                ]}
                            />
                            {isPending && <Spinner size={16} />}
                        </Flexbox>
                    }
                />
            )}
        </>
    )
}

OtpSmsProcess.displayName = 'OtpSmsProcess'
