import {useGalleryUpload} from '@/features/host-submissions/services/store/servicesGallery.ts'
import {
    StyledGalleryActionButton,
    StyledGalleryItemContainer,
    StyledGalleryItemImg,
    StyledGalleryItemLoaderContainer
} from '@/features/host-submissions/services/components/gallery-step-item/style.ts'
import {AlertTriangleIcon, RefreshCcw02Icon, Trash01Icon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {FC} from 'react'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {
    BulkPresignedPhotosResponse,
    useBulkPresignedPhotos
} from '@/features/host-submissions/services/queries/useBulkPresignedPhotos'
import {GalleryItem} from '@/features/host-submissions/types'
import {useDeletePhotos} from '@/features/host-submissions/services/queries/useDeletePhotos'
import {CircularProgressBar} from '@/features/experiences-host-video/components/circular-progress-bar/CircularProgressBar.tsx'
import {FlagsAlertText} from '../../../components/flags-alert-text/FlagsAlertText'

interface GalleryProps {
    galleryItem: GalleryItem
    serviceId: number
    onSuccessRetry: (data: BulkPresignedPhotosResponse) => void
}
export const GalleryStepItem: FC<GalleryProps> = ({onSuccessRetry, serviceId, galleryItem}) => {
    const {t} = useTranslation()
    const removeGalleryItem = useGalleryUpload(store => store.removeGalleryItem)
    const setAcceptedFiles = useGalleryUpload(store => store.setAcceptedFiles)
    const uploadProgressValues = useGalleryUpload(store => store.uploadProgressValues)
    const deletePhoto = useDeletePhotos({
        mutationOptions: {
            onSuccess: (_, variables) => {
                removeGalleryItem(variables.mediaId)
            }
        }
    })

    const bulkPresignedRetryQuery = useBulkPresignedPhotos({
        urlParams: {serviceId: serviceId},
        options: {onSuccess: onSuccessRetry}
    })

    const onDelete = () => {
        deletePhoto.mutate({mediaId: galleryItem.id, serviceId})
    }

    const onRetry = async () => {
        if (galleryItem.blob) {
            setAcceptedFiles([galleryItem.blob])
            await bulkPresignedRetryQuery.mutateAsync({medias: [galleryItem.blob.name], is_offering_image: false})
            removeGalleryItem(galleryItem.id)
        }
    }

    return (
        <Flexbox direction="column" gap={2} width="100%" height="100%">
            <StyledGalleryItemContainer>
                <StyledGalleryItemImg
                    src={galleryItem.src}
                    alt={'img'}
                    onLoad={() => galleryItem.src && URL.revokeObjectURL(galleryItem.src)}
                />
                {galleryItem.status == 'error' ? (
                    <>
                        <StyledGalleryActionButton
                            disabled={bulkPresignedRetryQuery.isPending}
                            variant={'ghost'}
                            shape={'square'}
                            onClick={onRetry}
                        >
                            {bulkPresignedRetryQuery.isPending ? <Spinner /> : <RefreshCcw02Icon />}
                        </StyledGalleryActionButton>
                        <StyledGalleryItemLoaderContainer>
                            <Flexbox direction={'column'} gap={2} align={'center'} style={{textAlign: 'center'}}>
                                <AlertTriangleIcon />
                                {t('errors:default')}
                            </Flexbox>
                        </StyledGalleryItemLoaderContainer>
                    </>
                ) : galleryItem.status == 'pending' ? (
                    <StyledGalleryItemLoaderContainer>
                        <CircularProgressBar progress={uploadProgressValues[galleryItem.id] || 0} />
                    </StyledGalleryItemLoaderContainer>
                ) : (
                    <StyledGalleryActionButton
                        disabled={deletePhoto.isPending}
                        variant={'ghost'}
                        shape={'square'}
                        onClick={onDelete}
                    >
                        {deletePhoto.isPending ? <Spinner /> : <Trash01Icon />}
                    </StyledGalleryActionButton>
                )}
            </StyledGalleryItemContainer>

            <FlagsAlertText flags={galleryItem?.flags} title={t('commons:photo')} />
        </Flexbox>
    )
}
