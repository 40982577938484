import {handleCloseSlidingModal, SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {z} from 'zod'
import {
    StyledForm,
    StyledFormContent,
    StyledModalHeader,
    StyledHeaderTitle,
    StyledSlidingModal
} from '@/features/host-submissions/experiences/components/activity-form-modal/style'
import {Button} from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon'
import i18n, {useTranslation} from '@/translations/i18n'
import {ModalBody, ModalFooter} from '@/components/ui/modal-atoms/ModalAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import InputText from '@/components/commons/input-text/InputText'
import {zodResolver} from '@hookform/resolvers/zod'
import TextArea from '@/components/commons/textarea/TextArea'
import {Divider} from '@/components/ui/divider/Divider'
import {Spinner} from '@components/ui/spinner/Spinner'
import toast from 'react-hot-toast'
import {useState} from 'react'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Activity, ExperienceExtended, UploadedMedia} from '../../types'
import {useAddActivity} from '../../queries/useAddActivity'
import {ActivityDurationSelector} from '../activity-duration-selector/ActivityDurationSelector'
import {ActivityPhotoUploader} from '../activity-photo-uploader/ActivityPhotoUploader'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {useUpdateActivity} from '../../queries/useUpdateActivity'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'

// limits
export const ACTIVITY_TITLE_MAX_LENGTH = 50
export const ACTIVITY_DESCRIPTION_MAX_LENGTH = 200

// types
const ActivityFormValidationSchema = z.object({
    title: z
        .string({required_error: i18n.t('errors:field_required')})
        .min(1, {message: i18n.t('errors:field_required')}),
    description: z.string({required_error: i18n.t('errors:field_required')}).min(1, {
        message: i18n.t('errors:field_required')
    }),
    duration: z.string({required_error: i18n.t('errors:field_required')}),
    main_photo: UploadedMedia.extend({
        url: z
            .string({required_error: i18n.t('errors:field_required')})
            .min(1, {message: i18n.t('errors:field_required')})
    }).refine(data => !!data?.url, {
        message: i18n.t('errors:field_required'),
        path: ['url']
    })
})

export type ActivityFormValidationSchema = z.infer<typeof ActivityFormValidationSchema>

interface ActivityFormModalProps {
    experienceId: ExperienceExtended['id']
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
    slidingModalState: SlidingModalState
    gallery: UploadedMedia[]
    defaultValues?: Partial<Activity>
    galleryId: number
    activityPosition: number
}

export const ActivityFormModal = ({
    experienceId,
    setSlidingModalState,
    slidingModalState,
    gallery,
    defaultValues,
    galleryId,
    activityPosition
}: ActivityFormModalProps) => {
    const {t} = useTranslation()
    const uploadStatusState = useState<'idle' | 'pending' | 'success' | 'error'>('idle')
    const titleFlags = defaultValues?.answers?.find(field => field.questionKeyName == 'title')?.parentFlags
    const descriptionFlags = defaultValues?.answers?.find(field => field.questionKeyName == 'description')?.parentFlags
    const durationFlags = defaultValues?.answers?.find(field => field.questionKeyName == 'duration')?.parentFlags
    // methods
    const form = useForm<ActivityFormValidationSchema>({
        resolver: zodResolver(ActivityFormValidationSchema),
        defaultValues: defaultValues
            ? {
                  duration: defaultValues.durationInMinutes ? String(defaultValues.durationInMinutes) : undefined,
                  title: defaultValues.title ?? '',
                  description: defaultValues.description ?? '',
                  main_photo: {
                      url: defaultValues?.coverImage?.url ?? '',
                      id: defaultValues?.coverImage?.id ?? undefined
                  }
              }
            : {main_photo: {url: ''}}
    })

    const addActivityMutation = useAddActivity(experienceId, {
        onSuccess: () => {
            handleCloseSlidingModal(setSlidingModalState)
        },
        onError: () => {
            handleCloseSlidingModal(setSlidingModalState)
            toast.error(t('errors:default'))
        }
    })

    const updateActivityMutation = useUpdateActivity({
        experienceId,
        activityId: defaultValues?.id,
        options: {
            onSuccess: () => {
                void queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
                handleCloseSlidingModal(setSlidingModalState)
            },
            onError: () => toast.error(t('errors:default'))
        }
    })

    // observer
    const title = useWatch({control: form.control, name: 'title'})
    const description = useWatch({control: form.control, name: 'description'})

    // actions
    const handleSubmit = form.handleSubmit(formValues => {
        const payload = {
            title: formValues.title,
            description: formValues.description,
            durationInMinutes: formValues.duration.toString(),
            coverImageId: formValues.main_photo.id,
            position: activityPosition
        }

        return defaultValues ? updateActivityMutation.mutate(payload) : addActivityMutation.mutate(payload)
    })

    return (
        <StyledSlidingModal slidingModalState={slidingModalState}>
            <StyledModalHeader>
                <Button variant="ghost" shape="square" onClick={() => handleCloseSlidingModal(setSlidingModalState)}>
                    <XCloseIcon size={36} />
                </Button>
                <StyledHeaderTitle>{t('experiences:experience_itinerary_step:activity')}</StyledHeaderTitle>
            </StyledModalHeader>
            <ModalBody>
                <StyledForm
                    onSubmit={e => {
                        e.preventDefault()
                    }}
                >
                    <Flexbox direction="column" gap={4} width="100%">
                        <StyledFormContent direction="column" gap={2} width="100%">
                            <InputText
                                type="text"
                                label={t('experiences:experience_itinerary_step:create_activity_modal:title_label')}
                                placeholder={t(
                                    'experiences:experience_itinerary_step:create_activity_modal:title_placeholder'
                                )}
                                maxLength={ACTIVITY_TITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: ACTIVITY_TITLE_MAX_LENGTH - (title?.length ?? 0)
                                })}
                                errorMessage={form.formState.errors.title?.message}
                                {...form.register('title')}
                            />
                            <FlagsAlertText
                                flags={titleFlags}
                                title={t('experiences:experience_itinerary_step:create_activity_modal:title_label')}
                                detailLevel="offering_title"
                            />
                        </StyledFormContent>

                        <StyledFormContent direction="column" gap={2} width="100%">
                            <TextArea
                                rows={3}
                                label={t(
                                    'experiences:experience_itinerary_step:create_activity_modal:label_description'
                                )}
                                placeholder={t(
                                    'experiences:experience_itinerary_step:create_activity_modal:description_placeholder'
                                )}
                                maxLength={ACTIVITY_DESCRIPTION_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: ACTIVITY_DESCRIPTION_MAX_LENGTH - (description?.length ?? 0)
                                })}
                                errorMessage={form.formState.errors.description?.message}
                                {...form.register('description')}
                            />
                            <FlagsAlertText
                                flags={descriptionFlags}
                                title={t(
                                    'experiences:experience_itinerary_step:create_activity_modal:label_description'
                                )}
                            />
                        </StyledFormContent>

                        <StyledFormContent direction="column" gap={2} width="100%">
                            <Controller
                                control={form.control}
                                name="duration"
                                render={({field}) => (
                                    <ActivityDurationSelector
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={form.formState.errors.duration?.message}
                                        ref={field.ref}
                                    />
                                )}
                            />

                            <FlagsAlertText
                                flags={durationFlags}
                                title={t('experiences:experience_itinerary_step:create_activity_modal:duration_label')}
                            />
                        </StyledFormContent>

                        <Divider direction="horizontal" endSpacing={2} startSpacing={2} />
                        <Flexbox direction="column" gap={3} width="100%">
                            <Controller
                                control={form.control}
                                name="main_photo"
                                render={({field}) => (
                                    <ActivityPhotoUploader
                                        experienceId={experienceId}
                                        value={field.value}
                                        onChange={field.onChange}
                                        uploadStatusState={uploadStatusState}
                                        gallery={gallery}
                                        galleryId={galleryId}
                                    />
                                )}
                            />
                            <InputHelpText error={form.formState.errors.main_photo?.url?.message} />
                        </Flexbox>
                    </Flexbox>
                </StyledForm>
            </ModalBody>
            <ModalFooter>
                <Flexbox align="center" justify="space-between" width="100%">
                    <Button variant="tertiary" onClick={() => handleCloseSlidingModal(setSlidingModalState)}>
                        {t('commons:cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={
                            addActivityMutation.isPending ||
                            updateActivityMutation.isPending ||
                            uploadStatusState[0] == 'pending'
                        }
                    >
                        {t('commons:confirm')}
                        {(addActivityMutation.isPending || updateActivityMutation.isPending) && <Spinner />}
                    </Button>
                </Flexbox>
            </ModalFooter>
        </StyledSlidingModal>
    )
}
