import {useState} from 'react'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import toast from 'react-hot-toast'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Toggle} from '@components/ui/toggle/Toggle.tsx'
import {NotifyChannels} from '@/features/service-acceptance-flow/types.ts'
import {StyledToggleWrapper} from '@/features/otp-flow/components/otp-channel-toggle-step/style.ts'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {maskPhoneNumber} from '@/features/service-acceptance-flow/utils.ts'
import {useNotificationPreferences} from '@/features/otp-flow/queries/useNotificationPreferences'
import {getNotificationPreferences} from '@/features/otp-flow/utils'
import {User} from '@/features/sso/types'
import {Host} from '@/features/service/types'

export const OtpChannelToggleStep = () => {
    const {t} = useTranslation()
    //accept store
    const updateServiceNotifyConsent = useServiceAcceptStore(state => state.updateServiceNotifyConsent)
    //otp store
    const changeOtpStep = useOtpStore(state => state.changeStep)
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    const service = useOtpStore(state => state.service)
    const currentHost = useOtpStore(state => state.currentHost)
    //auth store
    const user = useAuthStore(state => state.user)
    //email and phone to show
    const {phone, email} = getNotificationPreferences({
        servicePreferences: service?.notification_preferences,
        coAlarmPreferences: coAlarmRequest?.notification_preferences,
        user: user as User,
        currentHost: currentHost as Host
    })
    //state
    const consentInitialState = coAlarmRequest?.notification_preferences?.send_phone_notification
        ? coAlarmRequest?.notification_preferences?.send_phone_notification
        : service?.notification_preferences?.send_phone_notification
    const [smsConsent, setSmsConsent] = useState(consentInitialState ?? false)

    //notification accept toggle
    const {mutate: changeNotificationPermission, isPending} = useNotificationPreferences({
        onSuccess: () => {
            toast.success(
                smsConsent
                    ? t('service_details:text_notifications_modal:notification_enabled')
                    : t('service_details:text_notifications_modal:notification_disabled'),
                {duration: 4500}
            )
            updateServiceNotifyConsent('sms', smsConsent)
        },
        onError: error => errorHandler(error)
    })

    const onChangePermissionToggle = (value: boolean, type: NotifyChannels) => {
        if (coAlarmRequest && coAlarmRequest?.notification_preferences) {
            changeNotificationPermission({
                urlParams: {
                    notifySettingsId: coAlarmRequest.notification_preferences.id
                },
                params: {
                    ...(type == 'sms' ? {send_phone_notification: value} : {send_email_notification: value})
                }
            })
            setSmsConsent(value)
        } else if (service && service.notification_preferences) {
            changeNotificationPermission({
                urlParams: {
                    notifySettingsId: service.notification_preferences.id
                },
                params: {
                    ...(type == 'sms' ? {send_phone_notification: value} : {send_email_notification: value})
                }
            })
            setSmsConsent(value)
        } else {
            toast.error('errors:default')
        }
    }

    return (
        <Flexbox direction={'column'} gap={4} width={'100%'}>
            <StyledToggleWrapper justify={'space-between'} gap={4} width={'100%'} align={'center'}>
                <Flexbox direction={'column'} gap={1}>
                    <h2>{t('commons:sms')}</h2>
                    {phone && (
                        <small onClick={() => changeOtpStep('edit', true)}>
                            {t('commons:phone')}: +{maskPhoneNumber(phone)}
                            <span
                                role="button"
                                aria-label={t('service_details:text_notifications_modal:edit_phone_number')}
                            >
                                {t('commons:edit')}
                            </span>
                        </small>
                    )}
                    {!phone && (
                        <small onClick={() => changeOtpStep('edit', true)}>
                            <span
                                role="button"
                                aria-label={t('service_details:text_notifications_modal:add_phone_number')}
                            >
                                {t('service_details:text_notifications_modal:add_phone_number')}
                            </span>
                        </small>
                    )}
                </Flexbox>
                <Toggle
                    value={smsConsent}
                    disabled={isPending}
                    role="switch"
                    aria-checked={smsConsent}
                    aria-label={t('service_details:text_notifications_modal:switch_label')}
                    onChange={toggled => onChangePermissionToggle(toggled, 'sms')}
                />
            </StyledToggleWrapper>
            <StyledToggleWrapper justify={'space-between'} gap={4} width={'100%'} align={'center'}>
                <Flexbox direction={'column'} gap={1}>
                    <h2>{t('commons:email')}</h2>
                    <small>{email}</small>
                </Flexbox>
            </StyledToggleWrapper>
        </Flexbox>
    )
}

OtpChannelToggleStep.displayName = 'OtpChannelToggleStep'
