import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    FieldTitle,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useLayoutEffect, useState} from 'react'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '@/features/host-submissions/experiences/utils'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {z} from 'zod'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {ExperienceExtended, StepKeyName} from '../../types'
import {StyledAddressAutocompleteItem, StyledInputWrapper, StyledSearchText} from './style'
import {debounce} from '@/utilities/helpers'
import {useAsync} from '@/hooks/useAsync'
import useAddressGeocoding from '@/hooks/useAddressGeocoding'
import InputText from '@/components/commons/input-text/InputText'
import {Edit03Icon, MarkerPin01Icon, SearchLgIcon} from '@/components/ui/icon'
import {useTheme} from 'styled-components'
import Skeleton from '@/components/ui/skeleton/Skeleton'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import TextArea from '@/components/commons/textarea/TextArea'
import {Divider} from '@/components/ui/divider/Divider'
import {retrieveAddressDataFromComponents} from '@/features/host-submissions/services/utils'
import {CountrySelect} from '../country-select/CountrySelect'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {InputContent} from '../atoms/Atoms'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {Coordinates} from '@/features/host-submissions/services/types'

const DESCRIPTION_MAX_LENGTH = 300

export const LocationStepValidationSchema = z.object({
    location_address: z.string().min(1, {message: i18n.t('errors:field_required')}),
    location_city: z.string().min(1, {message: i18n.t('errors:field_required')}),
    country_region: z.string().min(1, {message: i18n.t('experiences:select_country')}),
    location_zip: z.string().nullable(),
    location_state: z.string().min(1, {message: i18n.t('errors:field_required')}),
    location_address_extras: z.string().optional(),
    meeting_point_name: z.string().min(1, {message: i18n.t('errors:field_required')}),
    meeting_point_description: z.string().min(1, {message: i18n.t('errors:field_required')}),
    location_latitude: z.string().nullable().optional(),
    location_longitude: z.string().nullable().optional()
})
export type LocationStepValidationSchema = z.infer<typeof LocationStepValidationSchema>

export const ExperienceLocationStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const [isAddManuallyMode, setIsAddManuallyMode] = useState(false)
    const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[] | null>(null)
    const searchPlace = useAsync()
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)
    const flagsCount = updatesRequiredSteps.location.flatMap(field => field.flags).length
    const meetingPointTitleFlags = updatesRequiredSteps.location.find(
        field => field.questionKeyName == 'meeting_point_name'
    )?.flags
    const meetingPointDescriptionFlags = updatesRequiredSteps.location.find(
        field => field.questionKeyName == 'meeting_point_description'
    )?.flags

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.about_you_more_info,
        currentStep: StepKeyName.enum.location,
        nextStep: StepKeyName.enum.gallery,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })

    const defaultValues = makeExperiencesFormDefaultValues(experience).location

    const form = useForm<LocationStepValidationSchema>({
        resolver: zodResolver(LocationStepValidationSchema),
        defaultValues
    })
    useLayoutEffect(() => {
        if (
            defaultValues &&
            (defaultValues.location_address ||
                defaultValues.location_city ||
                defaultValues.country_region ||
                defaultValues.location_zip ||
                defaultValues.location_state ||
                defaultValues.location_address_extras)
        ) {
            setIsAddManuallyMode(true)
        }
    }, [])

    const [meetingPointDescriptionWatch] = useWatch({
        control: form.control,
        name: ['meeting_point_description']
    })
    // actions
    const onLatLngFound = (coordinates: Coordinates) => {
        form.clearErrors(['location_address', 'location_city', 'country_region', 'location_state', 'location_zip'])
        // Store latitude and longitude
        form.setValue('location_latitude', coordinates.latitude)
        form.setValue('location_longitude', coordinates.longitude)
    }

    const onChangeAddress = (value: google.maps.GeocoderResult | null) => {
        if (!value) return
        const {city, country, zip_code, state} = retrieveAddressDataFromComponents(value.address_components)

        form.setValue('location_address', value.formatted_address, {shouldValidate: true})
        form.setValue('location_city', city)
        form.setValue('country_region', country)
        form.setValue('location_state', state)
        form.setValue('location_zip', zip_code)

        // Store latitude and longitude if geometry is available
        if (value.geometry && value.geometry.location) {
            form.setValue('location_latitude', value.geometry.location.lat().toString())
            form.setValue('location_longitude', value.geometry.location.lng().toString())
        }

        setIsAddManuallyMode(true)
    }

    const searchPlaces = debounce(async (value: string) => {
        if (!value) {
            setPredictions(null)
            return
        }

        try {
            const autocomplete = new google.maps.places.AutocompleteService()
            const result = await searchPlace.run(
                autocomplete.getPlacePredictions({
                    input: value,
                    types: ['address']
                })
            )
            setPredictions(result?.predictions ?? null)
        } catch (err) {
            console.error(err)
        }
    }, 750)

    //Geocoder's logic and utils
    const {retrieveLatLng, retrieveStructured} = useAddressGeocoding({
        onLatLngFound,
        onStructuredAddressFound: value => {
            onChangeAddress(value)
        }
    })

    // On select value retrieve all the geocoding data
    const handleSelectAddress = async (placeId: string) => {
        if (!predictions) return
        const address = predictions.find(prediction => prediction.place_id == placeId)?.description
        if (address) {
            await retrieveStructured(placeId)
            await retrieveLatLng(address)
        }
    }

    const onSubmit = form.handleSubmit(data => {
        changeStep.handleChangeStep(false, data)
    })

    return (
        <>
            <Content>
                <Title>{t('experiences:experience_location_step:title')}</Title>
                {isAddManuallyMode ? (
                    <Flexbox gap={6} direction="column" width="100%">
                        <Flexbox gap={1.5} direction="column" width="100%">
                            <FieldTitle>{t('experiences:experience_location_step:confirm_your_address')}</FieldTitle>
                            <StyledSearchText onClick={() => setIsAddManuallyMode(false)}>
                                {t('experiences:experience_location_step:search_address')}
                            </StyledSearchText>

                            {flagsCount >= 1 && (
                                <AlertBanner
                                    title={t('commons:x_items_improve', {count: flagsCount})}
                                    paragraph={t('experiences:update_required_paragraph')}
                                />
                            )}
                        </Flexbox>

                        <StyledInputWrapper direction="column" gap={4} width="100%">
                            <Controller
                                control={form.control}
                                name="country_region"
                                render={({field, fieldState}) => (
                                    <CountrySelect
                                        value={field.value}
                                        onChange={value => field.onChange(value)}
                                        errorMessage={fieldState.error?.message}
                                    />
                                )}
                            />

                            <InputText
                                type={'text'}
                                label={t('experiences:street_address')}
                                errorMessage={form.formState.errors.location_address?.message}
                                {...form.register('location_address')}
                            />

                            <InputText
                                type={'text'}
                                label={t('experiences:apartment')}
                                errorMessage={form.formState.errors.location_address_extras?.message}
                                {...form.register('location_address_extras')}
                            />

                            <InputText
                                type={'text'}
                                label={t('experiences:city')}
                                errorMessage={form.formState.errors.location_city?.message}
                                {...form.register('location_city')}
                            />

                            <InputText
                                type={'text'}
                                label={t('experiences:state')}
                                errorMessage={form.formState.errors.location_state?.message}
                                {...form.register('location_state')}
                            />

                            <InputText
                                type={'text'}
                                label={t('experiences:zip_code')}
                                errorMessage={form.formState.errors.location_zip?.message}
                                {...form.register('location_zip')}
                            />

                            <Divider direction="horizontal" startSpacing={2} endSpacing={2} />
                            <InputContent direction="column" gap={1.5}>
                                <InputText
                                    type={'text'}
                                    label={t('experiences:experience_location_step:location_label')}
                                    errorMessage={form.formState.errors.meeting_point_name?.message}
                                    {...form.register('meeting_point_name')}
                                />
                                <FlagsAlertText
                                    flags={meetingPointTitleFlags}
                                    title={t('experiences:experience_location_step:location_label')}
                                />
                            </InputContent>

                            <InputContent direction="column" gap={1.5}>
                                <TextArea
                                    rows={6}
                                    label={t('experiences:experience_location_step:location_description')}
                                    errorMessage={form.formState.errors.meeting_point_description?.message}
                                    maxLength={DESCRIPTION_MAX_LENGTH}
                                    helpText={t('commons:x_characters_available', {
                                        count: DESCRIPTION_MAX_LENGTH - meetingPointDescriptionWatch.length
                                    })}
                                    {...form.register('meeting_point_description')}
                                />
                                <FlagsAlertText
                                    flags={meetingPointDescriptionFlags}
                                    title={t('experiences:experience_location_step:location_description')}
                                />
                            </InputContent>
                        </StyledInputWrapper>
                    </Flexbox>
                ) : (
                    <StyledInputWrapper direction="column" gap={4} width="100%">
                        <InputText
                            type={'text'}
                            typeIcon={<SearchLgIcon fill={palette.neutral[500]} size={20} />}
                            placeholder={t('experiences:experience_location_step:enter_address')}
                            errorMessage={
                                form.formState.errors.location_address?.message
                                    ? t('services:step_service_location:search_address_warning')
                                    : ''
                            }
                            onChange={e => searchPlaces(e.target.value)}
                        />

                        <Flexbox gap={1} direction="column" width="100%">
                            {searchPlace.isLoading ? (
                                <StyledAddressAutocompleteItem $disabled>
                                    <Flexbox direction="column" gap={1}>
                                        <Skeleton height={40} width="100%" />
                                        <Skeleton height={40} width="80%" />
                                    </Flexbox>
                                </StyledAddressAutocompleteItem>
                            ) : searchPlace.isError ? (
                                <StyledAddressAutocompleteItem $disabled>
                                    <InputHelpText error={t('errors:default')} />
                                </StyledAddressAutocompleteItem>
                            ) : predictions && predictions?.length > 0 ? (
                                predictions.map(prediction => (
                                    <StyledAddressAutocompleteItem
                                        onClick={() => handleSelectAddress(prediction.place_id)}
                                        key={prediction.place_id}
                                        $disabled={false}
                                    >
                                        <Flexbox gap={2} align="center">
                                            <MarkerPin01Icon color={palette.neutral['500']} size={20} />
                                            <span style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                                {prediction.description}
                                            </span>
                                        </Flexbox>
                                    </StyledAddressAutocompleteItem>
                                ))
                            ) : (
                                <StyledAddressAutocompleteItem $disabled>
                                    <Flexbox
                                        gap={2}
                                        align="center"
                                        style={{cursor: 'pointer'}}
                                        onClick={() => setIsAddManuallyMode(true)}
                                    >
                                        <Edit03Icon color={palette.neutral['500']} size={20} />
                                        <span>{t('experiences:experience_location_step:add_manually')}</span>
                                    </Flexbox>
                                </StyledAddressAutocompleteItem>
                            )}
                        </Flexbox>
                    </StyledInputWrapper>
                )}
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('location')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                {changeStep.hasNextStep ? (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button onClick={onSubmit}>{t('commons:confirm')}</Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
