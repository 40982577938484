import {Button} from '@components/ui/button/Button.tsx'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    HeadingGroup,
    Paragraph,
    FieldTitle,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {
    AccessibilityHumanIcon,
    AccessibilityWideEntranceIcon,
    GlassesIcon,
    VolunteerIcon,
    NoFlashIcon,
    NoStairsIcon,
    PerkingIcon,
    ToiletIcon,
    YogaIcon
} from '@/components/ui/icon'
import {z} from 'zod'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {
    StyledCheckboxLabel,
    StyledHelpText
} from '@/features/host-submissions/experiences/components/experience-accessibility-step/style'
import {ExperienceExtended, FormFieldKeyName, StepKeyName} from '../../types'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '../../utils'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ObjectKeys} from '@/utilities/helpers'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'
import TextArea from '@/components/commons/textarea/TextArea'
import {StyledInputContent} from '../experience-about-yourself-step/style'

const ValidationSchema = z.object({
    sign_language_options: z.boolean().default(false),
    designated_sighted_guide: z.boolean().default(false),
    step_free_access: z.boolean().default(false),
    step_free_access_bathroom: z.boolean().default(false),
    disabled_parking_spot: z.boolean().default(false),
    entrances_wider_32_inch: z.boolean().default(false),
    mainly_flat_leveled_ground: z.boolean().default(false),
    no_extreme_sensory_stimuli: z.boolean().default(false),
    quiet_retreat_space_available: z.boolean().default(false),
    free_admission_access_providers: z.boolean().default(false),
    accessibility_additional_information: z.string().optional(),
    accessibility_additional_qualifications: z.string().optional()
})

export type ValidationSchema = z.infer<typeof ValidationSchema>

export const ExperienceAccessibilityStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)
    const flagsCount = updatesRequiredSteps.accessibility.flatMap(field => field.flags).length
    const accessibilityFlags = updatesRequiredSteps.accessibility.find(
        field => field.questionKeyName == 'accessibility_features'
    )?.flags
    const additionalInformationFlags = updatesRequiredSteps.accessibility.find(
        field => field.questionKeyName == 'accessibility_additional_information'
    )?.flags

    const additionalQualificationsFlags = updatesRequiredSteps.accessibility.find(
        field => field.questionKeyName == 'accessibility_additional_qualifications'
    )?.flags
    const isUpgrade = experience?.isUpgrade
    const changeStep = useChangeStep({
        experience,
        previousStep: isUpgrade ? StepKeyName.enum.itinerary : StepKeyName.enum.guests,
        currentStep: StepKeyName.enum.accessibility,
        nextStep: StepKeyName.enum.legal,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })
    const defaultFormValues = makeExperiencesFormDefaultValues(experience).accessibility
    const accessibilityFeatures = defaultFormValues?.accessibility_features ?? []

    const form = useForm<ValidationSchema>({
        resolver: zodResolver(ValidationSchema),
        reValidateMode: 'onChange',
        defaultValues: {
            [FormFieldKeyName.enum.sign_language_options]: accessibilityFeatures.includes('sign_language_options'),
            [FormFieldKeyName.enum.designated_sighted_guide]:
                accessibilityFeatures.includes('designated_sighted_guide'),
            [FormFieldKeyName.enum.step_free_access]: accessibilityFeatures.includes('step_free_access'),
            [FormFieldKeyName.enum.step_free_access_bathroom]:
                accessibilityFeatures.includes('step_free_access_bathroom'),
            [FormFieldKeyName.enum.disabled_parking_spot]: accessibilityFeatures.includes('disabled_parking_spot'),
            [FormFieldKeyName.enum.entrances_wider_32_inch]: accessibilityFeatures.includes('entrances_wider_32_inch'),
            [FormFieldKeyName.enum.mainly_flat_leveled_ground]:
                accessibilityFeatures.includes('mainly_flat_leveled_ground'),
            [FormFieldKeyName.enum.no_extreme_sensory_stimuli]:
                accessibilityFeatures.includes('no_extreme_sensory_stimuli'),
            [FormFieldKeyName.enum.quiet_retreat_space_available]: accessibilityFeatures.includes(
                'quiet_retreat_space_available'
            ),
            [FormFieldKeyName.enum.free_admission_access_providers]: accessibilityFeatures.includes(
                'free_admission_access_providers'
            ),
            [FormFieldKeyName.enum.accessibility_additional_information]:
                defaultFormValues?.accessibility_additional_information,
            [FormFieldKeyName.enum.accessibility_additional_qualifications]:
                defaultFormValues?.accessibility_additional_qualifications
        }
    })

    const onSubmit = form.handleSubmit(data => {
        const {accessibility_additional_information, accessibility_additional_qualifications, ...rest} = data
        const accessibilityFeatures = ObjectKeys(rest).filter(key => rest[key])
        const dataToSend = {
            accessibility_features: accessibilityFeatures,
            accessibility_additional_information,
            accessibility_additional_qualifications
        }
        changeStep.handleChangeStep(false, dataToSend)
    })
    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:accessibility_step:title')}</Title>
                    <Paragraph>{t('experiences:accessibility_step:description')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('commons:x_items_improve', {count: flagsCount})}
                            paragraph={t('experiences:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={4}>
                    <FlagsAlertText flags={accessibilityFlags} title={t('experiences:accessibility_step:title')} />

                    <Flexbox direction="column" gap={6} width="100%">
                        <FieldTitle>{t('experiences:accessibility_step:part1_title')}</FieldTitle>

                        {/* sign_language_options */}
                        <Flexbox gap={4} width="100%">
                            <VolunteerIcon />
                            <StyledCheckboxLabel htmlFor="sign_language_options">
                                {t('experiences:accessibility_step:question1_label')}
                                <span>{t('experiences:accessibility_step:question1_note')}</span>
                            </StyledCheckboxLabel>
                            <Checkbox id="sign_language_options" {...form.register('sign_language_options')} />
                        </Flexbox>

                        {/* designated_sighted_guide */}
                        <Flexbox gap={4} width="100%">
                            <GlassesIcon />
                            <StyledCheckboxLabel htmlFor="designated_sighted_guide">
                                {t('experiences:accessibility_step:question2_label')}
                                <span>{t('experiences:accessibility_step:question2_note')}</span>
                            </StyledCheckboxLabel>
                            <Checkbox id="designated_sighted_guide" {...form.register('designated_sighted_guide')} />
                        </Flexbox>
                    </Flexbox>

                    <Divider direction="horizontal" />

                    <Flexbox direction="column" gap={6}>
                        <FieldTitle>{t('experiences:accessibility_step:part2_title')}</FieldTitle>
                        {/* step_free_access */}
                        <Flexbox gap={4} width="100%">
                            <NoStairsIcon />
                            <StyledCheckboxLabel htmlFor="step_free_access">
                                {t('experiences:accessibility_step:question3_label')}
                                <span>{t('experiences:accessibility_step:question3_note')}</span>
                            </StyledCheckboxLabel>
                            <Checkbox id="step_free_access" {...form.register('step_free_access')} />
                        </Flexbox>

                        {/* step_free_access_bathroom */}
                        <Flexbox gap={4} width="100%">
                            <ToiletIcon />
                            <StyledCheckboxLabel htmlFor="step_free_access_bathroom">
                                {t('experiences:accessibility_step:question4_label')}
                                <span>{t('experiences:accessibility_step:question4_note')}</span>
                            </StyledCheckboxLabel>
                            <Checkbox id="step_free_access_bathroom" {...form.register('step_free_access_bathroom')} />
                        </Flexbox>

                        {/* disabled_parking_spot */}
                        <Flexbox gap={4} width="100%">
                            <PerkingIcon />
                            <StyledCheckboxLabel htmlFor="disabled_parking_spot">
                                {t('experiences:accessibility_step:question5_label')}
                                <span>{t('experiences:accessibility_step:question5_note')}</span>
                            </StyledCheckboxLabel>
                            <Checkbox id="disabled_parking_spot" {...form.register('disabled_parking_spot')} />
                        </Flexbox>

                        {/* entrances_wider_32_inch */}
                        <Flexbox gap={4} width="100%">
                            <AccessibilityWideEntranceIcon />
                            <StyledCheckboxLabel htmlFor="entrances_wider_32_inch">
                                {t('experiences:accessibility_step:question6_label')}
                                <span>{t('experiences:accessibility_step:question6_note')}</span>
                            </StyledCheckboxLabel>
                            <Checkbox id="entrances_wider_32_inch" {...form.register('entrances_wider_32_inch')} />
                        </Flexbox>

                        {/* mainly_flat_leveled_ground */}
                        <Flexbox gap={4} width="100%">
                            <AccessibilityHumanIcon />
                            <StyledCheckboxLabel htmlFor="mainly_flat_leveled_ground">
                                {t('experiences:accessibility_step:question7_label')}
                                <span>{t('experiences:accessibility_step:question7_note')}</span>
                            </StyledCheckboxLabel>
                            <Checkbox
                                id="mainly_flat_leveled_ground"
                                {...form.register('mainly_flat_leveled_ground')}
                            />
                        </Flexbox>
                    </Flexbox>

                    <Divider direction="horizontal" />

                    <Flexbox direction="column" gap={6}>
                        <FieldTitle>{t('experiences:accessibility_step:part3_title')}</FieldTitle>

                        {/* no_extreme_sensory_stimuli */}
                        <Flexbox gap={4} width="100%">
                            <NoFlashIcon />
                            <StyledCheckboxLabel htmlFor="no_extreme_sensory_stimuli">
                                {t('experiences:accessibility_step:question8_label')}
                                <span>{t('experiences:accessibility_step:question8_note')}</span>
                            </StyledCheckboxLabel>
                            <Checkbox
                                id="no_extreme_sensory_stimuli"
                                {...form.register('no_extreme_sensory_stimuli')}
                            />
                        </Flexbox>

                        {/* quiet_retreat_space_available */}
                        <Flexbox gap={4} width="100%">
                            <YogaIcon />
                            <StyledCheckboxLabel htmlFor="quiet_retreat_space_available">
                                {t('experiences:accessibility_step:question9_label')}
                                <span>{t('experiences:accessibility_step:question9_note')}</span>
                            </StyledCheckboxLabel>
                            <Checkbox
                                id="quiet_retreat_space_available"
                                {...form.register('quiet_retreat_space_available')}
                            />
                        </Flexbox>
                    </Flexbox>

                    <Divider direction="horizontal" />

                    <Flexbox direction="column" gap={6}>
                        <FieldTitle>{t('experiences:accessibility_step:part4_title')}</FieldTitle>

                        {/* free_admission_access_providers */}
                        <Flexbox gap={4} width="100%">
                            <StyledCheckboxLabel htmlFor="free_admission_access_providers">
                                {t('experiences:accessibility_step:question10_label')}
                                <span>
                                    <Trans
                                        i18nKey={'experiences:accessibility_step:question10_note'}
                                        components={{
                                            a: (
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/3081"
                                                />
                                            )
                                        }}
                                    />
                                </span>
                            </StyledCheckboxLabel>
                            <Checkbox
                                id="free_admission_access_providers"
                                {...form.register('free_admission_access_providers')}
                            />
                        </Flexbox>

                        <Divider direction="horizontal" />

                        <Flexbox direction="column" gap={6}>
                            <FieldTitle>{t('experiences:accessibility_step:part5_title')}</FieldTitle>

                            <StyledInputContent gap={4} direction="column" width="100%">
                                <StyledHelpText>{t('experiences:accessibility_step:question11_note')}</StyledHelpText>
                                <TextArea
                                    rows={6}
                                    errorMessage={form.formState.errors.accessibility_additional_information?.message}
                                    {...form.register('accessibility_additional_information')}
                                />
                                <FlagsAlertText
                                    flags={additionalInformationFlags}
                                    title={t('experiences:accessibility_step:part5_title')}
                                />
                            </StyledInputContent>

                            <StyledInputContent gap={4} direction="column" width="100%">
                                <StyledHelpText> {t('experiences:accessibility_step:question12_note')}</StyledHelpText>
                                <TextArea
                                    rows={6}
                                    errorMessage={
                                        form.formState.errors.accessibility_additional_qualifications?.message
                                    }
                                    {...form.register('accessibility_additional_qualifications')}
                                />
                                <FlagsAlertText
                                    flags={additionalQualificationsFlags}
                                    title={t('experiences:accessibility_step:part5_title')}
                                />
                            </StyledInputContent>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('accessibility')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>

                {changeStep.hasNextStep ? (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button onClick={onSubmit}>
                        {t('commons:confirm')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
