import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {errorHandler} from '@/utilities/helpers'
import {httpDeleteActivity} from '../http/experiences.http'
import {ExperienceExtended} from '../types'

export const useDeleteActivity = ({
    activityId,
    experienceId,
    options
}: {
    activityId: number
    experienceId: number
    options?: Omit<UseMutationOptions, 'mutationFn'>
}) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_ACTIVITY],
        mutationFn: () => httpDeleteActivity({urlParams: {activityId, experienceId}}),
        onError: errorHandler,
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            queryClient.setQueryData(
                [QUERY_KEYS.EXPERIENCE, experienceId],
                (previousData: ExperienceExtended): ExperienceExtended => ({
                    ...previousData,
                    itineraries: previousData.itineraries
                        ? previousData.itineraries.filter(itinerary => itinerary.id != activityId)
                        : null
                })
            )

            options?.onSuccess?.(data, variables, context)
        }
    })
}
