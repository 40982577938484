import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpServiceDecline} from '@/features/service-decline/http/serviceDecline.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'

type useDeclineServiceQuoteProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useServiceDecline = (options?: useDeclineServiceQuoteProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.DECLINE_QUOTE],
        mutationFn: httpServiceDecline,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICES_PROPOSALS]})
            options?.onSuccess?.()
        },
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error)
    })
}
