import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {httpSendFeedback, HttpSendFeedbackPayload} from '@/features/feedback-form/http/feedbackForm.http'
import {ErrorResponseData} from '@/types/commons.ts'
import {AxiosError} from 'axios'

export const useSendFeedback = (
    hostCode: number,
    hostPassword: string,
    quoteId: number,
    options?: Omit<
        UseMutationOptions<unknown, AxiosError<ErrorResponseData>, HttpSendFeedbackPayload>,
        'mutationKey' | 'mutationFn'
    >
) => {
    return useMutation<unknown, AxiosError<ErrorResponseData>, HttpSendFeedbackPayload>({
        ...options,
        mutationKey: [MUTATION_KEYS.SEND_FEEDBACK],
        mutationFn: payload => httpSendFeedback(hostCode, hostPassword, quoteId, payload)
    })
}
