import InputText from '@/components/commons/input-text/InputText'
import styled, {css} from 'styled-components'

export const StyledLinkField = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        gap: ${spacing}px;
        align-items: flex-end;
    `
)

export const StyledInputText = styled(InputText)`
    & label {
        overflow: visible;
        white-space: normal;
    }
`
