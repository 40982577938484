import {
    Content,
    FieldTitle,
    HeadingGroup,
    Paragraph,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import InputText from '@/components/commons/input-text/InputText.tsx'
import TextArea from '@/components/commons/textarea/TextArea.tsx'
import {StyledContent, StyledText} from './style'
import {FC, useMemo, useState} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {Label} from '@/components/ui/label/Label'
import {errorHandler, raise} from '@/utilities/helpers'
import {RestoreFieldsBanner} from '@/features/host-submissions/components/restore-fields-banner/RestoreFieldsBanner'
import {ExperienceExtended, StepKeyName} from '@/features/host-submissions/experiences/types'
import {Footer} from '@/features/host-submissions/components/atoms/Atoms'
import {ContinueEditModal} from '@/features/host-submissions/components/continue-edit-modal/ContinueEditModal'
import {getPreviousAnswerData, hasRelevantFieldChanges} from '../../utils'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ConfirmSubmitEnhanceModal} from '@/features/host-submissions/components/confirm-submit-enhance-modal/ConfirmSubmitEnhanceModal'
import {useGetAdditionalPhotos} from '../../queries/useGetAdditionalPhotos'
import {useSubmitHostApproval} from '../../queries/useSubmitHostApproval'
import {useUpdateHostApprovalQuestions} from '../../queries/useUpdateHostApprovalQuestions'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

const RECOGNITION_TITLE_MAX_LENGTH = 35
const RECOGNITION_SUBTITLE_MAX_LENGTH = 50
const EXPERTISE_MIN_LENGTH = 150
const EXPERTISE_MAX_LENGTH = 360
const EXPERIENCE_TITLE_MAX_LENGTH = 50

export const EnhanceAboutYouStepValidationSchema = z.object({
    improvedHostExperienceTitle: z.string().min(1, {message: i18n.t('errors:field_required')}),
    improvedHostExperienceDescription: z
        .string({required_error: i18n.t('errors:field_required')})
        .min(EXPERTISE_MIN_LENGTH, {
            message: i18n.t('errors:field_min_character', {count: EXPERTISE_MIN_LENGTH})
        }),
    improvedHostRecognitionTitle: z.string().optional(),
    improvedHostRecognitionSubtitle: z.string().optional()
})
export type EnhanceAboutYouStepValidationSchema = z.infer<typeof EnhanceAboutYouStepValidationSchema>

const getDefaultValuesEnhanceAboutYouForm = (experience: ExperienceExtended) => {
    return experience.expertise.answers.reduce(
        (acc, val) => {
            if (val.questionKeyName == 'improved_host_experience_title') {
                return {...acc, improvedHostExperienceTitle: val.answer}
            }
            if (val.questionKeyName == 'improved_host_expertise_description') {
                return {...acc, improvedHostExperienceDescription: val.answer}
            }
            if (val.questionKeyName == 'improved_host_recognition_title') {
                return {...acc, improvedHostRecognitionTitle: val.answer}
            }
            if (val.questionKeyName == 'improved_host_recognition_subtitle') {
                return {...acc, improvedHostRecognitionSubtitle: val.answer}
            }
            return acc
        },
        {
            improvedHostExperienceTitle: '',
            improvedHostExperienceDescription: '',
            improvedHostRecognitionTitle: '',
            improvedHostRecognitionSubtitle: ''
        }
    )
}

export const EnhanceAboutYouStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isEditMode, setIsEditMode] = useState(false)
    const [isContinueEditModalOpen, setIsContinueEditModalOpen] = useState(false)
    const [isConfirmSubmitToReviewModalOpen, setIsConfirmSubmitToReviewModalOpen] = useState(false)
    const [isFieldChanged, setIsFieldChanged] = useState(false)
    const continueEditingExperiencesValue = sessionStorage.getItem('continueEditingExperiencesConfirmed')
    const isContinueEditingConfirmed = continueEditingExperiencesValue
        ? JSON.parse(continueEditingExperiencesValue)
        : false

    const additionalPhotosQuery = useGetAdditionalPhotos({
        experienceId: experience.id,
        galleryId: experience?.gallery?.id ?? raise('galleryId is nullish')
    })
    const hasNewGalleryPhoto = experience.gallery?.images.some(image => !image?.parentId)

    const restoreDataMutation = useUpdateHostApprovalQuestions({
        experienceId: experience.id,
        options: {
            onError: errorHandler,
            onSuccess: data => {
                setIsFieldChanged(false)
                form.reset(getDefaultValuesEnhanceAboutYouForm(data))
            }
        }
    })
    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.enhance_your_experience,
        currentStep: StepKeyName.enum.enhance_about_you,
        nextStep: StepKeyName.enum.enhance_gallery
    })

    const updateEnhanceStepSuccess = () => {
        const hasAdditionalPhotos = additionalPhotosQuery.data && additionalPhotosQuery.data.length > 0

        if (hasAdditionalPhotos) {
            changeStep.handleChangeStep(false)
        } else {
            setIsConfirmSubmitToReviewModalOpen(true)
        }
    }
    const updateEnhanceStepMutation = useUpdateHostApprovalQuestions({
        experienceId: experience.id,
        options: {onError: errorHandler, onSuccess: updateEnhanceStepSuccess}
    })
    const submitExperienceMutation = useSubmitHostApproval({
        experienceId: experience.id,
        options: {onError: errorHandler}
    })

    const defaultValues = getDefaultValuesEnhanceAboutYouForm(experience)
    const form = useForm<EnhanceAboutYouStepValidationSchema>({
        resolver: zodResolver(EnhanceAboutYouStepValidationSchema),
        defaultValues
    })

    const isPrevStepChanged = useMemo(() => {
        const remappedActivities = experience.itineraries
            ? experience.itineraries?.map(activity => activity.answers)
            : []
        const activitiesChanged = hasRelevantFieldChanges(remappedActivities, [
            'improved_title',
            'improved_description',
            'main_photo_url'
        ])
        const answersChanged = experience
            ? hasRelevantFieldChanges(
                  [experience.expertise.answers],
                  [
                      'improved_host_experience_title',
                      'improved_host_expertise_description',
                      'improved_host_recognition_title',
                      'improved_host_recognition_subtitle',
                      'improved_experience_title',
                      'improved_experience_description'
                  ]
              )
            : false
        return activitiesChanged || answersChanged
    }, [experience])

    const [titleWatch, expertiseWatch, recognitionTitleWatch, recognitionSubtitleWatch] = useWatch({
        control: form.control,
        name: [
            'improvedHostExperienceTitle',
            'improvedHostExperienceDescription',
            'improvedHostRecognitionTitle',
            'improvedHostRecognitionSubtitle'
        ]
    })

    const onSubmit = form.handleSubmit(data => {
        updateEnhanceStepMutation.mutate(data)
    })

    const onRestoreValues = () => {
        const previousAnswerData = getPreviousAnswerData(experience)
        restoreDataMutation.mutate(previousAnswerData)
    }

    return (
        <>
            <Content gap={6}>
                <HeadingGroup direction="column" gap={4}>
                    <Title>{t('host_review:enhance_your_service_step_title')}</Title>
                    <Paragraph>{t('host_review:enhance_your_service_step_paragraph')}</Paragraph>
                    {(isFieldChanged || isPrevStepChanged) && (
                        // delete hideRestoreAction when it need
                        <RestoreFieldsBanner onRestore={onRestoreValues} isLoading={false} hideRestoreAction={true} />
                    )}
                </HeadingGroup>

                <Flexbox gap={5} width="100%" direction="column">
                    <Flexbox justify="space-between" gap={2} width="100%">
                        <FieldTitle>{t(`experiences:enhance_your_experience_step:about_you`)}</FieldTitle>
                        <Button
                            shape="text"
                            variant="tertiary"
                            onClick={async () => {
                                if (isEditMode) {
                                    const isValid = await form.trigger()
                                    !!isValid && setIsEditMode(false)
                                    const isFieldChanged =
                                        titleWatch != defaultValues.improvedHostExperienceTitle ||
                                        expertiseWatch != defaultValues.improvedHostExperienceDescription ||
                                        recognitionTitleWatch != defaultValues.improvedHostRecognitionTitle ||
                                        recognitionSubtitleWatch != defaultValues.improvedHostRecognitionSubtitle

                                    setIsFieldChanged(isFieldChanged)
                                } else {
                                    if (!isFieldChanged && !isContinueEditingConfirmed) {
                                        setIsContinueEditModalOpen(true)
                                    } else {
                                        setIsEditMode(true)
                                    }
                                }
                            }}
                        >
                            {t(isEditMode ? 'commons:done' : 'commons:edit')}
                        </Button>
                    </Flexbox>

                    <StyledContent direction="column" gap={4} width="100%">
                        {isEditMode ? (
                            <InputText
                                type={'text'}
                                label={t('experiences:enhance_your_experience_step:title_label')}
                                maxLength={EXPERIENCE_TITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: EXPERIENCE_TITLE_MAX_LENGTH - titleWatch.length
                                })}
                                errorMessage={form.formState.errors.improvedHostExperienceTitle?.message}
                                {...form.register('improvedHostExperienceTitle')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>{t('experiences:enhance_your_experience_step:title_label')}</Label>
                                <StyledText>{titleWatch}</StyledText>
                                <InputHelpText error={form.formState.errors.improvedHostExperienceTitle?.message} />
                            </Flexbox>
                        )}

                        {isEditMode ? (
                            <TextArea
                                rows={6}
                                label={t('experiences:enhance_your_experience_step:expertise_label')}
                                maxLength={EXPERTISE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: EXPERTISE_MAX_LENGTH - expertiseWatch.length
                                })}
                                errorMessage={form.formState.errors.improvedHostExperienceDescription?.message}
                                {...form.register('improvedHostExperienceDescription')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>{t('experiences:enhance_your_experience_step:expertise_label')}</Label>
                                <StyledText>{expertiseWatch}</StyledText>
                                <InputHelpText
                                    error={form.formState.errors.improvedHostExperienceDescription?.message}
                                />
                            </Flexbox>
                        )}

                        {isEditMode ? (
                            <InputText
                                type={'text'}
                                label={t('host_review:recognition_fields:recognition_title_label')}
                                maxLength={RECOGNITION_TITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: RECOGNITION_TITLE_MAX_LENGTH - (recognitionTitleWatch?.length ?? 0)
                                })}
                                errorMessage={form.formState.errors.improvedHostRecognitionTitle?.message}
                                {...form.register('improvedHostRecognitionTitle')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>{t('host_review:recognition_fields:recognition_title_label')}</Label>
                                <StyledText>{recognitionTitleWatch}</StyledText>
                                <InputHelpText error={form.formState.errors.improvedHostRecognitionTitle?.message} />
                            </Flexbox>
                        )}

                        {isEditMode ? (
                            <InputText
                                type={'text'}
                                label={t('host_review:recognition_fields:recognition_subtitle_label')}
                                maxLength={RECOGNITION_SUBTITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: RECOGNITION_SUBTITLE_MAX_LENGTH - (recognitionSubtitleWatch?.length ?? 0)
                                })}
                                errorMessage={form.formState.errors.improvedHostRecognitionSubtitle?.message}
                                {...form.register('improvedHostRecognitionSubtitle')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>{t('host_review:recognition_fields:recognition_subtitle_label')}</Label>
                                <StyledText>{recognitionSubtitleWatch}</StyledText>
                                <InputHelpText error={form.formState.errors.improvedHostRecognitionSubtitle?.message} />
                            </Flexbox>
                        )}
                    </StyledContent>
                </Flexbox>
            </Content>
            <Footer>
                <Button
                    variant="tertiary"
                    disabled={
                        changeStep.isBackLoading || changeStep.isContinueLoading || updateEnhanceStepMutation.isPending
                    }
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>

                <Button
                    onClick={onSubmit}
                    disabled={
                        changeStep.isBackLoading ||
                        isEditMode ||
                        changeStep.isContinueLoading ||
                        updateEnhanceStepMutation.isPending
                    }
                >
                    {t('commons:continue')}
                    {(changeStep.isContinueLoading || updateEnhanceStepMutation.isPending) && <Spinner />}
                </Button>
            </Footer>

            {isConfirmSubmitToReviewModalOpen && (
                <ConfirmSubmitEnhanceModal
                    onClose={() => setIsConfirmSubmitToReviewModalOpen(false)}
                    isSendToReview={isPrevStepChanged || isFieldChanged || !!hasNewGalleryPhoto}
                    onSubmit={() => submitExperienceMutation.mutate()}
                    isLoading={submitExperienceMutation.isPending}
                />
            )}

            {isContinueEditModalOpen && (
                <ContinueEditModal
                    onClose={() => {
                        setIsContinueEditModalOpen(false)
                    }}
                    onContinue={() => {
                        setIsEditMode(true)
                        setIsContinueEditModalOpen(false)
                        sessionStorage.setItem('continueEditingExperiencesConfirmed', 'true')
                    }}
                />
            )}
        </>
    )
}
