import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Content, Footer, HeadingGroup, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Image01Icon, Lightbulb01Icon, PlusIcon, Trash01Icon} from '@components/ui/icon'
import {useDropzone} from 'react-dropzone'
import {
    StyledFooterText,
    StyledGalleryDropzone,
    StyledGalleryGrid,
    StyledGalleryTrigger,
    StyledTipText
} from '@/features/host-submissions/experiences/components/experience-gallery-step/style'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {httpUploadFile} from '@/services/upload.http'
import toast from 'react-hot-toast'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {raise} from '@/utilities/helpers'
import {ExperienceExtended, StepKeyName, UploadedMedia} from '../../types'
import {useGalleryUpload} from '@/features/host-submissions/services/store/servicesGallery'
import {useDeletePhotos} from '../../queries/useDeletePhotos'
import {BulkPresignedPhotosResponse, useBulkPresignedPhotos} from '../../queries/useBulkPresignedPhotos'
import {GalleryItem} from '@/features/host-submissions/types'
import {httpConfirmUploadPhoto} from '../../http/experiences.http'
import {getExperiencesStepProgressPercentage} from '../../utils'
import {useChangeStep} from '../../hooks/useChangeStep'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'
import {ExperienceGalleryImageItem} from '../experience-gallery-image-item/ExperienceGalleryImageItem'
import {SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {GalleryTipsModal} from '../gallery-tips-modal/GalleryTipsModal'
import {useSetGalleryCover} from '../../queries/useSetGalleryCover'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {DeclinedGalleryPhotos} from '../declined-gallery-photos/DeclinedGalleryPhotos'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

const MAX_FILE_TO_UPLOAD = 25
export const MINIMUM_IMAGES_REQUIRED = 5
export const ExperienceGalleryStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const [selectedImages, setSelectedImages] = useState<number[]>([])
    const [isDeclinedPhotoShown, setIsDeclinedPhotoShown] = useState(false)
    const [declinedPhotos, setDeclinedPhotos] = useState<UploadedMedia[]>([])
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const gallery = useGalleryUpload(store => store.gallery)
    const acceptedFiles = useGalleryUpload(store => store.acceptedFiles)
    const setAcceptedFiles = useGalleryUpload(store => store.setAcceptedFiles)
    const setGalleryItemProgressValue = useGalleryUpload(store => store.setGalleryItemProgressValue)
    const addGalleryItems = useGalleryUpload(store => store.addGalleryItems)
    const setGalleryItemStatus = useGalleryUpload(store => store.setGalleryItemStatus)
    const setGalleryItemSrc = useGalleryUpload(store => store.setGalleryItemSrc)
    const galleryCounter = useGalleryUpload(store => store.galleryCounter)
    const clearGallery = useGalleryUpload(store => store.clearGallery)

    const [isErrorGalleryCover, setIsErrorGalleryCover] = useState(false)
    const errorGalleryCoverRef = useRef<HTMLDivElement>(null)

    const deletePhotoMutation = useDeletePhotos({
        experienceId: experience.id,
        galleryId: experience?.gallery?.id ?? raise('galleryId is nullish'),
        mutationOptions: {
            onSuccess: () => setSelectedImages([])
        }
    })
    const setCoverMutation = useSetGalleryCover({
        experienceId: experience.id,
        galleryId: experience?.gallery?.id ?? raise('galleryId is nullish'),
        imageId: selectedImages[0],
        mutationOptions: {
            onSuccess: () => setSelectedImages([])
        }
    })

    const galleryFlags = experience.gallery?.parentFlags ?? []
    const onSuccess = (data: BulkPresignedPhotosResponse) => {
        const galleryItems: GalleryItem[] = data.map((value, index) => ({
            id: value.media.id,
            progressValue: 0,
            status: 'pending',
            blob: acceptedFiles[index],
            src: URL.createObjectURL(acceptedFiles[index]),
            presignedUrl: value.presignedUrl
        }))
        addGalleryItems(galleryItems)
        setAcceptedFiles([])
        void uploadFiles(galleryItems)
    }

    const bulkPresignedQuery = useBulkPresignedPhotos({
        urlParams: {experienceId: experience.id, galleryId: experience?.gallery?.id ?? raise('galleryId is nullish')},
        options: {
            onSuccess: onSuccess
        }
    })
    useLayoutEffect(() => {
        const images = experience.gallery?.images || []
        const declined = images.filter(img => !!img.parentFlags?.length)
        setDeclinedPhotos(declined)
        setIsDeclinedPhotoShown(declined.length > 0)
    }, [])

    useEffect(() => {
        const images = experience.gallery?.images || []
        const approved = images.filter(img => !img.parentFlags?.length)

        addGalleryItems(
            approved.map(media => ({
                id: media.id,
                src: media.url,
                status: 'success',
                flags: media.parentFlags,
                isCover: media?.isCover
            })) || []
        )

        return () => clearGallery()
    }, [experience.gallery])

    const dropzoneState = useDropzone({
        disabled: bulkPresignedQuery.isPending,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        maxFiles: MAX_FILE_TO_UPLOAD,
        multiple: true,
        onDropAccepted: acceptedFiles => {
            setAcceptedFiles(acceptedFiles)
            bulkPresignedQuery.mutate({medias: acceptedFiles.map(file => file.name)})
        },
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > MAX_FILE_TO_UPLOAD) {
                return toast.error(t('errors:dropzone_max_file', {count: MAX_FILE_TO_UPLOAD}))
            }
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    const uploadFile = async (galleryItem: GalleryItem, uploadNextGroup: () => void) => {
        try {
            await httpUploadFile({
                url: galleryItem.presignedUrl || raise('the presignedUrl is undefined'),
                payload: galleryItem.blob || raise('the file is undefined'),
                onUploadProgress: progressEvent =>
                    setGalleryItemProgressValue(
                        galleryItem.id,
                        progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100
                    )
            })
            const response = await httpConfirmUploadPhoto({
                params: {responseType: 'extended'},
                urlParams: {
                    galleryId: experience?.gallery?.id ?? raise('galleryId is nullish'),
                    experienceId: experience.id,
                    mediaId: galleryItem.id
                }
            })
            setGalleryItemSrc(galleryItem.id, response.data.url)
            setGalleryItemStatus(galleryItem.id, 'success')
        } catch (error) {
            setGalleryItemStatus(galleryItem.id, 'error')
        } finally {
            uploadNextGroup()
        }
    }

    const uploadFiles = (galleryItems: GalleryItem[]) => {
        let filesQueueIndex = 0
        const firstGroupSize = 6
        const othersGroupSize = 3
        let queue = [galleryItems.slice(0, firstGroupSize)]

        for (let index = firstGroupSize; index < galleryItems.length; index += othersGroupSize) {
            queue = queue.concat([galleryItems.slice(index, index + othersGroupSize)])
        }
        const filesQueue = queue

        const uploadFilesGroup = (galleryItems: GalleryItem[]) => {
            galleryItems.map(
                item =>
                    void uploadFile(item, () => {
                        const nextFilesQueue = filesQueue[++filesQueueIndex]
                        if (nextFilesQueue) {
                            uploadFilesGroup(nextFilesQueue)
                        }
                    })
            )
        }

        uploadFilesGroup(filesQueue[0])
    }

    const onSelectImages = (imageId: number) => {
        if (selectedImages.includes(imageId)) {
            setSelectedImages(selectedImages.filter(id => id !== imageId))
        } else {
            setSelectedImages([...selectedImages, imageId])
        }
    }

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.location,
        currentStep: StepKeyName.enum.gallery,
        nextStep: StepKeyName.enum.experience,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })

    const hasGalleryCover = !!gallery.find(image => image.isCover)

    return isDeclinedPhotoShown ? (
        <DeclinedGalleryPhotos
            isBackLoading={changeStep.isBackLoading}
            onBack={() => changeStep.handleChangeStep(true)}
            onContinue={() => setIsDeclinedPhotoShown(false)}
            declinedPhoto={declinedPhotos}
        />
    ) : (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:experience_gallery_step:title')}</Title>

                    <FlagsAlertText
                        flags={galleryFlags}
                        title={t('experiences:experience_gallery_step:title')}
                        variant="banner"
                    />

                    <StyledTipText gap={2} onClick={() => setSlidingModalState('open')}>
                        <Lightbulb01Icon />
                        <p>{t('experiences:experience_gallery_step:tip_modal:header_title')}</p>
                    </StyledTipText>
                </HeadingGroup>
                {gallery.length > 0 ? (
                    <>
                        <Flexbox justify={'space-between'} gap={2} align={'center'}>
                            {MINIMUM_IMAGES_REQUIRED &&
                                (galleryCounter >= MINIMUM_IMAGES_REQUIRED
                                    ? t('experiences:experience_gallery_step:x_added', {count: galleryCounter})
                                    : t('experiences:experience_gallery_step:upload_at_last_x', {
                                          count: MINIMUM_IMAGES_REQUIRED - galleryCounter
                                      }))}
                            <Dropzone state={dropzoneState}>
                                <Button
                                    variant={'secondary'}
                                    disabled={bulkPresignedQuery.isPending}
                                    onClick={() => setSelectedImages([])}
                                >
                                    {bulkPresignedQuery.isPending ? <Spinner /> : <PlusIcon />}
                                    {t('experiences:experience_gallery_step:add_photos')}
                                </Button>
                            </Dropzone>
                        </Flexbox>

                        <Flexbox gap={1} direction="column">
                            <div ref={errorGalleryCoverRef}>
                                {isErrorGalleryCover && (
                                    <InputHelpText
                                        error={t('experiences:experience_gallery_step:gallery_cover_error')}
                                    />
                                )}
                            </div>
                            <StyledGalleryGrid>
                                {gallery.map((item, index) => (
                                    <ExperienceGalleryImageItem
                                        key={index}
                                        imageItem={item}
                                        isSelected={selectedImages.includes(item.id)}
                                        onSelect={() => {
                                            onSelectImages(item.id)
                                            setIsErrorGalleryCover(false)
                                        }}
                                        onSuccessRetry={onSuccess}
                                        experienceId={experience.id}
                                        galleryId={experience?.gallery?.id ?? raise('galleryId is nullish')}
                                    />
                                ))}
                            </StyledGalleryGrid>
                        </Flexbox>
                    </>
                ) : (
                    <StyledGalleryDropzone state={dropzoneState}>
                        <StyledGalleryTrigger direction="column" gap={1} align="center" justify="center" width={'100%'}>
                            <Flexbox direction="column" gap={4} align="center" justify="center" width="100%">
                                {bulkPresignedQuery.isPending ? <Spinner size={32} /> : <Image01Icon size={32} />}
                                <h3>{t('experiences:experience_gallery_step:dropzone_label')}</h3>
                            </Flexbox>

                            <p>
                                {t('experiences:experience_gallery_step:at_least_x', {
                                    count: MINIMUM_IMAGES_REQUIRED
                                })}
                            </p>
                        </StyledGalleryTrigger>
                    </StyledGalleryDropzone>
                )}

                <StyledFooterText>{t('experiences:photo_legal_disclaimer')}</StyledFooterText>
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('gallery')}>
                {selectedImages.length > 0 ? (
                    <>
                        <Button variant="tertiary" onClick={() => setSelectedImages([])}>
                            {t('experiences:experience_gallery_step:deselect')}
                        </Button>

                        <Flexbox gap={3}>
                            {selectedImages.length == 1 && (
                                <Button
                                    variant="secondary"
                                    onClick={() => setCoverMutation.mutate()}
                                    disabled={!!gallery.find(image => image.id == selectedImages[0])?.isCover}
                                >
                                    {t('experiences:experience_gallery_step:set_cover')}
                                    {setCoverMutation.isPending && <Spinner />}
                                </Button>
                            )}
                            <Button
                                variant="secondary"
                                onClick={() =>
                                    deletePhotoMutation.mutate({imageIds: selectedImages, responseType: 'extended'})
                                }
                                shape="square"
                            >
                                {deletePhotoMutation.isPending ? <Spinner /> : <Trash01Icon size={22} />}
                            </Button>
                        </Flexbox>
                    </>
                ) : (
                    <>
                        <Button
                            variant="tertiary"
                            disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                            onClick={() =>
                                declinedPhotos.length > 0
                                    ? setIsDeclinedPhotoShown(true)
                                    : changeStep.handleChangeStep(true)
                            }
                        >
                            {t('commons:back')}
                            {changeStep.isBackLoading && <Spinner />}
                        </Button>
                        {changeStep.hasNextStep ? (
                            <Button
                                disabled={
                                    changeStep.isBackLoading ||
                                    changeStep.isContinueLoading ||
                                    galleryCounter < MINIMUM_IMAGES_REQUIRED ||
                                    gallery.some(item => item.status == 'pending')
                                }
                                onClick={() => {
                                    if (!hasGalleryCover) {
                                        setIsErrorGalleryCover(true)
                                        errorGalleryCoverRef.current?.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'center'
                                        })
                                    } else {
                                        changeStep.handleChangeStep(false)
                                    }
                                }}
                            >
                                {t('commons:continue')}
                                {changeStep.isContinueLoading && <Spinner />}
                            </Button>
                        ) : (
                            <Button
                                disabled={
                                    changeStep.isBackLoading ||
                                    changeStep.isContinueLoading ||
                                    galleryCounter < MINIMUM_IMAGES_REQUIRED ||
                                    gallery.some(item => item.status == 'pending')
                                }
                                onClick={() => {
                                    if (!hasGalleryCover) {
                                        setIsErrorGalleryCover(true)
                                        errorGalleryCoverRef.current?.scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'center'
                                        })
                                    } else {
                                        changeStep.handleChangeStep(false)
                                    }
                                }}
                            >
                                {t('commons:confirm')}
                            </Button>
                        )}
                    </>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}

            {slidingModalState != 'closed' && (
                <GalleryTipsModal slidingModalState={slidingModalState} setSlidingModalState={setSlidingModalState} />
            )}
        </>
    )
}
