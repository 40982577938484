import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    FieldTitle,
    FieldDescription,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {SettingsPrimaryLanguageSelect} from '@/features/host-submissions/experiences/components/settings-primary-language-select/SettingsPrimaryLanguageSelect'
import {z} from 'zod'
import {Controller, FormProvider, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {SettingsSecondaryLanguageSelect} from '@/features/host-submissions/experiences/components/settings-secondary-language-select/SettingsSecondaryLanguageSelect'
import {ExperienceExtended, StepKeyName} from '@/features/host-submissions/experiences/types'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '@/features/host-submissions/experiences/utils'
import {DefaultSelector} from '../atoms/Atoms'
import {useChangeStep} from '../../hooks/useChangeStep'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'

const ExperienceSettingsValidationSchema = z.object({
    main_language: z.string(),
    additional_languages: z.string().array().optional(),
    maximum_public_group_size: z.coerce
        .number()
        .nullable()
        .refine(value => value !== null && !isNaN(value) && value >= 1, {
            message: i18n.t('experiences:settings_step:maximum_size_required')
        }),
    maximum_private_group_size: z.coerce
        .number()
        .nullable()
        .refine(value => value !== null && !isNaN(value) && value >= 1, {
            message: i18n.t('experiences:settings_step:maximum_size_required')
        })
})

export type ExperienceSettingsValidationSchema = z.infer<typeof ExperienceSettingsValidationSchema>

const MAX_PUBLIC_SIZE = 200
const MAX_PRIVATE_SIZE = 30
export const ExperienceSettingsStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)

    const flagsCount = updatesRequiredSteps.settings.flatMap(field => field.flags).length
    const mainLanguageFlags = updatesRequiredSteps.settings.find(field => field.questionKeyName == 'main_language')
        ?.flags
    const extraLanguagesFlags = updatesRequiredSteps.settings.find(
        field => field.questionKeyName == 'additional_languages'
    )?.flags

    const groupMaxPublicSizeFlags = updatesRequiredSteps.settings.find(
        field => field.questionKeyName == 'maximum_public_group_size'
    )?.flags
    const groupMaxPrivateSizeFlags = updatesRequiredSteps.settings.find(
        field => field.questionKeyName == 'maximum_private_group_size'
    )?.flags

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.discounts,
        currentStep: StepKeyName.enum.settings,
        nextStep: StepKeyName.enum.availability,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })

    const defaultValues = makeExperiencesFormDefaultValues(experience).settings

    const form = useForm<ExperienceSettingsValidationSchema>({
        resolver: zodResolver(ExperienceSettingsValidationSchema),
        defaultValues
    })

    const onSubmit = form.handleSubmit(data => {
        changeStep.handleChangeStep(false, data)
    })
    return (
        <>
            <Content>
                <Title>{t('experiences:settings_step:title')}</Title>
                <FormProvider {...form}>
                    <Flexbox direction="column" gap={6}>
                        <Flexbox direction="column" gap={4} width="100%">
                            <Flexbox direction="column" gap={1.5}>
                                <FieldTitle>{t('experiences:settings_step:language_title')}</FieldTitle>
                                <FieldDescription>{t('experiences:settings_step:language_hint')}</FieldDescription>
                                {flagsCount >= 1 && (
                                    <AlertBanner
                                        title={t('commons:x_items_improve', {count: flagsCount})}
                                        paragraph={t('experiences:update_required_paragraph')}
                                    />
                                )}
                            </Flexbox>

                            <FlagsAlertText
                                flags={mainLanguageFlags}
                                title={t('services:step_other_details_more_details:select_primary_language')}
                            />

                            <FlagsAlertText
                                flags={extraLanguagesFlags}
                                title={t('services:step_other_details_more_details:additional_languages')}
                            />

                            <SettingsPrimaryLanguageSelect />

                            <SettingsSecondaryLanguageSelect />
                        </Flexbox>

                        <Divider direction="horizontal" />

                        <Flexbox direction="column" gap={4} width="100%">
                            <Flexbox direction={'column'} gap={1.5}>
                                <FieldTitle>{t('experiences:settings_step:size')}</FieldTitle>
                                <FieldDescription> {t('experiences:settings_step:size_note')}</FieldDescription>

                                <FlagsAlertText
                                    flags={groupMaxPublicSizeFlags}
                                    title={t('services:step_other_details_more_details:maximum_size')}
                                />
                                <FlagsAlertText
                                    flags={groupMaxPrivateSizeFlags}
                                    title={t('services:step_other_details_more_details:maximum_size')}
                                />
                            </Flexbox>

                            <Flexbox direction={'column'} gap={1.5} width="100%">
                                <Controller
                                    control={form.control}
                                    name="maximum_public_group_size"
                                    render={({field}) => (
                                        <DefaultSelector
                                            array={Array.from({length: MAX_PUBLIC_SIZE}, (_, index) =>
                                                String(index + 1)
                                            )}
                                            value={(field.value ?? '').toString()}
                                            onChange={field.onChange}
                                            label={t('experiences:settings_step:max_public_groups_size')}
                                            placeholder={t('experiences:settings_step:max_groups_size_placeholder')}
                                            errorMessage={
                                                form.formState.errors.maximum_public_group_size?.message || undefined
                                            }
                                            ref={field.ref}
                                        />
                                    )}
                                />
                                <FieldDescription>
                                    {t('experiences:settings_step:public_size_note', {count: MAX_PUBLIC_SIZE})}
                                </FieldDescription>
                            </Flexbox>

                            <Flexbox direction={'column'} gap={1.5} width="100%">
                                <Controller
                                    control={form.control}
                                    name="maximum_private_group_size"
                                    render={({field}) => (
                                        <DefaultSelector
                                            array={Array.from({length: MAX_PRIVATE_SIZE}, (_, index) =>
                                                String(index + 1)
                                            )}
                                            value={(field.value ?? '').toString()}
                                            onChange={field.onChange}
                                            label={t('experiences:settings_step:max_private_groups_size')}
                                            placeholder={t('experiences:settings_step:max_groups_size_placeholder')}
                                            errorMessage={
                                                form.formState.errors.maximum_private_group_size?.message || undefined
                                            }
                                            ref={field.ref}
                                        />
                                    )}
                                />

                                <FieldDescription>
                                    {t('experiences:settings_step:private_size_note', {count: MAX_PRIVATE_SIZE})}
                                </FieldDescription>
                            </Flexbox>
                        </Flexbox>
                        <Divider direction="horizontal" />
                    </Flexbox>
                </FormProvider>
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('settings')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                {changeStep.hasNextStep ? (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:confirm')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                )}
            </Footer>
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
