import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useInputSearch} from '@/hooks/useInputSearch'
import {ComboboxProvider, SelectProvider} from '@ariakit/react'
import {useMemo, useRef} from 'react'
import {CountrySelectTrigger} from './CountrySelectTrigger'
import {CountrySelectPopover} from './CountrySelectPopover'
import {useGetCountries} from '../../queries/useCountries'

export const CountrySelect = ({
    value,
    onChange,
    errorMessage
}: {
    value?: string | null
    errorMessage?: string
    onChange: (value: string | null) => void
}) => {
    const {onSearch, onResetSearch, searchValue} = useInputSearch(1, 350)
    const inputCountryRef = useRef(null)
    const countriesQuery = useGetCountries()

    const countries = countriesQuery.data?.data ?? []
    const filteredData = useMemo(() => {
        return countries.filter(country => country.name.toLowerCase().includes(searchValue.toLowerCase()))
    }, [searchValue, countries])

    return (
        <ComboboxProvider setValue={onSearch} resetValueOnHide>
            <SelectProvider
                value={value ?? ''}
                setValue={value => onChange(value)}
                setOpen={() => onResetSearch(inputCountryRef)}
            >
                <Flexbox direction="column" gap={1.5} width="100%">
                    <CountrySelectTrigger
                        country={countries.find(country => country.code == value)?.name}
                        errorMessage={errorMessage}
                    />
                    <CountrySelectPopover
                        isLoading={countriesQuery.isLoading}
                        isError={countriesQuery.isError}
                        countries={filteredData}
                    />
                </Flexbox>
            </SelectProvider>
        </ComboboxProvider>
    )
}
