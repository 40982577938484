import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {Service} from '@/features/host-submissions/services/types.ts'
import {
    httpUpdateServiceLocation,
    HttpUpdateServiceLocationOptions
} from '@/features/host-submissions/services/http/services.http'
import {QUERY_KEYS} from '@/queryClient.ts'

export const useUpdateLocation = ({
    serviceId,
    expertiseId,
    options
}: {
    serviceId: number
    expertiseId: number
    options?: Omit<UseMutationOptions<Service, unknown, HttpUpdateServiceLocationOptions['payload']>, 'mutationFn'>
}) => {
    const queryClient = useQueryClient()
    const queryKey = [QUERY_KEYS.SERVICE, serviceId]

    return useMutation({
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpUpdateServiceLocation({
                    urlParams: {serviceId, expertiseId},
                    params: {response_type: 'extended'},
                    payload: payload
                }),
                responseShape: Service,
                onZodError: captureException
            }),
        ...options,
        onMutate: async () => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({queryKey})
            const previousService = queryClient.getQueryData<Service>(queryKey)
            return {previousService}
        },
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.SERVICE, serviceId]})
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE, serviceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
