import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldTitle,
    Title,
    AlertText,
    Footer
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ExperienceExtended, StepKeyName} from '@/features/host-submissions/experiences/types'
import {FC} from 'react'
import {StyledStep} from './style'
import {getUpdatesRequiredSteps} from '../../utils'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'

export const SuggestionsStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)
    const flagsCount = {
        [StepKeyName.enum.about_you]: updatesRequiredSteps.about_you.flatMap(field => field.flags).length,
        [StepKeyName.enum.about_you_more_info]: updatesRequiredSteps.about_you_more_info.flatMap(field => field.flags)
            .length,
        [StepKeyName.enum.location]: updatesRequiredSteps.location.flatMap(field => field.flags).length,
        [StepKeyName.enum.gallery]:
            updatesRequiredSteps.gallery.images.flatMap(field => field.flags).length +
            updatesRequiredSteps.gallery.flags.length,
        [StepKeyName.enum.experience]: updatesRequiredSteps.experience.flatMap(field => field.flags).length,
        [StepKeyName.enum.itinerary]: updatesRequiredSteps.itinerary.flatMap(activity =>
            activity.flatMap(field => field.flags)
        ).length,
        [StepKeyName.enum.pricing]: updatesRequiredSteps.pricing.flatMap(field => field.flags).length,
        [StepKeyName.enum.discounts]: updatesRequiredSteps.discounts.flatMap(field => field.flags).length,
        [StepKeyName.enum.settings]: updatesRequiredSteps.settings.flatMap(field => field.flags).length,
        [StepKeyName.enum.availability]: updatesRequiredSteps.availability.flatMap(field => field.flags).length,
        [StepKeyName.enum.guests]: updatesRequiredSteps.guests.flatMap(field => field.flags).length,
        [StepKeyName.enum.accessibility]: updatesRequiredSteps.accessibility.flatMap(field => field.flags).length
    }

    const changeStep = useChangeStep({
        experience,
        currentStep: StepKeyName.enum.suggestions
    })

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:step_suggestions:title')}</Title>
                    <Paragraph>{t('experiences:step_suggestions:paragraph')}</Paragraph>
                </HeadingGroup>

                <Flexbox direction="column">
                    {flagsCount.about_you >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:experience_about_yourself_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.about_you})} />
                        </StyledStep>
                    )}

                    {flagsCount.about_you_more_info >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:experience_about_yourself_more_info_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.about_you_more_info})} />
                        </StyledStep>
                    )}

                    {flagsCount.location >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:experience_location_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.location})} />
                        </StyledStep>
                    )}

                    {flagsCount.gallery >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:experience_gallery_step:title')}</FieldTitle>
                            <AlertText text={t('experiences:experience_gallery_step:update_required_title')} />
                        </StyledStep>
                    )}

                    {flagsCount.experience >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:experience_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.experience})} />
                        </StyledStep>
                    )}

                    {flagsCount.itinerary >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:experience_itinerary_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.itinerary})} />
                        </StyledStep>
                    )}

                    {flagsCount.pricing >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:experience_pricing_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.pricing})} />
                        </StyledStep>
                    )}

                    {flagsCount.discounts >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:experience_discounts_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.discounts})} />
                        </StyledStep>
                    )}

                    {flagsCount.settings >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:settings_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.settings})} />
                        </StyledStep>
                    )}

                    {flagsCount.availability >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:availability_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.availability})} />
                        </StyledStep>
                    )}

                    {flagsCount.guests >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:experience_guest_requirements_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.guests})} />
                        </StyledStep>
                    )}

                    {flagsCount.accessibility >= 1 && (
                        <StyledStep>
                            <FieldTitle>{t('experiences:accessibility_step:title')}</FieldTitle>
                            <AlertText text={t('commons:x_items_improve', {count: flagsCount.accessibility})} />
                        </StyledStep>
                    )}
                </Flexbox>
            </Content>
            <Footer>
                <Flexbox width="100%" justify="center">
                    <Button
                        style={{maxWidth: 800}}
                        fullWidth
                        size="lg"
                        onClick={() => changeStep.handleChangeStep(false)}
                    >
                        {t('experiences:step_suggestions:cta')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                </Flexbox>
            </Footer>
        </>
    )
}
