import i18next, {Namespace, ParseKeys, TOptions} from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next, TransProps, useTranslation as i18nUseTranslation, Trans as I18nTrans} from 'react-i18next'
//translations
import en from './en/en.json'
import it from './it/it.json'
import de from './de/de.json'
import fr from './fr/fr.json'
import es from './es/es.json'
import ja from './ja/ja.json'
import ko from './ko/ko.json'
import nl from './nl/nl.json'
import pt from './pt/pt.json'
import ru from './ru/ru.json'
import zh from './zh/zh.json'
import ar from './ar/ar.json'
import az from './az/az.json'
import bg from './bg/bg.json'
import bs from './bs/bs.json'
import ca from './ca/ca.json'
import sr_ME from './sr_ME/sr_ME.json'
import cs from './cs/cs.json'
import da from './da/da.json'
import el from './el/el.json'
import et from './et/et.json'
import fi from './fi/fi.json'
import ga from './ga/ga.json'
import he from './he/he.json'
import hi from './hi/hi.json'
import hr from './hr/hr.json'
import hu from './hu/hu.json'
import hy from './hy/hy.json'
import id from './id/id.json'
import is from './is/is.json'
import ka from './ka/ka.json'
import kn from './kn/kn.json'
import lt from './lt/lt.json'
import lv from './lv/lv.json'
import mk from './mk/mk.json'
import mr from './mr/mr.json'
import ms from './ms/ms.json'
import mt from './mt/mt.json'
import nb from './nb/nb.json'
import pl from './pl/pl.json'
import ro from './ro/ro.json'
import sk from './sk/sk.json'
import sl from './sl/sl.json'
import sq from './sq/sq.json'
import sr from './sr/sr.json'
import sv from './sv/sv.json'
import sw from './sw/sw.json'
import th from './th/th.json'
import tl from './tl/tl.json'
import tr from './tr/tr.json'
import uk from './uk/uk.json'
import vi from './vi/vi.json'
import xh from './xh/xh.json'
import zu from './zu/zu.json'
import en_AU from './en_AU/en_AU.json'
import en_CA from './en_CA/en_CA.json'
import en_uk from './en_uk/en_uk.json'
import es_LATAM from './es_LATAM/es_LATAM.json'
import es_MX from './es_MX/es_MX.json'
import fr_CA from './fr_CA/fr_CA.json'
import es_AR from './es_AR/es_AR.json'
import nl_BE from './nl_BE/nl_BE.json'
import pt_br from './pt_br/pt_br.json'
import zh_hk from './zh_hk/zh_hk.json'
import zh_TW from './zh_TW/zh_TW.json'

//dayjs locales
import 'dayjs/locale/it'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/ja'
import 'dayjs/locale/ko'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt'
import 'dayjs/locale/ru'
import 'dayjs/locale/zh'

import {HTMLProps} from 'react'
import {_DefaultNamespace} from 'react-i18next/TransWithoutContext'

export const MAIN_LANGUAGES = [
    {label: 'English (EN)', value: 'en', flagCode: 'gb', json: en},
    {label: 'Español (ES)', value: 'es', flagCode: 'es', json: es},
    {label: 'Deutsch (DE)', value: 'de', flagCode: 'de', json: de},
    {label: 'Français (FR)', value: 'fr', flagCode: 'fr', json: fr},
    {label: 'Italiano (IT)', value: 'it', flagCode: 'it', json: it},
    {label: '日本語 (JP)', value: 'ja', flagCode: 'jp', json: ja},
    {label: '한국어 (KO)', value: 'ko', flagCode: 'kr', json: ko},
    {label: 'Nederlands (NL)', value: 'nl', flagCode: 'nl', json: nl},
    {label: 'Português (PT)', value: 'pt', flagCode: 'pt', json: pt},
    {label: 'Русский (RU)', value: 'ru', flagCode: 'ru', json: ru},
    {label: '中文 (ZH-CH)', value: 'zh', flagCode: 'cn', json: zh}
]

export const SERVICES_LANGUAGES = [
    {label: 'English (EN)', value: 'en', flagCode: 'gb', json: en},
    {label: 'English (EN-AU)', value: 'en_AU', flagCode: 'au', json: en_AU},
    {label: 'English (EN-CA)', value: 'en_CA', flagCode: 'ca', json: en_CA},
    {label: 'English (EN-UK)', value: 'en_GB', flagCode: 'gb', json: en_uk},
    {label: 'Català (CA)', value: 'ca', flagCode: 'es', json: ca},
    {label: 'Español (ES)', value: 'es', flagCode: 'es', json: es},
    {label: 'Español (ES-MX)', value: 'es_MX', flagCode: 'mx', json: es_MX},
    {label: 'Français (FR)', value: 'fr', flagCode: 'fr', json: fr},
    {label: 'Français (FR-CA)', value: 'fr_CA', flagCode: 'ca', json: fr_CA},
    {label: 'Italiano (IT)', value: 'it', flagCode: 'it', json: it},
    {label: '中文 (ZH-CH)', value: 'zh', flagCode: 'cn', json: zh}
]

export const APP_LANGUAGES = [
    ...MAIN_LANGUAGES,
    {label: 'العربية (AR)', value: 'ar', flagCode: 'sa', json: ar},
    {label: 'Azərbaycan (AZ)', value: 'az', flagCode: 'az', json: az},
    {label: 'Български (BG)', value: 'bg', flagCode: 'bg', json: bg},
    {label: 'Bosanski (BS)', value: 'bs', flagCode: 'ba', json: bs},
    {label: 'Català (CA)', value: 'ca', flagCode: 'es', json: ca},
    {label: 'Montenegrin (CNR)', value: 'sr_ME', flagCode: 'me', json: sr_ME},
    {label: 'Čeština (CS)', value: 'cs', flagCode: 'cz', json: cs},
    {label: 'Dansk (DA)', value: 'da', flagCode: 'dk', json: da},
    {label: 'Ελληνικά (EL)', value: 'el', flagCode: 'gr', json: el},
    {label: 'Eesti (ET)', value: 'et', flagCode: 'ee', json: et},
    {label: 'Suomi (FI)', value: 'fi', flagCode: 'fi', json: fi},
    {label: 'Gaeilge (GA)', value: 'ga', flagCode: 'ie', json: ga},
    {label: 'עברית (HE)', value: 'he', flagCode: 'il', json: he},
    {label: 'हिंदी (HI)', value: 'hi', flagCode: 'in', json: hi},
    {label: 'Hrvatski (HR)', value: 'hr', flagCode: 'hr', json: hr},
    {label: 'Magyar (HU)', value: 'hu', flagCode: 'hu', json: hu},
    {label: 'Հայերեն (HY)', value: 'hy', flagCode: 'am', json: hy},
    {label: 'Bahasa Indonesia (ID)', value: 'id', flagCode: 'id', json: id},
    {label: 'Íslenska (IS)', value: 'is', flagCode: 'is', json: is},
    {label: 'ქართული (KA)', value: 'ka', flagCode: 'ge', json: ka},
    {label: 'ಕನ್ನಡ (KN)', value: 'kn', flagCode: 'in', json: kn},
    {label: 'Lietuvių (LT)', value: 'lt', flagCode: 'lt', json: lt},
    {label: 'Latviešu (LV)', value: 'lv', flagCode: 'lv', json: lv},
    {label: 'Македонски (MK)', value: 'mk', flagCode: 'mk', json: mk},
    {label: 'मराठी (MR)', value: 'mr', flagCode: 'in', json: mr},
    {label: 'Bahasa Melayu (MS)', value: 'ms', flagCode: 'my', json: ms},
    {label: 'Malti (MT)', value: 'mt', flagCode: 'mt', json: mt},
    {label: 'Norsk Bokmål (NB)', value: 'nb', flagCode: 'no', json: nb},
    {label: 'Polski (PL)', value: 'pl', flagCode: 'pl', json: pl},
    {label: 'Română (RO)', value: 'ro', flagCode: 'ro', json: ro},
    {label: 'Slovenčina (SK)', value: 'sk', flagCode: 'sk', json: sk},
    {label: 'Slovenščina (SL)', value: 'sl', flagCode: 'si', json: sl},
    {label: 'Shqip (SQ)', value: 'sq', flagCode: 'al', json: sq},
    {label: 'Српски (SR)', value: 'sr', flagCode: 'rs', json: sr},
    {label: 'Svenska (SV)', value: 'sv', flagCode: 'se', json: sv},
    {label: 'Kiswahili (SW)', value: 'sw', flagCode: 'tz', json: sw},
    {label: 'ไทย (TH)', value: 'th', flagCode: 'th', json: th},
    {label: 'Filipino (TL)', value: 'tl', flagCode: 'ph', json: tl},
    {label: 'Türkçe (TR)', value: 'tr', flagCode: 'tr', json: tr},
    {label: 'Українська (UK)', value: 'uk', flagCode: 'ua', json: uk},
    {label: 'Tiếng Việt (VI)', value: 'vi', flagCode: 'vn', json: vi},
    {label: 'isiXhosa (XH)', value: 'xh', flagCode: 'za', json: xh},
    {label: 'isiZulu (ZU)', value: 'zu', flagCode: 'za', json: zu},
    {label: 'English (EN-AU)', value: 'en_AU', flagCode: 'au', json: en_AU},
    {label: 'English (EN-CA)', value: 'en_CA', flagCode: 'ca', json: en_CA},
    {label: 'English (EN-UK)', value: 'en_GB', flagCode: 'gb', json: en_uk},
    {label: 'Español (ES-LATAM)', value: 'es_419', flagCode: 'mx', json: es_LATAM},
    {label: 'Español (ES-MX)', value: 'es_MX', flagCode: 'mx', json: es_MX},
    {label: 'Français (FR-CA)', value: 'fr_CA', flagCode: 'ca', json: fr_CA},
    {label: 'Español (ES-AR)', value: 'es_AR', flagCode: 'ar', json: es_AR},
    {label: 'Nederlands (NL-BE)', value: 'nl_BE', flagCode: 'be', json: nl_BE},
    {label: 'Português (PT-BR)', value: 'pt_BR', flagCode: 'br', json: pt_br},
    {label: '中文 (ZH-HK)', value: 'zh_HK', flagCode: 'hk', json: zh_hk},
    {label: '中文 (ZH-TW)', value: 'zh_TW', flagCode: 'tw', json: zh_TW}
]

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: Object.fromEntries(APP_LANGUAGES.map(language => [language.value, language.json])),
        ns: [],
        defaultNS: '',
        keySeparator: false,
        fallbackLng: 'en',
        debug: false,
        react: {
            useSuspense: false
        },
        supportedLngs: APP_LANGUAGES.map(language => language.value),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupLocalStorage: 'i18nextLng',
            convertDetectedLanguage: lng => lng.replace('-', '_')
        }
    })

/*These types are needed to get the TKey list*/
type RemoveSuffix<S extends string> = S extends `${infer P}_other` | `${infer P}_one` | `${infer P}_zero` ? P : S
type FlattenObjectKeys<T, Prefix extends string = ''> = {
    [K in keyof T & string]: T[K] extends object
        ? FlattenObjectKeys<T[K], `${Prefix}${K}:`>
        : RemoveSuffix<`${Prefix}${K}`>
}[keyof T & string]

export type TKey = RemoveSuffix<FlattenObjectKeys<typeof en>>

export const useTranslation = () => {
    const translation = i18nUseTranslation()

    return {
        ...translation,
        t: (key: TKey, options?: TOptions) => translation.t(key, options)
    }
}

export const Trans = <
    Key extends ParseKeys<Ns, TOpt, KPrefix>,
    Ns extends Namespace = _DefaultNamespace,
    TOpt extends TOptions = Record<string, unknown>,
    KPrefix = undefined,
    E = HTMLProps<HTMLDivElement>,
    I18NKey extends TKey | undefined = undefined
>({
    i18nKey,
    ...props
}: Omit<TransProps<Key, Ns, TOpt, KPrefix, E>, 'i18nKey'> & {i18nKey: I18NKey}) => (
    <I18nTrans {...props} i18nKey={i18nKey} />
)

const i18n = {...i18next, t: (key: TKey, options?: TOptions) => i18next.t(key, options)}

export default i18n
