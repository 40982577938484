import {HTMLAttributes, forwardRef, ReactNode} from 'react'
import {
    StyledFieldDescription,
    StyledInputContent,
    StyledPopover,
    StyledPopoverWrapper,
    StyledSelectorTrigger
} from './style'
import {Label} from '@/components/ui/label/Label'
import {SelectItem, SelectList} from '@/components/ui/select-atoms/SelectAtoms'
import {SelectProvider} from '@ariakit/react'
import {Flexbox, FlexBoxCustomProps} from '@/components/ui/flexbox/FlexBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {ExperiencesFlag} from '@/features/host-submissions/experiences/types'
import {TKey, useTranslation} from '@/translations/i18n'

interface DefaultSelectorProps {
    errorMessage?: string
    onChange?: (value: string) => void
    value?: string
    label: ReactNode
    placeholder: ReactNode
    note?: ReactNode
    array: string[]
    disabled?: boolean
    flags?: ExperiencesFlag[]
    labelPrefix?: string
}

export const DefaultSelector = forwardRef<HTMLSelectElement, DefaultSelectorProps>(
    ({errorMessage, onChange, value, label, placeholder, note, disabled = false, array, flags, labelPrefix}, ref) => {
        const {t} = useTranslation()
        return (
            <Flexbox direction="column" gap={1.5} width="100%">
                <SelectProvider value={value ?? ''} setValue={onChange}>
                    <Label>{label}</Label>
                    {note && <StyledFieldDescription>{note}</StyledFieldDescription>}
                    <FlagsAlertText flags={flags} title={label} />
                    <StyledSelectorTrigger
                        fullWidth
                        disabled={disabled}
                        placeholder={placeholder}
                        displayValue={value => (labelPrefix ? t(`${labelPrefix}:${value}` as TKey) : value)}
                        $hasError={!!errorMessage}
                        ref={ref}
                    />
                    <StyledPopoverWrapper>
                        <StyledPopover portal={false}>
                            <SelectList>
                                {array.map((value, index) => {
                                    return (
                                        <SelectItem key={index} value={value.toString()}>
                                            {labelPrefix ? t(`${labelPrefix}:${value}` as TKey) : value}
                                        </SelectItem>
                                    )
                                })}
                            </SelectList>
                        </StyledPopover>
                    </StyledPopoverWrapper>
                </SelectProvider>
                {errorMessage && <InputHelpText error={errorMessage} />}
            </Flexbox>
        )
    }
)

export const InputContent = ({
    ...rest
}: HTMLAttributes<HTMLDivElement> & FlexBoxCustomProps & {children: ReactNode}) => (
    <StyledInputContent {...rest} width="100%" />
)
