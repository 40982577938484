import {z} from 'zod'

export const ParentFlag = z.object({
    key_name: z.enum([
        'alcohol_without_license',
        'alcohol_smoking',
        'animal_welfare_violations',
        'black_white',
        'blurry',
        'blurry_profile_picture',
        'cannot_be_cropped_vertically',
        'collages',
        'duplicate',
        'expertise_unrelated',
        'heavy_filters_applied',
        'hosts_experience_unclear',
        'inappropriate_content',
        'insufficient_formal_experience',
        'irrelevant_lacking_context',
        'lack_photo_variety',
        'misleading_inaccurate',
        'missing_career_achievements',
        'multiple_countries_selected',
        'not_enough_online_presence',
        'not_enough_experience_years',
        'passport_or_logo',
        'photo_angle_awkward',
        'policy_violation',
        'poor_lighting_background',
        'reputation_proof_insufficient',
        'selfie_style',
        'service_description_unclear',
        'spelling_grammatical_errors',
        'stock_photos_or_screenshots',
        'stretched_distorted',
        'text_graphics_overlaid',
        'title_needs_update',
        'too_dark',

        'ineligible',
        'photo_does_not_show_host',
        'not_qualified',
        'inadequate_photo_quality',
        'mixed',
        'experience_not_service',
        'offering_duplicated',
        'restricted_content'
    ])
})
export type ParentFlag = z.infer<typeof ParentFlag>

export const GalleryItem = z.object({
    id: z.number().positive().int(),
    progressValue: z.number().optional(),
    blob: z.instanceof(File).optional(),
    src: z.string().url().optional(),
    presignedUrl: z.string().optional(),
    status: z.enum(['pending', 'success', 'error', 'idle']).default('idle'),
    flags: z.array(ParentFlag).optional(),
    isCover: z.boolean().optional().nullable()
})
export type GalleryItem = z.infer<typeof GalleryItem>

export const ServiceStatus = z.enum([
    'approved',
    'declined',
    'in_progress',
    'under_review',
    'pending_id_verification',
    'update_required',
    'waitlisted',
    'approval_required',
    'review_host_edits',
    'pending_export',
    'pending_publishing',
    'publishing_enabled'
])
