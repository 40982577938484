import {FC, useState} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import {generatePath, useNavigate, useOutletContext} from 'react-router-dom'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {HostExperienceProposal} from '@/features/experiences-host-video/types.ts'
import {
    StyledServicePromoCardBody,
    StyledServicePromoCardCtas,
    StyledServicePromoCardFooter,
    StyledServicePromoCardQuoteInfo,
    StyledServicePromoCardTitle,
    StyledServicePromoCardWrapper
} from '@/features/promotional-services/components/service-promo-card/style.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import toast from 'react-hot-toast'
import {useAsync} from '@hooks/useAsync.ts'
import {httpStoreHostExperience} from '@/features/experiences-host-video/queries/experiences-host-video.http'
import {Button} from '@components/ui/button/Button.tsx'
import {routes} from '@utilities/constants'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {StyledSSOModal} from '@/features/in-progress-services/components/in-progress-section/style.ts'
import {AirbnbSSOWidget} from '@/features/sso/components/airbnb-sso-widget/AirbnbSSOWidget'
import {ClapperboardIcon} from '@components/ui/icon'
import {useTheme} from 'styled-components'
import {useAuthStore} from '@/store/AuthStore.tsx'

export const HostExpProposalsSectionCard: FC<{experience: HostExperienceProposal}> = ({experience}) => {
    const {t} = useTranslation()
    const accessToken = useAuthStore(store => store.accessToken)
    const {palette} = useTheme()
    const queryClient = useQueryClient()
    const {urlParams} = useOutletContext<{urlParams: DashboardURLParams}>()
    const [showSSOModal, setShowSSOModal] = useState(false)
    const getStarted = useAsync()
    const navigate = useNavigate()

    const onCtaClick = async (
        experiencesHostVideoId: number,
        experienceTask: HostExperienceProposal['experience_task']
    ) => {
        if (accessToken) {
            try {
                if (experienceTask) {
                    navigate(
                        generatePath(routes.EXPERIENCE_HOST_VIDEO.path, {
                            hostCode: urlParams.hostCode,
                            hostPassword: urlParams.hostPassword,
                            taskId: experienceTask?.id,
                            experiencesHostVideoId
                        })
                    )
                } else {
                    const response = await getStarted.run(
                        httpStoreHostExperience({urlParams: {...urlParams, experiencesHostVideoId}})
                    )
                    await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.HOST_EXPERIENCE_PROPOSALS]})
                    navigate(
                        generatePath(routes.EXPERIENCE_HOST_VIDEO.path, {
                            hostCode: urlParams.hostCode,
                            hostPassword: urlParams.hostPassword,
                            taskId: response.data.id,
                            experiencesHostVideoId
                        })
                    )
                }
            } catch (error) {
                toast.error(t('errors:default'))
            }
        } else {
            setShowSSOModal(true)
        }
    }

    return (
        <StyledServicePromoCardWrapper>
            <StyledServicePromoCardBody direction={'column'}>
                <StyledServicePromoCardTitle direction={'column'}>
                    <a
                        href={undefined}
                        style={{height: 'auto'}}
                        title={experience.name ?? 'Experience trailer'}
                        target="_blank"
                    >
                        {experience.name ?? 'Experience trailer'}
                    </a>
                    <p>{experience.address}</p>
                </StyledServicePromoCardTitle>
            </StyledServicePromoCardBody>
            <Divider direction="horizontal" />
            <StyledServicePromoCardFooter align={'center'} justify={'space-between'}>
                <StyledServicePromoCardQuoteInfo direction={'column'}>
                    <h6>{t('commons:free')}</h6>
                </StyledServicePromoCardQuoteInfo>
                <StyledServicePromoCardCtas>
                    <Button
                        disabled={getStarted.isLoading}
                        onClick={() => onCtaClick(experience.id, experience.experience_task)}
                        variant={'primary'}
                        size={'sm'}
                    >
                        {experience.experience_task ? t('experiences_host_video:resume') : t('commons:get_started')}
                        {getStarted.isLoading && <Spinner />}
                    </Button>
                </StyledServicePromoCardCtas>
            </StyledServicePromoCardFooter>

            {showSSOModal && (
                <StyledSSOModal width={372} onClose={() => setShowSSOModal(false)}>
                    <AirbnbSSOWidget
                        redirectUrl={generatePath(routes.EXPERIENCE_HOST_VIDEO_LOGIN.path, {
                            airbnbId: experience.airbnb_id
                        })}
                        serviceIcon={<ClapperboardIcon size={40} fill={palette.neutral.black} />}
                        title={t('experiences_host_video:sso:title')}
                        subtitle={t('experiences_host_video:sso:subtitle')}
                        disclaimer={t('experiences_host_video:sso:disclaimer')}
                    />
                </StyledSSOModal>
            )}
        </StyledServicePromoCardWrapper>
    )
}
