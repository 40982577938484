import {useTranslation} from '@/translations/i18n.tsx'
import {Dispatch, FC, SetStateAction} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ProgressBar} from '@/features/experiences-host-video/components/progress-bar/ProgressBar.tsx'
import {
    StyledFriendRequirementsModalPerspectiveStep,
    StyledTitle
} from '@/features/experiences-host-video/components/friend-onboarding-modal-prespective-step/style.ts'
import {FriendOnboardingModalStep} from '@/features/experiences-host-video/components/friend-onboarding-modal/FriendOnboardingModal.tsx'
import {
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoModalBody
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {Perspective} from '@/features/experiences-host-video/components/perspective/Perspective'

interface HostFriendRequirementsModalPerspectiveStepProps {
    setStep: Dispatch<SetStateAction<FriendOnboardingModalStep>>
}
export const FriendOnboardingModalPerspectiveStep: FC<HostFriendRequirementsModalPerspectiveStepProps> = ({
    setStep
}) => {
    const {t} = useTranslation()

    return (
        <StyledFriendRequirementsModalPerspectiveStep>
            <StyledTitle>{t('experiences_host_video:perspective:title')}</StyledTitle>
            <ExperiencesHostVideoModalBody>
                <Perspective />
            </ExperiencesHostVideoModalBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <Flexbox width="100%" gap={1}>
                        <ProgressBar percentage={100} />
                        <ProgressBar percentage={100} />
                        <ProgressBar percentage={0} />
                    </Flexbox>
                    <Flexbox direction="column" gap={4.5} width="100%">
                        <Flexbox justify="space-between" width="100%">
                            <Button variant="tertiary" onClick={() => setStep('requirements')}>
                                {t('commons:back')}
                            </Button>
                            <Button variant="primary" onClick={() => setStep('checklist')}>
                                {t('commons:continue')}
                            </Button>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </StyledFriendRequirementsModalPerspectiveStep>
    )
}
