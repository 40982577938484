import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps
} from '@/features/host-submissions/services/utils.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    Footer,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useLayoutEffect, useState} from 'react'
import {Service} from '@/features/host-submissions/services/types.ts'
import {GalleryItem} from '@/features/host-submissions/types'
import {UploadedMedia} from '@/features/host-submissions/services/types'
import {useGalleryUpload} from '@/features/host-submissions/services/store/servicesGallery.ts'
import {Image01Icon, PlusIcon} from '@components/ui/icon'
import {useDropzone} from 'react-dropzone'
import {
    StyledFooterText,
    StyledGalleryDropzone,
    StyledGalleryGrid,
    StyledGalleryTrigger
} from '@/features/host-submissions/services/components/gallery-step/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {
    BulkPresignedPhotosResponse,
    useBulkPresignedPhotos
} from '@/features/host-submissions/services/queries/useBulkPresignedPhotos'
import {errorHandler, raise} from '@utilities/helpers.ts'
import {httpUploadFile} from '@services/upload.http.ts'
import {httpConfirmUploadPhoto} from '@/features/host-submissions/services/http/services.http'
import {useUpdateStepToOtherDetailsAboutGuest} from '@/features/host-submissions/services/queries/useUpdateStepToOtherDetailsAboutGuest'
import {useUpdateStepToOfferings} from '@/features/host-submissions/services/queries/useUpdateStepToOfferings'
import {GalleryStepItem} from '@/features/host-submissions/services/components/gallery-step-item/GalleryStepItem.tsx'
import toast from 'react-hot-toast'
import {ConfirmSubmitModal} from '@/features/host-submissions/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {FlagsAlertText} from '../../../components/flags-alert-text/FlagsAlertText'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {DeclinedGalleryPhotos} from '../declined-gallery-photos/DeclinedGalleryPhotos'
import {useUpdateStepToOtherDetailsLegal} from '../../queries/useUpdateStepToOtherDetailsLegal'

export const MAX_FILE_TO_UPLOAD = 25
export const GalleryStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const [isDeclinedPhotoShown, setIsDeclinedPhotoShown] = useState(false)
    const [declinedPhotos, setDeclinedPhotos] = useState<UploadedMedia[]>([])

    const gallery = useGalleryUpload(store => store.gallery)
    const acceptedFiles = useGalleryUpload(store => store.acceptedFiles)
    const setAcceptedFiles = useGalleryUpload(store => store.setAcceptedFiles)
    const setGalleryItemProgressValue = useGalleryUpload(store => store.setGalleryItemProgressValue)
    const addGalleryItems = useGalleryUpload(store => store.addGalleryItems)
    const setGalleryItemStatus = useGalleryUpload(store => store.setGalleryItemStatus)
    const setGalleryItemSrc = useGalleryUpload(store => store.setGalleryItemSrc)
    const galleryCounter = useGalleryUpload(store => store.galleryCounter)
    const clearGallery = useGalleryUpload(store => store.clearGallery)
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const galleryFlags = service.gallery?.parent_flags ?? []
    const updateStepToOfferingsMutation = useUpdateStepToOfferings({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateStepToOtherDetailsAboutGuest = useUpdateStepToOtherDetailsAboutGuest({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    // is back true here for upgrade flow and go forward without payload
    const updateStepToOtherDetailsLegal = useUpdateStepToOtherDetailsLegal<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'gallery', updatesRequiredSteps}).prev?.()
            : updateStepToOfferingsMutation
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'gallery'>({service, currentStep: 'gallery', updatesRequiredSteps}).next?.()
            : service?.is_upgrade
            ? updateStepToOtherDetailsLegal
            : updateStepToOtherDetailsAboutGuest

    const onSuccess = (data: BulkPresignedPhotosResponse) => {
        const galleryItems: GalleryItem[] = data.map((value, index) => ({
            id: value.media.id,
            progressValue: 0,
            status: 'pending',
            blob: acceptedFiles[index],
            src: URL.createObjectURL(acceptedFiles[index]),
            presignedUrl: value.presigned_url
        }))
        addGalleryItems(galleryItems)
        setAcceptedFiles([])
        void uploadFiles(galleryItems)
    }

    const bulkPresignedQuery = useBulkPresignedPhotos({
        urlParams: {serviceId: service.id},
        options: {
            onSuccess: onSuccess
        }
    })

    useLayoutEffect(() => {
        const images = service.gallery?.images || []

        const declined = images.filter(img => !!img.parent_flags.length)
        const approved = images.filter(img => !img.parent_flags.length)

        setDeclinedPhotos(declined)
        setIsDeclinedPhotoShown(declined.length > 0)

        addGalleryItems(
            approved.map(media => ({
                id: media.id,
                src: media.url,
                status: 'success',
                flags: media.parent_flags
            }))
        )

        return () => clearGallery()
    }, [])

    const dropzoneState = useDropzone({
        disabled: bulkPresignedQuery.isPending,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        maxFiles: MAX_FILE_TO_UPLOAD,
        multiple: true,
        onDropAccepted: acceptedFiles => {
            setAcceptedFiles(acceptedFiles)
            bulkPresignedQuery.mutate({medias: acceptedFiles.map(file => file.name), is_offering_image: false})
        },
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > MAX_FILE_TO_UPLOAD) {
                return toast.error(t('errors:dropzone_max_file', {count: MAX_FILE_TO_UPLOAD}))
            }
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    const uploadFile = async (galleryItem: GalleryItem, uploadNextGroup: () => void) => {
        try {
            await httpUploadFile({
                url: galleryItem.presignedUrl || raise('the presignedUrl is undefined'),
                payload: galleryItem.blob || raise('the file is undefined'),
                onUploadProgress: progressEvent =>
                    setGalleryItemProgressValue(
                        galleryItem.id,
                        progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100
                    )
            })
            const response = await httpConfirmUploadPhoto({
                serviceId: service.id,
                mediaId: galleryItem.id
            })
            setGalleryItemSrc(galleryItem.id, response.data.url)
            setGalleryItemStatus(galleryItem.id, 'success')
        } catch (error) {
            setGalleryItemStatus(galleryItem.id, 'error')
        } finally {
            uploadNextGroup()
        }
    }

    const uploadFiles = (galleryItems: GalleryItem[]) => {
        let filesQueueIndex = 0
        const firstGroupSize = 6
        const othersGroupSize = 3
        let queue = [galleryItems.slice(0, firstGroupSize)]

        for (let index = firstGroupSize; index < galleryItems.length; index += othersGroupSize) {
            queue = queue.concat([galleryItems.slice(index, index + othersGroupSize)])
        }
        const filesQueue = queue

        const uploadFilesGroup = (galleryItems: GalleryItem[]) => {
            galleryItems.map(
                item =>
                    void uploadFile(item, () => {
                        const nextFilesQueue = filesQueue[++filesQueueIndex]
                        if (nextFilesQueue) {
                            uploadFilesGroup(nextFilesQueue)
                        }
                    })
            )
        }

        uploadFilesGroup(filesQueue[0])
    }

    return isDeclinedPhotoShown ? (
        <DeclinedGalleryPhotos
            service={service}
            onContinue={() => setIsDeclinedPhotoShown(false)}
            declinedPhoto={declinedPhotos}
        />
    ) : (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_gallery:title')}</Title>
                    <Paragraph>{t('services:step_gallery:paragraph')}</Paragraph>

                    <FlagsAlertText flags={galleryFlags} title={t('services:step_gallery:title')} variant="banner" />
                </HeadingGroup>
                {gallery.length > 0 ? (
                    <>
                        <Flexbox justify={'space-between'} gap={2} align={'center'}>
                            {service.minimum_image_required &&
                                (galleryCounter >= service.minimum_image_required
                                    ? t('services:step_gallery:x_added', {count: galleryCounter})
                                    : t('services:step_gallery:upload_at_last_x', {
                                          count: service.minimum_image_required - galleryCounter
                                      }))}
                            <Dropzone state={dropzoneState}>
                                <Button variant={'secondary'} disabled={bulkPresignedQuery.isPending}>
                                    {bulkPresignedQuery.isPending ? <Spinner /> : <PlusIcon />}
                                    {t('services:step_gallery:upload_photos')}
                                </Button>
                            </Dropzone>
                        </Flexbox>
                        <StyledGalleryGrid>
                            {gallery.map((item, index) => (
                                <GalleryStepItem
                                    key={index}
                                    galleryItem={item}
                                    onSuccessRetry={onSuccess}
                                    serviceId={service.id}
                                />
                            ))}
                        </StyledGalleryGrid>
                    </>
                ) : (
                    <>
                        <StyledGalleryDropzone state={dropzoneState}>
                            <StyledGalleryTrigger
                                direction="column"
                                gap={1}
                                align="center"
                                justify="center"
                                width={'100%'}
                            >
                                <Flexbox direction="column" gap={4} align="center" justify="center" width={'100%'}>
                                    {bulkPresignedQuery.isPending ? <Spinner size={32} /> : <Image01Icon size={32} />}
                                    <h3>{t('services:step_gallery:dropzone_label')}</h3>
                                </Flexbox>
                                {service.minimum_image_required && (
                                    <p>
                                        {t('services:step_gallery:at_least_x', {
                                            count: service.minimum_image_required
                                        })}
                                    </p>
                                )}
                            </StyledGalleryTrigger>
                        </StyledGalleryDropzone>
                    </>
                )}

                <StyledFooterText>{t('services:step_gallery:footer')}</StyledFooterText>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('gallery')}>
                <Button
                    variant="tertiary"
                    disabled={
                        prevStepMutation?.isPending ||
                        nextStepMutation?.isPending ||
                        gallery.some(item => item.status == 'pending') ||
                        bulkPresignedQuery.isPending
                    }
                    onClick={() =>
                        declinedPhotos.length > 0 ? setIsDeclinedPhotoShown(true) : prevStepMutation?.mutate(undefined)
                    }
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button
                        disabled={
                            nextStepMutation.isPending ||
                            galleryCounter < (service.minimum_image_required || 1) ||
                            gallery.some(item => item.status == 'pending')
                        }
                        onClick={() => nextStepMutation.mutate(undefined)}
                    >
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={prevStepMutation?.isPending} onClick={() => setIsConfirmSubmitOpen(true)}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'gallery'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={undefined}
                />
            )}
        </>
    )
}
