import i18n, {MAIN_LANGUAGES, SERVICES_LANGUAGES} from '@/translations/i18n'
import {checkIsExperiencesRoute, checkIsServicesRoute} from '@/utilities/helpers'
import {useEffect} from 'react'
import {Toaster} from 'react-hot-toast'
import {Outlet, useLocation} from 'react-router-dom'
import {useTranslation} from '@/translations/i18n.tsx'

export const Root = () => {
    const location = useLocation()
    const {
        i18n: {language}
    } = useTranslation()

    useEffect(() => {
        const isExperiences = checkIsExperiencesRoute(location.pathname)
        const isServices = checkIsServicesRoute(location.pathname)

        const isMainLanguage = MAIN_LANGUAGES.some(lang => lang.value === language)
        const isServiceLanguage = SERVICES_LANGUAGES.some(lang => lang.value === language)

        if ((!isExperiences && !isServices && !isMainLanguage) || (isServices && !isServiceLanguage)) {
            i18n.changeLanguage('en')
        }
    }, [language, location.pathname])

    return (
        <>
            <Toaster position="top-right" />
            <Outlet />
        </>
    )
}
