import {useEffect, useCallback, useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledServiceAreaParserBox} from '@/features/host-submissions/services/components/service-location-step/style.ts'
import {CheckDone01Icon, PlusIcon} from '@components/ui/icon'
import {Place} from '@/features/host-submissions/services/types'
import {useTranslation} from 'react-i18next'
import {VALID_AIRBNB_DOMAINS} from '@/features/host-submissions/services/utils'

type ServiceAreaMessage = {
    data: 'SERVICE_AREA'
    places: Place[]
}

export function ServiceAreaParser({
    initialServiceArea,
    onUpdateCb
}: {
    initialServiceArea: Place[]
    onUpdateCb: (areas: Place[]) => void
}) {
    const {t} = useTranslation()
    const [serviceArea, setServiceArea] = useState<Place[]>(initialServiceArea)

    // Handle service area updates from URL params on mount and when redirected
    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        if (params.has('sa')) {
            const serviceArea = params
                .getAll('sa')
                .map(value => {
                    const [placeId, name] = value.split(',')
                    return placeId && name ? {placeId, name} : null
                })
                .filter((place): place is Place => place !== null)

            if (serviceArea.length > 0) {
                setServiceArea(serviceArea)
                onUpdateCb(serviceArea)
            }
        }
    }, [onUpdateCb])

    // Handle service area updates from postMessage
    useEffect(() => {
        const handleMessage = (event: MessageEvent<ServiceAreaMessage>) => {
            if (!VALID_AIRBNB_DOMAINS.includes(event.origin)) return

            const data = event.data
            if (!data || data.data !== 'SERVICE_AREA') return

            setServiceArea(data.places)
            onUpdateCb(data.places)
        }

        window.addEventListener('message', handleMessage)
        return () => window.removeEventListener('message', handleMessage)
    }, [onUpdateCb])

    const openServiceAreaPopup = useCallback(() => {
        const params = new URLSearchParams()

        // Add existing service areas if any
        serviceArea?.forEach(({placeId, name}) => {
            params.append('sa', `${placeId},${name}`)
        })

        // Add redirect URL for webview support
        params.append('redirect', window.location.href)

        const baseUrl = 'https://airbnb.com/create-service-area'
        const url = `${baseUrl}?${params.toString()}`
        const popupConfig = `popup=yes,width=500,height=${window.innerHeight}`

        window.open(url, 'service-area', popupConfig)
    }, [serviceArea])

    return (
        <StyledServiceAreaParserBox onClick={openServiceAreaPopup} justify={'space-between'} align={'center'} gap={3}>
            <Flexbox direction={'column'} gap={2}>
                <h3>{t('services:step_service_location:service_area:title')}</h3>
                <p>
                    {serviceArea?.length >= 1
                        ? serviceArea.map(({name}) => `${name}`).join(', ')
                        : t('services:step_service_location:service_area:add_placeholder')}
                </p>
            </Flexbox>
            {serviceArea?.length >= 1 ? <CheckDone01Icon /> : <PlusIcon />}
        </StyledServiceAreaParserBox>
    )
}
