import {z} from 'zod'
import {ServiceStatus} from '@/features/host-submissions/types'

export const ExperiencesFlag = z.object({
    keyName: z.enum([
        'spelling_grammar_or_formatting_issues',
        'not_representative_of_activity',
        'not_descriptive_enough',
        'spelling_or_grammatical_errors',
        'more_details_needed',
        'lack_of_relevance_to_location',
        'lack_of_cultural_component',
        'experience_missing_or_unrelated_to_activity',
        'insufficient_details',
        'add_intro_activity',
        'add_final_activity',
        'merge_repetitive_activities',
        'separate_activities_long_duration',
        'photo_is_missing',
        'photo_is_low_quality_or_irrelevant',
        'photo_includes_multiple_people',
        'photo_is_a_passport_id_photo',
        'photo_is_low_quality',
        'photos_do_not_match_activity_or_place_description',
        'photos_lack_variety',
        'photos_do_not_show_unposed_guest_interaction',
        'photos_do_not_clearly_convey_who_the_host_is',
        'photos_do_not_show_activity_highlights',
        'photos_appear_to_be_screenshots',
        'photos_show_a_policy_violation',
        'more_details_on_location',
        'venue_is_not_suitable_for_activity',
        'not_defined_or_too_many_options_shared',
        'lack_photo_variety',
        'requires_pro_photography'
    ])
})
export type ExperiencesFlag = z.infer<typeof ExperiencesFlag>

export const Country = z.object({
    code: z.string(),
    name: z.string()
})
export type Country = z.infer<typeof Country>

export const UploadedMedia = z.object({
    id: z.number(),
    url: z.string().url(),
    parentFlags: z.array(ExperiencesFlag).optional(),
    parentId: z.number().optional().nullable(),
    isHostApproved: z.boolean().optional().nullable(),
    isCover: z.boolean().optional().nullable()
})
export type UploadedMedia = z.infer<typeof UploadedMedia>

export const ActivityQuestionKeyName = z.enum([
    'description',
    'duration',
    'title',
    'main_photo_url',
    'improved_title',
    'improved_description'
])
export type ActivityQuestionKeyName = z.infer<typeof ActivityQuestionKeyName>

export const Activity = z.object({
    id: z.number().positive(),
    coverImage: UploadedMedia.nullable(),
    title: z.string().nullable(),
    durationInMinutes: z.number().nullable(),
    description: z.string().nullable(),
    position: z.number().nullable(),
    answers: z
        .object({
            parentFlags: z.array(ExperiencesFlag),
            id: z.number().positive(),
            answer: z.string(),
            previousAnswer: z.string().nullable(),
            questionKeyName: z.enum([
                'description',
                'title',
                'main_photo_url',
                'improved_title',
                'improved_description'
            ]),
            coverImage: UploadedMedia.nullable().optional()
        })
        .or(
            z.object({
                parentFlags: z.array(ExperiencesFlag),
                id: z.number().positive(),
                coverImage: UploadedMedia.nullable().optional(),
                questionKeyName: z.literal(ActivityQuestionKeyName.enum.duration),
                answer: z.number(),
                previousAnswer: z.number().nullable()
            })
        )
        .array()
})
export type Activity = z.infer<typeof Activity>

export const StepKeyName = z.enum([
    'home',
    'category',
    'about_you',
    'about_you_more_info',
    'location',
    'gallery',
    'experience',
    'itinerary',
    'pricing',
    'discounts',
    'settings',
    'availability',
    'guests',
    'accessibility',
    'legal',
    'terms',
    'suggestions',
    'enhance_your_experience',
    'enhance_about_you',
    'enhance_gallery'
])
export type StepKeyName = z.infer<typeof StepKeyName>

export const Category = z.enum([
    'history_culture',
    'food_drink',
    'nature_outdoors',
    'art_design',
    'entertainment',
    'fitness_wellness',
    'generic'
])
export type Category = z.infer<typeof Category>

export const CategoryToMap = z.enum([
    'history_culture',
    'food_drink',
    'nature_outdoors',
    'art_design',
    'fitness_wellness'
])
export type CategoryToMap = z.infer<typeof CategoryToMap>

export const CategoryFormat = z.enum([
    'landmark_tour',
    'museum_tour',
    'cultural_tour',
    'live_performances',
    'food_tour',
    'tasting',
    'cooking_classes',
    'dining',
    'gallery_tour',
    'art_workshop',
    'architecture_tour',
    'shopping_fashion',
    'outdoor',
    'water_sports',
    'flying',
    'wildlife',
    'concerts',
    'theater_dance',
    'nightlife',
    'games',
    'workouts',
    'wellness',
    'beauty',

    'catering',
    'chef',
    'prepared_meals',
    'photography',
    'massage',
    'hair_styling',
    'makeup',
    'nails',
    'personal_training',
    'spa_treatments'
])

export type CategoryFormat = z.infer<typeof CategoryFormat>

export const QuestionKeyName = z.enum([
    'expertise_years',
    'host_experience_title',
    'host_expertise_description',
    'host_first_name',
    'host_last_name', //TODO check if BE added
    'online_profile_links',
    'profile_photo',
    'residential_country_region',
    'residential_address',
    'residential_address_extras',
    'residential_city',
    'residential_state',
    'hosting_as_business',
    'business_name',
    'residential_zip',
    'location_address',
    'country_region',
    'location_address_extras',
    'location_city',
    'location_state',
    'location_zip',
    'experience_description',
    'listing_title',
    'guest_individual_price',
    'instance_private_groups',
    'group_minimum_price',
    'always_require_minimum_price',
    'new_listing_discount',
    'early_bird_discount',
    'main_language',
    'additional_languages',
    'maximum_private_group_size',
    'maximum_public_group_size',
    'first_guests_cutoff_time',
    'additional_guests_cutoff_time',
    'unlisted_datetimes_allowed',
    'experience_start_time',
    'cancellation_policy',
    'skill_level',
    'activity_level',
    'parents_kids_under_2',
    'guest_bringing_items',
    'guest_bring_something',
    'accessibility_additional_information',
    'accessibility_additional_qualifications',
    'accessibility_features',
    'minimum_age',
    'host_discusses_poi_significance',
    'host_serve_alcohol',
    'host_serve_food',
    'host_transporting_guests_method',
    'host_transports_guests',
    'involves_national_park',
    'involves_point_of_interest',
    'is_licensed_establishment',
    'prepared_in_restaurant',
    'experience_title',
    'host_recognition_title',
    'host_recognition_subtitle',
    'meeting_point_description',
    'meeting_point_name',

    // enhance
    'improved_host_experience_title',
    'improved_host_expertise_description',
    'improved_host_recognition_title',
    'improved_host_recognition_subtitle',
    'improved_host_credential',
    'improved_experience_title',
    'improved_experience_description',
    // to check
    'discounts',
    'guests_minimum_number',

    // Not used
    'cancel_7_days_before',
    'cancel_24_hours_before',
    'offer_large_group_discount',
    'residential_street',
    'activity_description',
    'activity_duration',
    'activity_main_photo',
    'activity_title',
    'byline',
    'guest_activities',
    'host_credentials',
    'host_expertise',
    'host_name',
    'host_recognition',
    'location_address_extras',
    'location_description',
    'residential_address_extras',
    'expertise_summary',
    'recognition_summary',
    'credentials_summary',
    'have_accessibility_features',
    'host_credential',
    'title',
    'description',
    'duration',
    'main_photo_url',
    'improved_title',
    'improved_description'
])
export type QuestionKeyName = z.infer<typeof QuestionKeyName>

export const FormFieldKeyName = z.enum([
    ...QuestionKeyName.options,
    'categoryKeyName',
    'subCategoryKeyName',
    'currency',
    'discounts',
    //
    'first_name',
    'last_name',
    'sign_language_options',
    'designated_sighted_guide',
    'step_free_access',
    'step_free_access_bathroom',
    'disabled_parking_spot',
    'entrances_wider_32_inch',
    'mainly_flat_leveled_ground',
    'no_extreme_sensory_stimuli',
    'quiet_retreat_space_available',
    'free_admission_access_providers',
    'activities'
])
export type FormFieldKeyName = z.infer<typeof FormFieldKeyName>

export const TransportingGuestsMode = z.enum(['car', 'boat', 'plane', 'motorcycle'])
export type TransportingGuestsMode = z.infer<typeof TransportingGuestsMode>

export const ActivityLevel = z.enum(['light', 'moderate', 'strenuous', 'extreme'])
export type ActivityLevel = z.infer<typeof ActivityLevel>
export const SkillLevel = z.enum(['beginner', 'intermediate', 'advanced', 'expert'])
export type SkillLevel = z.infer<typeof SkillLevel>

export const AccessibilityFeatures = z.enum([
    'sign_language_options',
    'designated_sighted_guide',
    'step_free_access',
    'step_free_access_bathroom',
    'disabled_parking_spot',
    'entrances_wider_32_inch',
    'mainly_flat_leveled_ground',
    'no_extreme_sensory_stimuli',
    'quiet_retreat_space_available',
    'free_admission_access_providers'
])
export type AccessibilityFeatures = z.infer<typeof AccessibilityFeatures>

export const ExperienceBaseQuestion = z.object({
    id: z.number(),
    parentFlags: z.array(ExperiencesFlag)
})

const ExperienceBase = z.object({
    id: z.number().int().positive(),
    title: z.string().nullable(),
    stepKeyName: StepKeyName,
    isUpgrade: z.boolean(),
    isMigratedToService: z.coerce.boolean(),
    serviceSubmissionId: z.number().nullable(),
    serviceSubmissionStatus: ServiceStatus.nullable(),
    createdAt: z.string().nullable(),
    serviceTitle: z.string().nullable(),
    status: z
        .enum([
            'archived_pending_host_approval',
            'archived_update_required',
            'canceled',
            'declined',
            'in_progress',
            'pending_export',
            'pending_merchandising',
            'pending_publishing',
            'pending_shell_creation',
            'pending_shell_submission',
            'publishing_enabled',
            'under_review',
            'update_required',
            'license_required',
            'host_in_progress',
            'pending_id_verification',
            'approval_required',
            'approved',
            'migrated_to_service'
        ])
        .nullable()
})

export const ExperienceSmall = ExperienceBase
export type ExperienceSmall = z.infer<typeof ExperienceSmall>

export const ExperienceRegular = ExperienceBase.extend({})
export type ExperienceRegular = z.infer<typeof ExperienceRegular>

export const ExperienceExtended = ExperienceRegular.extend({
    category: z.object({
        category: z
            .object({
                keyName: Category
            })
            .nullable(),
        subCategory: z
            .object({
                keyName: CategoryFormat
            })
            .nullable()
    }),
    step: z.object({keyName: StepKeyName}),
    currency: z.string().nullable().optional(),
    itineraries: z.array(Activity).nullable(),
    minimumImageRequired: z.number().nullable(),
    discounts: z.array(
        z.object({
            groupSize: z.number().int().positive(),
            discountInPercentage: z.number()
        })
    ),
    gallery: z
        .object({
            id: z.number(),
            images: z.array(UploadedMedia),
            parentFlags: z.array(ExperiencesFlag).optional()
        })
        .nullable(),
    host: z.object({
        airbnbVerifiedPhone: z.string().min(1).nullable(),
        profilePhotoUrl: z.string().url().nullable(),
        firstName: z.string()
    }),
    questions: z
        .array(
            z.object({
                keyName: QuestionKeyName
            })
        )
        .nullable(),
    expertise: z.object({
        answers: z.array(
            //We need to use literal instead of enum to simplify the remap util (it doesn't work with z.enum)
            z.discriminatedUnion('questionKeyName', [
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.expertise_years),
                    answer: z.coerce.number(),
                    previousAnswer: z.coerce.number().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_expertise_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_experience_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_first_name),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_last_name),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.online_profile_links),
                    answer: z.array(z.string().url()),
                    previousAnswer: z.array(z.string().url()).nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.profile_photo),
                    answer: z.string().url().nullable(),
                    previousAnswer: z.string().url().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_country_region),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_address),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_address_extras),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_city),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_state),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.hosting_as_business),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.business_name),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_zip),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_address),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.country_region),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_address_extras),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_city),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_state),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_zip),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.listing_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.experience_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guest_individual_price),
                    answer: z.number(),
                    previousAnswer: z.number().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.instance_private_groups),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.group_minimum_price),
                    answer: z.number(),
                    previousAnswer: z.number().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.always_require_minimum_price),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.new_listing_discount),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.early_bird_discount),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.discounts),
                    answer: z.array(
                        z.object({
                            groupSize: z.number().int().positive(),
                            discountInPercentage: z.number()
                        })
                    ),
                    previousAnswer: z
                        .array(
                            z.object({
                                groupSize: z.number().int().positive(),
                                discountInPercentage: z.number()
                            })
                        )
                        .nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.additional_languages),
                    answer: z.array(z.string()).nullable(),
                    previousAnswer: z.array(z.string()).nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.maximum_private_group_size),
                    answer: z.number(),
                    previousAnswer: z.number().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.maximum_public_group_size),
                    answer: z.number(),
                    previousAnswer: z.number().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guests_minimum_number),
                    answer: z.number(),
                    previousAnswer: z.number().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.cancel_7_days_before),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.cancel_24_hours_before),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.main_language),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.experience_start_time),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.first_guests_cutoff_time),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.additional_guests_cutoff_time),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.unlisted_datetimes_allowed),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.cancellation_policy),
                    answer: z.enum(['7_days', '24_hours']),
                    previousAnswer: z.enum(['7_days', '24_hours']).nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.activity_level),
                    answer: ActivityLevel,
                    previousAnswer: ActivityLevel.nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.skill_level),
                    answer: SkillLevel,
                    previousAnswer: SkillLevel.nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.parents_kids_under_2),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guest_bring_something),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guest_bringing_items),
                    answer: z.array(z.string()),
                    previousAnswer: z.array(z.string()).nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.accessibility_features),
                    answer: z.array(AccessibilityFeatures),
                    previousAnswer: z.array(AccessibilityFeatures).nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.accessibility_additional_information),
                    answer: z.string().nullable(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.accessibility_additional_qualifications),
                    answer: z.string().nullable(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.minimum_age),
                    answer: z.number(),
                    previousAnswer: z.number().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.involves_national_park),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.involves_point_of_interest),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_discusses_poi_significance),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_transports_guests),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_serve_food),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_serve_alcohol),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.prepared_in_restaurant),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.is_licensed_establishment),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_transporting_guests_method),
                    answer: TransportingGuestsMode.array(),
                    previousAnswer: TransportingGuestsMode.array().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.experience_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_recognition_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_recognition_subtitle),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.meeting_point_name),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.meeting_point_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),

                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_experience_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_expertise_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_recognition_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_recognition_subtitle),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_host_credential),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_experience_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_experience_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),

                // Not used
                z.object({
                    questionKeyName: z.literal(QuestionKeyName.enum.host_credential),
                    answer: z.string(),
                    previousAnswer: z.string().nullable(),
                    parentFlags: z.array(ExperiencesFlag)
                }),
                z.object({
                    questionKeyName: z.literal(QuestionKeyName.enum.title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable(),
                    parentFlags: z.array(ExperiencesFlag)
                }),
                z.object({
                    questionKeyName: z.literal(QuestionKeyName.enum.description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable(),
                    parentFlags: z.array(ExperiencesFlag)
                }),

                z.object({
                    questionKeyName: z.literal(QuestionKeyName.enum.expertise_summary),
                    answer: z.string(),
                    previousAnswer: z.string().nullable(),
                    parentFlags: z.array(ExperiencesFlag)
                }),

                z.object({
                    questionKeyName: z.literal(QuestionKeyName.enum.credentials_summary),
                    answer: z.string(),
                    previousAnswer: z.string().nullable(),
                    parentFlags: z.array(ExperiencesFlag)
                }),

                z.object({
                    questionKeyName: z.literal(QuestionKeyName.enum.recognition_summary),
                    answer: z.string(),
                    previousAnswer: z.string().nullable(),
                    parentFlags: z.array(ExperiencesFlag)
                }),

                z.object({
                    questionKeyName: z.literal(QuestionKeyName.enum.residential_street),
                    answer: z.string(),
                    previousAnswer: z.string().nullable(),
                    parentFlags: z.array(ExperiencesFlag)
                }),

                z.object({
                    questionKeyName: z.literal(QuestionKeyName.enum.have_accessibility_features),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable(),
                    parentFlags: z.array(ExperiencesFlag)
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.offer_large_group_discount),
                    answer: z.boolean(),
                    previousAnswer: z.boolean().nullable(),
                    parentFlags: z.array(ExperiencesFlag)
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.main_photo_url),
                    previousAnswer: z.string().url().nullable(),
                    answer: z.string().url()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.duration),
                    answer: z.number(),
                    previousAnswer: z.number().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.guest_activities),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.location_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.byline),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_title),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                }),
                ExperienceBaseQuestion.extend({
                    questionKeyName: z.literal(QuestionKeyName.enum.improved_description),
                    answer: z.string(),
                    previousAnswer: z.string().nullable()
                })
            ])
        )
    })
})
export type ExperienceExtended = z.infer<typeof ExperienceExtended>

export type UpdateEnhanceStepPayload = {
    improvedExperienceTitle?: string
    improvedExperienceDescription?: string
    improvedHostExperienceTitle?: string
    improvedHostExperienceDescription?: string
    improvedHostRecognitionTitle?: string
    improvedHostRecognitionSubtitle?: string
    itineraries?: {
        id: number
        improvedTitle: string
        improvedDescription: string
        mainPhoto?: number
    }[]
}
