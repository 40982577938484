import {FC, useMemo, useState} from 'react'
import {useTranslation, APP_LANGUAGES, MAIN_LANGUAGES, SERVICES_LANGUAGES} from '@/translations/i18n.tsx'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledLangModal, StyledLangModalList} from './style'
import {Modal} from '@/components/ui/modal/Modal'
import {LangSelectionModalHeader} from './LangSelectionModalHeader.tsx'
import {useLocation} from 'react-router-dom'
import {checkIsExperiencesRoute, checkIsServicesRoute} from '@/utilities/helpers.ts'

interface LangModalProps {
    onCloseCb: () => void
}

export const LangSelectionModal: FC<LangModalProps> = ({onCloseCb}) => {
    const {
        t,
        i18n,
        i18n: {language}
    } = useTranslation()
    const location = useLocation()
    const isExperiencesRoute = checkIsExperiencesRoute(location.pathname)
    const isServicesRoute = checkIsServicesRoute(location.pathname)
    const availableLanguages = isExperiencesRoute
        ? APP_LANGUAGES
        : isServicesRoute
        ? SERVICES_LANGUAGES
        : MAIN_LANGUAGES

    const currentLanguage = availableLanguages.find(lang => lang.value == language) ?? availableLanguages[0]

    const [search, setSearch] = useState('')
    const onChangeLanguage = async (nextLanguage: string) => {
        await i18n.changeLanguage(nextLanguage)
    }

    const languagesList = useMemo(() => {
        return [currentLanguage, ...availableLanguages.filter(lang => lang.value !== currentLanguage.value)].filter(
            lang =>
                lang.label.toLowerCase().includes(search.toLowerCase()) ||
                lang.value.toLowerCase().includes(search.toLowerCase())
        )
    }, [currentLanguage, search, isExperiencesRoute, isServicesRoute])

    return (
        <Modal width={850} onOverlayClick={onCloseCb}>
            <StyledLangModal>
                <LangSelectionModalHeader onClose={onCloseCb} onChangeSearch={setSearch} />

                {languagesList.length >= 1 ? (
                    <StyledLangModalList>
                        {languagesList.map(lang => (
                            <button
                                className={lang.value == currentLanguage.value ? 'current-language' : ''}
                                onClick={() => onChangeLanguage(lang.value)}
                                key={lang.value}
                            >
                                {lang.label}
                            </button>
                        ))}
                    </StyledLangModalList>
                ) : (
                    <Flexbox height="100%" width="100%" justify="center" align="center" padding={6}>
                        {t('commons:no_language_results')}
                    </Flexbox>
                )}
            </StyledLangModal>
        </Modal>
    )
}

LangSelectionModal.displayName = 'LangSelectionModal'
