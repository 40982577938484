import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {AlertBanner, FieldTitle, FieldDescription} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {PlusIcon} from '@/components/ui/icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledPhoneNumber} from './style'
import {ChangePhoneModal} from '../change-phone-modal/ChangePhoneModal'

export const ChangePhoneNumber: FC<{
    airbnbVerifiedPhone?: string | null
    userPhone?: string | null
    isSubmitted: boolean
    onSuccess: (phoneNumber: string) => void
}> = ({airbnbVerifiedPhone, userPhone, isSubmitted, onSuccess}) => {
    const {t} = useTranslation()
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)

    return (
        <>
            <Flexbox direction="column" gap={4} width="100%">
                <Flexbox direction="column" gap={1.5}>
                    <FieldTitle>{t('services:step_host_expertise_about_yourself:phone_title')}</FieldTitle>
                    <FieldDescription>
                        {t('services:step_host_expertise_about_yourself:phone_description')}
                    </FieldDescription>
                </Flexbox>

                {airbnbVerifiedPhone || userPhone ? (
                    <Flexbox justify="space-between" align="center" gap={4} width="100%">
                        <StyledPhoneNumber>+{airbnbVerifiedPhone ?? userPhone}</StyledPhoneNumber>
                        <Button variant="tertiary" onClick={() => setIsPhoneModalOpen(true)}>
                            {t('commons:edit')}
                        </Button>
                    </Flexbox>
                ) : (
                    <Button variant="tertiary" onClick={() => setIsPhoneModalOpen(true)}>
                        <PlusIcon />
                        {t('services:step_host_expertise_about_yourself:phone_cta')}
                    </Button>
                )}
                {!airbnbVerifiedPhone && !!userPhone && (
                    <AlertBanner title={t('services:step_host_expertise_about_yourself:phone_alert')} />
                )}
                {isSubmitted && !airbnbVerifiedPhone && !userPhone && (
                    <InputHelpText error={t('errors:field_required')} />
                )}
            </Flexbox>

            {isPhoneModalOpen && <ChangePhoneModal onClose={() => setIsPhoneModalOpen(false)} onSuccess={onSuccess} />}
        </>
    )
}
