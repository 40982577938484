import axiosNode from '@/axiosInstanceNode'
import axios from '@/axiosInstance.ts'
import {ResponseType} from '@/types/commons'
import {parseResponseData} from '@/utilities/helpers'
import {captureException} from '@sentry/react'
import {z} from 'zod'

/*Upload profile photo*/
export interface HttpUploadProfilePhotoOptions {
    urlParams: {hostId: string}
    payload: FormData
    params: {response_type: ResponseType}
}

export const httpUploadProfilePhoto = async ({urlParams, payload, params}: HttpUploadProfilePhotoOptions) => {
    const response = await axios.post(`elevate/hosts/${urlParams.hostId}/profile-photo`, payload, {
        params,
        headers: {'Content-Type': 'multipart/form-data'}
    })

    return parseResponseData({
        responseData: response.data,
        responseShape: z.object({url: z.string().url()}),
        onZodError: captureException
    })
}

/*Change phone number*/
export const httpAddPhoneNumber = ({payload}: {payload: {phone: string}}) =>
    axiosNode.post('hosts/verification-phone', payload)

export const httpVerifyPhoneNumber = ({payload}: {payload: {verificationCode: number}}) =>
    axiosNode.post('hosts/verification-phone/verify', payload)

export const httpResendVerificationCode = () => axiosNode.post('hosts/verification-phone/resend')
