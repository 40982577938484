import {genericRootFlowSteps} from '@/features/chatbot/flows/genericRootFlowSteps.tsx'
import {genericServicePageSteps} from '@/features/chatbot/flows/genericServicePageSteps.tsx'
import {hp0} from '@/features/chatbot/flows/hp0.tsx'
import {HttpGetServiceResponse} from '@/features/service/http/service.http'
import {hp1} from '@/features/chatbot/flows/hp1.tsx'
import {hp2} from '@/features/chatbot/flows/hp2.tsx'
import {hp3} from '@/features/chatbot/flows/hp3.tsx'
import {hp4} from '@/features/chatbot/flows/hp4.tsx'
import {hp5} from '@/features/chatbot/flows/hp5.tsx'
import {hp6} from '@/features/chatbot/flows/hp6.tsx'
import {hp7} from '@/features/chatbot/flows/hp7.tsx'
import {hp10} from '@/features/chatbot/flows/hp10.tsx'
import {hp11} from '@/features/chatbot/flows/hp11.tsx'
import {hp9} from '@/features/chatbot/flows/hp9.tsx'
import {hp8} from '@/features/chatbot/flows/hp8.tsx'
import {hp20} from '@/features/chatbot/flows/hp20.tsx'
import {hp21} from '@/features/chatbot/flows/hp21.tsx'
import {hp30} from '@/features/chatbot/flows/hp30.tsx'
import {finalFlowsSteps} from '@/features/chatbot/flows/finalStep.tsx'
import {hp1Luxe} from '@/features/chatbot/flows/hp1Luxe.tsx'
import {hp31} from '@/features/chatbot/flows/hp31.tsx'
import {hp32} from '@/features/chatbot/flows/hp32.tsx'
import {getServiceType} from '@utilities/helpers.ts'
import {luxeServicePageSteps} from '@/features/chatbot/flows/luxeServicePageSteps.tsx'
import {hp33} from '@/features/chatbot/flows/hp33.tsx'
import {hp35} from '@/features/chatbot/flows/hp35.tsx'
import {hp34} from '@/features/chatbot/flows/hp34.tsx'

interface ChatbotMainStepsParams {
    service: HttpGetServiceResponse | null
}

export const mainSteps = ({service}: ChatbotMainStepsParams) => {
    const quoteLink = `https://hostservices.frontend.k-stage.dev/hosts/${service?.entity.code}/${service?.entity.password}/quotes/${service?.quote.id}`
    const isPending = service?.assignment?.task_status.status == 'waitlisted' || service?.quote.quote_status == 'signed'
    const isLuxeWalkthrough = service
        ? getServiceType(service?.task_media.name, service?.task_category.name) == 'walkthrough'
        : false
    const serviceBlocked = service?.task_media?.name == 'video'

    return serviceBlocked
        ? [
              ...hp11({quoteLink}) // General ticket submission | API: TicketSubmit
          ]
        : [
              ...(!service
                  ? genericRootFlowSteps()
                  : isLuxeWalkthrough
                  ? luxeServicePageSteps({service})
                  : genericServicePageSteps({service})), //Root flows
              ...hp0(), // How to request a new photo-shoot for another listing
              ...hp1({
                  backToGeneric: !service,
                  quotePrice: service?.quote.amount ?? null,
                  listingTitle: service?.entity.title ?? 'this listing'
              }), // // Quote information | API: SimpleLink
              ...hp1Luxe(), // // Quote information | API: SimpleLink
              ...hp2({service}), // Images info | API: SimpleLink
              ...hp3({expirationDate: service?.quote.expire_at}), // Quote and Expiration information
              ...hp4({quoteLink}), // Wrong bedroom number | API: TicketSubmit
              ...hp5(), // Photoshoot scheduling
              ...hp6({backToGeneric: !service}), // Preparing for the photoshoot | API: SimpleLink
              ...hp7({
                  isPending: isPending,
                  isAutoCanceledOrSoftCanceled:
                      service?.assignment?.task_status.status == 'auto_cancelled' ||
                      service?.assignment?.task_status.status == 'soft_cancelled'
              }), // Photographer match | API: SimpleLink
              ...hp8({quoteLink}), // Production and Post statuses | API: GetStatus, TicketSubmit
              ...hp9({quoteLink}), // Incorrect upload | API: TicketSubmit
              ...hp10({quoteLink}), // Missing images | API: TicketSubmit
              ...hp11({quoteLink}), // General ticket sumbission | API: TicketSubmit
              ...hp20({
                  photographerName: service?.assignment?.photographer?.name,
                  photographerContact:
                      service?.assignment?.photographer?.phone ?? service?.assignment?.photographer?.email
              }), // General ticket sumbission | API: TicketSubmit
              ...hp21({
                  isAutoCanceledOrSoftCanceled:
                      service?.assignment?.task_status.status != 'pending_assets' &&
                      (service?.assignment?.task_status.status == 'auto_cancelled' ||
                          service?.assignment?.task_status.status == 'soft_cancelled'),
                  isPendingShootDate:
                      service?.assignment?.task_status.status != 'pending_assets' && !service?.assignment?.to_shoot_at,
                  photographerName: service?.assignment?.photographer?.name,
                  photographerContact:
                      service?.assignment?.photographer?.phone ?? service?.assignment?.photographer?.email
              }), // Coordination with the photographer | API: SendReminder, AssignNew
              ...hp30(),
              ...hp31(), //What is Luxe ( Walkthrough flow )
              ...hp32({quoteLink}), //Luxe General ticket sumbission | API: TicketSubmit
              ...hp33({freelancer: service?.assignment?.photographer}), //Luxe scheduling
              ...hp34({service}), //Luxe Coordination with my home consultant
              ...hp35({quoteLink, service}), //Luxe other
              ...finalFlowsSteps({backToGeneric: !service, isLuxeWalkthrough: isLuxeWalkthrough}) //rating steps
          ]
}
