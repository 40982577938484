import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient.ts'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'
import {httpAcceptRequestedService} from '@/features/service-requests/http/service-requests.http'
import {HttpAcceptRequestedServiceParams} from '@/features/service-requests/types.ts'
import {ServiceProposal} from '@/features/promotional-services/types.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {sendQuoteAcceptedMxEvent} from '@utilities/mixpanel/mixpanelEventCenter.ts'

type useOtpValidationProps = {
    onSuccess?: (data: ServiceProposal) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useAcceptManualRequest = (options?: useOtpValidationProps) => {
    const queryClient = useQueryClient()
    const currentManualRequest = useServiceAcceptStore(state => state.manualRequest)

    return useMutation({
        mutationKey: [MUTATION_KEYS.ACCEPT_REQUESTED_SERVICE],
        mutationFn: (params: HttpAcceptRequestedServiceParams) =>
            parseAxiosPromise({
                axiosPromise: httpAcceptRequestedService(params),
                onZodError: captureException,
                responseShape: ServiceProposal
            }),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: async data => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.PHOTO_SERVICE_REQUESTS]})
            options?.onSuccess?.(data)
            // Mixpanel accept organic request
            if (currentManualRequest) {
                sendQuoteAcceptedMxEvent(data, currentManualRequest)
            }
        }
    })
}
