import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {FC} from 'react'
import {SlidingModalCloseButton, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {ExperiencesHostVideoModalBody} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {FieldTitle, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
import {StyledGalleryTipsModal, StyledPreviewImage, StyledTip, StyledTitle} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ActivityIcon, Food02Icon, Nature02Icon} from '@/components/ui/icon'
import {Divider} from '@/components/ui/divider/Divider'
import experiencesTipPhoto from '@/assets/images/experiencesTipPhoto.png'

interface GalleryTipsModalProps {
    slidingModalState: SlidingModalState
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
}
export const GalleryTipsModal: FC<GalleryTipsModalProps> = ({slidingModalState, setSlidingModalState}) => {
    const {t} = useTranslation()

    return (
        <StyledGalleryTipsModal slidingModalState={slidingModalState}>
            <StyledTitle>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                <h3>{t('experiences:experience_gallery_step:tip_modal:header_title')}</h3>
            </StyledTitle>
            <ExperiencesHostVideoModalBody>
                <Title>{t('experiences:experience_gallery_step:tip_modal:title')}</Title>

                <Flexbox gap={4} direction="column">
                    <StyledTip>
                        <span>
                            <ActivityIcon />
                        </span>
                        <Flexbox gap={1} direction="column">
                            <h4>{t('experiences:experience_gallery_step:tip_modal:tip_1_title')}</h4>
                            <p>{t('experiences:experience_gallery_step:tip_modal:tip_1_decs')}</p>
                        </Flexbox>
                    </StyledTip>

                    <StyledTip>
                        <span>
                            <Food02Icon />
                        </span>
                        <Flexbox gap={1} direction="column">
                            <h4>{t('experiences:experience_gallery_step:tip_modal:tip_2_title')}</h4>
                            <p>{t('experiences:experience_gallery_step:tip_modal:tip_2_decs')}</p>
                        </Flexbox>
                    </StyledTip>

                    <StyledTip>
                        <span>
                            <Nature02Icon />
                        </span>
                        <Flexbox gap={1} direction="column">
                            <h4>{t('experiences:experience_gallery_step:tip_modal:tip_3_title')}</h4>
                            <p>
                                <Trans
                                    i18nKey={'experiences:experience_gallery_step:tip_modal:tip_3_decs'}
                                    components={{
                                        a: (
                                            <a
                                                style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.airbnb.com/e/host-experiences-photo-guide-hpc"
                                            />
                                        )
                                    }}
                                />
                            </p>
                        </Flexbox>
                    </StyledTip>

                    <Divider direction="horizontal" startSpacing={2} />
                </Flexbox>

                <Flexbox gap={6} direction="column">
                    <FieldTitle>{t('experiences:experience_gallery_step:tip_modal:inspiration')}</FieldTitle>

                    <StyledPreviewImage alt="inspiration tip" src={experiencesTipPhoto} />
                </Flexbox>
            </ExperiencesHostVideoModalBody>
        </StyledGalleryTipsModal>
    )
}
