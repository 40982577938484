import {SelectPopover, SelectTrigger} from '@/components/ui/select-atoms/SelectAtoms'
import styled, {css} from 'styled-components'

export const StyledSelectDurationSelectorTrigger = styled(SelectTrigger)<{$hasError?: boolean}>(
    ({theme: {typography, palette}, $hasError}) => css`
        ${typography.textMd};
        font-weight: 400;
        ${$hasError &&
        css`
            border: 1px solid ${palette.danger['300']};
        `};
    `
)

export const StyledDurationSelectPopoverWrapper = styled.div(
    ({theme: {zIndex}}) => css`
        z-index: ${zIndex.modal};
    `
)

export const StyledDurationSelectPopover = styled(SelectPopover)`
    max-height: 200px;
`
