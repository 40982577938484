import axios from 'axios'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@utilities/constants'
import {envVars} from '@/envVars.ts'
import {useAuthStore} from '@/store/AuthStore.tsx'

const axiosConfig = {
    baseURL: envVars.VITE_API_ENDPOINT_NODE,
    headers: {
        common: {
            Authorization: localStorage.getItem('accessToken')
                ? `Bearer ${localStorage.getItem('accessToken')}`
                : false,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        post: {
            'Access-Control-Allow-Origin': '*'
        }
    }
}

// create axios custom instance with custom config
const axiosInstanceNode = axios.create(axiosConfig)

axiosInstanceNode.interceptors.request.use(request => {
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
    if (token) request.headers['Authorization'] = `Bearer ${token}`
    return request
})

axiosInstanceNode.interceptors.response.use(
    response => {
        // pass response without change
        return response
    },
    error => {
        // get error info
        const statusCode = error?.response?.status

        switch (statusCode) {
            case 401: {
                localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
                localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
                useAuthStore.getState().reset()
                return Promise.reject(error)
            }
            default:
                return Promise.reject(error)
        }
    }
)

export default axiosInstanceNode
