import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {AlertCircleIcon, File02Icon} from '@/components/ui/icon'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {TKey, useTranslation} from '@/translations/i18n'
import {Helmet} from 'react-helmet'
import {
    StyledButtonLink,
    StyledCard,
    StyledEmptyState,
    StyledExperiences,
    StyledList,
    StyledStatusBullet
} from '@/pages/experiences/style'
import {Button} from '@/components/ui/button/Button'
import {useEffect, useRef, useState} from 'react'
import {SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {useExperiences} from '@/features/host-submissions/experiences/queries/useExperiences'
import {ExperiencesSupportModal} from '@/features/host-submissions/experiences/components/experiences-support-modal/ExperiencesSupportModal'
import {ExperienceSmall, StepKeyName} from '@/features/host-submissions/experiences/types'
import {useInView} from '@/hooks/useInView'
import {httpCreateExperience} from '@/features/host-submissions/experiences/http/experiences.http'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {errorHandler, formatLocaleDate} from '@/utilities/helpers'
import {generatePath, useNavigate} from 'react-router-dom'
import {useAsync} from '@/hooks/useAsync'
import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {z} from 'zod'
import {httpSetReferralIds} from '@/features/sso/http/auth.http'
import {routes} from '@/utilities/constants'
import {serviceStatusToLabel} from '../services/Services'
import {StyledServiceStatusBullet} from '../services/style'

const statusToLabel = {
    host_in_progress: 'experiences:status_in_progress',
    pending_export: 'experiences:status_approved',
    approved: 'experiences:status_approved',
    pending_merchandising: 'experiences:status_in_progress',
    in_progress: 'experiences:status_in_progress',
    pending_shell_submission: 'experiences:status_in_progress',
    pending_shell_creation: 'experiences:status_in_progress',
    publishing_enabled: 'experiences:status_approved',
    pending_publishing: 'experiences:status_approved',
    canceled: 'experiences:status_declined',
    declined: 'experiences:status_declined',
    under_review: 'experiences:status_in_review',
    license_required: 'experiences:status_update_required',
    pending_id_verification: 'experiences:status_pending_id_verification',
    archived_update_required: 'experiences:status_update_required',
    update_required: 'experiences:status_update_required',
    archived_pending_host_approval: 'experiences:approval_required',
    approval_required: 'experiences:approval_required'
} as const satisfies Record<NonNullable<Exclude<ExperienceSmall['status'], 'migrated_to_service'>>, TKey>

export const Experiences = () => {
    const {t} = useTranslation()
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null)
    const {searchParams, setSearchParams} = useParsedSearchParams(z.object({referral_id: z.string().optional()}))
    const [supportSlidingModalState, setSupportSlidingModalState] = useState<SlidingModalState>('closed')
    const {remappedData, isLoading, hasNextPage, isFetching, fetchNextPage, isError} = useExperiences({
        responseType: 'small'
    })
    const isInView = useInView(loadMoreButtonRef, {threshold: 0.5})

    const createExperienceAsync = useAsync()
    const continueAsync = useAsync()
    const navigate = useNavigate()

    const getCorrectDescription = (experience: ExperienceSmall) => {
        switch (experience.status) {
            case 'in_progress':
                return t(
                    experience.isUpgrade ? 'experiences:complete_experience_update' : 'experiences:complete_experience'
                )
            case 'update_required':
                return t('experiences:complete_experience_update_required')
            default:
                return experience.createdAt
                    ? t('services:created_on', {
                          date: formatLocaleDate(experience.createdAt, 'll')
                      })
                    : '-'
        }
    }
    const setReferralId = async () => {
        if (searchParams.referral_id) {
            try {
                await continueAsync.run(
                    httpSetReferralIds({payload: {experiences_referral_id: searchParams.referral_id}})
                )
                setSearchParams({referral_id: undefined})
            } catch (error) {
                errorHandler(error)
            }
        } else {
            setSearchParams({referral_id: undefined})
        }
    }

    useEffect(() => {
        void setReferralId()
    }, [])

    useEffect(() => {
        if (isInView && hasNextPage && !isFetching) {
            fetchNextPage()
        }
    }, [isInView, hasNextPage, isFetching])

    const createExperience = async () => {
        try {
            const response = await createExperienceAsync.run(httpCreateExperience())
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCES]})
            navigate(String(response.data.id))
        } catch (error) {
            errorHandler(error)
        }
    }

    if (isLoading)
        return (
            <Flexbox height="100%" justify="center" align="center">
                <Spinner size={48} />
            </Flexbox>
        )

    if (isError) return <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />

    return (
        <>
            <Helmet title={t('experiences:meta_title')} />

            <StyledExperiences>
                <Flexbox direction="column" gap={6}>
                    <Flexbox justify={'space-between'} align={'center'} width={'100%'}>
                        <h2>{t('experiences:title')}</h2>

                        {remappedData.length >= 1 && (
                            <Button
                                variant={'tertiary'}
                                size={'sm'}
                                onClick={() => setSupportSlidingModalState('open')}
                            >
                                {t('commons:need_help')}
                            </Button>
                        )}
                        {supportSlidingModalState != 'closed' && (
                            <ExperiencesSupportModal
                                slidingModalState={supportSlidingModalState}
                                setSlidingModalState={setSupportSlidingModalState}
                            />
                        )}
                    </Flexbox>
                    {remappedData.length >= 1 && (
                        <Button
                            variant="secondary"
                            size="sm"
                            disabled={createExperienceAsync.isLoading}
                            onClick={createExperience}
                        >
                            {t('experiences:create_experience')}
                            {createExperienceAsync.isLoading && <Spinner />}
                        </Button>
                    )}
                </Flexbox>
                {remappedData.length == 0 ? (
                    <StyledEmptyState>
                        <File02Icon size={42} />
                        <h3>{t('experiences:no_experiences_yet')}</h3>
                        <Button
                            variant="secondary"
                            size="sm"
                            disabled={createExperienceAsync.isLoading}
                            onClick={createExperience}
                        >
                            {t('experiences:create_experience')}
                            {createExperienceAsync.isLoading && <Spinner />}
                        </Button>
                    </StyledEmptyState>
                ) : (
                    <>
                        <StyledList>
                            {remappedData.map(experience => (
                                <StyledCard key={experience.id}>
                                    <Flexbox direction="column" gap={2}>
                                        <h4>
                                            {experience.status == 'migrated_to_service'
                                                ? experience.serviceTitle
                                                : experience.title}
                                        </h4>

                                        <p>{getCorrectDescription(experience)}</p>
                                    </Flexbox>
                                    <Flexbox justify="space-between" align="center" gap={2} width="100%">
                                        {experience.status && experience.stepKeyName != StepKeyName.enum.home && (
                                            <Flexbox align="center" gap={2.5}>
                                                {experience.status == 'migrated_to_service' ? (
                                                    experience.serviceSubmissionStatus && (
                                                        <>
                                                            <StyledServiceStatusBullet
                                                                $status={experience.serviceSubmissionStatus}
                                                            />
                                                            <p>
                                                                {t(
                                                                    serviceStatusToLabel[
                                                                        experience.serviceSubmissionStatus
                                                                    ]
                                                                )}
                                                            </p>
                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        <StyledStatusBullet $status={experience.status} />

                                                        <p>{t(statusToLabel[experience.status])}</p>
                                                    </>
                                                )}
                                            </Flexbox>
                                        )}

                                        <StyledButtonLink
                                            to={
                                                experience.isMigratedToService
                                                    ? generatePath(routes.SERVICE.path, {
                                                          id: experience.serviceSubmissionId
                                                      })
                                                    : experience.id.toString()
                                            }
                                            size="sm"
                                        >
                                            {t('commons:open')}
                                        </StyledButtonLink>
                                    </Flexbox>
                                </StyledCard>
                            ))}
                        </StyledList>

                        {hasNextPage && (
                            <Button
                                disabled={isFetching}
                                ref={loadMoreButtonRef}
                                onClick={() => fetchNextPage()}
                                variant="ghost"
                            >
                                {t('categories:load_more')}
                                {isFetching && <Spinner />}
                            </Button>
                        )}
                    </>
                )}
            </StyledExperiences>
        </>
    )
}
