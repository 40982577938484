import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient.ts'
import {httpGetExperiences} from '@/features/host-submissions/experiences/http/experiences.http'
import {ResponseType} from '@/types/commons'

export const useExperiences = ({responseType}: {responseType: ResponseType}) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.EXPERIENCES, responseType],
        queryFn: ({pageParam}: {pageParam: string | undefined}) =>
            httpGetExperiences({
                params: {
                    responseType: responseType,
                    page: pageParam
                }
            }),
        initialPageParam: undefined,
        getNextPageParam: ({meta: {currentPage, lastPage}}) =>
            currentPage !== lastPage ? `${currentPage + 1}` : undefined
    })

    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        //to avoid results like [undefined]
        remappedData: query.data?.pages[0]?.data ? query.data.pages.flatMap(page => page.data) : []
    }
}
