import Container from '@components/ui/container/Container.tsx'
import {Helmet} from 'react-helmet'
import {useTranslation} from '@/translations/i18n.tsx'
import {AirbnbSSOWidget} from '@/features/sso/components/airbnb-sso-widget/AirbnbSSOWidget'
import CoAlarmLogoIcon from '@assets/images/co-alarm/logo_icon.svg'
import {StyledLearnMoreLink} from './style'
import {routes} from '@/utilities/constants'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {Navigate} from 'react-router-dom'

export const CoAlarmSsoLogin = () => {
    const {t} = useTranslation()
    const accessToken = useAuthStore(store => store.accessToken)

    if (accessToken) {
        return <Navigate to={routes.CO_ALARM_DASHBOARD.path} replace />
    }

    return (
        <Container>
            <Helmet title={t('sso_login:page_title_meta')} />

            <AirbnbSSOWidget
                serviceIcon={<img src={CoAlarmLogoIcon} alt={'smoke-icon'} />}
                title={t('coAlarm:login:title')}
                subtitle={t('coAlarm:login:subtitle')}
                redirectUrl={routes.CO_ALARM_DASHBOARD.path}
                disclaimer={
                    <StyledLearnMoreLink
                        variant="tertiary"
                        to="https://www.airbnb.com/help/article/3267"
                        target="_blank"
                    >
                        {t('commons:learn_more')}
                    </StyledLearnMoreLink>
                }
            />
        </Container>
    )
}

CoAlarmSsoLogin.displayName = 'CoAlarmSsoLogin'
