import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps
} from '@/features/host-submissions/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    Footer,
    FieldTitle,
    Title,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {z} from 'zod'
import {useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {AnswerItem} from '@components/commons/answer-item/AnswerItem.tsx'
import {CheckIcon, XCloseIcon} from '@components/ui/icon'
import {
    StyledInputContainer,
    StyledList
} from '@/features/host-submissions/services/components/other-details-about-guests-step/style.ts'
import InputText from '@components/commons/input-text/InputText.tsx'
import TextArea from '@components/commons/textarea/TextArea.tsx'
import {Service} from '@/features/host-submissions/services/types.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {useUpdateStepToOtherDetailsMoreDetails} from '@/features/host-submissions/services/queries/useUpdateStepToOtherDetailsMoreDetails'
import {useUpdateStepToGallery} from '@/features/host-submissions/services/queries/useUpdateStepToGallery'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {ConfirmSubmitModal} from '@/features/host-submissions/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {FlagsAlertText} from '../../../components/flags-alert-text/FlagsAlertText'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

const ValidationSchema = z
    .object({
        age_limit: z.enum(['yes', 'no'], {
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        age: z.coerce
            .number()
            .max(100, {
                message: i18n.t('services:step_other_details_about_guests:age_limit')
            })
            .nullish(),
        bring_anything: z.enum(['yes', 'no'], {
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        what_bring: z.string().nullish()
    })
    .superRefine((arg, context) => {
        if (arg.age_limit == 'yes' && !arg.age) {
            context.addIssue({
                code: 'custom',
                path: ['age'],
                message: i18n.t('services:step_other_details_about_guests:question2_error')
            })
        }
        if (arg.bring_anything == 'yes' && !arg.what_bring) {
            context.addIssue({
                code: 'custom',
                path: ['what_bring'],
                message: i18n.t('services:step_other_details_about_guests:question4_error')
            })
        }
    })
type ValidationSchema = z.infer<typeof ValidationSchema>

export const OtherDetailsAboutGuestsStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const form = useForm<ValidationSchema>({
        resolver: zodResolver(ValidationSchema),
        defaultValues: service.expertise?.question_answer.reduce((previousValue, currentValue) => {
            if (currentValue.question_key_name == 'age_limit' && currentValue.answer) {
                return {...previousValue, age_limit: currentValue.answer}
            }
            if (currentValue.question_key_name == 'age' && currentValue.answer) {
                return {...previousValue, age: currentValue.answer}
            }
            if (currentValue.question_key_name == 'bring_anything' && currentValue.answer) {
                return {...previousValue, bring_anything: currentValue.answer}
            }
            if (currentValue.question_key_name == 'what_bring' && currentValue.answer) {
                return {...previousValue, what_bring: currentValue.answer}
            }
            return previousValue
        }, {})
    })
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const ageLimitFlags = updatesRequiredSteps.aboutGuests.find(field => field.questionKeyName == 'age_limit')?.flags
    const ageFlags = updatesRequiredSteps.aboutGuests.find(field => field.questionKeyName == 'age')?.flags
    const bringAnythingFlags = updatesRequiredSteps.aboutGuests.find(field => field.questionKeyName == 'bring_anything')
        ?.flags
    const whatBringFlags = updatesRequiredSteps.aboutGuests.find(field => field.questionKeyName == 'what_bring')?.flags
    const flagsCount = updatesRequiredSteps.aboutGuests.flatMap(field => field.flags).length
    const updateStepToGalleryMutation = useUpdateStepToGallery({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateStepToOtherDetailsMoreDetails = useUpdateStepToOtherDetailsMoreDetails({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'aboutGuests', updatesRequiredSteps}).prev?.()
            : updateStepToGalleryMutation
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'aboutGuests'>({
                  service,
                  currentStep: 'aboutGuests',
                  updatesRequiredSteps
              }).next?.()
            : updateStepToOtherDetailsMoreDetails

    const watchHaseAgeLimit = useWatch({control: form.control, name: 'age_limit'})
    const needBringAnythingLimit = useWatch({control: form.control, name: 'bring_anything'})

    const onSubmit = form.handleSubmit(data => {
        nextStepMutation?.mutate({
            age: data.age || undefined,
            age_limit: data.age_limit,
            bring_anything: data.bring_anything,
            what_bring: data.what_bring || undefined
        })
    })
    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_other_details_about_guests:title')}</Title>
                    <Paragraph>{t('services:add_details_to_help_guests')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('services:x_items_improve', {count: flagsCount})}
                            paragraph={t('services:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction={'column'} gap={6} align={'stretch'}>
                    <Flexbox direction={'column'} gap={4} align={'stretch'} width="100%">
                        <Flexbox direction={'column'} gap={1.5}>
                            <FieldTitle>{t('services:step_other_details_about_guests:question1_label')}</FieldTitle>

                            <FlagsAlertText
                                flags={ageLimitFlags}
                                title={t('services:step_other_details_about_guests:question1_label')}
                            />
                        </Flexbox>
                        <StyledList>
                            <AnswerItem
                                type="radio"
                                value={'yes'}
                                label={
                                    <Flexbox direction="column" gap={2}>
                                        <CheckIcon />
                                        {t('commons:yes')}
                                    </Flexbox>
                                }
                                {...form.register('age_limit')}
                            />
                            <AnswerItem
                                type="radio"
                                value={'no'}
                                label={
                                    <Flexbox direction="column" gap={2}>
                                        <XCloseIcon />
                                        {t('commons:no')}
                                    </Flexbox>
                                }
                                {...form.register('age_limit', {
                                    onChange: () => form.setValue('age', null)
                                })}
                            />
                        </StyledList>
                        {form.formState.errors.age_limit && (
                            <InputHelpText error={form.formState.errors.age_limit?.message} />
                        )}
                    </Flexbox>
                    {watchHaseAgeLimit == 'yes' && (
                        <StyledInputContainer direction={'column'} gap={1.5} width="100%">
                            <FlagsAlertText
                                flags={ageFlags}
                                title={t('services:step_other_details_about_guests:question2_label')}
                            />
                            <InputText
                                type={'number'}
                                inputMode={'numeric'}
                                label={t('services:step_other_details_about_guests:question2_label')}
                                placeholder={t('services:step_other_details_about_guests:question2_placeholder')}
                                errorMessage={form.formState.errors.age?.message || undefined}
                                {...form.register('age')}
                            />
                        </StyledInputContainer>
                    )}
                    <Divider direction={'horizontal'} />
                    <Flexbox direction={'column'} gap={4} align={'stretch'}>
                        <Flexbox direction={'column'} gap={1.5}>
                            <FieldTitle>{t('services:step_other_details_about_guests:question3_label')}</FieldTitle>

                            <FlagsAlertText
                                flags={bringAnythingFlags}
                                title={t('services:step_other_details_about_guests:question3_label')}
                            />
                        </Flexbox>
                        <StyledList>
                            <AnswerItem
                                type="radio"
                                value={'yes'}
                                label={
                                    <Flexbox direction="column" gap={2}>
                                        <CheckIcon />
                                        {t('commons:yes')}
                                    </Flexbox>
                                }
                                {...form.register('bring_anything')}
                            />
                            <AnswerItem
                                type="radio"
                                value={'no'}
                                label={
                                    <Flexbox direction="column" gap={2}>
                                        <XCloseIcon />
                                        {t('commons:no')}
                                    </Flexbox>
                                }
                                {...form.register('bring_anything', {
                                    onChange: () => form.setValue('what_bring', '')
                                })}
                            />
                        </StyledList>
                        {form.formState.errors.bring_anything && (
                            <InputHelpText error={form.formState.errors.bring_anything?.message} />
                        )}
                    </Flexbox>
                    {needBringAnythingLimit == 'yes' && (
                        <StyledInputContainer direction={'column'} gap={1.5} width="100%">
                            <FlagsAlertText
                                flags={whatBringFlags}
                                title={t('services:step_other_details_about_guests:question4_label')}
                            />
                            <TextArea
                                rows={6}
                                maxLength={1000}
                                label={t('services:step_other_details_about_guests:question4_label')}
                                placeholder={t('services:step_other_details_about_guests:question4_placeholder')}
                                errorMessage={form.formState.errors.what_bring?.message || undefined}
                                {...form.register('what_bring')}
                            />
                        </StyledInputContainer>
                    )}
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('about_guests')}>
                <Button
                    variant="tertiary"
                    disabled={prevStepMutation?.isPending || nextStepMutation?.isPending}
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button disabled={nextStepMutation.isPending || prevStepMutation?.isPending} onClick={onSubmit}>
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={prevStepMutation?.isPending} onClick={() => setIsConfirmSubmitOpen(true)}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'aboutGuests'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={{
                        age: form.getValues().age || undefined,
                        age_limit: form.getValues().age_limit,
                        bring_anything: form.getValues().bring_anything,
                        what_bring: form.getValues().what_bring || undefined
                    }}
                />
            )}
        </>
    )
}
