import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise, raise} from '@utilities/helpers.ts'
import {httpGetVoiceover} from '@/features/experiences-host-video/queries/experiences-host-video.http'
import {captureException} from '@sentry/react'
import {ExperiencesHostVideoUploadRouteParams} from '@/features/experiences-host-video/types.ts'
import {UploadedMedia} from '@/features/host-submissions/services/types.ts'
import {z} from 'zod'

const ShowVoiceoversResponse = z.array(UploadedMedia.and(z.object({experience_task_status_id: z.number()})))
type ShowVoiceoversResponse = z.infer<typeof ShowVoiceoversResponse>

interface UseShowQuizArgs {
    urlParams: ExperiencesHostVideoUploadRouteParams
    queryOptions?: Omit<UseQueryOptions<unknown, unknown, ShowVoiceoversResponse>, 'queryKey' | 'queryFn'>
}
export const useShowVoiceovers = ({urlParams, queryOptions}: UseShowQuizArgs) =>
    useQuery<unknown, unknown, ShowVoiceoversResponse>({
        queryKey: [QUERY_KEYS.EXPERIENCES_HOST_VIDEO_VOICEOVER, urlParams],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetVoiceover({
                    urlParams: {...urlParams, videoSetId: urlParams.videoSetId || raise('videoSetId is undefined')}
                }),
                responseShape: ShowVoiceoversResponse,
                onZodError: captureException
            }),
        ...queryOptions
    })
