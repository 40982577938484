import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {AlertCircleIcon, CheckIcon, XCloseIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {FC} from 'react'
import dayjs from '@utilities/dayjs.ts'
import {useAnswerCancellationRequest} from '@/features/service/queries/useAnswerCancellationRequest'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'

export const HomeCancellationRequestedStatusDescription: FC<{
    assignedFreelancer?: string
    cancellationRequestedAt?: Date | null
}> = ({assignedFreelancer, cancellationRequestedAt}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const answerCancellationRequestMutation = useAnswerCancellationRequest()
    const daysCount = dayjs(dayjs(cancellationRequestedAt).add(15, 'day')).diff(dayjs(), 'days')

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <AlertCircleIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:home:assigned_cancellation_requested_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:home:assigned_cancellation_requested_paragraph', {
                        freelancer: assignedFreelancer,
                        daysCount: daysCount < 0 ? 0 : daysCount
                    })}
                </StatusDescriptionParagraph>
                <Flexbox gap={2}>
                    <Button
                        style={{minWidth: 128}}
                        variant="secondary"
                        size="sm"
                        disabled={answerCancellationRequestMutation.isPending}
                        onClick={() =>
                            answerCancellationRequestMutation.mutate({
                                urlParams,
                                payload: {action: 'confirmed'}
                            })
                        }
                    >
                        <CheckIcon />
                        {t('commons:confirm')}
                        {answerCancellationRequestMutation.isPending && <Spinner size={16} />}
                    </Button>
                    <Button
                        style={{minWidth: 128}}
                        variant="secondary"
                        size="sm"
                        disabled={answerCancellationRequestMutation.isPending}
                        onClick={() =>
                            answerCancellationRequestMutation.mutate({
                                urlParams,
                                payload: {action: 'rejected'}
                            })
                        }
                    >
                        <XCloseIcon />
                        {t('commons:reject')}
                        {answerCancellationRequestMutation.isPending && <Spinner size={16} />}
                    </Button>
                </Flexbox>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}
