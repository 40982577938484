import {Modal} from '@components/ui/modal/Modal.tsx'
import {ModalFooter, ModalHeader, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {FC} from 'react'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {StyledBody, StyledModalParagraph, StyledTitle} from './style'
import {Spinner} from '@/components/ui/spinner/Spinner'

export const ConfirmSubmitEnhanceModal: FC<{
    onClose: () => void
    isSendToReview: boolean
    onSubmit: () => void
    isLoading: boolean
}> = ({onClose, isSendToReview, isLoading, onSubmit}) => {
    const {t} = useTranslation()

    return (
        <Modal width={360}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <StyledBody>
                <StyledTitle>
                    {t(
                        isSendToReview
                            ? 'host_review:confirm_submit_modal_title'
                            : 'host_review:confirm_proposals_modal:title'
                    )}
                </StyledTitle>
                <StyledModalParagraph>
                    <Trans
                        i18nKey={
                            isSendToReview
                                ? 'host_review:confirm_submit_modal_paragraph'
                                : 'host_review:confirm_proposals_modal_paragraph'
                        }
                        components={{a: <a href={'https://www.airbnb.com/help/article/2908'} target="_blank" />}}
                    />
                </StyledModalParagraph>
            </StyledBody>
            <ModalFooter justify="space-between">
                <Button variant="tertiary" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>

                <Button variant="primary" onClick={onSubmit} disabled={isLoading}>
                    {t(isSendToReview ? 'commons:submit_for_review' : 'commons:submit')}
                    {isLoading && <Spinner />}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
