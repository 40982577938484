import styled, {css} from 'styled-components'
import {Button} from '@/components/ui/button/Button'

export const StyledGalleryItemImg = styled.img(
    () => css`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `
)

export const StyledGalleryItemContainer = styled.div(
    ({theme: {palette, opacities}}) => css`
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        background: ${palette.neutral['600']}${opacities[30]};
        aspect-ratio: 1;
        width: 100%;
    `
)

export const StyledGalleryActionButton = styled(Button)(
    ({theme: {palette, opacities, spacing}}) => css`
        position: absolute;
        background: ${palette.neutral.white}${opacities[80]};
        top: ${spacing * 2}px;
        right: ${spacing * 2}px;
        z-index: 1;
    `
)
