import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledPhotoUploaderFloatingItem = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
`

export const StyledPreviewImageWrapper = styled.div<{$isSelected: boolean}>(
    ({theme: {palette}, $isSelected}) => css`
        position: relative;
        width: 100%;
        cursor: pointer;
        & img {
            width: 100%;
            ${$isSelected &&
            css`
                border-radius: 8px;
                border: 2px solid ${palette.neutral[900]};
            `}
        }
    `
)

export const StyledSelectIconWrapper = styled(Flexbox)(
    ({theme: {spacing, shadows, palette, opacities}}) => css`
        border-radius: 50%;
        background: ${palette.neutral.white}${opacities[90]};
        padding: ${spacing * 2}px;
        box-shadow: ${shadows.xs};
        position: absolute;
        right: ${spacing * 2}px;
        top: ${spacing * 2}px;
    `
)
