import {ComboboxItem, ComboboxPopover} from '@/components/ui/combobox-atoms/ComboboxAtoms'
import {SelectComboboxList} from '@/components/ui/select-atoms/SelectAtoms'
import styled, {css} from 'styled-components'

export const StyledInputAddressSelectComboboxPopover = styled(ComboboxPopover)(
    ({theme: {zIndex}}) => css`
        padding: 0;
        gap: 0;
        z-index: ${zIndex.modal};
        width: 100%;
        /* This was added to enable text overflow, which seems to be prevented by the scroll area corner 
        Open scroll-area/style.ts to look at the scroll area fix integration */
        & > div {
            overflow-x: hidden;
        }
    `
)
export const StyledInputAddressSelectComboboxList = styled(SelectComboboxList)(
    ({theme: {spacing}}) => css`
        padding: ${spacing}px;
    `
)

export const StyledInputAddressAutocompleteComboboxItem = styled(ComboboxItem)`
    display: block;
`
