import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Requirements} from '@/features/experiences-host-video/components/requirements/Requirements'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import toast from 'react-hot-toast'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'

export const ReadyToFilmSpecsStep = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const backUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const continueUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })

    return (
        <>
            <ExperiencesHostVideoBody gap={2}>
                <Title>{t('experiences_host_video:first_the_specs')}</Title>
                <Requirements />
            </ExperiencesHostVideoBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={backUpdateStepMutation.isPending || continueUpdateStepMutation.isPending}
                            variant="tertiary"
                            onClick={() => backUpdateStepMutation.mutate({status: 'ready-to-film', step: 'intro'})}
                        >
                            {t('commons:back')}
                            {backUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            onClick={() =>
                                continueUpdateStepMutation.mutate({
                                    status: 'ready-to-film',
                                    step: 'perspective'
                                })
                            }
                            disabled={continueUpdateStepMutation.isPending || backUpdateStepMutation.isPending}
                        >
                            {t('commons:continue')}
                            {continueUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
