import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    HttpDeclineServiceReasonsResponse,
    httpGetServiceDeclineReasons,
    HttpServiceDeclineReasonsParams
} from '@/features/service-decline/http/serviceDecline.http'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'

export const useDeclineServiceReasons = (params: HttpServiceDeclineReasonsParams) => {
    return useQuery({
        queryKey: [QUERY_KEYS.SERVICE_DECLINE_REASONS, params.task_media_name],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetServiceDeclineReasons(params),
                onZodError: captureException,
                responseShape: HttpDeclineServiceReasonsResponse
            })
    })
}
