import {useEffect} from 'react'
import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {httpSubmitSurvey} from '@/features/chatbot/http/surveySubmit.http'
import {formatLocaleDate} from '@utilities/helpers.ts'
import {ChatbotStep} from '@/features/chatbot/types.ts'

const valueClassMap = {
    '1': 'veryPoor',
    '2': 'poor',
    '3': 'average',
    '4': 'good',
    '5': 'excellent'
}
export type RatingValue = '1' | '2' | '3' | '4' | '5'

export const SendSurvey = () => {
    const service = useChatbotStore(state => state.service)
    const entity = service?.entity
    const chatbotRef = useChatbotStore(state => state.chatbotRef)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const chatbotSteps = chatbotRef?.current?.state?.renderedSteps
    const surveyRating: RatingValue = chatbotSteps?.find((x: ChatbotStep) => x.id === 's_options_rating').value
    const surveyNotes = chatbotSteps?.find((x: ChatbotStep) => x.id === 's_user_comment').value ?? 'No comment'
    const startingFlow = Object.values(chatbotSteps)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        .map(step => step?.id)
        .join(', ')
        .split(', ')
        .slice(1)
        .join(', ') // This now gives all the flows names, not only the starting one (and cuts off Step "1")

    useEffect(() => {
        onSendSurveyResults()
    }, [])

    const onSendSurveyResults = async () => {
        if (!service) return
        try {
            await httpSubmitSurvey({
                code: Number(entity?.code),
                password: String(entity?.password),
                media: Number(service?.task_media.id),
                data: {
                    flow: startingFlow,
                    notes: surveyNotes,
                    rating: parseInt(surveyRating),
                    thread_id: `${service?.quote.id}-${formatLocaleDate(new Date(), 'lll')}`
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            <p>This is just a recap of your Survey!</p>
            <p>Rating: {valueClassMap[surveyRating]}</p>
            <p>Comment: {surveyNotes}</p>
        </div>
    )
}

SendSurvey.displayName = 'SendSurvey'
