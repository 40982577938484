import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    FieldDescription,
    HeadingGroup,
    FieldTitle,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '@/features/host-submissions/experiences/utils'
import {ExperienceExtended, StepKeyName} from '@/features/host-submissions/experiences/types'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {z} from 'zod'
import {useFieldArray, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {StyledFieldWrapper, StyledPercent} from './style'
import InputText from '@/components/commons/input-text/InputText'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useChangeStep} from '../../hooks/useChangeStep'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'

const DiscountsStepValidationSchema = z.object({
    new_listing_discount: z.boolean().default(false),
    early_bird_discount: z.boolean().default(false),
    discounts: z.array(
        z.object({
            groupSize: z
                .number({required_error: i18n.t('errors:field_required')})
                .positive()
                .min(1)
                .nullable(),
            discountInPercentage: z
                .number({required_error: i18n.t('errors:field_required')})
                .positive()
                .max(100, {
                    message: i18n.t('errors:max_value', {count: 100})
                })
                .nullable()
        })
    )
})

export type DiscountsStepValidationSchema = z.infer<typeof DiscountsStepValidationSchema>

export const ExperienceDiscountsStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)

    const flagsCount = updatesRequiredSteps.discounts.flatMap(field => field.flags).length
    const largeGroupFlags = updatesRequiredSteps.discounts.find(field => field.questionKeyName == 'discounts')?.flags
    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.pricing,
        currentStep: StepKeyName.enum.discounts,
        nextStep: StepKeyName.enum.settings,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })

    const defaultValues = makeExperiencesFormDefaultValues(experience).discounts

    const form = useForm<DiscountsStepValidationSchema>({
        resolver: zodResolver(DiscountsStepValidationSchema),
        reValidateMode: 'onChange',
        defaultValues
    })
    const fieldArray = useFieldArray({control: form.control, name: 'discounts'})

    const onSubmit = form.handleSubmit(data => {
        changeStep.handleChangeStep(false, data)
    })
    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:experience_discounts_step:title')}</Title>
                    <p>{t('experiences:experience_discounts_step:description')}</p>

                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('commons:x_items_improve', {count: flagsCount})}
                            paragraph={t('experiences:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={6}>
                    <Flexbox direction="column" gap={4}>
                        <FieldTitle>{t('experiences:experience_discounts_step:new_listing')}</FieldTitle>
                        <FieldDescription>
                            {t('experiences:experience_discounts_step:new_listing_hint')}
                        </FieldDescription>

                        <Checkbox
                            id="new_listing"
                            label={t('experiences:experience_discounts_step:new_listing_apply')}
                            {...form.register('new_listing_discount')}
                        />
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4}>
                        <FieldTitle>{t('experiences:experience_discounts_step:large_group')}</FieldTitle>
                        <FieldDescription>
                            {t('experiences:experience_discounts_step:large_group_hint')}
                            <FlagsAlertText
                                flags={largeGroupFlags}
                                title={t('experiences:experience_discounts_step:large_group')}
                            />
                        </FieldDescription>

                        {fieldArray.fields.length > 0 &&
                            fieldArray.fields.map((field, index) => (
                                <StyledFieldWrapper key={field.id}>
                                    <InputText
                                        inputSize="sm"
                                        type="number"
                                        label={t('experiences:experience_discounts_step:min_guest')}
                                        {...form.register(`discounts.${index}.groupSize`, {
                                            setValueAs: value =>
                                                value == '' || value == 0 || value == null
                                                    ? undefined
                                                    : parseFloat(value)
                                        })}
                                    />

                                    <InputText
                                        inputSize="sm"
                                        type="number"
                                        typeIcon={
                                            <StyledPercent align="center" justify="center">
                                                %
                                            </StyledPercent>
                                        }
                                        label={t('experiences:experience_discounts_step:discount')}
                                        {...form.register(`discounts.${index}.discountInPercentage`, {
                                            setValueAs: value =>
                                                value == '' || value == 0 || value == null
                                                    ? undefined
                                                    : parseFloat(value)
                                        })}
                                    />

                                    <Button variant="ghost" shape="square" onClick={() => fieldArray.remove(index)}>
                                        <Trash01Icon />
                                    </Button>

                                    {form.formState.errors.discounts?.[index]?.groupSize && (
                                        <InputHelpText
                                            error={form.formState.errors.discounts[index]?.groupSize?.message}
                                        />
                                    )}

                                    {form.formState.errors.discounts?.[index]?.discountInPercentage && (
                                        <InputHelpText
                                            error={
                                                form.formState.errors.discounts[index]?.discountInPercentage?.message
                                            }
                                        />
                                    )}
                                </StyledFieldWrapper>
                            ))}

                        <Button
                            variant="tertiary"
                            shape="text"
                            style={{width: 'max-content'}}
                            onClick={() => fieldArray.append({discountInPercentage: null, groupSize: null})}
                        >
                            <PlusIcon />
                            {t('experiences:experience_discounts_step:add_group_discount')}
                        </Button>
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4}>
                        <FieldTitle>{t('experiences:experience_discounts_step:early_bird')}</FieldTitle>
                        <FieldDescription>
                            {t('experiences:experience_discounts_step:early_bird_hint')}
                        </FieldDescription>

                        <Checkbox
                            id="early_bird"
                            label={t('experiences:experience_discounts_step:apply_early_bird_discount')}
                            {...form.register('early_bird_discount')}
                        />
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('discounts')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>

                {changeStep.hasNextStep ? (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button onClick={onSubmit}>
                        {t('commons:confirm')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
