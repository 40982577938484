import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {
    httpGetAmenitiesAutocomplete,
    HttpGetAmenitiesAutocompleteResponse
} from '@/features/matterport/http/matterport.http'
import {captureException} from '@sentry/react'
import {useQuery} from '@tanstack/react-query'

export const useAmenitiesList = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.AMENITIES_AUTOCOMPLETE],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetAmenitiesAutocomplete(),
                onZodError: captureException,
                responseShape: HttpGetAmenitiesAutocompleteResponse
            })
    })
}
