import styled, {css} from 'styled-components'

export const StyledList = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${spacing * 3}px;
        width: 100%;
    `
)

export const StyledSubCategoryTitle = styled.h3(
    ({theme: {typography}}) => css`
        ${typography.textXlFixed}
        font-weight: 500;
    `
)
