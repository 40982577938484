import {useTranslation} from '@/translations/i18n.tsx'
import {Paragraph} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
import {InfoCircleIcon} from '@components/ui/icon'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {PerspectiveType} from '@/features/experiences-host-video/components/perspective-type/PerspectiveType.tsx'
import {StyledPerspectiveInfo} from '@/features/experiences-host-video/components/perspective/style'

import wideShotCamOperator from '@assets/videos/experiences-host-video/wideshot_camoperator.mp4'
import wideShotCamOperatorPreview from '@/assets/images/experiences-host-video/wideshot_camoperator_preview.png'
import wideShotActual from '@assets/videos/experiences-host-video/wideshot_actual.mp4'
import wideShotActualPreview from '@/assets/images/experiences-host-video/wideshot_actual_preview.png'

import mediumShotCamOperator from '@assets/videos/experiences-host-video/medium_shot_camoperator.mp4'
import mediumShotCamOperatorPreview from '@/assets/images/experiences-host-video/medium_shot_camoperator.png'
import mediumShotActual from '@assets/videos/experiences-host-video/medium_shot_actual.mp4'
import mediumShotActualPreview from '@/assets/images/experiences-host-video/medium_shot_actual_preview.png'

import closeUpCamOperator from '@assets/videos/experiences-host-video/close_up_camera_operator.mp4'
import closeUpCamOperatorPreview from '@/assets/images/experiences-host-video/close_up_cameraoperator_preview.png'
import closeUpActual from '@assets/videos/experiences-host-video/close_up_actual.mp4'
import closeUpActualPreview from '@/assets/images/experiences-host-video/close_up_actual_preview.png'

export const Perspective = () => {
    const {t} = useTranslation()

    return (
        <Flexbox direction="column" gap={6}>
            <Paragraph>{t('experiences_host_video:perspective:description')}</Paragraph>
            <StyledPerspectiveInfo>
                <InfoCircleIcon size={20} />
                <Flexbox direction="column" gap={1}>
                    <h3>{t('experiences_host_video:perspective:info_title')}</h3>
                    <p>{t('experiences_host_video:perspective:info_description')}</p>
                </Flexbox>
            </StyledPerspectiveInfo>
            <PerspectiveType
                title={t('experiences_host_video:perspective:wide:title')}
                description={t('experiences_host_video:perspective:wide:description')}
                leftCardTitle={t('experiences_host_video:how_should_film')}
                rightCardTitle={t('experiences_host_video:how_it_should_look')}
                leftCardVideoSrc={wideShotCamOperator}
                rightCardVideoSrc={wideShotActual}
                leftCardPosterSrc={wideShotCamOperatorPreview}
                rightCardPosterSrc={wideShotActualPreview}
            />
            <PerspectiveType
                title={t('experiences_host_video:perspective:medium:title')}
                description={t('experiences_host_video:perspective:medium:description')}
                leftCardTitle={t('experiences_host_video:how_should_film')}
                rightCardTitle={t('experiences_host_video:how_it_should_look')}
                leftCardVideoSrc={mediumShotCamOperator}
                rightCardVideoSrc={mediumShotActual}
                leftCardPosterSrc={mediumShotCamOperatorPreview}
                rightCardPosterSrc={mediumShotActualPreview}
            />
            <PerspectiveType
                title={t('experiences_host_video:perspective:close_up:title')}
                description={t('experiences_host_video:perspective:close_up:description')}
                leftCardTitle={t('experiences_host_video:how_should_film')}
                rightCardTitle={t('experiences_host_video:how_it_should_look')}
                leftCardVideoSrc={closeUpCamOperator}
                rightCardVideoSrc={closeUpActual}
                leftCardPosterSrc={closeUpCamOperatorPreview}
                rightCardPosterSrc={closeUpActualPreview}
            />
        </Flexbox>
    )
}
