import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const StyledContent = styled(Flexbox)<{$disabled: boolean}>(
    ({$disabled}) => css`
        & > div {
            width: 100%;
        }
        ${$disabled &&
        css`
            cursor: not-allowed;
            opacity: 0.5;
        `}
    `
)

export const StyledText = styled.p(
    ({theme: {palette, spacing}}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
        border-radius: 8px;
        border: 1px solid ${palette.neutral[300]};
        background: ${palette.neutral[50]};
        width: 100%;
        min-height: 38px;
    `
)
