import {Fragment, RefObject, useEffect, useRef} from 'react'
import {CoAlarmListingRemappedRequest} from '@/features/co-alarm/types'
import {useTranslation} from '@/translations/i18n.tsx'
import {StyledCoAlarmListingStatus} from '@/features/co-alarm/components/co-alarm-listings-table/style'
import {ListingThumbnailImage} from '@/components/ui/listing-thumbnail-atoms/ListingThumbnailAtoms'
import {coAlarmStatusToIconLabel} from '@/features/co-alarm/utils'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ButtonLink} from '@components/ui/button-link/ButtonLink'
import {Divider} from '@components/ui/divider/Divider'
import {generatePath} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {CoAlarmListingsListItemLoader} from '../co-alarm-listings-list-item-loader/CoAlarmListingsListItemLoader'
import {EmptyResultsBox} from '@/components/commons/empty-results-box/EmptyResultsBox'
import ErrorMessage from '@/components/commons/table/ErrorMessage'
import {useInView} from '@/hooks/useInView'
import {Button} from '@components/ui/button/Button'
import {Spinner} from '@components/ui/spinner/Spinner'
import {CoAlarmListingsListThumbnailName, StyledCoAlarmListingListUpdatedAt} from './style'
import dayjs from 'dayjs'

interface CoAlarmListingsList {
    data: CoAlarmListingRemappedRequest[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    isFetching: boolean
    hasNextPage: boolean
    searchRef: RefObject<HTMLInputElement> | null
    onChangePage: () => void
    onResetSearch: (inputRef: RefObject<HTMLInputElement>) => void
    searchValue?: string
}

export const CoAlarmListingsList = ({
    data,
    isChangingPage,
    isError,
    isLoading,
    isFetching,
    hasNextPage,
    searchRef,
    onChangePage,
    onResetSearch,
    searchValue
}: CoAlarmListingsList) => {
    const {t} = useTranslation()
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null)
    const isInView = useInView(loadMoreButtonRef, {threshold: 0.1})

    useEffect(() => {
        if (isInView && hasNextPage && !isFetching) {
            onChangePage()
        }
    }, [isInView, hasNextPage, isChangingPage])

    if (!isLoading && isError) {
        return <ErrorMessage />
    }

    if (!isLoading && !searchValue && data.length == 0) {
        return (
            <EmptyResultsBox
                title={t('commons:empty_listings_results')}
                subtitle={t('coAlarm:dashboard:empty_listings_results_subtitle')}
                width={'100%'}
                customContent={
                    <ButtonLink to={`https://www.airbnb.com/hosting/listings`} target="_blank">
                        {t('commons:your_active_listings')}
                    </ButtonLink>
                }
            />
        )
    }

    if (!isLoading && searchValue && data.length == 0) {
        return (
            <EmptyResultsBox
                onResetCb={() => searchRef && onResetSearch(searchRef)}
                title={t('commons:no_results_found')}
                subtitle={t('commons:no_results_listings_message', {value: searchValue})}
                width="100%"
            />
        )
    }

    return (
        <Flexbox direction="column" width="100%" gap={4}>
            {isLoading
                ? Array.from({length: 4}).map((_, index) => <CoAlarmListingsListItemLoader key={index} />)
                : data.map((listing, index) => {
                      const {label, icon} = coAlarmStatusToIconLabel[listing.co_alarm.status]
                      return (
                          <Fragment key={listing.id}>
                              <Flexbox direction="column" gap={6} width="100%">
                                  <Flexbox gap={3} direction="column">
                                      <ListingThumbnailImage src={listing.image_url} alt={listing.name} />
                                      <CoAlarmListingsListThumbnailName>
                                          <a href={`https://www.airbnb.com/rooms/${listing.id}`} target="_blank">
                                              {listing.name}
                                          </a>
                                      </CoAlarmListingsListThumbnailName>
                                  </Flexbox>
                                  <Flexbox gap={2.5}>
                                      {icon}
                                      <Flexbox direction="column">
                                          <StyledCoAlarmListingStatus>{t(label)}</StyledCoAlarmListingStatus>
                                          <StyledCoAlarmListingListUpdatedAt>
                                              {listing.co_alarm?.request?.updated_at
                                                  ? dayjs(listing.co_alarm.request.updated_at).format('LLL')
                                                  : ''}
                                          </StyledCoAlarmListingListUpdatedAt>
                                      </Flexbox>
                                  </Flexbox>
                                  {listing?.co_alarm?.status == 'eligible' ||
                                  listing?.co_alarm?.status == 'ineligible' ? (
                                      <ButtonLink
                                          size="sm"
                                          variant="tertiary"
                                          shape="text"
                                          target="_blank"
                                          to={`https://www.airbnb.com/resources/hosting-homes/a/reducing-carbon-monoxide-exposure-in-your-space-510`}
                                      >
                                          {t('commons:learn_more')}
                                      </ButtonLink>
                                  ) : (
                                      listing.co_alarm.request?.id &&
                                      listing.co_alarm.status != 'installed' && (
                                          <ButtonLink
                                              size="sm"
                                              variant="secondary"
                                              fullWidth
                                              to={generatePath(routes.CO_ALARM_REQUEST_DETAILS.path, {
                                                  listingId: listing.id,
                                                  requestId: listing.co_alarm.request.id
                                              })}
                                          >
                                              {t('commons:details')}
                                          </ButtonLink>
                                      )
                                  )}
                              </Flexbox>
                              {index + 1 < data.length && (
                                  <Divider direction="horizontal" startSpacing={5} endSpacing={5} />
                              )}
                          </Fragment>
                      )
                  })}

            {isChangingPage && Array.from({length: 4}).map((_, index) => <CoAlarmListingsListItemLoader key={index} />)}

            {hasNextPage && (
                <Button disabled={isFetching} ref={loadMoreButtonRef} onClick={() => onChangePage()} variant="ghost">
                    {t('categories:load_more')}
                    {isFetching && <Spinner size={16} />}
                </Button>
            )}
        </Flexbox>
    )
}
