import {
    StyledAcceptanceFlowCompleted,
    StyledAcceptStepBody,
    StyledAcceptStepDesc,
    StyledAcceptStepFooter
} from '@/features/service-acceptance-flow/components/style.ts'
import {AcceptanceStepsConfigByService} from '@/features/service-acceptance-flow/utils.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {useMemo} from 'react'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {errorHandler, getServiceType} from '@utilities/helpers.ts'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {Mail01Icon} from '@components/ui/icon'
import {useTheme} from 'styled-components'
import {OtpEmailProcess} from '@/features/otp-flow/components/otp-mail-process/OtpEmailProcess.tsx'
import toast from 'react-hot-toast'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {AcceptanceFlowCompletedMessage} from '@/features/service-acceptance-flow/components/AcceptanceFlowCompleted.tsx'
import {useNotificationPreferences} from '@/features/otp-flow/queries/useNotificationPreferences'

export const EmailNotifyConfirmationStep = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    //otp store
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    const service = useOtpStore(state => state.service)
    const optProcessStep = useOtpStore(state => state.step)
    //accept store
    const acceptServiceModalToggle = useServiceAcceptStore(state => state.acceptServiceModalToggle)
    const changeAcceptModalStep = useServiceAcceptStore(state => state.changeStep)
    const portraitEnabled = useServiceAcceptStore(state => state.portraitEnabled)
    const serviceType = useMemo(
        () => (service ? getServiceType(service.task_media.name, service.task_category.name) : 'home'),
        [service]
    )
    //notification accept toggle
    const {mutate: changeNotificationPermission, isPending} = useNotificationPreferences({
        onSuccess: () => {
            if (portraitEnabled) {
                changeAcceptModalStep('portrait')
            } else {
                if (coAlarmRequest) {
                    toast.success(
                        <StyledAcceptanceFlowCompleted direction={'column'} gap={1}>
                            <p>{t('coAlarm:smokeForm:form:sent_successfully_toast:paragraph_email')}</p>
                        </StyledAcceptanceFlowCompleted>,
                        {duration: 2500}
                    )
                } else {
                    toast.success(<AcceptanceFlowCompletedMessage />, {duration: 2500})
                }
                acceptServiceModalToggle()
            }
        },
        onError: error => errorHandler(error)
    })

    const onChangePermissionToggle = () => {
        if (coAlarmRequest && coAlarmRequest.notification_preferences) {
            changeNotificationPermission({
                urlParams: {
                    notifySettingsId: coAlarmRequest.notification_preferences.id
                },
                params: {
                    send_email_notification: true
                }
            })
        } else if (service && service.notification_preferences) {
            changeNotificationPermission({
                urlParams: {
                    notifySettingsId: service.notification_preferences.id
                },
                params: {
                    send_email_notification: true
                }
            })
        } else {
            toast.error('errors:default')
        }
    }
    //modal composition utils
    const notifyEmailBodySubtitle = useMemo(() => {
        if (coAlarmRequest) {
            return t(AcceptanceStepsConfigByService['email']['coAlarm'].subtitle)
        } else if (service) {
            return t(AcceptanceStepsConfigByService['email'][serviceType].subtitle)
        } else {
            return ''
        }
    }, [service, coAlarmRequest])

    return (
        <>
            <StyledAcceptStepBody direction={'column'} gap={6}>
                <StyledAcceptStepDesc direction={'column'} gap={2} disabled={optProcessStep !== 'base'}>
                    <IconContainer size={'lg'}>
                        <Mail01Icon fill={palette.primary['600']} />
                    </IconContainer>
                    <h5>{t(AcceptanceStepsConfigByService['email'][serviceType].title)}</h5>
                    <small>{notifyEmailBodySubtitle}</small>
                </StyledAcceptStepDesc>

                <OtpEmailProcess />
            </StyledAcceptStepBody>

            <Divider startSpacing={0} endSpacing={0} direction="horizontal" />
            <StyledAcceptStepFooter justify={'space-between'}>
                <Button
                    fullWidth={true}
                    variant={'primary'}
                    size={'md'}
                    disabled={optProcessStep !== 'base' || isPending}
                    onClick={() => onChangePermissionToggle()}
                >
                    {t(AcceptanceStepsConfigByService['email'][serviceType].primary_cta)}
                </Button>
            </StyledAcceptStepFooter>
        </>
    )
}

EmailNotifyConfirmationStep.displayName = 'EmailNotifyConfirmationStep'
