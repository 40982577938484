import {forwardRef, ReactNode} from 'react'

import {Label} from '@/components/ui/label/Label'
import {SelectItem, SelectList} from '@/components/ui/select-atoms/SelectAtoms'
import {SelectProvider} from '@ariakit/react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {ExperiencesFlag} from '@/features/host-submissions/experiences/types'
import {StyledFieldDescription, StyledPopover, StyledPopoverWrapper, StyledSelectorTrigger} from '../atoms/style'

interface CutoffTimeSelectorProps {
    errorMessage?: string
    onChange?: (value: string) => void
    value?: string
    label: ReactNode
    placeholder: ReactNode
    note?: ReactNode
    array: {value: string; label: string}[]
    disabled?: boolean
    flags?: ExperiencesFlag[]
}

export const CutoffTimeSelector = forwardRef<HTMLSelectElement, CutoffTimeSelectorProps>(
    ({errorMessage, onChange, value, label, placeholder, note, disabled = false, array, flags}, ref) => {
        return (
            <Flexbox direction="column" gap={1.5} width="100%">
                <SelectProvider value={value ?? ''} setValue={onChange}>
                    <Label>{label}</Label>
                    {note && <StyledFieldDescription>{note}</StyledFieldDescription>}
                    <FlagsAlertText flags={flags} title={label} />
                    <StyledSelectorTrigger
                        fullWidth
                        disabled={disabled}
                        placeholder={placeholder}
                        displayValue={value => array.find(a => a.value == value)?.label}
                        $hasError={!!errorMessage}
                        ref={ref}
                    />
                    <StyledPopoverWrapper>
                        <StyledPopover portal={false}>
                            <SelectList>
                                {array.map(({value, label}, index) => {
                                    return (
                                        <SelectItem key={index} value={value.toString()}>
                                            {label}
                                        </SelectItem>
                                    )
                                })}
                            </SelectList>
                        </StyledPopover>
                    </StyledPopoverWrapper>
                </SelectProvider>
                {errorMessage && <InputHelpText error={errorMessage} />}
            </Flexbox>
        )
    }
)
