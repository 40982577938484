import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise, raise} from '@utilities/helpers.ts'
import {httpShowFriendQuiz} from '@/features/experiences-host-video/queries/experiences-host-video.http'
import {captureException} from '@sentry/react'
import {ExperiencesHostVideoRouteParams, Quiz} from '@/features/experiences-host-video/types.ts'

interface UseShowQuizArgs {
    urlParams: ExperiencesHostVideoRouteParams & {quizId?: number}
    queryOptions?: Omit<UseQueryOptions<unknown, unknown, Quiz>, 'queryKey' | 'queryFn'>
}
export const useShowFriendQuiz = ({urlParams, queryOptions}: UseShowQuizArgs) =>
    useQuery<unknown, unknown, Quiz>({
        queryKey: [QUERY_KEYS.EXPERIENCES_HOST_VIDEO_QUIZ, urlParams],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpShowFriendQuiz({
                    urlParams: {...urlParams, quizId: urlParams.quizId || raise('quizId is undefined')},
                    params: {response_type: 'extended'}
                }),
                responseShape: Quiz,
                onZodError: captureException
            }),
        ...queryOptions
    })
