import {Content, HeadingGroup, Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Service, UploadedMedia} from '@/features/host-submissions/services/types.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {
    StyledGalleryGrid,
    StyledEmptyState
} from '@/features/host-submissions/services/components/enhance-service-gallery-step/style'
import {useEffect, useState} from 'react'
import {EnhanceServiceUploadPhotoStep} from '@/features/host-submissions/services/components/enhance-service-upload-photo-step/EnhanceServiceUploadPhotoStep'
import {useUpdateStepToEnhanceAboutYouStep} from '@/features/host-submissions/services/queries/useUpdateStepToEnhanceAboutYouStep'
import {errorHandler, raise} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {hasRelevantFieldChanges} from '@/features/host-submissions/services/utils'
import {useURLParsedParams} from '@/hooks/useURLParsedParams'
import {z} from 'zod'
import {useGalleryPhoto} from '@/features/host-submissions/services/queries/useGalleryPhoto'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useAcceptAdditionalPhotos} from '@/features/host-submissions/services/queries/useAcceptAdditionalPhotos'
import {AdditionalPhotoItem} from '../../../components/additional-photo-item/AdditionalPhotoItem'
import {useDeclineAdditionalPhotos} from '../../queries/useDeclineAdditionalPhotos'
import {ImageXIcon} from '@/components/ui/icon'
import {Footer} from '@/features/host-submissions/components/atoms/Atoms'
import {ConfirmSubmitEnhanceModal} from '@/features/host-submissions/components/confirm-submit-enhance-modal/ConfirmSubmitEnhanceModal'
import {useSubmitHostApprovalService} from '../../queries/useSubmitHostApprovalService'
import {StyledFooterText} from '../enhance-service-upload-photo-step/style'

export const EnhanceServiceGalleryStep = ({service}: {service: Service}) => {
    const {t} = useTranslation()
    const [isUploadMorePhotoShown, setIsUploadMorePhotoShown] = useState(false)
    const [isConfirmSubmitToReviewModalOpen, setIsConfirmSubmitToReviewModalOpen] = useState(false)
    const [isPrevStepChanged, setIsPrevStepChanged] = useState(false)
    const [selectedPhotos, setSelectedPhotos] = useState<UploadedMedia[]>([])
    const [deletedPhotos, setDeletedPhotos] = useState<UploadedMedia[]>([])
    const submitService = useSubmitHostApprovalService({
        serviceId: service.id,
        options: {onError: errorHandler, onSuccess: () => setIsConfirmSubmitToReviewModalOpen(false)}
    })
    const prevStepMutation = useUpdateStepToEnhanceAboutYouStep({
        serviceId: service.id,
        options: {onError: errorHandler}
    })

    const updateEnhanceStepSuccess = () => {
        if (service.minimum_image_required && totalImages < service.minimum_image_required) {
            setIsUploadMorePhotoShown(true)
        } else {
            setIsConfirmSubmitToReviewModalOpen(true)
        }
    }

    const declineAdditionalPhotosMutation = useDeclineAdditionalPhotos({
        serviceId: service.id,
        mutationOptions: {onError: errorHandler, onSuccess: updateEnhanceStepSuccess}
    })

    const acceptAdditionalPhotosMutation = useAcceptAdditionalPhotos({
        serviceId: service.id,
        mutationOptions: {
            onError: errorHandler,
            onSuccess: () =>
                deletedPhotos.length > 0
                    ? declineAdditionalPhotosMutation.mutate({images: deletedPhotos.map(img => img.id)})
                    : updateEnhanceStepSuccess()
        }
    })

    const urlParams = useURLParsedParams(z.object({id: z.coerce.number()}))
    const galleryQuery = useGalleryPhoto(urlParams.success ? urlParams.data.id : raise('Invalid urlParams'), true)

    useEffect(() => {
        const images = galleryQuery.data || []
        const additionalPhotos = images.filter(img => img.is_host_approved == null || !!img.is_host_approved)

        setSelectedPhotos(additionalPhotos ?? [])
    }, [galleryQuery.data])

    const approvedGalleryPhotos =
        service.gallery?.images.filter(
            img => !img.parent_flags.length && (img.is_host_approved == null || !!img.is_host_approved)
        ) ?? []

    const totalImages = approvedGalleryPhotos.length

    useEffect(() => {
        const offeringsChanged = hasRelevantFieldChanges(service.offerings, [
            'improved_title',
            'improved_description',
            'main_photo'
        ])
        const expertiseChanged = service.expertise
            ? hasRelevantFieldChanges(
                  [service.expertise],
                  [
                      'host_experience_title',
                      'service_description',
                      'host_experience_description',
                      'host_education_title',
                      'host_education_description',
                      'host_career_highlight_title',
                      'host_career_highlight_description',
                      'service_title'
                  ]
              )
            : false
        const hasNewGalleryPhoto = service.gallery?.images.some(image => !image.parent_id)

        setIsPrevStepChanged(offeringsChanged || expertiseChanged || !!hasNewGalleryPhoto)
    }, [service])

    const onContinue = () => {
        if (selectedPhotos.length > 0) {
            acceptAdditionalPhotosMutation.mutate({images: selectedPhotos.map(img => img.id)})
        } else if (deletedPhotos.length > 0) {
            declineAdditionalPhotosMutation.mutate({images: deletedPhotos.map(img => img.id)})
        } else {
            updateEnhanceStepSuccess()
        }
    }

    const onDelete = (id: number) => {
        setSelectedPhotos(prevSelected => prevSelected.filter(photo => photo.id !== id))

        setDeletedPhotos(prevDeleted => {
            if (prevDeleted.some(photo => photo.id === id)) return prevDeleted
            const photoToAdd = selectedPhotos.find(photo => photo.id === id)
            return photoToAdd ? [...prevDeleted, photoToAdd] : prevDeleted
        })
    }

    if (galleryQuery.isLoading)
        return (
            <Flexbox align="center" justify="center">
                <Spinner size={32} />
            </Flexbox>
        )

    return isUploadMorePhotoShown ? (
        <EnhanceServiceUploadPhotoStep
            service={service}
            onBack={() => setIsUploadMorePhotoShown(false)}
            minimumImageRequired={service.minimum_image_required ? service.minimum_image_required - totalImages : 0}
        />
    ) : (
        <>
            <Content>
                <HeadingGroup direction="column" gap={4}>
                    <Title>{t('services:enhance_service_gallery_step:title')}</Title>
                    <Paragraph>{t('services:enhance_service_gallery_step:paragraph')}</Paragraph>
                </HeadingGroup>
                {selectedPhotos.length > 0 ? (
                    <StyledGalleryGrid>
                        {selectedPhotos.map(item => (
                            <AdditionalPhotoItem key={item.id} image={item} onDelete={() => onDelete(item.id)} />
                        ))}
                    </StyledGalleryGrid>
                ) : (
                    <StyledEmptyState direction="column" gap={3} align="center" justify="center" width={'100%'}>
                        <ImageXIcon size={32} />
                        <p>{t('services:enhance_service_gallery_step:empty_state_selected_photo')}</p>
                    </StyledEmptyState>
                )}

                <StyledFooterText>{t('services:step_gallery:footer')}</StyledFooterText>
            </Content>

            <Footer>
                <Button
                    variant="tertiary"
                    disabled={
                        prevStepMutation?.isPending ||
                        acceptAdditionalPhotosMutation.isPending ||
                        declineAdditionalPhotosMutation.isPending
                    }
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>

                <Button
                    onClick={onContinue}
                    disabled={
                        prevStepMutation?.isPending ||
                        acceptAdditionalPhotosMutation.isPending ||
                        declineAdditionalPhotosMutation.isPending
                    }
                >
                    {t('commons:continue')}
                    {(acceptAdditionalPhotosMutation.isPending || declineAdditionalPhotosMutation.isPending) && (
                        <Spinner />
                    )}
                </Button>
            </Footer>

            {isConfirmSubmitToReviewModalOpen && (
                <ConfirmSubmitEnhanceModal
                    onClose={() => setIsConfirmSubmitToReviewModalOpen(false)}
                    isSendToReview={isPrevStepChanged}
                    onSubmit={() => submitService.mutate()}
                    isLoading={submitService.isPending}
                />
            )}
        </>
    )
}
