import {
    categoryToIcon,
    categoryToLabel,
    getStepProgressPercentage
} from '@/features/host-submissions/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    Footer,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {errorHandler, ObjectEntries} from '@utilities/helpers.ts'
import {AnswerItem} from '@components/commons/answer-item/AnswerItem.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FC, useRef, useState} from 'react'
import {StyledList} from '@/features/host-submissions/services/components/host-expertise-category-step/style.ts'
import {Service} from '@/features/host-submissions/services/types.ts'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {useUpdateStepToGetStarted} from '@/features/host-submissions/services/queries/useUpdateStepToGetStarted'
import {useUpdateStepToHostExpertiseCareer} from '@/features/host-submissions/services/queries/useUpdateStepToHostExpertiseCareer'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const HostExpertiseCategoryStep: FC<{
    serviceId: number
    expertiseId: number
    serviceCategory: Service['category']
}> = ({serviceId, expertiseId, serviceCategory}) => {
    const {t} = useTranslation()
    const [selectedCategory, setSelectedCategory] = useState<
        NonNullable<Service['category']>['key_name'] | null | undefined
    >(serviceCategory?.key_name)
    const [isError, setIsError] = useState(false)
    const updateStepToHostExpertiseCareerMutation = useUpdateStepToHostExpertiseCareer({
        serviceId,
        expertiseId,
        options: {onError: errorHandler}
    })
    const updateStepToGetStartedMutation = useUpdateStepToGetStarted({
        serviceId,
        expertiseId,
        options: {onError: errorHandler}
    })

    const errorRef = useRef<HTMLDivElement>(null)

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_host_expertise_category:title')}</Title>
                    <Paragraph>{t('services:step_host_expertise_category:paragraph')}</Paragraph>
                </HeadingGroup>
                <StyledList>
                    {ObjectEntries(categoryToLabel)
                        .sort(([, aValue], [, bValue]) => (t(aValue) < t(bValue) ? -1 : t(aValue) > t(bValue) ? 1 : 0))
                        .map(([categoryKey, categoryValue]) => (
                            <AnswerItem
                                key={categoryKey}
                                type="radio"
                                name="category"
                                checked={selectedCategory == categoryKey}
                                value={categoryKey}
                                label={
                                    <Flexbox direction="column" gap={2}>
                                        {categoryToIcon[categoryKey]}
                                        {t(categoryValue)}
                                    </Flexbox>
                                }
                                onChange={() => {
                                    setIsError(false)
                                    setSelectedCategory(categoryKey)
                                }}
                            />
                        ))}
                    <div ref={errorRef}>{isError && <InputHelpText error={t('services:select_option_error')} />}</div>
                </StyledList>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('category')}>
                <Button
                    variant="tertiary"
                    disabled={
                        updateStepToGetStartedMutation.isPending || updateStepToHostExpertiseCareerMutation.isPending
                    }
                    onClick={() => updateStepToGetStartedMutation.mutate(undefined)}
                >
                    {t('commons:back')}
                    {updateStepToGetStartedMutation.isPending && <Spinner />}
                </Button>
                <Button
                    disabled={
                        updateStepToGetStartedMutation.isPending || updateStepToHostExpertiseCareerMutation.isPending
                    }
                    onClick={() => {
                        if (!selectedCategory) {
                            setIsError(true)
                            errorRef.current?.scrollIntoView()
                        } else {
                            updateStepToHostExpertiseCareerMutation.mutate({category: selectedCategory})
                        }
                    }}
                >
                    {t('commons:continue')}
                    {updateStepToHostExpertiseCareerMutation.isPending && <Spinner />}
                </Button>
            </Footer>
        </>
    )
}
