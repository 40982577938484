import {useTranslation} from '@/translations/i18n.tsx'
import {FC, useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {handleCloseSlidingModal, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useFormContext} from 'react-hook-form'
import {
    StyledMoreDetailsPrimaryLanguageButton,
    StyledMoreDetailsPrimaryLanguageSelectBody,
    StyledMoreDetailsPrimaryLanguageSlidingModal,
    StyledMoreDetailsPrimaryLanguageSlidingModalHeader
} from '@/features/host-submissions/services/components/more-details-primary-language-select/style.ts'
import {Radio} from '@components/commons/radio/Radio.tsx'
import {MoreDetailsValidationSchema} from '@/features/host-submissions/services/components/other-details-more-details-step/OtherDetailsMoreDetailsStep.tsx'
import {ChevronRightIcon, XCloseIcon} from '@components/ui/icon'
import {ModalFooter, ModalTitle} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {SUPPORTED_LANGUAGES} from '@/utilities/constants/countries'

export const MoreDetailsPrimaryLanguageSelect: FC = () => {
    const {t} = useTranslation()
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const form = useFormContext<MoreDetailsValidationSchema>()
    const selectedLanguage = SUPPORTED_LANGUAGES.find(language => language.value == form.getValues('main_language'))
    const secondaryLanguages = form.getValues('extra_languages')
    const [primaryLanguageCode, setPrimaryLanguageCode] = useState(selectedLanguage?.value)

    const onClickConfirm = () => {
        if (primaryLanguageCode) {
            form.setValue('main_language', primaryLanguageCode)
            if (secondaryLanguages) {
                form.setValue('extra_languages', [])
            }
            handleCloseSlidingModal(setSlidingModalState)
        }
    }
    const onClose = () => {
        setPrimaryLanguageCode(selectedLanguage?.value)
        handleCloseSlidingModal(setSlidingModalState)
    }

    return (
        <>
            <StyledMoreDetailsPrimaryLanguageButton
                variant={'secondary'}
                onClick={() => setSlidingModalState('open')}
                fullWidth
            >
                {selectedLanguage?.label}
                <ChevronRightIcon />
            </StyledMoreDetailsPrimaryLanguageButton>
            {slidingModalState != 'closed' && (
                <StyledMoreDetailsPrimaryLanguageSlidingModal
                    slidingModalState={slidingModalState}
                    onOverlayClick={onClose}
                >
                    <StyledMoreDetailsPrimaryLanguageSlidingModalHeader>
                        <Button shape="square" onClick={onClose} variant={'ghost'} size={'sm'}>
                            <XCloseIcon />
                        </Button>
                        <ModalTitle>{t('services:step_other_details_more_details:select_primary_language')}</ModalTitle>
                    </StyledMoreDetailsPrimaryLanguageSlidingModalHeader>
                    <StyledMoreDetailsPrimaryLanguageSelectBody direction={'column'} gap={4} align={'stretch'}>
                        {SUPPORTED_LANGUAGES.map(language => (
                            <Radio
                                key={language.value}
                                checked={language.value == primaryLanguageCode}
                                name={'primary-language'}
                                label={language.label}
                                value={language.value}
                                onChange={() => setPrimaryLanguageCode(language.value)}
                            />
                        ))}
                    </StyledMoreDetailsPrimaryLanguageSelectBody>
                    <ModalFooter justify={'space-between'}>
                        <Button onClick={onClose} variant={'tertiary'}>
                            {t('commons:close')}
                        </Button>
                        <Button onClick={onClickConfirm}>{t('commons:confirm')}</Button>
                    </ModalFooter>
                </StyledMoreDetailsPrimaryLanguageSlidingModal>
            )}
        </>
    )
}
