import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {
    HttpRemoveCategoryPayload,
    HttpRemoveCategoryUrlParams,
    httpRemoveCategory
} from '@/features/categories/http/categories.http'
import {ErrorResponseData} from '@/types/commons.ts'
import {AxiosError} from 'axios'

type UseRemoveCategoryProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useRemoveCategory = (urlParams: HttpRemoveCategoryUrlParams, options?: UseRemoveCategoryProps) => {
    return useMutation<unknown, AxiosError<ErrorResponseData>, HttpRemoveCategoryPayload>({
        ...options,
        mutationKey: [MUTATION_KEYS.REMOVE_CATEGORY],
        mutationFn: payload => httpRemoveCategory({urlParams, payload}),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.LISTINGS_CATEGORIES]})
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.CATEGORIES_AVAILABILITY, urlParams.listingId]})
            options?.onSuccess?.()
        },
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error)
    })
}
