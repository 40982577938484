import {SelectItem, SelectPopover, SelectArrow, SelectList, ComboboxList} from '@ariakit/react'
import styled, {css, DefaultTheme} from 'styled-components'
import {Button} from '@components/ui/button/Button'
import {Virtuoso} from 'react-virtuoso'
import {DESKTOP_TABLE_SCROLLBAR_WIDTH} from '@/components/commons/table/DesktopTable/style'

export const makeSelectPopoverStyle = ({palette, shadows, spacing}: DefaultTheme) => css`
    background-color: ${palette.neutral.white};
    border: 1px solid ${palette.neutral[200]};
    border-radius: 8px;
    box-shadow: ${shadows.lg};
    padding: ${spacing}px ${spacing * 1.5}px;
    max-height: 400px;
    overflow-y: hidden;
    display: grid;
    grid-template-rows: minmax(0, 1fr);
`

export const StyledSelectPopover = styled(SelectPopover)(
    ({theme}) => css`
        ${makeSelectPopoverStyle(theme)}
    `
)

export const StyledSelectComboboxPopover = styled(StyledSelectPopover)(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        gap: ${spacing}px;
    `
)

export const StyledSelectList = styled(SelectList)`
    overflow-y: auto;
`

export const StyledSelectComboboxList = styled(ComboboxList)`
    overflow-y: auto;
`

export const makeSelectItemStyle = ({palette, spacing, typography}: DefaultTheme) => css`
    ${typography.textMd};
    padding: ${spacing * 2.5}px ${spacing * 2}px;
    cursor: pointer;
    border-radius: 6px;
    margin: 1px 0;
    color: ${palette.neutral[900]};
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: ease-in-out 200ms;

    &[aria-disabled='true'] {
        color: ${palette.neutral['400']};
    }

    &[aria-selected='true'] {
        background-color: ${palette.primary['100']};
    }

    &:not([aria-selected='true'])[data-active-item='true'] {
        background-color: ${palette.neutral['50']};
    }
`

export const StyledSelectItem = styled(SelectItem)(
    ({theme}) => css`
        ${makeSelectItemStyle(theme)}
    `
)

export const StyledSelectTrigger = styled(Button)<{$hasChildren: boolean; $hasError: boolean}>(
    ({theme: {palette}, $hasChildren, $hasError}) => css`
        justify-content: space-between;
        box-shadow: none;
        ${!$hasChildren &&
        css`
            font-weight: 400;
            color: ${palette.neutral['400']};
        `};

        &:not(:disabled):hover,
        &:not(:disabled):focus,
        &[aria-expanded='true'] {
            box-shadow: 0 0 0 4px ${palette.primary[100]};
            outline: 1px solid ${$hasError ? palette.danger[600] : palette.primary[300]};
            outline-offset: -1px;
        }

        &:disabled {
            background-color: ${palette.neutral['100']};
        }
    `
)

export const StyledSelectArrow = styled(SelectArrow)<{$isOpen: boolean}>(
    ({theme: {palette}, $isOpen}) => css`
        fill: ${palette.neutral[500]};
        transition: ease-in-out 200ms;
        margin-left: auto;
        ${$isOpen &&
        css`
            transform: rotate(180deg);
        `}
    `
)

export const StyledSelectVirtualList: typeof Virtuoso = styled(Virtuoso)(
    ({theme: {palette}}) => css`
        height: 200px !important;
        &::-webkit-scrollbar {
            width: ${DESKTOP_TABLE_SCROLLBAR_WIDTH};
            background-color: ${palette.neutral[50]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.neutral[300]};
            border-radius: 3px;

            &:hover {
                background-color: ${palette.neutral[400]};
            }
        }
    `
)
