import {HeadingGroup, Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {TKey, useTranslation} from '@/translations/i18n.tsx'
import {CheckIcon} from '@components/ui/icon'
import {StyledContent} from '@/features/host-submissions/components/approved-step/style'

export const ApprovedStep = ({description}: {description: TKey}) => {
    const {t} = useTranslation()

    return (
        <StyledContent gap={6}>
            <span>
                <CheckIcon />
            </span>
            <HeadingGroup align="center">
                <Title>{t('services:step_approved:title')}</Title>
                <Paragraph>{t(description)}</Paragraph>
            </HeadingGroup>
        </StyledContent>
    )
}
