import styled, {css} from 'styled-components'

export const StyledParagraph = styled.div(
    ({theme: {typography, spacing}}) => css`
        ${typography.textSm}
        & a {
            text-decoration: underline;
            cursor: pointer;
            font-weight: 500;
        }

        & ul {
            & li {
                &:before {
                    content: '• ';
                    margin-left: ${-spacing * 1.5}px;
                }

                margin-left: ${spacing * 2}px;
            }
        }
    `
)
