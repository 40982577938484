import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {raise} from '@utilities/helpers.ts'
import {httpUpdateActivity, HttpUpdateActivityOptions} from '../http/experiences.http'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {ExperienceExtended} from '../types'

export const useUpdateActivity = ({
    experienceId,
    activityId,
    options
}: {
    experienceId: number
    activityId?: number
    options?: UseMutationOptions<ExperienceExtended, unknown, Omit<HttpUpdateActivityOptions['payload'], 'mutationFn'>>
}) => {
    return useMutation({
        ...options,
        mutationFn: payload =>
            httpUpdateActivity({
                urlParams: {
                    experienceId,
                    activityId: activityId ?? raise('activity id is null')
                },
                params: {responseType: 'extended'},
                payload: payload
            }),
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
