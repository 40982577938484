import {routes} from '@utilities/constants'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {StyledSSOModal} from '@/features/in-progress-services/components/in-progress-section/style.ts'
import {AirbnbSSOWidget} from '@/features/sso/components/airbnb-sso-widget/AirbnbSSOWidget'
import {Camera01Icon} from '@components/ui/icon'
import {useTheme} from 'styled-components'
import {useTranslation} from '@/translations/i18n.tsx'

export const NewPhotoRequest = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const navigate = useNavigate()
    const accessToken = useAuthStore(state => state.accessToken)
    //State
    const [showSSOModal, setShowSSOModal] = useState(false)

    //handle request photoShoot btn click
    const onPhotoShootRequestClick = () => {
        if (accessToken) {
            return navigate(routes.PHOTOSHOOT_REQUEST.path)
        } else {
            setShowSSOModal(true)
        }
    }
    return (
        <>
            {showSSOModal && (
                <StyledSSOModal width={372} onClose={() => setShowSSOModal(!showSSOModal)}>
                    <AirbnbSSOWidget
                        serviceIcon={<Camera01Icon size={40} fill={palette.neutral.black} />}
                        title={t('photoshoot_landing:sso:title')}
                        subtitle={t('photoshoot_landing:sso:subtitle')}
                        disclaimer={t('photoshoot_landing:sso:disclaimer')}
                    />
                </StyledSSOModal>
            )}

            <Button variant={'primary'} size={'md'} onClick={onPhotoShootRequestClick}>
                {t('commons:request_a_photoshoot')}
            </Button>
        </>
    )
}

NewPhotoRequest.displayName = 'NewPhotoRequest'
