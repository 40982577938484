import styled, {css} from 'styled-components'
import {SlidingModal, SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'

export const StyledGalleryTipsModal = styled(SlidingModal)(
    ({theme: {palette}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr);
        color: ${palette.neutral[900]};
    `
)

export const StyledTitle = styled(SlidingModalHeader)(
    ({theme: {typography}}) => css`
        position: relative;

        & h3 {
            ${typography.textSm}
            font-weight: 500;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    `
)

export const StyledTip = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        display: grid;
        grid-template-columns: 64px 1fr;
        gap: ${spacing * 4}px;

        & span {
            width: 64px;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 12px;
            background: ${palette.neutral[100]};
        }

        & h4 {
            color: ${palette.neutral[900]};
            font-weight: 500;
        }

        & p {
            ${typography.textSm}
            color: ${palette.neutral[700]};
        }
    `
)

export const StyledPreviewImage = styled.img`
    object-fit: contain;
`
