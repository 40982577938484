import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpDeleteQuestionAnswerNote} from '@/features/experiences-host-video/queries/experiences-host-video.http'

interface UseDeleteQuestionAnswerNote {
    params: {
        hostCode: number
        hostPassword: string
        taskId: number
        experiencesHostVideoId: number
    }
    mutationOptions?: Omit<
        UseMutationOptions<
            unknown,
            unknown,
            {
                questionAnswerId: number
                noteId: number
            }
        >,
        'mutationFn'
    >
}
export const useDeleteQuestionAnswerNote = ({params, mutationOptions}: UseDeleteQuestionAnswerNote) => {
    return useMutation<unknown, unknown, {questionAnswerId: number; noteId: number}>({
        mutationFn: ({questionAnswerId, noteId}) =>
            httpDeleteQuestionAnswerNote({
                urlParams: {...params},
                payload: {questionAnswerId, noteId}
            }),

        ...mutationOptions
    })
}
