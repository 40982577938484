import {useClipsUpload} from '@/features/experiences-host-video/stores/experiencesHostVideoUpload.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {PlusIcon} from '@components/ui/icon'
import {DropzoneState} from 'react-dropzone'
import {useTranslation} from '@/translations/i18n.tsx'
import {httpBulkDeleteUploadedClips} from '@/features/experiences-host-video/queries/experiences-host-video.http'
import {errorHandler, raise} from '@utilities/helpers.ts'
import {useAsync} from '@hooks/useAsync.ts'
import {UploadingClipItem} from '@/features/experiences-host-video/components/uploading-clip-item/UploadingClipItem.tsx'
import {UploadedClipItem} from '@/features/experiences-host-video/components/uploaded-clip-item/UploadedClipsItem.tsx'
import {
    StyledPreviewUploadedClipsContainer,
    StyledPreviewUploadedClipsCounterLabel,
    StyledPreviewUploadedClipsRemoveAllButton
} from '@/features/experiences-host-video/components/uploaded-clips-view/style.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {ErrorClipsItem} from '@/features/experiences-host-video/components/error-clip-item/ErrorClipsItem.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const UploadedClipsView = ({dropzoneState}: {dropzoneState: DropzoneState}) => {
    const {t} = useTranslation()
    const uploadingFiles = useClipsUpload(store => store.uploadingFiles)
    const uploadedFiles = useClipsUpload(store => store.uploadedFiles)
    const errorFiles = useClipsUpload(store => store.errorFiles)
    const videosetId = useClipsUpload(store => store.videosetId)
    const removeFile = useAsync()
    const removeUploadedFiles = useClipsUpload(store => store.removeUploadedFiles)
    const routeParams = useExperiencesHostVideoRouteParams()
    const removeAllErrorFiles = useClipsUpload(store => store.removeAllErrorFiles)
    const onRemove = async () => {
        try {
            if (uploadedFiles.length > 0) {
                await removeFile.run(
                    httpBulkDeleteUploadedClips({
                        urlParams: {...routeParams, videoSetId: videosetId ?? raise('videosetId is undefined')}
                    })
                )
                removeUploadedFiles(uploadedFiles.map(uploadedFile => uploadedFile.id))
            }
            removeAllErrorFiles()
        } catch (error) {
            errorHandler(error)
        }
    }

    return (
        <Flexbox direction={'column'} gap={6}>
            <Flexbox direction={'column'} gap={2} width={'100%'}>
                <Flexbox justify={'space-between'} align={'center'} width={'100%'}>
                    <StyledPreviewUploadedClipsCounterLabel>
                        {t('experiences_host_video:upload_clip:clip_counter', {count: uploadedFiles.length})}
                    </StyledPreviewUploadedClipsCounterLabel>
                    <Dropzone state={dropzoneState}>
                        <Button variant={'secondary'}>
                            <PlusIcon />
                            {t('experiences_host_video:upload_clip:add_clips')}
                        </Button>
                    </Dropzone>
                </Flexbox>
                <StyledPreviewUploadedClipsRemoveAllButton
                    variant={'tertiary'}
                    disabled={removeFile.isLoading || uploadingFiles.length != 0}
                    onClick={onRemove}
                >
                    {t('experiences_host_video:upload_clip:remove_all')}
                    {removeFile.isLoading && <Spinner />}
                </StyledPreviewUploadedClipsRemoveAllButton>
            </Flexbox>
            <StyledPreviewUploadedClipsContainer>
                {errorFiles.map((errorFile, index) => (
                    <ErrorClipsItem key={index} file={errorFile.file} />
                ))}
                {uploadingFiles.map((uploadingFile, index) => (
                    <UploadingClipItem
                        key={index}
                        progressValue={uploadingFile.progressValue}
                        file={uploadingFile.file}
                    />
                ))}
                {uploadedFiles.map((uploadedFile, index) => (
                    <UploadedClipItem
                        key={index}
                        url={uploadedFile.url}
                        id={uploadedFile.id}
                        parent_flags={uploadedFile.parent_flags}
                    />
                ))}
            </StyledPreviewUploadedClipsContainer>
        </Flexbox>
    )
}
