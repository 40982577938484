import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import styled, {css} from 'styled-components'

export const StyledGalleryDropzone = styled(Dropzone)(
    ({theme: {palette}}) => css`
        width: 100%;
        height: 180px;
        border-radius: 16px;
        border: 2px dashed ${palette.neutral[300]};
    `
)

export const StyledGalleryTrigger = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        height: 100%;

        & svg {
            color: ${palette.neutral[600]};
        }
        & h3 {
            font-weight: 500;
            color: ${palette.neutral[900]};
        }

        & p {
            ${typography.textSm};
            color: ${palette.neutral[700]};
        }
    `
)

export const StyledGalleryGrid = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: ${spacing * 4}px;
        ${mediaQueries.m} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    `
)

export const StyledTipText = styled(Flexbox)(
    ({theme: {palette}}) => css`
        cursor: pointer;
        & svg {
            & path {
                stroke: ${palette.blue[600]};
            }
        }
        & p {
            text-decoration: underline;
        }
    `
)

export const StyledFooterText = styled.p(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[700]};
    `
)
