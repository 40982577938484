import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledLocationBox = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 3}px ${spacing * 4}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 8px;
    `
)

export const StyledServiceAreaParserBox = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        padding: ${spacing * 5}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 12px;
        cursor: pointer;

        & h3 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        & p {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }
    `
)
