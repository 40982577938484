import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {Device, ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'
import {httpGetDevices} from '@/features/experiences-host-video/queries/experiences-host-video.http'

const Response = z.object({data: z.array(Device)})
type Response = z.infer<typeof Response>

interface UseDevicesArgs {
    urlParams: ExperiencesHostVideoRouteParams
    params: {
        limit: number
        page: number
        search?: string
    }
    queryOptions?: Omit<UseQueryOptions<unknown, unknown, Response>, 'queryKey' | 'queryFn'>
}
export const useDevices = ({urlParams, params, queryOptions}: UseDevicesArgs) =>
    useQuery({
        queryKey: [QUERY_KEYS.EXPERIENCES_HOST_VIDEO_DEVICES, urlParams, params],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetDevices({urlParams, params}),
                responseShape: Response,
                onZodError: captureException
            }),
        ...queryOptions
    })
