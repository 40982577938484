import {
    Content,
    FieldTitle,
    HeadingGroup,
    Paragraph,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {EditFieldSection} from '@/features/host-submissions/components/edit-field-section/EditFieldSection'
import TextArea from '@components/commons/textarea/TextArea.tsx'
import {z} from 'zod'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import InputText from '@components/commons/input-text/InputText.tsx'
import {FC, Fragment, useMemo, useState} from 'react'
import {RestoreFieldsBanner} from '@/features/host-submissions/components/restore-fields-banner/RestoreFieldsBanner'
import {ExperienceExtended, StepKeyName} from '@/features/host-submissions/experiences/types'
import {Footer} from '@/features/host-submissions/components/atoms/Atoms'
import {ContinueEditModal} from '@/features/host-submissions/components/continue-edit-modal/ContinueEditModal'
import {
    EditActivitySection,
    EditActivityValidationSchema
} from '@/features/host-submissions/experiences/components/edit-activity-section/EditActivitySection'
import {useChangeStep} from '../../hooks/useChangeStep'
import {getPreviousAnswerData, hasRelevantFieldChanges} from '../../utils'
import {errorHandler, raise} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useUpdateHostApprovalQuestions} from '../../queries/useUpdateHostApprovalQuestions'

export const TITLE_MAX_LENGTH = 50
export const DESCRIPTION_MAX_LENGTH = 90

const EnhanceYourExperienceValidationSchema = z.object({
    improvedExperienceTitle: z.string().min(1, {message: i18n.t('errors:field_required')}),
    improvedExperienceDescription: z.string().min(1, {message: i18n.t('errors:field_required')}),
    itineraries: z.array(EditActivityValidationSchema)
})
export type EnhanceYourExperienceValidationSchema = z.infer<typeof EnhanceYourExperienceValidationSchema>

const getDefaultValuesEnhanceForm = (experience: ExperienceExtended) => {
    const remappedActivityData = experience.itineraries
        ? experience.itineraries.map(activity =>
              activity.answers.reduce(
                  (acc, val) => {
                      if (val.questionKeyName == 'improved_title') {
                          return {...acc, improvedTitle: val.answer}
                      }
                      if (val.questionKeyName == 'improved_description') {
                          return {...acc, improvedDescription: val.answer}
                      }

                      return acc
                  },
                  {
                      improvedTitle: '',
                      improvedDescription: '',
                      mainPhoto: {src: activity?.coverImage?.url ?? '', id: activity?.coverImage?.id},
                      id: activity.id
                  }
              )
          )
        : []

    return experience.expertise.answers.reduce(
        (previousValue, currentValue) => {
            if (currentValue.questionKeyName == 'improved_experience_title' && currentValue.answer) {
                return {...previousValue, improvedExperienceTitle: currentValue.answer}
            }
            if (currentValue.questionKeyName == 'improved_experience_description' && currentValue.answer) {
                return {...previousValue, improvedExperienceDescription: currentValue.answer}
            }
            return previousValue
        },
        {
            improvedExperienceTitle: '',
            improvedExperienceDescription: '',
            itineraries: remappedActivityData
        }
    )
}

export const EnhanceYourExperienceStep: FC<{experience: ExperienceExtended}> = ({experience}) => {
    const {t} = useTranslation()
    const [isEditMode, setIsEditMode] = useState(false)
    const continueEditingExperiencesValue = sessionStorage.getItem('continueEditingExperiencesConfirmed')
    const isContinueEditingConfirmed = continueEditingExperiencesValue
        ? JSON.parse(continueEditingExperiencesValue)
        : false
    const [isContinueEditModalOpen, setIsContinueEditModalOpen] = useState(false)
    const [isFieldChanged, setIsFieldChanged] = useState(false)

    const restoreDataMutation = useUpdateHostApprovalQuestions({
        experienceId: experience.id,
        options: {
            onError: errorHandler,
            onSuccess: data => {
                setIsFieldChanged(false)
                form.reset(getDefaultValuesEnhanceForm(data))
            }
        }
    })
    const changeStep = useChangeStep({
        experience,
        currentStep: StepKeyName.enum.enhance_your_experience,
        nextStep: StepKeyName.enum.enhance_about_you
    })

    const updateEnhanceStepMutation = useUpdateHostApprovalQuestions({
        experienceId: experience.id,
        options: {onError: errorHandler, onSuccess: () => changeStep.handleChangeStep(false)}
    })
    const defaultValues = getDefaultValuesEnhanceForm(experience)

    const form = useForm<EnhanceYourExperienceValidationSchema>({
        resolver: zodResolver(EnhanceYourExperienceValidationSchema),
        defaultValues
    })

    const isPrevStepChanged = useMemo(() => {
        const remappedActivities = experience.itineraries
            ? experience.itineraries?.map(activity => activity.answers)
            : []
        const activitiesChanged = hasRelevantFieldChanges(remappedActivities, [
            'improved_title',
            'improved_description',
            'main_photo_url'
        ])
        const answersChanged = experience
            ? hasRelevantFieldChanges(
                  [experience.expertise.answers],
                  [
                      'improved_host_experience_title',
                      'improved_host_expertise_description',
                      'improved_host_recognition_title',
                      'improved_host_recognition_subtitle',
                      'improved_experience_title',
                      'improved_experience_description'
                  ]
              )
            : false
        return activitiesChanged || answersChanged
    }, [experience])

    const [experienceTitleWatch, experienceDescriptionWatch, activitiesWatch] = useWatch({
        control: form.control,
        name: ['improvedExperienceTitle', 'improvedExperienceDescription', 'itineraries']
    })

    const checkIfFormValid = async (fieldName: keyof EnhanceYourExperienceValidationSchema) =>
        await form.trigger(fieldName)

    const onSubmit = form.handleSubmit(data => {
        const dataToSend = {
            ...data,
            itineraries: data.itineraries.map(({mainPhoto, ...rest}) => ({
                ...rest,
                mainPhoto: mainPhoto?.id
            }))
        }
        updateEnhanceStepMutation.mutate(dataToSend)
    })

    const onRestoreValues = () => {
        const previousAnswerData = getPreviousAnswerData(experience)
        restoreDataMutation.mutate(previousAnswerData)
    }

    return (
        <>
            <Content gap={6}>
                <HeadingGroup direction="column" gap={4}>
                    <Title>{t('host_review:enhance_your_service_step_title')}</Title>
                    <Paragraph>{t('host_review:enhance_your_service_step_paragraph')}</Paragraph>
                    {(isFieldChanged || isPrevStepChanged) && (
                        // delete hideRestoreAction when it need
                        <RestoreFieldsBanner
                            onRestore={onRestoreValues}
                            isLoading={restoreDataMutation.isPending}
                            hideRestoreAction={true}
                        />
                    )}
                </HeadingGroup>
                <Flexbox gap={5} direction="column">
                    <FieldTitle>{t('experiences:enhance_your_experience_step:sub_title')}</FieldTitle>

                    <EditFieldSection
                        disabled={isEditMode}
                        label={t('experiences:enhance_your_experience_step:experience_title')}
                        text={experienceTitleWatch}
                        checkIfFormValid={() => checkIfFormValid('improvedExperienceTitle')}
                        onEdit={() => {
                            if (!isFieldChanged && !isContinueEditingConfirmed) {
                                setIsContinueEditModalOpen(true)
                            } else {
                                setIsEditMode(true)
                            }
                        }}
                        onConfirm={() => {
                            setIsFieldChanged(
                                defaultValues.improvedExperienceTitle != experienceTitleWatch ||
                                    defaultValues.improvedExperienceDescription != experienceDescriptionWatch
                            )
                            setIsEditMode(false)
                        }}
                        errorMessage={form.formState.errors.improvedExperienceTitle?.message}
                        editableComponents={
                            <InputText
                                type={'text'}
                                maxLength={TITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: TITLE_MAX_LENGTH - experienceTitleWatch.length
                                })}
                                errorMessage={form.formState.errors.improvedExperienceTitle?.message}
                                {...form.register('improvedExperienceTitle')}
                            />
                        }
                    />

                    <EditFieldSection
                        disabled={isEditMode}
                        label={t('experiences:enhance_your_experience_step:experience_description')}
                        text={experienceDescriptionWatch}
                        checkIfFormValid={() => checkIfFormValid('improvedExperienceDescription')}
                        onEdit={() => {
                            if (!isFieldChanged && !isContinueEditingConfirmed) {
                                setIsContinueEditModalOpen(true)
                            } else {
                                setIsEditMode(true)
                            }
                        }}
                        onConfirm={() => {
                            setIsFieldChanged(
                                defaultValues.improvedExperienceTitle != experienceTitleWatch ||
                                    defaultValues.improvedExperienceDescription != experienceDescriptionWatch
                            )
                            setIsEditMode(false)
                        }}
                        errorMessage={form.formState.errors.improvedExperienceDescription?.message}
                        editableComponents={
                            <TextArea
                                rows={4}
                                maxLength={DESCRIPTION_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: DESCRIPTION_MAX_LENGTH - experienceDescriptionWatch.length
                                })}
                                errorMessage={form.formState.errors.improvedExperienceDescription?.message}
                                {...form.register('improvedExperienceDescription')}
                            />
                        }
                    />

                    <Divider direction="horizontal" />

                    {activitiesWatch.map((activity, index) => (
                        <Fragment key={`itineraries.${index}`}>
                            <Controller
                                control={form.control}
                                name={`itineraries.${index}`}
                                render={({field: {onChange}}) => (
                                    <EditActivitySection
                                        activityNumber={index + 1}
                                        experienceId={experience.id}
                                        id={activity.id}
                                        disabled={isEditMode}
                                        title={activity.improvedTitle}
                                        description={activity.improvedDescription}
                                        mainPhoto={activity.mainPhoto}
                                        galleryId={experience?.gallery?.id ?? raise('galleryId is nullish')}
                                        errorMessages={form.formState.errors.itineraries?.[index]}
                                        onConfirm={(data: EditActivityValidationSchema) => {
                                            const defaultActivityValues = defaultValues.itineraries.find(
                                                item => item.id == activity.id
                                            )
                                            setIsFieldChanged(
                                                defaultValues.improvedExperienceTitle != experienceTitleWatch ||
                                                    defaultValues.improvedExperienceDescription !=
                                                        experienceDescriptionWatch ||
                                                    defaultActivityValues?.improvedTitle != data.improvedTitle ||
                                                    defaultActivityValues?.improvedDescription !=
                                                        data.improvedDescription ||
                                                    defaultActivityValues?.mainPhoto.src != data.mainPhoto?.src
                                            )
                                            onChange(data)

                                            setIsEditMode(false)
                                        }}
                                        onEdit={() => {
                                            if (!isFieldChanged && !isContinueEditingConfirmed) {
                                                setIsContinueEditModalOpen(true)
                                            } else {
                                                setIsEditMode(true)
                                            }
                                        }}
                                    />
                                )}
                            />

                            {index != activitiesWatch.length - 1 && (
                                <Divider startSpacing={1} endSpacing={1} direction="horizontal" />
                            )}
                        </Fragment>
                    ))}
                </Flexbox>
            </Content>

            <Footer>
                <Flexbox width={'100%'} justify={'center'}>
                    <Button
                        style={{maxWidth: 800}}
                        fullWidth
                        size="lg"
                        onClick={onSubmit}
                        disabled={changeStep.isContinueLoading || isEditMode || updateEnhanceStepMutation.isPending}
                    >
                        {t('commons:continue')}
                        {(changeStep.isContinueLoading || updateEnhanceStepMutation.isPending) && <Spinner />}
                    </Button>
                </Flexbox>
            </Footer>

            {isContinueEditModalOpen && (
                <ContinueEditModal
                    onClose={() => {
                        setIsContinueEditModalOpen(false)
                    }}
                    onContinue={() => {
                        setIsEditMode(true)
                        setIsContinueEditModalOpen(false)
                        sessionStorage.setItem('continueEditingExperiencesConfirmed', 'true')
                    }}
                />
            )}
        </>
    )
}
