import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpAcceptAdditionalPhotos} from '../http/experiences.http'

interface UseAcceptAdditionalPhotosProps {
    experienceId: number
    galleryId: number
    mutationOptions?: Omit<UseMutationOptions<unknown, unknown, {images: number[]}>, 'mutationFn'>
}

export const useAcceptAdditionalPhotos = ({
    experienceId,
    galleryId,
    mutationOptions
}: UseAcceptAdditionalPhotosProps) => {
    const queryClient = useQueryClient()
    return useMutation<unknown, unknown, {images: number[]}>({
        mutationFn: ({images}: {images: number[]}) =>
            httpAcceptAdditionalPhotos({
                urlParams: {experienceId, galleryId},
                params: {
                    responseType: 'extended'
                },
                payload: {images}
            }),
        ...mutationOptions,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            mutationOptions?.onSuccess?.(data, variables, context)
        }
    })
}
