import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient'
import {errorHandler} from '@/utilities/helpers'
import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {ExperienceExtended} from '../types'
import {httpAddActivity, HttpAddActivityOptions} from '../http/experiences.http'

export const useAddActivity = (
    experienceId: number,
    options?: Omit<UseMutationOptions<ExperienceExtended, unknown, HttpAddActivityOptions['payload']>, 'mutationFn'>
) => {
    return useMutation({
        ...options,
        mutationKey: [MUTATION_KEYS.ADD_ACTIVITY],
        mutationFn: payload =>
            httpAddActivity({
                urlParams: {experienceId},
                payload,
                params: {responseType: 'extended'}
            }),
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        },
        onError: errorHandler
    })
}
