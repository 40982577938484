import styled, {css} from 'styled-components'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'

export const StyledAlertIconWrapper = styled(Flexbox)(
    ({theme: {palette}}) => css`
        width: 44px;
        height: 44px;
        background-color: ${palette.warning[600]};
        border-radius: 50%;
        flex-shrink: 0;
        & svg {
            fill: ${palette.neutral.white};
        }
    `
)

export const StyledEditAlertWrapper = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        & h3 {
            font-weight: 500;
            ${typography.textSm}
        }

        & p {
            color: ${palette.neutral[600]};
            ${typography.textSm}
        }
    `
)
