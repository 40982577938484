import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledPhotoGrid} from './style'
import {PlusIcon} from '@/components/ui/icon'
import {useTranslation} from '@/translations/i18n'
import {useDropzone} from 'react-dropzone'
import {FieldTitle} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {Button} from '@/components/ui/button/Button'
import {Dropzone} from '@/components/ui/dropzone/Dropzone'
import {httpUploadFile} from '@/services/upload.http'
import {raise} from '@/utilities/helpers'
import {Dispatch, SetStateAction, useState} from 'react'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {httpConfirmUploadPhoto} from '@/features/host-submissions/experiences/http/experiences.http'
import {ExperienceGalleryImageItem} from '@/features/host-submissions/experiences/components/experience-gallery-image-item/ExperienceGalleryImageItem'
import {
    BulkPresignedPhotosResponse,
    useBulkPresignedPhotos
} from '@/features/host-submissions/experiences/queries/useBulkPresignedPhotos'
import {GalleryItem} from '@/features/host-submissions/types'
import {UploadedMedia} from '../../types'

interface ActivityPhotoUploaderProps {
    experienceId: number
    value: UploadedMedia
    onChange: (value: UploadedMedia) => void
    uploadStatusState: [
        'pending' | 'success' | 'error' | 'idle',
        Dispatch<SetStateAction<'pending' | 'success' | 'error' | 'idle'>>
    ]
    gallery: UploadedMedia[]
    galleryId: number
}

export const ActivityPhotoUploader = ({
    experienceId,
    value,
    onChange,
    uploadStatusState,
    gallery,
    galleryId
}: ActivityPhotoUploaderProps) => {
    const {t} = useTranslation()
    const [uploadProgressValue, setUploadProgressValue] = useState(0)
    const [uploadStatus, setUploadStatus] = uploadStatusState
    const [acceptedFile, setAcceptedFile] = useState<null | File>(null)

    const onSuccess = (data: BulkPresignedPhotosResponse) => {
        const value = data[0]

        if (acceptedFile) {
            const galleryItem: GalleryItem = {
                id: value.media.id,
                progressValue: 0,
                status: 'pending',
                blob: acceptedFile,
                presignedUrl: value.presignedUrl
            }

            onChange({id: value.media.id, url: value.presignedUrl})
            void uploadFile(galleryItem)
        }
    }

    const bulkPresignedMutation = useBulkPresignedPhotos({
        urlParams: {experienceId, galleryId},
        options: {onSuccess}
    })

    const dropzoneState = useDropzone({
        disabled: bulkPresignedMutation.isPending || uploadStatus == 'pending',
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        multiple: false,
        onDropAccepted: acceptedFiles => {
            setUploadStatus('pending')

            if (acceptedFiles.length) {
                setAcceptedFile(acceptedFiles[0])

                bulkPresignedMutation.mutate({medias: [acceptedFiles[0].name]})
            }
        },
        onDropRejected: rejectedFiles => {
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    const invalidateQueryGallery = () => {
        void queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
        void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
    }

    const uploadFile = async (galleryItem: GalleryItem) => {
        try {
            await httpUploadFile({
                url: galleryItem.presignedUrl || raise('the presignedUrl is undefined'),
                payload: galleryItem.blob || raise('the file is undefined'),
                onUploadProgress: progressEvent =>
                    setUploadProgressValue(
                        progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100
                    )
            })
            const response = await httpConfirmUploadPhoto({
                params: {responseType: 'extended'},
                urlParams: {
                    galleryId,
                    experienceId,
                    mediaId: galleryItem.id
                }
            })

            if (response.data.id && response.data.url) {
                onChange({
                    id: response.data.id,
                    url: decodeURIComponent(response.data.url.replace(/\\/g, ''))
                })

                invalidateQueryGallery()
                setAcceptedFile(null)
            }

            setUploadStatus('success')
            setUploadProgressValue(0)
        } catch (error) {
            setUploadStatus('error')
            setUploadProgressValue(0)
        }
    }

    return (
        <Flexbox direction="column" gap={4} width="100%">
            <Flexbox justify="space-between" align="center" gap={4} width="100%">
                <FieldTitle>{t('experiences:experience_itinerary_step:create_activity_modal:photo_label')}</FieldTitle>

                <Dropzone state={dropzoneState}>
                    <Button
                        variant="secondary"
                        onClick={event => event.preventDefault()}
                        style={{whiteSpace: 'nowrap'}}
                        disabled={uploadStatus == 'pending'}
                    >
                        <PlusIcon size={20} />
                        {t('experiences:experience_itinerary_step:create_activity_modal:add_photo')}
                        {uploadStatus == 'pending' && <Spinner />}
                    </Button>
                </Dropzone>
            </Flexbox>

            <StyledPhotoGrid>
                {acceptedFile && (
                    <ExperienceGalleryImageItem
                        imageItem={{
                            blob: acceptedFile,
                            status: uploadStatus,
                            id: Date.now(),
                            progressValue: uploadProgressValue
                        }}
                        isSelected={true}
                        onSuccessRetry={onSuccess}
                        experienceId={experienceId}
                        galleryId={galleryId}
                    />
                )}

                {gallery.map(image => {
                    const isSelected = image.id == value?.id
                    return (
                        <ExperienceGalleryImageItem
                            imageItem={{...image, src: image.url, status: 'success', isCover: false}}
                            key={image.id}
                            onSelect={() => onChange(image)}
                            isSelected={isSelected}
                            onSuccessRetry={onSuccess}
                            experienceId={experienceId}
                            galleryId={galleryId}
                        />
                    )
                })}
            </StyledPhotoGrid>
        </Flexbox>
    )
}
