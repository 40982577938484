import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {HttpGetServiceResponse} from '@/features/service/http/service.http'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import i18next from 'i18next'

interface luxeServicePageStepsParams {
    service: HttpGetServiceResponse
}

export const luxeServicePageSteps = ({service}: luxeServicePageStepsParams): ChatbotStep[] => {
    const quoteStatus = service?.quote?.quote_status
    const taskStatus = service?.assignment?.task_status?.status
    const isQuoteNotSigned = quoteStatus !== 'signed' && quoteStatus !== 'completed' && taskStatus != 'auto_cancelled'
    const isScheduled = taskStatus == 'scheduled'

    return [
        {
            id: 'service_detail_luxe',
            component: (
                <p>
                    {i18next.t('chatbot:luxe_service_page_steps:service_detail_component')}{' '}
                    <strong> {service.entity.title ?? service.entity.external_id} </strong>
                </p>
            ),
            asMessage: true,
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'service_detail_luxe',
                    source: 'Chatbot',
                    text: `Hey, you are now talking with our virtual assistant, About the listing: ${service.entity.title}`
                })
                return 'start_luxe'
            }
        },
        {
            id: 'start_luxe',
            options: [
                ...(isQuoteNotSigned || taskStatus == 'accepted'
                    ? [
                          {
                              value: 'HP33',
                              label: i18next.t('chatbot:luxe_service_page_steps:start_HP33_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🗓️ Scheduling my walkthrough'
                                  })
                                  return 'HP33'
                              }
                          }
                      ]
                    : []),
                ...((taskStatus == 'soft_cancelled' ||
                    taskStatus == 'pending_assets' ||
                    taskStatus == 'accepted' ||
                    isScheduled ||
                    quoteStatus == 'completed' ||
                    taskStatus == 'pending_payment') &&
                taskStatus !== 'completed'
                    ? [
                          {
                              value: 'HP34',
                              label: i18next.t('chatbot:luxe_service_page_steps:start_HP34_label'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'start',
                                      source: 'User',
                                      text: '🤝 Coordination with my home consultant'
                                  })
                                  return 'HP34'
                              }
                          }
                      ]
                    : []),
                {
                    value: 'HP35',
                    label: i18next.t('chatbot:luxe_service_page_steps:start_HP35_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: 'Other'
                        })
                        return 'HP35'
                    }
                },
                {
                    value: 'HP1_luxe',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP1_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP1_luxe',
                            source: 'User',
                            text: '💸 Price Quote'
                        })
                        return 'HP1_luxe'
                    }
                },
                {
                    value: 'HP11',
                    label: i18next.t('chatbot:generic_service_page_steps:start_HP11_label'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'start',
                            source: 'User',
                            text: '🧭 My topic is not listed'
                        })
                        return 'HP11'
                    }
                }
            ]
        }
    ]
}
