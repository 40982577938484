import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetService, HttpGetServiceParams, HttpGetServiceResponse} from '@/features/service/http/service.http'
import {parseAxiosPromise} from '@utilities/helpers.ts'

export const useServiceDetails = (
    params: HttpGetServiceParams,
    options?: Omit<UseQueryOptions<HttpGetServiceResponse>, 'queryFn' | 'queryKey'>
) => {
    return useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SERVICE_DETAILS, params],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetService(params),
                responseShape: HttpGetServiceResponse
            })
    })
}
