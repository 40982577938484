import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {OtpPendingConfirmStep} from '@/features/otp-flow/components/otp-pending-confirmation-step/OtpPendingConfirmStep.tsx'
import {AcceptanceStepsConfigByService} from '@/features/service-acceptance-flow/utils.ts'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {StyledSendAgainLink} from '@/features/otp-flow/components/style.ts'
import {errorHandler} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {OtpEmailBaseStep} from '@/features/otp-flow/components/otp-mail-process/OtpEmailBaseStep.tsx'
import {OtpEmailEditStep} from '@/features/otp-flow/components/otp-mail-process/OtpEmailEditStep.tsx'
import {useNotificationPreferences} from '@/features/otp-flow/queries/useNotificationPreferences'
import {getNotifySettingsId} from '@/features/otp-flow/utils'

export const OtpEmailProcess = () => {
    const {t} = useTranslation()
    //otp store step
    const service = useOtpStore(state => state.service)
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    const optSmsActiveStep = useOtpStore(state => state.step)
    const sendTo = useOtpStore(state => state.sendTo)

    const {mutate: requestVerificationCode, isPending} = useNotificationPreferences({
        onSuccess: () => {
            toast.success(t('service_accept:common:code_resent_email'), {duration: 2500})
        },
        onError: error => errorHandler(error)
    })

    const onResendOptCodeRequest = () => {
        const notifySettingsId = getNotifySettingsId({
            service,
            coAlarmRequest
        })

        if (notifySettingsId) {
            requestVerificationCode({
                urlParams: {
                    notifySettingsId: notifySettingsId
                },
                params: {
                    email: sendTo
                }
            })
        } else {
            toast.error(t('errors:default'))
        }
    }

    return (
        <>
            {optSmsActiveStep == 'base' && <OtpEmailBaseStep />}
            {optSmsActiveStep == 'edit' && <OtpEmailEditStep />}
            {optSmsActiveStep == 'pending_confirmation' && (
                <OtpPendingConfirmStep
                    type={'email'}
                    title={t(AcceptanceStepsConfigByService['email'].generic.pending_confirm.title)}
                    subtitle={t(AcceptanceStepsConfigByService['email'].generic.pending_confirm.subtitle, {
                        email: sendTo
                    })}
                    helpText={
                        <Flexbox align={'center'} gap={1}>
                            <Trans
                                i18nKey={AcceptanceStepsConfigByService['email'].generic.pending_confirm.field_help}
                                components={[
                                    <StyledSendAgainLink
                                        isPending={isPending}
                                        onClick={() => onResendOptCodeRequest()}
                                    />
                                ]}
                            />
                            {isPending && <Spinner size={16} />}
                        </Flexbox>
                    }
                />
            )}
        </>
    )
}

OtpEmailProcess.displayName = 'OtpEmailProcess'
