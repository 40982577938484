import InputText from '@/components/commons/input-text/InputText'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledLinkField = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        gap: ${spacing}px;
        align-items: flex-end;
        width: 100%;
    `
)

export const StyledInputText = styled(InputText)`
    & label {
        overflow: visible;
        white-space: normal;
    }
`

export const StyledInputContent = styled(Flexbox)`
    & > div {
        width: 100%;
    }
`
