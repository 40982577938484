import styled, {css} from 'styled-components'
import Container from '@components/ui/container/Container.tsx'

export const StyledContainer = styled(Container)(
    () => css`
        display: flex;
        justify-content: center;
        align-items: center;
    `
)

export const StyledContent = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        width: 100%;
        max-width: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: ${spacing * 10}px;
        color: ${palette.neutral[900]};
        & h1 {
            ${typography.displayXs};
            font-weight: 500;
        }
        & h2,
        h5 {
            ${typography.textSm};
        }
        & h3 {
            ${typography.textXl};
            font-weight: 500;
        }
        & h4 {
            color: ${palette.neutral[700]};
        }
    `
)

export const StyledCard = styled.div(
    ({theme: {spacing, palette}}) => css`
        border: 1px solid ${palette.neutral[300]};
        border-radius: 16px;
        padding: ${spacing * 4}px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: ${spacing * 4}px;
        align-items: center;
        text-align: center;
    `
)

export const StyledContainerSuccess = styled(Container)(
    ({theme: {palette, spacing, typography}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 6}px;
        text-align: center;
        color: ${palette.neutral[900]};
        & > span {
            height: 56px;
            width: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: ${palette.success[50]};
            color: ${palette.success[600]};
            & svg {
                width: 42px;
                height: 42px;
            }
        }
        & h1 {
            ${typography.textXl};
            font-weight: 500;
        }
        & p {
            ${typography.textMd};
        }
    `
)
