import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {FieldDescription} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
import {SelectPopover, SelectTrigger} from '@/components/ui/select-atoms/SelectAtoms'
import styled, {css} from 'styled-components'

export const StyledSelectorTrigger = styled(SelectTrigger)<{$hasError?: boolean}>(
    ({theme: {typography, palette}, $hasError}) => css`
        ${typography.textMd};
        font-weight: 400;
        ${$hasError &&
        css`
            border: 1px solid ${palette.danger['300']};
        `};
    `
)

export const StyledPopoverWrapper = styled.div(
    ({theme: {zIndex}}) => css`
        z-index: ${zIndex.modal};
    `
)

export const StyledPopover = styled(SelectPopover)`
    max-height: 200px;
`

export const StyledFieldDescription = styled(FieldDescription)(
    ({theme: {spacing}}) => css`
        margin-bottom: ${spacing * 2.5}px;
    `
)

export const StyledInputContent = styled(Flexbox)`
    & > div {
        width: 100%;
    }
`
