import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {useEffect, useState} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Label} from '@/components/ui/label/Label'
import {Button} from '@/components/ui/button/Button'
import {
    StyledContent,
    StyledImagePlaceholderBox,
    StyledPreviewImageWrapper,
    StyledText
} from '@/features/host-submissions/services/components/edit-offering-section/style'
import {PreviewImage} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
import {OfferingTypes} from '@/features/host-submissions/services/types'
import {GalleryItem} from '@/features/host-submissions/types'
import InputText from '@/components/commons/input-text/InputText'
import TextArea from '@/components/commons/textarea/TextArea'
import {UploadOfferingPhotoModal} from '@/features/host-submissions/services/components/upload-offering-photo-modal/UploadOfferingPhotoModal'
import {SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {Image01Icon} from '@/components/ui/icon'
import {FieldErrors, useForm} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

export const TITLE_MAX_LENGTH = 32
export const DESCRIPTION_MIN_LENGTH = 30
export const DESCRIPTION_MAX_LENGTH = 250

export const EditOfferingValidationSchema = z.object({
    improved_title: z.string().min(1, {message: i18n.t('errors:field_required')}),
    improved_description: z.string({required_error: i18n.t('errors:field_required')}).min(DESCRIPTION_MIN_LENGTH, {
        message: i18n.t('errors:field_min_character', {count: DESCRIPTION_MIN_LENGTH})
    }),
    main_photo: GalleryItem.extend({
        src: z
            .string({required_error: i18n.t('errors:field_required')})
            .min(1, {message: i18n.t('errors:field_required')})
            .optional()
    })
        .nullable()
        .refine(data => !!data?.src, {
            message: i18n.t('errors:field_required'),
            path: ['src']
        }),
    type: OfferingTypes,
    id: z.number()
})
export type EditOfferingValidationSchema = z.infer<typeof EditOfferingValidationSchema>

interface EditSectionProps {
    type: OfferingTypes
    title: string
    description: string
    mainPhoto: GalleryItem | null
    onEdit?: () => void
    onConfirm?: (data: EditOfferingValidationSchema) => void
    disabled?: boolean
    serviceId: number
    id: number
    errorMessages?: FieldErrors<EditOfferingValidationSchema>
}
export const EditOfferingSection = ({
    description,
    type,
    mainPhoto,
    title,
    disabled = false,
    id,
    onEdit,
    onConfirm,
    serviceId,
    errorMessages
}: EditSectionProps) => {
    const {t} = useTranslation()
    const [isEditMode, setIsEditMode] = useState(false)
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')

    const defaultValues = {
        improved_title: title,
        improved_description: description,
        main_photo: mainPhoto,
        type,
        id
    }
    const form = useForm<EditOfferingValidationSchema>({
        resolver: zodResolver(EditOfferingValidationSchema),
        defaultValues
    })

    useEffect(() => {
        form.reset(defaultValues)
    }, [title, description, mainPhoto?.src])

    const mainPhotoWatch = form.watch('main_photo')
    const titleWatch = form.watch('improved_title')
    const descriptionWatch = form.watch('improved_description')

    const onSubmit = form.handleSubmit(data => {
        onConfirm?.(data)
        setIsEditMode(false)
    })

    const changeMode = () => {
        if (isEditMode) {
            onSubmit()
        } else {
            setIsEditMode(true)
            onEdit?.()
        }
    }

    return (
        <StyledContent gap={4} width="100%" direction="column" $disabled={disabled && !isEditMode}>
            <Flexbox justify="space-between" gap={2} width="100%">
                <Label>{t(`services:step_offerings:offering_type_title:${type}`)}</Label>
                <Button disabled={disabled && !isEditMode} shape="text" variant="tertiary" onClick={changeMode}>
                    {t(isEditMode ? 'commons:done' : 'commons:edit')}
                </Button>
            </Flexbox>
            <Flexbox direction="column" gap={1.6}>
                <Flexbox align="center" gap={4}>
                    {mainPhotoWatch?.src ? (
                        <StyledPreviewImageWrapper>
                            <PreviewImage src={mainPhotoWatch.src} />
                        </StyledPreviewImageWrapper>
                    ) : (
                        <StyledImagePlaceholderBox>
                            <Image01Icon />
                        </StyledImagePlaceholderBox>
                    )}

                    {isEditMode && (
                        <Button variant="secondary" onClick={() => setSlidingModalState('open')}>
                            {t('commons:change_photo')}
                        </Button>
                    )}
                </Flexbox>
                <InputHelpText
                    error={form.formState.errors.main_photo?.src?.message ?? errorMessages?.main_photo?.src?.message}
                />
            </Flexbox>
            {isEditMode ? (
                <InputText
                    type={'text'}
                    label={t('services:step_offerings:offering_title_label')}
                    maxLength={TITLE_MAX_LENGTH}
                    helpText={t('commons:x_characters_available', {
                        count: TITLE_MAX_LENGTH - titleWatch.length
                    })}
                    errorMessage={form.formState.errors.improved_title?.message}
                    {...form.register('improved_title')}
                />
            ) : (
                <Flexbox direction="column" gap={1.5} width="100%">
                    <Label>{t('services:step_offerings:offering_title_label')}</Label>
                    <StyledText>{title}</StyledText>
                    <InputHelpText error={errorMessages?.improved_title?.message} />
                </Flexbox>
            )}

            {isEditMode ? (
                <TextArea
                    rows={3}
                    label={t('services:step_offerings:offering_description_label')}
                    maxLength={DESCRIPTION_MAX_LENGTH}
                    helpText={t('commons:x_characters_available', {
                        count: DESCRIPTION_MAX_LENGTH - descriptionWatch.length
                    })}
                    errorMessage={form.formState.errors.improved_description?.message}
                    {...form.register('improved_description')}
                />
            ) : (
                <Flexbox direction="column" gap={1.5} width="100%">
                    <Label>{t('services:step_offerings:offering_description_label')}</Label>
                    <StyledText>{description}</StyledText>
                    <InputHelpText error={errorMessages?.improved_description?.message} />
                </Flexbox>
            )}

            {slidingModalState != 'closed' && (
                <UploadOfferingPhotoModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    offeringType={type}
                    setMainPhotoValue={(mainPhoto: GalleryItem | null) => form.setValue('main_photo', mainPhoto)}
                    offeringMainPhoto={mainPhotoWatch}
                    serviceId={serviceId}
                />
            )}
        </StyledContent>
    )
}
