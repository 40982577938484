import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetCategories, HttpGetCategoriesResponse} from '../http/categories.http.ts'
import {parseAxiosPromise} from '@/utilities/helpers.ts'
import {captureException} from '@sentry/react'

export const useCategories = (options?: Omit<UseQueryOptions<HttpGetCategoriesResponse>, 'queryFn' | 'queryKey'>) => {
    const query = useQuery({
        ...options,
        staleTime: 120 * 60 * 1000, //2h
        queryKey: [QUERY_KEYS.CATEGORIES],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetCategories(),
                onZodError: captureException,
                responseShape: HttpGetCategoriesResponse
            })
    })

    return {
        ...query,
        data: query.data ? query.data : []
    }
}
