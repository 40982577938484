import {Trans, useTranslation} from '@/translations/i18n.tsx'
import Container from '@components/ui/container/Container.tsx'
import {Helmet} from 'react-helmet'
import {AirbnbSSOWidget} from '@/features/sso/components/airbnb-sso-widget/AirbnbSSOWidget'
import {AirbnbIcon} from '@components/ui/icon'
import {useTheme} from 'styled-components'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {routes} from '@utilities/constants'
import {Navigate} from 'react-router-dom'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {z} from 'zod'
import {ParsedBoolean} from '@/types/commons.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledDescription} from '@pages/uid-login/style.ts'

export const UidLogin = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const accessToken = useAuthStore(store => store.accessToken)
    const {searchParams} = useParsedSearchParams(z.object({lead: z.string(), isChangeProfile: ParsedBoolean}).partial())
    const redirectUrl = searchParams.lead
        ? `${routes.UID_LOGIN_CONFIRM.path}?lead=${searchParams.lead}`
        : routes.UID_LOGIN_CONFIRM.path

    if (accessToken) {
        return <Navigate to={redirectUrl} replace />
    }

    return (
        <Container>
            <Helmet title={t('sso_login:page_title_meta')} />
            {searchParams.isChangeProfile ? (
                <AirbnbSSOWidget
                    title={t('uid_login_confirm:change_profile')}
                    subtitle={
                        <Flexbox direction="column" align="center">
                            <StyledDescription>
                                <Trans
                                    i18nKey="sso_login:uid_change_profile_description1"
                                    components={{a: <a href="https://www.airbnb.com/" target="_blank" />}}
                                />
                            </StyledDescription>
                            <StyledDescription>{t('sso_login:uid_change_profile_description2')}</StyledDescription>
                        </Flexbox>
                    }
                    redirectUrl={redirectUrl}
                />
            ) : (
                <AirbnbSSOWidget
                    serviceIcon={<AirbnbIcon size={64} fill={theme.palette.primary['600']} />}
                    title={t('sso_login:connect_to_airbnb')}
                    subtitle={<StyledDescription>{t('sso_login:uuid_subtitle')}</StyledDescription>}
                    redirectUrl={redirectUrl}
                />
            )}
        </Container>
    )
}
