import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ArrowRightIcon} from '@/components/ui/icon'
import styled, {css} from 'styled-components'

export const StyledActivityItem = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 48px 1fr;
        column-gap: ${spacing * 3}px;
        align-items: start;
        width: 100%;
    `
)

export const StyledItemTextWrapper = styled(Flexbox)`
    overflow: hidden;
    & > h3 {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
export const StyledArrowIcon = styled(ArrowRightIcon)`
    transform: rotate(270deg);
`

export const StyledArrowDownIcon = styled(ArrowRightIcon)`
    transform: rotate(90deg);
`

export const StyledDropdownMenuText = styled.p(
    ({theme: {palette}}) => css`
        font-weight: 500;
        color: ${palette.neutral['900']};
    `
)

export const StyledActivityWrapper = styled.div<{$isLast: boolean}>(
    ({theme: {palette}, $isLast}) => css`
        position: relative;
        width: 100%;
        ${!$isLast &&
        css`
            &::after {
                content: '';
                position: absolute;
                left: 40px;
                width: 2px;
                height: 16px;
                background-color: ${palette.neutral[300]};
                z-index: 1;
            }
        `}
    `
)

export const StyledImagePlaceholderBox = styled.div(
    ({theme: {palette}}) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${palette.neutral['100']};
        height: 48px;
        border-radius: 8px;

        & > svg {
            fill: ${palette.neutral['400']};
        }
    `
)
