import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {z} from 'zod'
import {useAsync} from '@hooks/useAsync.ts'
import {httpCreateService} from '@/features/host-submissions/services/http/services.http'
import {errorHandler, formatLocaleDate} from '@utilities/helpers.ts'
import {useServices} from '@/features/host-submissions/services/queries/useServices'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon, File02Icon} from '@components/ui/icon'
import {useTranslation, TKey} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledCard,
    StyledEmptyState,
    StyledServices,
    StyledList,
    StyledServiceStatusBullet
} from '@pages/services/style.ts'
import {Helmet} from 'react-helmet'
import {Button} from '@components/ui/button/Button.tsx'
import {useNavigate} from 'react-router-dom'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {Service} from '@/features/host-submissions/services/types.ts'
import {ExpServiceSupportModal} from '@/features/host-submissions/services/components/exp-service-support-modal/ExpServiceSupportModal.tsx'
import {useEffect, useState} from 'react'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {httpSetReferralIds} from '@/features/sso/http/auth.http'

export const serviceStatusToLabel = {
    approved: 'services:status_approved',
    declined: 'services:status_declined',
    in_progress: 'services:status_in_progress',
    under_review: 'services:status_in_review',
    review_host_edits: 'services:status_in_review',
    pending_id_verification: 'services:status_pending_id_verification',
    update_required: 'services:status_update_required',
    waitlisted: 'services:status_waitlisted',
    approval_required: 'services:approval_required',
    pending_export: 'services:approval_required',
    pending_publishing: 'services:approval_required',
    publishing_enabled: 'services:approval_required'
} as const satisfies Record<Service['status'], TKey>

export const Services = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const {searchParams, setSearchParams} = useParsedSearchParams(z.object({referral_id: z.string().optional()}))
    const createServiceAsync = useAsync()
    const continueAsync = useAsync()
    const servicesQuery = useServices({params: {response_type: 'regular'}})
    const [supportSlidingModalState, setSupportSlidingModalState] = useState<SlidingModalState>('closed')

    const setReferralId = async () => {
        if (searchParams.referral_id) {
            try {
                await continueAsync.run(httpSetReferralIds({payload: {services_referral_id: searchParams.referral_id}}))
                setSearchParams({referral_id: undefined})
            } catch (error) {
                errorHandler(error)
            }
        } else {
            setSearchParams({referral_id: undefined})
        }
    }

    useEffect(() => {
        void setReferralId()
    }, [])

    const createService = async () => {
        try {
            const response = await createServiceAsync.run(httpCreateService())
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICES]})
            navigate(String(response.data.id))
        } catch (error) {
            errorHandler(error)
        }
    }

    return (
        <>
            <Helmet title={t('services:meta_title')} />
            {servicesQuery.isPending ? (
                <Flexbox height="100%" justify="center" align="center">
                    <Spinner size={48} />
                </Flexbox>
            ) : servicesQuery.isError ? (
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            ) : (
                <StyledServices>
                    <Flexbox direction="column" gap={6}>
                        <Flexbox justify={'space-between'} align={'center'} width={'100%'}>
                            <h2>{t('services:title')}</h2>
                            {servicesQuery.data.length >= 1 && (
                                <Button
                                    variant={'tertiary'}
                                    size={'sm'}
                                    onClick={() => setSupportSlidingModalState('open')}
                                >
                                    {t('services:need_help:title')}
                                </Button>
                            )}
                            {supportSlidingModalState != 'closed' && (
                                <ExpServiceSupportModal
                                    slidingModalState={supportSlidingModalState}
                                    setSlidingModalState={setSupportSlidingModalState}
                                />
                            )}
                        </Flexbox>
                        {servicesQuery.data.length >= 1 && (
                            <Button
                                variant="secondary"
                                size="sm"
                                disabled={createServiceAsync.isLoading}
                                onClick={createService}
                            >
                                {t('services:create_a_service')}
                                {createServiceAsync.isLoading && <Spinner />}
                            </Button>
                        )}
                    </Flexbox>
                    {servicesQuery.data.length == 0 ? (
                        <StyledEmptyState>
                            <File02Icon size={42} />
                            <h3>{t('services:no_offerings_yet')}</h3>
                            <Button size="sm" disabled={createServiceAsync.isLoading} onClick={createService}>
                                {t('services:create_a_service')}
                                {createServiceAsync.isLoading && <Spinner />}
                            </Button>
                        </StyledEmptyState>
                    ) : (
                        <StyledList>
                            {servicesQuery.data.map(service => (
                                <StyledCard key={service.id}>
                                    <Flexbox direction="column" gap={2}>
                                        <h4>{service.title || t('services:new_submission')}</h4>

                                        {service?.is_upgrade && service.status == 'in_progress' ? (
                                            <p>{t('services:upgrade_description')}</p>
                                        ) : service.created_at ? (
                                            <p>
                                                {t('services:created_on', {
                                                    date: formatLocaleDate(service.created_at, 'll')
                                                })}
                                            </p>
                                        ) : (
                                            '-'
                                        )}
                                    </Flexbox>
                                    <Flexbox justify="space-between" align="center" gap={2}>
                                        <Flexbox align="center" gap={2.5}>
                                            <StyledServiceStatusBullet $status={service.status} />
                                            <p>{t(serviceStatusToLabel[service.status])}</p>
                                        </Flexbox>
                                        {service.status == 'approved' ||
                                        service.status == 'publishing_enabled' ||
                                        service.status == 'pending_export' ||
                                        service.status == 'pending_publishing' ? (
                                            <ButtonLink
                                                to="https://www.airbnb.com/experience-hosting/listings"
                                                target="_blank"
                                                size="sm"
                                            >
                                                {t('services:open_in_airbnb')}
                                            </ButtonLink>
                                        ) : (
                                            <ButtonLink to={service.id.toString()} size="sm">
                                                {t('commons:open')}
                                            </ButtonLink>
                                        )}
                                    </Flexbox>
                                </StyledCard>
                            ))}
                        </StyledList>
                    )}
                </StyledServices>
            )}
        </>
    )
}
