import {
    Content,
    FieldTitle,
    HeadingGroup,
    Paragraph,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {Service} from '@/features/host-submissions/services/types.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {EditFieldSection} from '@/features/host-submissions/components/edit-field-section/EditFieldSection'
import TextArea from '@components/commons/textarea/TextArea.tsx'
import {z} from 'zod'
import {Controller, FieldErrors, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import InputText from '@components/commons/input-text/InputText.tsx'
import {Fragment, useEffect, useMemo, useState} from 'react'
import {
    EditOfferingSection,
    EditOfferingValidationSchema
} from '@/features/host-submissions/services/components/edit-offering-section/EditOfferingSection'
import {ContinueEditModal} from '@/features/host-submissions/components/continue-edit-modal/ContinueEditModal'
import {errorHandler} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useUpdateStepToEnhanceAboutYouStep} from '@/features/host-submissions/services/queries/useUpdateStepToEnhanceAboutYouStep'
import {useUpdateEnhanceStep} from '@/features/host-submissions/services/queries/useUpdateEnhanceStep'
import {hasRelevantFieldChanges} from '../../utils'
import {Footer} from '@/features/host-submissions/components/atoms/Atoms'
import {RestoreFieldsBanner} from '@/features/host-submissions/components/restore-fields-banner/RestoreFieldsBanner'

export const SERVICE_TITLE_MAX_LENGTH = 50
export const SERVICE_DESCRIPTION_MAX_LENGTH = 90

const EnhanceServiceAboutServiceValidationSchema = z.object({
    service_title: z.string().min(1, {message: i18n.t('errors:field_required')}),
    service_description: z.string().min(1, {message: i18n.t('errors:field_required')}),
    offerings: z.array(EditOfferingValidationSchema)
})
export type EnhanceServiceAboutServiceValidationSchema = z.infer<typeof EnhanceServiceAboutServiceValidationSchema>

const getDefaultValuesEnhanceAboutServiceForm = (service: Service) => {
    const remappedOfferingData = service.offerings.map(offering =>
        offering.question_answer.reduce(
            (acc, val) => {
                if (val.question_key_name == 'improved_title') {
                    return {...acc, improved_title: val.answer}
                }
                if (val.question_key_name == 'improved_description') {
                    return {...acc, improved_description: val.answer}
                }
                if (val.question_key_name == 'main_photo') {
                    return {
                        ...acc,
                        main_photo: val.gallery_image?.id
                            ? {
                                  id: val.gallery_image.id,
                                  src: offering.main_photo_url ?? val.answer
                              }
                            : {src: ''}
                    }
                }
                return acc
            },
            {
                improved_title: '',
                improved_description: '',
                main_photo: {src: ''},
                type: offering.type,
                id: offering.id
            }
        )
    )

    return service.expertise?.question_answer.reduce(
        (previousValue, currentValue) => {
            if (currentValue.question_key_name == 'service_title' && currentValue.answer) {
                return {...previousValue, service_title: currentValue.answer}
            }
            if (currentValue.question_key_name == 'service_description' && currentValue.answer) {
                return {...previousValue, service_description: currentValue.answer}
            }
            return previousValue
        },
        {
            service_title: '',
            service_description: '',
            offerings: remappedOfferingData
        }
    )
}

export const EnhanceServiceAboutServiceStep = ({service}: {service: Service}) => {
    const {t} = useTranslation()
    const [isEditMode, setIsEditMode] = useState(false)
    const continueEditingServiceValue = sessionStorage.getItem('continueEditingServiceConfirmed')
    const isContinueEditingConfirmed = continueEditingServiceValue ? JSON.parse(continueEditingServiceValue) : false
    const [isContinueEditModalOpen, setIsContinueEditModalOpen] = useState(false)
    const [isFieldChanged, setIsFieldChanged] = useState(false)
    const [isPrevStepChanged, setIsPrevStepChanged] = useState(false)
    const nextStepMutation = useUpdateStepToEnhanceAboutYouStep({
        serviceId: service.id,
        options: {onError: errorHandler}
    })

    const updateEnhanceStepMutation = useUpdateEnhanceStep({
        serviceId: service.id,
        options: {
            onError: errorHandler,
            onSuccess: () => nextStepMutation.mutate()
        }
    })

    const restoreDataMutation = useUpdateEnhanceStep({
        serviceId: service.id,
        options: {
            onError: errorHandler,
            onSuccess: data => {
                setIsFieldChanged(false)
                form.reset(getDefaultValuesEnhanceAboutServiceForm(data))
            }
        }
    })

    const defaultValues = useMemo(() => getDefaultValuesEnhanceAboutServiceForm(service), [service])
    const form = useForm<EnhanceServiceAboutServiceValidationSchema>({
        resolver: zodResolver(EnhanceServiceAboutServiceValidationSchema),
        defaultValues
    })

    useEffect(() => {
        const offeringsChanged = hasRelevantFieldChanges(service.offerings, [
            'improved_title',
            'improved_description',
            'main_photo'
        ])
        const expertiseChanged = service.expertise
            ? hasRelevantFieldChanges(
                  [service.expertise],
                  [
                      'host_experience_title',
                      'service_description',
                      'host_experience_description',
                      'host_education_title',
                      'host_education_description',
                      'host_career_highlight_title',
                      'host_career_highlight_description',
                      'service_title'
                  ]
              )
            : false

        setIsPrevStepChanged(offeringsChanged || expertiseChanged)
    }, [service])

    const [serviceTitleWatch, serviceDescriptionWatch, offerings] = useWatch({
        control: form.control,
        name: ['service_title', 'service_description', 'offerings']
    })

    const checkIfFormValid = async (fieldName: keyof EnhanceServiceAboutServiceValidationSchema) =>
        await form.trigger(fieldName)

    const onSubmit = form.handleSubmit(data => {
        const dataToSend = {
            ...data,
            offerings: data.offerings.map(({main_photo, ...rest}) => ({
                ...rest,
                main_photo: main_photo?.src || ''
            }))
        }
        updateEnhanceStepMutation.mutate(dataToSend)
    })

    const offeringPreviousAnswerData = service.offerings.map(offering =>
        offering.question_answer.reduce(
            (acc, val) => {
                if (val.question_key_name == 'improved_title') {
                    return {...acc, improved_title: val.previous_answer ?? val.answer}
                }
                if (val.question_key_name == 'improved_description') {
                    return {...acc, improved_description: val.previous_answer ?? val.answer}
                }
                if (val.question_key_name == 'main_photo') {
                    return {
                        ...acc,
                        main_photo: val.previous_answer ?? val.answer
                    }
                }

                return acc
            },
            {
                improved_title: '',
                improved_description: '',
                main_photo: '',
                type: offering.type,
                id: offering.id
            }
        )
    )

    const previousAnswerData = service.expertise?.question_answer.reduce(
        (acc, val) => {
            if (
                [
                    'host_experience_title',
                    'host_experience_description',
                    'host_education_title',
                    'host_education_description',
                    'host_career_highlight_title',
                    'host_career_highlight_description',
                    'service_title',
                    'service_description'
                ].includes(val.question_key_name)
            ) {
                return {...acc, [val.question_key_name]: val.previous_answer}
            }
            return acc
        },
        {
            host_experience_title: '',
            host_experience_description: '',
            host_education_title: '',
            host_education_description: '',
            host_career_highlight_title: '',
            host_career_highlight_description: '',
            service_title: '',
            service_description: '',
            offerings: offeringPreviousAnswerData
        }
    )

    const onRestoreValues = () => {
        restoreDataMutation.mutate(previousAnswerData)
    }
    return (
        <>
            <Content gap={6}>
                <HeadingGroup direction="column" gap={4}>
                    <Title>{t('host_review:enhance_your_service_step_title')}</Title>
                    <Paragraph>{t('host_review:enhance_your_service_step_paragraph')}</Paragraph>

                    {(isFieldChanged || isPrevStepChanged) && (
                        <RestoreFieldsBanner
                            onRestore={onRestoreValues}
                            isLoading={restoreDataMutation.isPending}
                            hideRestoreAction={true}
                        />
                    )}
                </HeadingGroup>
                <Flexbox gap={5} direction="column">
                    <FieldTitle>{t('services:enhance_your_service_step:your_service')}</FieldTitle>

                    <EditFieldSection
                        disabled={isEditMode}
                        label={t('services:enhance_your_service_step:listing_title')}
                        text={serviceTitleWatch}
                        checkIfFormValid={() => checkIfFormValid('service_title')}
                        onEdit={() => {
                            if (!isFieldChanged && !isContinueEditingConfirmed) {
                                setIsContinueEditModalOpen(true)
                            } else {
                                setIsEditMode(true)
                            }
                        }}
                        onConfirm={() => {
                            setIsFieldChanged(
                                defaultValues.service_title != serviceTitleWatch ||
                                    defaultValues.service_description != serviceDescriptionWatch
                            )
                            setIsEditMode(false)
                        }}
                        errorMessage={form.formState.errors.service_title?.message}
                        editableComponents={
                            <InputText
                                type={'text'}
                                maxLength={SERVICE_TITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: SERVICE_TITLE_MAX_LENGTH - serviceTitleWatch.length
                                })}
                                errorMessage={form.formState.errors.service_title?.message}
                                {...form.register('service_title')}
                            />
                        }
                    />

                    <EditFieldSection
                        disabled={isEditMode}
                        label={t('services:enhance_your_service_step:listing_description')}
                        text={serviceDescriptionWatch}
                        checkIfFormValid={() => checkIfFormValid('service_description')}
                        onEdit={() => {
                            if (!isFieldChanged && !isContinueEditingConfirmed) {
                                setIsContinueEditModalOpen(true)
                            } else {
                                setIsEditMode(true)
                            }
                        }}
                        onConfirm={() => {
                            setIsFieldChanged(
                                defaultValues.service_title != serviceTitleWatch ||
                                    defaultValues.service_description != serviceDescriptionWatch
                            )
                            setIsEditMode(false)
                        }}
                        errorMessage={form.formState.errors.service_description?.message}
                        editableComponents={
                            <TextArea
                                rows={4}
                                maxLength={SERVICE_DESCRIPTION_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: SERVICE_DESCRIPTION_MAX_LENGTH - serviceDescriptionWatch.length
                                })}
                                errorMessage={form.formState.errors.service_description?.message}
                                {...form.register('service_description')}
                            />
                        }
                    />

                    <Divider direction="horizontal" />

                    {offerings.map((offering, index) => (
                        <Fragment key={`offerings.${index}`}>
                            <Controller
                                control={form.control}
                                name={`offerings.${index}`}
                                render={({field: {onChange}}) => (
                                    <EditOfferingSection
                                        serviceId={service.id}
                                        id={offering.id}
                                        disabled={isEditMode}
                                        type={offering.type}
                                        title={offering.improved_title}
                                        description={offering.improved_description}
                                        mainPhoto={offering.main_photo}
                                        errorMessages={
                                            form.formState.errors.offerings?.[
                                                index
                                            ] as FieldErrors<EditOfferingValidationSchema>
                                        }
                                        onConfirm={(data: EditOfferingValidationSchema) => {
                                            const defaultOfferingValues = defaultValues.offerings.find(
                                                item => item.id == offering.id
                                            )
                                            setIsFieldChanged(
                                                defaultValues.service_title != serviceTitleWatch ||
                                                    defaultValues.service_description != serviceDescriptionWatch ||
                                                    defaultOfferingValues?.improved_title != data.improved_title ||
                                                    defaultOfferingValues?.improved_description !=
                                                        data.improved_description ||
                                                    defaultOfferingValues?.main_photo.src != data.main_photo?.src
                                            )
                                            onChange(data)

                                            setIsEditMode(false)
                                        }}
                                        onEdit={() => {
                                            if (!isFieldChanged && !isContinueEditingConfirmed) {
                                                setIsContinueEditModalOpen(true)
                                            } else {
                                                setIsEditMode(true)
                                            }
                                        }}
                                    />
                                )}
                            />

                            {index != offerings.length - 1 && (
                                <Divider startSpacing={1} endSpacing={1} direction="horizontal" />
                            )}
                        </Fragment>
                    ))}
                </Flexbox>
            </Content>

            <Footer>
                <Flexbox width={'100%'} justify={'center'}>
                    <Button
                        style={{maxWidth: 800}}
                        fullWidth
                        size="lg"
                        disabled={
                            nextStepMutation.isPending ||
                            updateEnhanceStepMutation.isPending ||
                            isEditMode ||
                            restoreDataMutation.isPending
                        }
                        onClick={onSubmit}
                    >
                        {t('commons:continue')}
                        {(nextStepMutation.isPending || updateEnhanceStepMutation.isPending) && <Spinner />}
                    </Button>
                </Flexbox>
            </Footer>

            {isContinueEditModalOpen && (
                <ContinueEditModal
                    onClose={() => {
                        setIsContinueEditModalOpen(false)
                    }}
                    onContinue={() => {
                        setIsEditMode(true)
                        setIsContinueEditModalOpen(false)
                        sessionStorage.setItem('continueEditingServiceConfirmed', 'true')
                    }}
                />
            )}
        </>
    )
}
