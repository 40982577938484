import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SlidingModal, SlidingModalHeader} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const SettingsPrimaryLanguageSelectBody = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px ${spacing * 4}px;
        overflow: auto;
        & label {
            width: 100%;
        }
    `
)

export const SettingsPrimaryLanguageSlidingModal = styled(SlidingModal)(
    () => css`
        display: grid;
        grid-template-rows: auto 1fr auto;
    `
)

export const SettingsPrimaryLanguageSlidingModalHeader = styled(SlidingModalHeader)(
    () => css`
        display: grid;
        grid-template-columns: 36px 1fr 36px;
        align-items: center;
        justify-items: center;
    `
)

export const SettingsPrimaryLanguageButton = styled(Button)(
    () => css`
        justify-content: space-between;
    `
)
