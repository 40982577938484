import {ActionCard} from '@/features/service-actions/components/action-card/ActionCard.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {CheckCircleIcon} from '@components/ui/icon'
import {Dispatch, FC, SetStateAction, useState} from 'react'
import {httpClickAction} from '@/features/service-actions/http/actions.http'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'

export const CheckListingStatusAction: FC<{
    actionId: ServiceAction['id']
    isCompleted: boolean
    link?: string
    setPendingActionsCount: Dispatch<SetStateAction<number>>
}> = ({actionId, isCompleted, link, setPendingActionsCount}) => {
    const {t} = useTranslation()
    const [isClicked, setIsClicked] = useState(false)
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()

    const clickAction = () => {
        window.open(link, '_blank')
        httpClickAction({...urlParams, actionId})
        setIsClicked(true)
        setPendingActionsCount(state => state - 1)
    }

    return (
        <ActionCard
            onClick={link ? clickAction : undefined}
            isCompleted={isClicked || isCompleted}
            icon={CheckCircleIcon}
            paragraph={t('service_details:actions:check_listing_status:paragraph')}
            title={t('service_details:actions:check_listing_status:title')}
        />
    )
}

CheckListingStatusAction.displayName = 'CheckListingStatusAction'
