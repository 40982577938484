import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {TKey, useTranslation} from '@/translations/i18n.tsx'
import toast from 'react-hot-toast'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar'
import {StyledDeleteIconWrapper, StyledInputText} from './style'
import {useEffect, useMemo, useState} from 'react'
import {PlusIcon, Trash03Icon} from '@/components/ui/icon'
import {useShowExperiencesHostVideo} from '../../services/useShowExperiencesHostVideo'
import {useSelectQuestionAnswer} from '../../services/useSelectQuestionAnswer'
import {useDeleteQuestionAnswerNote} from '../../services/useDeleteQuestionAnswerNote'
import {Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
interface Highlight {
    id: number
    value: string
    label: TKey
    noteId: number | null
}

const initialHighlights: Highlight[] = [
    {id: 1, value: '', noteId: null, label: 'experiences_host_video:preparation_highlights:highlight_1'},
    {id: 2, value: '', noteId: null, label: 'experiences_host_video:preparation_highlights:highlight_2'},
    {id: 3, value: '', noteId: null, label: 'experiences_host_video:preparation_highlights:highlight_3'},
    {id: 4, value: '', noteId: null, label: 'experiences_host_video:preparation_highlights:highlight_4'}
]

export const PreparationHighlightsStep = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams: routeParams})

    const question = showExperiencesHostVideoQuery?.data?.questions.find(
        question => question.question_answer.question.key_name == 'highlights'
    )
    const answer = showExperiencesHostVideoQuery?.data?.answers.find(
        answer => answer.question_answer.question.key_name == 'highlights'
    )

    const [highlights, setHighlights] = useState(initialHighlights.slice(0, 3))
    const [deletingNoteId, setDeletingNoteId] = useState<number | null>(null)
    const hasMinValuesToContinue = useMemo(() => {
        return highlights.filter(highlight => highlight.value.trim() !== '').length >= 2
    }, [highlights])

    useEffect(() => {
        if (showExperiencesHostVideoQuery.data && answer) {
            setHighlights(
                answer?.notes.map((note, index) => ({
                    id: initialHighlights[index]?.id,
                    value: note.notes || '',
                    noteId: note.id,
                    label: initialHighlights[index]?.label
                }))
            )
        }
    }, [showExperiencesHostVideoQuery.data, answer])

    const backUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })

    const continueUpdateStepMutation = useUpdateStep({
        params: routeParams
    })

    const selectQuestionAnswerMutation = useSelectQuestionAnswer({
        params: routeParams
    })

    const deleteQuestionAnswerNoteMutation = useDeleteQuestionAnswerNote({
        params: routeParams,
        mutationOptions: {
            onSuccess: () => setDeletingNoteId(null),
            onError: () => {
                toast.error(t('errors:default'))
                setDeletingNoteId(null)
            }
        }
    })

    const addHighlight = () => {
        if (highlights.length < 4) {
            const missingHighlight = initialHighlights.find(highlight => !highlights.some(h => h.id === highlight.id))
            if (missingHighlight) {
                setHighlights([...highlights, missingHighlight])
            }
        }
    }

    const removeHighlight = async (id: number, noteId: number | null) => {
        if (highlights.length > 2) {
            if (noteId && question) {
                setDeletingNoteId(noteId)
                await deleteQuestionAnswerNoteMutation.mutateAsync({
                    questionAnswerId: question.id,
                    noteId
                })
            }
            setHighlights(prev => prev.filter(highlight => highlight.id !== id))
        }
    }

    const updateHighlightText = (id: number, value: string) => {
        setHighlights(prevHighlights =>
            prevHighlights.map(highlight => (highlight.id === id ? {...highlight, value} : highlight))
        )
    }

    const handleSaveHighlights = async () => {
        if (!question) return
        try {
            await selectQuestionAnswerMutation.mutateAsync({
                questionAnswerId: question.id,
                notes: highlights.map(highlight => highlight.value)
            })
            await continueUpdateStepMutation.mutateAsync({
                status: 'preparation',
                step: 'take-anything-home'
            })
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <>
            <ExperiencesHostVideoBody>
                <Flexbox direction="column" gap={4}>
                    <Title>{t('experiences_host_video:preparation_highlights:title')}</Title>
                    <Paragraph>{t('experiences_host_video:preparation_highlights:description')}</Paragraph>
                </Flexbox>
                <Flexbox direction="column" gap={4} width="100%" align="center">
                    {highlights.map(input => (
                        <Flexbox width="100%" gap={2} align="end" key={input.label}>
                            <StyledInputText
                                type="text"
                                label={t(input.label)}
                                defaultValue={input.value}
                                placeholder={t('experiences_host_video:preparation_highlights:input_placeholder')}
                                onChange={e => updateHighlightText(input.id, e.target.value)}
                            />
                            <StyledDeleteIconWrapper
                                $disabled={highlights.length <= 2}
                                align="center"
                                justify="center"
                                onClick={() => removeHighlight(input.id, input.noteId)}
                            >
                                {deleteQuestionAnswerNoteMutation.isPending && deletingNoteId == input.noteId ? (
                                    <Spinner size={20} />
                                ) : (
                                    <Trash03Icon width={20} height={20} />
                                )}
                            </StyledDeleteIconWrapper>
                        </Flexbox>
                    ))}

                    <Button variant="secondary" disabled={highlights.length == 4} onClick={addHighlight}>
                        <PlusIcon />
                        {t('experiences_host_video:preparation_highlights:add_highlight')}
                    </Button>
                </Flexbox>
            </ExperiencesHostVideoBody>

            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={
                                backUpdateStepMutation.isPending ||
                                continueUpdateStepMutation.isPending ||
                                selectQuestionAnswerMutation.isPending
                            }
                            variant="tertiary"
                            onClick={() =>
                                backUpdateStepMutation.mutate({status: 'preparation', step: 'experience-unique'})
                            }
                        >
                            {t('commons:back')}
                            {backUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            onClick={handleSaveHighlights}
                            disabled={
                                continueUpdateStepMutation.isPending ||
                                backUpdateStepMutation.isPending ||
                                selectQuestionAnswerMutation.isPending ||
                                !hasMinValuesToContinue
                            }
                        >
                            {t('commons:continue')}
                            {(continueUpdateStepMutation.isPending || selectQuestionAnswerMutation.isPending) && (
                                <Spinner />
                            )}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
