import {create} from 'zustand'
import {z} from 'zod'

export const GalleryItemTest = z.object({
    id: z.number().positive().int(),
    blob: z.instanceof(File).optional(),
    src: z.string().url().optional(),
    presignedUrl: z.string().optional(),
    status: z.enum(['pending', 'success', 'error', 'idle']).default('idle'),
    isCover: z.boolean().optional().nullable()
})
export type GalleryItemTest = z.infer<typeof GalleryItemTest>

interface GalleryUploadStore {
    photosetId: number | null
    acceptedFiles: File[]
    galleryCounter: number
    gallery: GalleryItemTest[]
    uploadProgressValues: Record<number, number>
    setAcceptedFiles: (acceptedFiles: File[]) => void
    addGalleryItems: (newItems: GalleryItemTest[]) => void
    clearGallery: () => void
    setGalleryItemProgressValue: (id: number, progressValue: number) => void
    setGalleryItemStatus: (id: number, status: GalleryItemTest['status']) => void
    setGalleryItemSrc: (id: number, src: GalleryItemTest['src']) => void
    removeGalleryItem: (id: number) => void
}

export const useGalleryUpload = create<GalleryUploadStore>((setState, getState) => ({
    photosetId: null,
    galleryCounter: 0,
    acceptedFiles: [],
    uploadProgressValues: {},
    gallery: [],
    setAcceptedFiles: acceptedFiles => setState({acceptedFiles}),
    addGalleryItems: newItems => {
        const prevGalleryItems = getState().gallery
        const prevGalleryCounter = getState().galleryCounter
        const prevUploadProgressValues = getState().uploadProgressValues
        const newUploadProgressValues = newItems.reduce(
            (acc, item) => {
                acc[item.id] = 0
                return acc
            },
            {...prevUploadProgressValues}
        )
        setState({
            gallery: [...newItems, ...prevGalleryItems],
            uploadProgressValues: newUploadProgressValues,
            galleryCounter: (prevGalleryCounter || 0) + newItems.length
        })
    },
    clearGallery: () => setState({gallery: [], galleryCounter: 0, uploadProgressValues: {}}),
    setGalleryItemProgressValue: (id, progressValue) => {
        const prevUploadProgressValues = getState().uploadProgressValues
        setState({
            uploadProgressValues: {
                ...prevUploadProgressValues,
                [id]: progressValue
            }
        })
    },
    setGalleryItemStatus: (id, status) => {
        const prevGallery = getState().gallery
        const prevGalleryCounter = getState().galleryCounter
        setState({
            gallery: prevGallery.map(item => {
                if (item.id == id) {
                    return {...item, status: status}
                }
                return item
            }),
            galleryCounter: status == 'error' ? prevGalleryCounter - 1 : prevGalleryCounter
        })
    },
    setGalleryItemSrc: (id, src) => {
        const prevGallery = getState().gallery
        setState({
            gallery: prevGallery.map(item => {
                if (item.id == id) {
                    return {...item, src: src}
                }
                return item
            })
        })
    },
    removeGalleryItem: (id: number) => {
        const prevGallery = getState().gallery
        const galleryCounter = getState().galleryCounter
        setState({
            gallery: prevGallery.filter(item => item.id != id),
            galleryCounter: galleryCounter && galleryCounter - 1
        })
    }
}))
