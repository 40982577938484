import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    StyledOfferingPhotoDropzone,
    StyledOfferingPhotoLabel,
    StyledPhotoUploaderFloatingItem,
    StyledPreviewImageWrapper
} from './style'
import {AlertTriangleIcon, Image01Icon} from '@/components/ui/icon'
import {useTranslation} from '@/translations/i18n'
import {useDropzone} from 'react-dropzone'
import {useTheme} from 'styled-components'
import {GalleryItem, ParentFlag} from '@/features/host-submissions/types'
import {PreviewImage, FieldDescription, FieldTitle} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {Button} from '@/components/ui/button/Button'
import {Dropzone} from '@/components/ui/dropzone/Dropzone'
import {
    BulkPresignedPhotosResponse,
    useBulkPresignedPhotos
} from '@/features/host-submissions/services/queries/useBulkPresignedPhotos'
import {httpUploadFile} from '@/services/upload.http'
import {raise} from '@/utilities/helpers'
import {httpConfirmUploadPhoto} from '@/features/host-submissions/services/http/services.http'
import {Dispatch, SetStateAction, useState} from 'react'
import {CircularProgressBar} from '@/features/experiences-host-video/components/circular-progress-bar/CircularProgressBar'
import {useDeletePhotos} from '../../queries/useDeletePhotos'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {FlagsAlertText} from '../../../components/flags-alert-text/FlagsAlertText'

interface OfferingPhotoUploaderProps {
    serviceId: number
    value: GalleryItem | null
    onChange: (value: GalleryItem | null) => void
    uploadStatusState: [
        'pending' | 'success' | 'error' | 'idle',
        Dispatch<SetStateAction<'pending' | 'success' | 'error' | 'idle'>>
    ]
    mainPhotoFlags?: ParentFlag[]
}

export const OfferingPhotoUploader = ({
    serviceId,
    value,
    onChange,
    uploadStatusState,
    mainPhotoFlags
}: OfferingPhotoUploaderProps) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const [uploadProgressValue, setUploadProgressValue] = useState(0)
    const [uploadStatus, setUploadStatus] = uploadStatusState
    const [acceptedFile, setAcceptedFile] = useState<null | File>(null)
    const [deletedFile, setDeletedFile] = useState<GalleryItem | null>(null)

    const deletePhoto = useDeletePhotos({
        mutationOptions: {
            onError: () => {
                onChange(deletedFile)
                setDeletedFile(null)
            }
        }
    })

    const onSuccess = (data: BulkPresignedPhotosResponse) => {
        const value = data[0]

        if (acceptedFile) {
            const galleryItem: GalleryItem = {
                id: value.media.id,
                progressValue: 0,
                status: 'pending',
                blob: acceptedFile,
                presignedUrl: value.presigned_url
            }

            onChange(galleryItem)
            void uploadFile(galleryItem)
        }

        setAcceptedFile(null)
    }

    const bulkPresignedQuery = useBulkPresignedPhotos({
        urlParams: {serviceId},
        options: {onSuccess}
    })

    const dropzoneState = useDropzone({
        disabled: bulkPresignedQuery.isPending || uploadStatus == 'pending',
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        multiple: false,
        onDropAccepted: async acceptedFiles => {
            setUploadStatus('pending')

            if (acceptedFiles.length) {
                setAcceptedFile(acceptedFiles[0])

                if (value?.id) {
                    await deletePhoto.mutateAsync({
                        mediaId: value.id,
                        serviceId: serviceId
                    })
                }

                bulkPresignedQuery.mutate({medias: [acceptedFiles[0].name], is_offering_image: true})
            }
        },
        onDropRejected: rejectedFiles => {
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    const uploadFile = async (galleryItem: GalleryItem) => {
        try {
            await httpUploadFile({
                url: galleryItem.presignedUrl || raise('the presignedUrl is undefined'),
                payload: galleryItem.blob || raise('the file is undefined'),
                onUploadProgress: progressEvent =>
                    setUploadProgressValue(
                        progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100
                    )
            })
            const response = await httpConfirmUploadPhoto({
                serviceId: serviceId,
                mediaId: galleryItem.id
            })

            if (response.data.id && response.data.url) {
                onChange({
                    id: response.data.id,
                    status: 'success',
                    src: decodeURIComponent(response.data.url.replace(/\\/g, ''))
                })
            }

            setUploadStatus('success')
            setUploadProgressValue(0)
        } catch (error) {
            setUploadStatus('error')
            setUploadProgressValue(0)
        }
    }

    return (
        <>
            <Flexbox justify="space-between" direction="column" gap={4} width="100%">
                <Flexbox direction="column" gap={1.5} width="100%">
                    <FieldTitle>{t('commons:photo')}</FieldTitle>
                    <FieldDescription>{t('services:step_offerings:photo_description')}</FieldDescription>

                    <FlagsAlertText flags={mainPhotoFlags} title={t('commons:photo')} />
                </Flexbox>
                {value?.id && uploadStatus != 'pending' && (
                    <Flexbox gap={3} style={{alignSelf: 'flex-end'}}>
                        <Dropzone state={dropzoneState}>
                            <Button
                                variant="secondary"
                                onClick={event => event.preventDefault()}
                                style={{whiteSpace: 'nowrap'}}
                            >
                                {t('commons:re_upload')}
                            </Button>
                        </Dropzone>
                    </Flexbox>
                )}
            </Flexbox>
            {value?.blob || value?.src ? (
                <StyledPreviewImageWrapper>
                    <PreviewImage
                        hasLowTransparency={uploadStatus == 'pending'}
                        src={value.blob ? URL.createObjectURL(value.blob) : value.src}
                    />
                    <StyledPhotoUploaderFloatingItem>
                        {uploadStatus == 'pending' && <CircularProgressBar progress={uploadProgressValue} />}
                        {uploadStatus == 'error' && (
                            <Flexbox direction={'column'} gap={2} align={'center'} style={{textAlign: 'center'}}>
                                <AlertTriangleIcon />
                                {t('errors:default')}
                            </Flexbox>
                        )}
                    </StyledPhotoUploaderFloatingItem>
                </StyledPreviewImageWrapper>
            ) : (
                <StyledOfferingPhotoDropzone state={dropzoneState}>
                    <Flexbox direction="column" align="center" justify="center" height="100%" gap={4}>
                        {uploadStatus == 'pending' ? (
                            <Spinner size={28} fill={palette.neutral['500']} />
                        ) : (
                            <>
                                <Image01Icon size={32} color={palette.neutral['600']} />
                                <StyledOfferingPhotoLabel>
                                    {t('services:step_offerings:tap_to_upload')}
                                </StyledOfferingPhotoLabel>
                            </>
                        )}
                    </Flexbox>
                </StyledOfferingPhotoDropzone>
            )}
        </>
    )
}
