import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    ButtonArea,
    PreviewImage,
    FieldTitle,
    AlertText
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {
    StyledActivityItem,
    StyledItemTextWrapper,
    StyledDropdownMenuText,
    StyledActivityWrapper,
    StyledImagePlaceholderBox,
    StyledArrowIcon,
    StyledArrowDownIcon
} from '@/features/host-submissions/experiences/components/activities/style'
import {DotsHorizontalIcon, Image01Icon, PlusIcon, Trash01Icon} from '@/components/ui/icon'
import {useTranslation} from '@/translations/i18n'
import {Button} from '@/components/ui/button/Button'
import {Spinner} from '@components/ui/spinner/Spinner'
import {Activity as ActivityType, ExperienceExtended} from '@/features/host-submissions/experiences/types'
import {MAX_ACTIVITIES_TO_ADD} from '@/features/host-submissions/experiences/components/experience-itinerary-step/ExperienceItineraryStep'
import DropdownMenu, {DropdownMenuItem} from '@/components/ui/dropdown-menu/DropdownMenu'
import {useTheme} from 'styled-components'
import {useDeleteActivity} from '@/features/host-submissions/experiences/queries/useDeleteActivity'
import {useUpdateActivity} from '../../queries/useUpdateActivity'
import {useMemo, useState} from 'react'

export const Activities = ({
    experienceId,
    activities,
    openCreateActivityModal,
    onSelect
}: {
    experienceId: number
    activities: NonNullable<ExperienceExtended['itineraries']>
    openCreateActivityModal: () => void
    onSelect: (activity: ActivityType) => void
}) => {
    const {t} = useTranslation()
    const sortedActivities = useMemo(() => {
        return activities.sort((a, b) => {
            if (a.position === null) return 1
            if (b.position === null) return -1
            return a.position - b.position
        })
    }, [activities])

    return (
        <Flexbox direction="column" gap={4} width="100%">
            {sortedActivities?.map((activity, index) => {
                const isLast = activities.length - 1 == index
                return (
                    <StyledActivityWrapper $isLast={activities.length - 1 == index} key={activity.id}>
                        <Activity
                            key={activity.id}
                            activity={activity}
                            experienceId={experienceId}
                            index={index}
                            onSelect={onSelect}
                            isLast={isLast}
                        />
                    </StyledActivityWrapper>
                )
            })}

            {activities && activities?.length < MAX_ACTIVITIES_TO_ADD && (
                <Button shape="text" variant="tertiary" onClick={openCreateActivityModal}>
                    <PlusIcon />
                    <span>{t('experiences:experience_itinerary_step:add_activity')}</span>
                </Button>
            )}
        </Flexbox>
    )
}

const Activity = ({
    activity,
    experienceId,
    index,
    onSelect,
    isLast
}: {
    activity: ActivityType
    experienceId: number
    index: number
    onSelect: (activity: ActivityType) => void
    isLast: boolean
}) => {
    const {t} = useTranslation()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const {palette} = useTheme()
    const deleteActivityMutation = useDeleteActivity({
        activityId: activity.id,
        experienceId
    })

    const moveActivityMutation = useUpdateActivity({
        experienceId,
        activityId: activity?.id
    })
    const onMoveUp = (activity: ActivityType) => {
        const newPosition = activity?.position != null ? activity.position - 1 : index
        if (index > 0) {
            moveActivityMutation.mutate({
                position: newPosition
            })
        }
    }

    const onMoveDown = (activity: ActivityType) => {
        const newPosition = activity?.position != null ? activity.position + 1 : index
        if (!isLast) {
            moveActivityMutation.mutate({
                position: newPosition
            })
        }
    }

    const activityNeedUpdate =
        !activity.durationInMinutes || !activity.coverImage?.url || !activity.title || !activity.description
    const activityFlagsCount = activity.answers.flatMap(answer => answer.parentFlags).length
    const filteredActions: DropdownMenuItem[] = [
        {
            component: <StyledDropdownMenuText>{t('commons:move_up')}</StyledDropdownMenuText>,
            icon: <StyledArrowIcon size={20} fill={palette.neutral[500]} />,
            onClickCb: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.preventDefault()
                e.stopPropagation()
                setIsDropdownOpen(false)
                onMoveUp(activity)
            },
            disabled: index == 0
        },
        {
            component: <StyledDropdownMenuText>{t('commons:move_down')}</StyledDropdownMenuText>,
            icon: <StyledArrowDownIcon size={20} fill={palette.neutral[500]} />,
            onClickCb: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.preventDefault()
                e.stopPropagation()
                setIsDropdownOpen(false)
                onMoveDown(activity)
            },
            disabled: isLast
        },
        {
            component: <StyledDropdownMenuText>{t('commons:delete')}</StyledDropdownMenuText>,
            onClickCb: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.preventDefault()
                e.stopPropagation()
                setIsDropdownOpen(false)
                deleteActivityMutation.mutate()
            },
            icon: <Trash01Icon size={20} fill={palette.neutral[500]} />
        }
    ].filter(action => !action.disabled)

    return (
        <ButtonArea
            fullWidth
            borderRadius="12px"
            padding={4}
            onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                onSelect(activity)
            }}
        >
            <Flexbox width="100%">
                <StyledActivityItem>
                    {activity.coverImage ? (
                        <PreviewImage src={activity.coverImage.url ?? ''} />
                    ) : (
                        <StyledImagePlaceholderBox>
                            <Image01Icon />
                        </StyledImagePlaceholderBox>
                    )}
                    <Flexbox direction="column" width="100%" gap={0.5}>
                        <StyledItemTextWrapper direction="column" width="100%" gap={1}>
                            <FieldTitle>
                                {activity.title} ·{' '}
                                {t('commons:mins', {
                                    minutes: activity.durationInMinutes
                                })}
                            </FieldTitle>

                            <p>{activity.description}</p>

                            {activityNeedUpdate && (
                                <AlertText text={t('experiences:experience_itinerary_step:missing_details')} />
                            )}
                            {activityFlagsCount >= 1 && <AlertText text={t('commons:can_be_improved')} />}
                        </StyledItemTextWrapper>
                    </Flexbox>
                </StyledActivityItem>

                <DropdownMenu
                    sideOffset={8}
                    triggerProps={{disabled: deleteActivityMutation.isPending}}
                    trigger={
                        deleteActivityMutation.isPending || moveActivityMutation.isPending ? (
                            <Spinner size={20} />
                        ) : (
                            <DotsHorizontalIcon size={20} />
                        )
                    }
                    actions={filteredActions}
                    rootProps={{
                        open: isDropdownOpen,
                        onOpenChange: setIsDropdownOpen
                    }}
                />
            </Flexbox>
        </ButtonArea>
    )
}
