import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledFieldWrapper = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) auto;
        gap: ${spacing}px;
        align-items: flex-end;
    `
)

export const StyledPercent = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        height: 100%;
        color: ${palette.neutral['600']};
        border-right: 1px solid ${palette.neutral['300']};
        padding-right: ${spacing * 3}px;
    `
)
