import {Offering, OfferingQuestionKeyName, Service} from '@/features/host-submissions/services/types.ts'
import {ParentFlag} from '@/features/host-submissions/types'
import {
    AlertTriangleIcon,
    CateringIcon,
    HairIcon,
    MakeupIcon,
    MassageIcon,
    NailsIcon,
    PersonalTrainingIcon,
    PhotographyIcon,
    PreparedMealsIcon,
    PrivateChefIcon,
    SpaTreatmentsIcon
} from '@components/ui/icon'
import countryToCurrency, {Countries} from 'country-to-currency'
import {ReactElement, ReactNode} from 'react'
import i18n, {TKey, Trans} from '@/translations/i18n.tsx'
import {ObjectEntries} from '@utilities/helpers.ts'
import {useUpdateStep} from '@/features/host-submissions/services/queries/useUpdateStep'
import {UpdateStepAnswers} from '@/features/host-submissions/services/http/services.http'

type ProgressStep =
    | Exclude<Service['step']['step'], 'host_expertise' | 'other_details' | 'declined'>
    | NonNullable<Service['step']['sub_step']>

const progressSteps = [
    'get_started',
    'category',
    'career',
    'expertise',
    'about_yourself',
    'service_location',
    'offerings',
    'gallery',
    'about_guests',
    'more_details',
    'legal',
    'terms'
] as const satisfies ReadonlyArray<ProgressStep>

export const getStepProgressPercentage = (stepName: ProgressStep) => {
    const stepIndex = progressSteps.findIndex(progressStep => progressStep == stepName)
    return ((stepIndex + 1) / progressSteps.length) * 100
}

export const categoryToLabel = {
    photography: 'services:category_photography',
    haircare: 'services:category_hair_styling',
    makeup: 'services:category_makeup',
    massage: 'services:category_massage',
    nails: 'services:category_nails',
    private_chef: 'services:category_chef',
    prepared_meals: 'services:category_prepared_meals',
    personal_training: 'services:category_personal_training',
    catering: 'services:category_catering',
    spa_treatments: 'services:category_spa_treatments',
    generic: 'services:no_category' //TODO remove 'generic' when the category step is enabled again
} as const satisfies Record<Exclude<NonNullable<Service['category']>['key_name'], 'boudoir_photography'>, TKey>

export const categoryToIcon = {
    photography: <PhotographyIcon />,
    private_chef: <PrivateChefIcon />,
    prepared_meals: <PreparedMealsIcon />,
    catering: <CateringIcon />,
    personal_training: <PersonalTrainingIcon />,
    massage: <MassageIcon />,
    spa_treatments: <SpaTreatmentsIcon />,
    haircare: <HairIcon />,
    makeup: <MakeupIcon />,
    nails: <NailsIcon />,
    generic: <AlertTriangleIcon /> //TODO remove 'generic' when the category step is enabled again
} as const satisfies Record<Exclude<NonNullable<Service['category']>['key_name'], 'boudoir_photography'>, ReactElement>

const isCountry = (value: string): value is Countries => {
    return Object.keys(countryToCurrency).includes(value)
}

// Define types in separate declarations for better reusability
type GeocoderAddressComponentType =
    | 'postal_town'
    | 'street_address'
    | 'route'
    | 'intersection'
    | 'political'
    | 'country'
    | 'administrative_area_level_1'
    | 'administrative_area_level_2'
    | 'administrative_area_level_3'
    | 'administrative_area_level_4'
    | 'administrative_area_level_5'
    | 'administrative_area_level_6'
    | 'administrative_area_level_7'
    | 'colloquial_area'
    | 'locality'
    | 'sublocality'
    | 'sublocality_level_1'
    | 'sublocality_level_2'
    | 'sublocality_level_3'
    | 'sublocality_level_4'
    | 'sublocality_level_5'
    | 'neighborhood'
    | 'premise'
    | 'subpremise'
    | 'plus_code'
    | 'postal_code'
    | 'natural_feature'
    | 'airport'
    | 'park'
    | 'point_of_interest'

type AddressComponent = {
    longName: string
    shortName: string
}

type AddressResult = {
    address: string
    city: string
    country: string
    zip_code: string | null
    state: string
}

type GeoLookup = Partial<Record<GeocoderAddressComponentType, AddressComponent>>

// Gets city name based on priority order of different address types
const getCityFromLookup = (lookup: GeoLookup): string => {
    return (
        lookup.postal_town?.longName ||
        lookup.administrative_area_level_3?.shortName ||
        lookup.locality?.longName ||
        lookup.sublocality?.longName ||
        ''
    )
}

// Validates and returns country code
const getValidCountry = (country: string): string => {
    return isCountry(country) ? country : ''
}

/**
 + * Transforms Google Maps Geocoder address components into a structured address object
 + * @param addressComponents - Array of Google Maps Geocoder address components
 + * @returns Structured address object
 + */
export const retrieveAddressDataFromComponents = (
    addressComponents: google.maps.GeocoderAddressComponent[]
): AddressResult => {
    // Transform address components into lookup object
    const lookup = addressComponents.reduce<GeoLookup>((addressValue, component) => {
        component.types.forEach(type => {
            addressValue[type as GeocoderAddressComponentType] = {
                longName: component.long_name,
                shortName: component.short_name
            }
        })
        return addressValue
    }, {})
    // Extract address components with priority order
    const address = lookup.route?.shortName ?? ''
    const city = getCityFromLookup(lookup)
    const state = lookup.administrative_area_level_1?.longName ?? ''
    const zip_code = lookup.postal_code?.shortName ?? null
    const country = getValidCountry(lookup.country?.shortName ?? '')
    return {
        address,
        city,
        country,
        zip_code,
        state
    }
}

type UpdatesRequiredStep = Array<{
    questionKeyName: Service['expertise']['question_answer'][number]['question_key_name'] | OfferingQuestionKeyName
    flags: ParentFlag[]
}>
type UpdatesRequiredSteps = {
    career: UpdatesRequiredStep
    expertise: UpdatesRequiredStep
    aboutYourself: UpdatesRequiredStep
    serviceLocation: UpdatesRequiredStep
    offerings: UpdatesRequiredStep[]
    gallery: {
        flags: ParentFlag[]
        images: {
            id: number
            flags: ParentFlag[]
        }[]
    }
    aboutGuests: UpdatesRequiredStep
    moreDetails: UpdatesRequiredStep
}

export const getUpdatesRequiredSteps = (service: Service): UpdatesRequiredSteps => {
    const expertises = service.expertise.question_answer.reduce(
        (result: Omit<UpdatesRequiredSteps, 'aboutGuests' | 'moreDetails'>, value) => {
            if (value.parent_flags.length >= 1) {
                if (['business_type', 'experience_years', 'summary', 'highlights'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        career: [
                            ...result.career,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                if (['inspiration', 'background', 'accolades'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        expertise: [
                            ...result.expertise,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                if (['links', 'hosting_modality', 'co_hosts', 'profile_photo'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        aboutYourself: [
                            ...result.aboutYourself,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                if (['location', 'cities'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        serviceLocation: [
                            ...result.serviceLocation,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                return result
            }
            return result
        },
        {
            career: [],
            expertise: [],
            aboutYourself: [],
            serviceLocation: [],
            offerings: [],
            gallery: {
                flags: [],
                images: []
            }
        }
    )

    const offerings = service.offerings.reduce((result: UpdatesRequiredSteps['offerings'], offering) => {
        return [
            ...result,
            offering.question_answer.reduce((acc: UpdatesRequiredSteps['offerings'][number], value) => {
                if (value.parent_flags.length >= 1) {
                    if (OfferingQuestionKeyName.options.includes(value.question_key_name)) {
                        return [...acc, {questionKeyName: value.question_key_name, flags: value.parent_flags}]
                    }
                    return acc
                }
                return acc
            }, [])
        ]
    }, [])

    const gallery = {
        flags: service.gallery?.parent_flags ?? [],
        images:
            service.gallery?.images.reduce((result: UpdatesRequiredSteps['gallery']['images'], value) => {
                if (value.parent_flags.length >= 1) {
                    return [...result, {id: value.id, flags: value.parent_flags}]
                }
                return result
            }, []) ?? []
    }

    const other = service.expertise.question_answer.reduce(
        (result: Pick<UpdatesRequiredSteps, 'aboutGuests' | 'moreDetails'>, value) => {
            if (value.parent_flags.length >= 1) {
                if (['age_limit', 'age', 'bring_anything', 'what_bring'].includes(value.question_key_name)) {
                    return {
                        ...result,
                        aboutGuests: [
                            ...result.aboutGuests,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                if (
                    [
                        'main_language',
                        'extra_languages',
                        'group_min_size',
                        'group_max_size',
                        'cancellation_policy'
                    ].includes(value.question_key_name)
                ) {
                    return {
                        ...result,
                        moreDetails: [
                            ...result.moreDetails,
                            {questionKeyName: value.question_key_name, flags: value.parent_flags}
                        ]
                    }
                }
                return result
            }
            return result
        },
        {
            aboutGuests: [],
            moreDetails: []
        }
    )

    return {
        ...expertises,
        offerings,
        gallery,
        ...other
    }
}

export const getPrevNextStepMutations = <Step extends keyof UpdateStepAnswers>({
    service,
    updatesRequiredSteps,
    currentStep
}: {
    service: Service
    updatesRequiredSteps: UpdatesRequiredSteps
    currentStep: keyof UpdatesRequiredSteps | 'suggestions'
}): {
    prev: (() => ReturnType<typeof useUpdateStep<Step, false>>) | null
    next: (() => ReturnType<typeof useUpdateStep<Step, false>>) | null
} => {
    const flaggedSteps = [
        'suggestions' as const,
        ...ObjectEntries(updatesRequiredSteps)
            .filter(([key, value]) => {
                if (key == 'offerings') {
                    return value.flat().length >= 1
                }
                if (key == 'gallery') {
                    return value.flags.length >= 1 || value.images.flat().length >= 1
                }
                return value.length >= 1
            })
            .map(([key]) => key)
    ]
    const currentStepIndex = flaggedSteps.findIndex(step => step == currentStep)

    const stepToStepSubStep = {
        suggestions: {step: 'suggestions', subStep: null},
        expertise: {step: 'host_expertise', subStep: 'expertise'},
        career: {step: 'host_expertise', subStep: 'career'},
        aboutYourself: {step: 'host_expertise', subStep: 'about_yourself'},
        gallery: {step: 'gallery', subStep: null},
        offerings: {step: 'offerings', subStep: null},
        serviceLocation: {step: 'service_location', subStep: null},
        aboutGuests: {step: 'other_details', subStep: 'about_guests'},
        moreDetails: {step: 'other_details', subStep: 'more_details'}
    } as const satisfies Record<
        typeof currentStep,
        {step: Service['step']['step']; subStep: Service['step']['sub_step']}
    >

    return {
        prev: flaggedSteps[currentStepIndex - 1]
            ? () =>
                  useUpdateStep<Step, false>({
                      serviceId: service.id,
                      expertiseId: service.expertise.id,
                      isSubmit: false,
                      ...stepToStepSubStep[flaggedSteps[currentStepIndex - 1]]
                  })
            : null,
        next: flaggedSteps[currentStepIndex + 1]
            ? () =>
                  useUpdateStep<Step, false>({
                      serviceId: service.id,
                      expertiseId: service.expertise.id,
                      isSubmit: false,
                      ...stepToStepSubStep[flaggedSteps[currentStepIndex + 1]]
                  })
            : null
    }
}

export const flagToDescription = (
    flag: ParentFlag['key_name'],
    detailLevel: 'default' | 'offering_title' | 'offering_description' = 'default'
): ReactNode =>
    (
        ({
            alcohol_smoking: i18n.t('services:flags:alcohol_smoking_title'),
            alcohol_without_license: i18n.t('services:flags:alcohol_without_license_title'),
            animal_welfare_violations: i18n.t('services:flags:animal_welfare_violations_title'),
            black_white: i18n.t('services:flags:black_white_title'),
            blurry: i18n.t('services:flags:blurry_title'),
            blurry_profile_picture: i18n.t('services:flags:blurry_profile_picture_description'),
            cannot_be_cropped_vertically: i18n.t('services:flags:cannot_be_cropped_vertically_title'),
            collages: i18n.t('services:flags:collages_title'),
            duplicate: i18n.t('services:flags:duplicate_title'),
            expertise_unrelated: i18n.t('services:flags:expertise_unrelated_description'),
            heavy_filters_applied: i18n.t('services:flags:heavy_filters_applied_title'),
            hosts_experience_unclear: i18n.t('services:flags:hosts_experience_unclear_description'),
            inappropriate_content: i18n.t('services:flags:inappropriate_content_title'),
            insufficient_formal_experience: i18n.t('services:flags:insufficient_formal_experience_description'),
            irrelevant_lacking_context: i18n.t('services:flags:irrelevant_lacking_context_title'),
            lack_photo_variety: i18n.t('services:flags:lack_photo_variety_description'),
            misleading_inaccurate: i18n.t('services:flags:misleading_inaccurate_title'),
            missing_career_achievements: i18n.t('services:flags:missing_career_achievements_description'),
            multiple_countries_selected: i18n.t('services:flags:multiple_countries_selected_description'),
            not_enough_experience_years: undefined,
            not_enough_online_presence: undefined,
            passport_or_logo: i18n.t('services:flags:passport_or_logo_description'),
            photo_angle_awkward: i18n.t('services:flags:photo_angle_awkward_description'),
            policy_violation: (
                <Trans
                    i18nKey="services:flags:policy_violation_description"
                    components={{a: <a href={'https://www.airbnb.com/help/article/1451'} />}}
                />
            ),
            poor_lighting_background: i18n.t('services:flags:poor_lighting_background_description'),
            reputation_proof_insufficient: i18n.t('services:flags:reputation_proof_insufficient_description'),
            selfie_style: i18n.t('services:flags:selfie_style_description'),
            service_description_unclear: (
                <Trans
                    i18nKey="services:flags:service_description_unclear_description"
                    components={{ul: <ul />, li: <li />}}
                />
            ),
            spelling_grammatical_errors:
                detailLevel == 'offering_description' ? (
                    i18n.t('services:flags:spelling_grammatical_errors_offering_description')
                ) : detailLevel == 'offering_title' ? (
                    <Trans
                        i18nKey="services:flags:spelling_grammatical_errors_offering_title"
                        components={{ul: <ul />, li: <li />}}
                    />
                ) : (
                    i18n.t('services:flags:spelling_grammatical_errors_description')
                ),
            stock_photos_or_screenshots: i18n.t('services:flags:stock_photos_or_screenshots_title'),
            stretched_distorted: i18n.t('services:flags:stretched_distorted_title'),
            text_graphics_overlaid: i18n.t('services:flags:text_graphics_overlaid_title'),
            title_needs_update: (
                <Trans i18nKey="services:flags:title_needs_update_description" components={{ul: <ul />, li: <li />}} />
            ),
            too_dark: i18n.t('services:flags:too_dark_title'),

            ineligible: undefined,
            photo_does_not_show_host: i18n.t('services:flags:photo_does_not_show_host_title'),
            not_qualified: undefined,
            inadequate_photo_quality: undefined,
            mixed: <Trans i18nKey="services:flags:mixed_title" />,
            experience_not_service: (
                <Trans
                    i18nKey="services:flags:experience_not_service_title"
                    components={{a: <a href={'https://elevate.translated.com/experiences-login'} target="_blank" />}}
                />
            ),
            offering_duplicated: <Trans i18nKey="services:flags:offering_duplicated_title" />,
            restricted_content: undefined
        }) as const satisfies Record<typeof flag, ReactNode>
    )[flag]

// all valid airbnb domains (from https://www.airbnb.com/douanes/reference/)
export const VALID_AIRBNB_DOMAINS = [
    'https://az.airbnb.com',
    'https://bg.airbnb.com',
    'https://bs.airbnb.com',
    'https://cnr.airbnb.com',
    'https://cs.airbnb.com',
    'https://da.airbnb.com',
    'https://de.airbnb.com',
    'https://el.airbnb.com',
    'https://es-l.airbnb.com',
    'https://es.airbnb.com',
    'https://et.airbnb.com',
    'https://fi.airbnb.com',
    'https://fr.airbnb.be',
    'https://fr.airbnb.ca',
    'https://fr.airbnb.ch',
    'https://fr.airbnb.com',
    'https://ga.airbnb.com',
    'https://ga.airbnb.ie',
    'https://he.airbnb.com',
    'https://hi.airbnb.co.in',
    'https://hi.airbnb.com',
    'https://hr.airbnb.com',
    'https://hu.airbnb.com',
    'https://hy.airbnb.com',
    'https://id.airbnb.com',
    'https://is.airbnb.com',
    'https://it.airbnb.ch',
    'https://it.airbnb.com',
    'https://ja.airbnb.com',
    'https://ka.airbnb.com',
    'https://kn.airbnb.com',
    'https://ko.airbnb.com',
    'https://lt.airbnb.com',
    'https://lv.airbnb.com',
    'https://mk.airbnb.com',
    'https://mr.airbnb.com',
    'https://ms.airbnb.com',
    'https://mt.airbnb.com',
    'https://mt.airbnb.com.mt',
    'https://nl.airbnb.com',
    'https://no.airbnb.com',
    'https://pl.airbnb.com',
    'https://pt.airbnb.com',
    'https://ro.airbnb.com',
    'https://ru.airbnb.com',
    'https://sk.airbnb.com',
    'https://sl.airbnb.com',
    'https://sq.airbnb.com',
    'https://sr.airbnb.com',
    'https://sv.airbnb.com',
    'https://sw.airbnb.com',
    'https://th.airbnb.com',
    'https://tl.airbnb.com',
    'https://tr.airbnb.com',
    'https://uk.airbnb.com',
    'https://vi.airbnb.com',
    'https://www.airbnb.ae',
    'https://www.airbnb.am',
    'https://www.airbnb.at',
    'https://www.airbnb.az',
    'https://www.airbnb.ba',
    'https://www.airbnb.be',
    'https://www.airbnb.ca',
    'https://www.airbnb.cat',
    'https://www.airbnb.ch',
    'https://www.airbnb.cl',
    'https://www.airbnb.cn',
    'https://www.airbnb.co.cr',
    'https://www.airbnb.co.id',
    'https://www.airbnb.co.in',
    'https://www.airbnb.co.kr',
    'https://www.airbnb.co.nz',
    'https://www.airbnb.co.uk',
    'https://www.airbnb.co.ve',
    'https://www.airbnb.co.za',
    'https://www.airbnb.com',
    'https://www.airbnb.com.ar',
    'https://www.airbnb.com.au',
    'https://www.airbnb.com.bo',
    'https://www.airbnb.com.br',
    'https://www.airbnb.com.bz',
    'https://www.airbnb.com.co',
    'https://www.airbnb.com.ec',
    'https://www.airbnb.com.ee',
    'https://www.airbnb.com.gt',
    'https://www.airbnb.com.hk',
    'https://www.airbnb.com.hn',
    'https://www.airbnb.com.mt',
    'https://www.airbnb.com.my',
    'https://www.airbnb.com.ni',
    'https://www.airbnb.com.pa',
    'https://www.airbnb.com.pe',
    'https://www.airbnb.com.ph',
    'https://www.airbnb.com.py',
    'https://www.airbnb.com.ro',
    'https://www.airbnb.com.sg',
    'https://www.airbnb.com.sv',
    'https://www.airbnb.com.tr',
    'https://www.airbnb.com.tw',
    'https://www.airbnb.com.ua',
    'https://www.airbnb.com.vn',
    'https://www.airbnb.cz',
    'https://www.airbnb.de',
    'https://www.airbnb.design',
    'https://www.airbnb.dk',
    'https://www.airbnb.es',
    'https://www.airbnb.fi',
    'https://www.airbnb.fr',
    'https://www.airbnb.gr',
    'https://www.airbnb.gy',
    'https://www.airbnb.hu',
    'https://www.airbnb.ie',
    'https://www.airbnb.is',
    'https://www.airbnb.it',
    'https://www.airbnb.jp',
    'https://www.airbnb.lt',
    'https://www.airbnb.lu',
    'https://www.airbnb.lv',
    'https://www.airbnb.me',
    'https://www.airbnb.mx',
    'https://www.airbnb.nl',
    'https://www.airbnb.no',
    'https://www.airbnb.org',
    'https://www.airbnb.pl',
    'https://www.airbnb.pt',
    'https://www.airbnb.rs',
    'https://www.airbnb.ru',
    'https://www.airbnb.se',
    'https://www.airbnb.si',
    'https://xh.airbnb.co.za',
    'https://xh.airbnb.com',
    'https://zh-cn.airbnb.com',
    'https://zh-t.airbnb.com',
    'https://zh.airbnb.com',
    'https://zu.airbnb.co.za',
    'https://zu.airbnb.com'
]

export const hasRelevantFieldChanges = (data: Service['expertise'][] | Offering[], keys: string[]) => {
    return data.some(item =>
        item.question_answer.some(q => keys.includes(q.question_key_name) && q.answer != q?.previous_answer)
    )
}
