import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    HeadingGroup,
    AlertBanner,
    FieldTitle,
    FieldDescription
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {z} from 'zod'
import InputText from '@/components/commons/input-text/InputText'
import {useFieldArray, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {ExperienceExtended, StepKeyName} from '../../types'
import TextArea from '@/components/commons/textarea/TextArea'
import {Divider} from '@/components/ui/divider/Divider'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledInputContent, StyledInputText, StyledLinkField} from './style'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '@/features/host-submissions/experiences/utils'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ChangeProfilePhoto} from '@/features/host-submissions/components/change-profile-photo/ChangeProfilePhoto'
import {useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {useAuthStore} from '@/store/AuthStore'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {ChangePhoneNumber} from '@/features/host-submissions/components/change-phone-number/ChangePhoneNumber'

export const EXPERTISE_MIN_LENGTH = 150
export const EXPERTISE_MAX_LENGTH = 500
export const EXPERIENCE_TITLE_MAX_LENGTH = 50
export const RECOGNITION_TITLE_MAX_LENGTH = 50
export const RECOGNITION_SUBTITLE_MAX_LENGTH = 100

export const AboutYourselfStepValidationSchema = z.object({
    expertise_years: z
        .string()
        .regex(/^[1-9]\d*$/, {message: i18n.t('errors:invalid_positive_number')})
        .max(2, {message: i18n.t('services:step_host_expertise_career:invalid_year_number')}),
    host_experience_title: z.string().min(1, {message: i18n.t('errors:field_required')}),
    host_expertise_description: z.string({required_error: i18n.t('errors:field_required')}).min(EXPERTISE_MIN_LENGTH, {
        message: i18n.t('errors:field_min_character', {count: EXPERTISE_MIN_LENGTH})
    }),
    host_first_name: z.string().min(1, {message: i18n.t('errors:field_required')}),
    profile_photo: z
        .string()
        .min(1, {message: i18n.t('errors:field_required')})
        .url(),
    online_profile_links: z.array(z.object({link: z.string().url(i18n.t('errors:invalid_http_url'))})).optional(),
    host_recognition_title: z.string().optional(),
    host_recognition_subtitle: z.string().optional()
})
export type AboutYourselfStepValidationSchema = z.infer<typeof AboutYourselfStepValidationSchema>

export const ExperienceAboutYourselfStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const user = useAuthStore(store => store.user)
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const [changeProfilePhotoIsPending, setChangeProfilePhotoIsPending] = useState(false)

    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)
    const flagsCount = updatesRequiredSteps.about_you.flatMap(field => field.flags).length
    const profilePhotoFlags = updatesRequiredSteps.about_you.find(field => field.questionKeyName == 'profile_photo')
        ?.flags
    const linksFlags = updatesRequiredSteps.about_you.find(field => field.questionKeyName == 'online_profile_links')
        ?.flags
    const expertiseYearsFlags = updatesRequiredSteps.about_you.find(field => field.questionKeyName == 'expertise_years')
        ?.flags
    const expertiseFlags = updatesRequiredSteps.about_you.find(
        field => field.questionKeyName == 'host_experience_title'
    )?.flags
    const backgroundFlags = updatesRequiredSteps.about_you.find(
        field => field.questionKeyName == 'host_expertise_description'
    )?.flags

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.category,
        currentStep: StepKeyName.enum.about_you,
        nextStep: StepKeyName.enum.about_you_more_info,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })

    const defaultValues = makeExperiencesFormDefaultValues(experience).about_you

    const form = useForm<AboutYourselfStepValidationSchema>({
        resolver: zodResolver(AboutYourselfStepValidationSchema),
        defaultValues: {
            ...defaultValues,
            online_profile_links: defaultValues?.online_profile_links?.map(link => ({link})) ?? [{link: ''}],
            profile_photo: defaultValues?.profile_photo || user?.picture_url || undefined
        }
    })
    const fieldArray = useFieldArray({control: form.control, name: 'online_profile_links'})
    const [experienceTitleWatch, expertiseDescriptionWatch, hostRecognitionTitleWatch, hostRecognitionSubtitleWatch] =
        useWatch({
            control: form.control,
            name: [
                'host_experience_title',
                'host_expertise_description',
                'host_recognition_title',
                'host_recognition_subtitle'
            ]
        })

    const onSuccessChangePhoto = (data: {url: string}) => {
        form.setValue('profile_photo', data.url)
        void queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experience.id]})
        void queryClient.setQueriesData<ExperienceExtended>(
            {queryKey: [QUERY_KEYS.EXPERIENCE, experience.id]},
            prevData =>
                prevData
                    ? {
                          ...prevData,
                          host: {
                              ...prevData.host,
                              profilePhotoUrl: data.url
                          }
                      }
                    : undefined
        )
    }

    const onSuccessChangePhone = (phoneNumber: string) => {
        queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE, experience.id]}, prevData => ({
            ...(prevData ?? {}),
            host: {airbnbVerifiedPhone: phoneNumber}
        }))
    }

    const onSubmit = form.handleSubmit(data =>
        (experience.host.profilePhotoUrl || user?.picture_url) && (experience.host.airbnbVerifiedPhone || user?.phone)
            ? changeStep.handleChangeStep(false, {
                  ...data,
                  online_profile_links: data?.online_profile_links
                      ? data.online_profile_links.map(({link}) => link).filter(value => value.trim() != '')
                      : []
              })
            : undefined
    )

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:experience_about_yourself_step:title')}</Title>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('commons:x_items_improve', {count: flagsCount})}
                            paragraph={t('experiences:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>

                <Flexbox gap={6} direction="column" width="100%">
                    <Flexbox gap={4} direction="column" width="100%">
                        <StyledInputContent gap={3} direction="column" width="100%">
                            <InputText
                                type={'number'}
                                inputMode={'numeric'}
                                label={t('experiences:experience_about_yourself_step:question1_label')}
                                placeholder={t('experiences:experience_about_yourself_step:question1_placeholder')}
                                errorMessage={form.formState.errors.expertise_years?.message}
                                {...form.register('expertise_years')}
                            />

                            <FlagsAlertText
                                flags={expertiseYearsFlags}
                                title={t('experiences:experience_about_yourself_step:question1_label')}
                            />
                        </StyledInputContent>

                        <StyledInputContent gap={3} direction="column" width="100%">
                            <InputText
                                type={'text'}
                                maxLength={EXPERIENCE_TITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: EXPERIENCE_TITLE_MAX_LENGTH - experienceTitleWatch.length
                                })}
                                label={t('experiences:experience_about_yourself_step:question2_label')}
                                placeholder={t('experiences:experience_about_yourself_step:question2_placeholder')}
                                errorMessage={form.formState.errors.host_experience_title?.message}
                                {...form.register('host_experience_title')}
                            />

                            <FlagsAlertText
                                flags={expertiseFlags}
                                title={t('experiences:experience_about_yourself_step:question2_label')}
                            />
                        </StyledInputContent>

                        <StyledInputContent gap={3} direction="column" width="100%">
                            <TextArea
                                rows={6}
                                maxLength={EXPERTISE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: EXPERTISE_MAX_LENGTH - expertiseDescriptionWatch.length
                                })}
                                label={t('experiences:experience_about_yourself_step:question3_label')}
                                placeholder={t('experiences:experience_about_yourself_step:question3_placeholder')}
                                errorMessage={form.formState.errors.host_expertise_description?.message}
                                {...form.register('host_expertise_description')}
                            />
                            <FlagsAlertText
                                flags={backgroundFlags}
                                title={t('experiences:experience_about_yourself_step:question3_label')}
                            />
                        </StyledInputContent>
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <StyledInputContent gap={4} direction="column" width="100%">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('host_review:recognition_fields:title')}</FieldTitle>
                            <FieldDescription>{t('host_review:recognition_fields:paragraph')}</FieldDescription>
                        </Flexbox>

                        <InputText
                            type={'text'}
                            maxLength={RECOGNITION_TITLE_MAX_LENGTH}
                            helpText={t('commons:x_characters_available', {
                                count: RECOGNITION_TITLE_MAX_LENGTH - (hostRecognitionTitleWatch ?? '').length
                            })}
                            label={t('host_review:recognition_fields:recognition_title_about_you')}
                            placeholder={t('host_review:recognition_fields:recognition_title_placeholder')}
                            errorMessage={form.formState.errors.host_recognition_title?.message}
                            {...form.register('host_recognition_title')}
                        />
                        <TextArea
                            rows={3}
                            maxLength={RECOGNITION_SUBTITLE_MAX_LENGTH}
                            helpText={t('commons:x_characters_available', {
                                count: RECOGNITION_SUBTITLE_MAX_LENGTH - (hostRecognitionSubtitleWatch ?? '').length
                            })}
                            label={t('host_review:recognition_fields:recognition_subtitle_about_you')}
                            placeholder={t('host_review:recognition_fields:recognition_subtitle_placeholder')}
                            errorMessage={form.formState.errors.host_recognition_subtitle?.message}
                            {...form.register('host_recognition_subtitle')}
                        />
                    </StyledInputContent>
                    <Divider direction="horizontal" />
                    <StyledInputContent gap={4} direction="column" width="100%">
                        <FieldTitle>{t('experiences:experience_about_yourself_step:your_name')}</FieldTitle>

                        <InputText
                            type={'text'}
                            label={t('experiences:experience_about_yourself_step:first_name')}
                            placeholder={t('experiences:experience_about_yourself_step:first_name')}
                            errorMessage={form.formState.errors.host_first_name?.message}
                            {...form.register('host_first_name')}
                        />
                    </StyledInputContent>
                    <Divider direction="horizontal" />
                    <ChangeProfilePhoto
                        onSuccess={onSuccessChangePhoto}
                        photoUrl={experience.host.profilePhotoUrl}
                        isSubmitted={form.formState.isSubmitted}
                        profilePhotoFlags={profilePhotoFlags}
                        setIsPending={setChangeProfilePhotoIsPending}
                        errorMessage={form.formState.errors.profile_photo?.message}
                    />

                    <Divider direction="horizontal" />

                    <ChangePhoneNumber
                        airbnbVerifiedPhone={experience.host.airbnbVerifiedPhone}
                        userPhone={user?.phone}
                        isSubmitted={form.formState.isSubmitted}
                        onSuccess={onSuccessChangePhone}
                    />

                    <Divider direction="horizontal" />

                    <Flexbox direction="column" gap={4} width="100%">
                        <Flexbox direction="column" gap={1.5} align="stretch">
                            <FieldTitle>{t('experiences:experience_about_yourself_step:question4_title')}</FieldTitle>
                            <FieldDescription>{t('host_review:social_link_consent_policy')}</FieldDescription>

                            <FlagsAlertText
                                flags={linksFlags}
                                title={t('experiences:experience_about_yourself_step:question4_title')}
                            />
                        </Flexbox>

                        {fieldArray.fields.map((field, index, array) => (
                            <StyledLinkField key={field.id}>
                                <StyledInputText
                                    inputSize="sm"
                                    type="text"
                                    label={t('experiences:experience_about_yourself_step:question4_label')}
                                    placeholder={t('experiences:experience_about_yourself_step:question4_placeholder')}
                                    {...form.register(`online_profile_links.${index}.link`)}
                                />
                                <Button
                                    variant="ghost"
                                    shape="square"
                                    onClick={() => fieldArray.remove(index)}
                                    style={{visibility: array.length == 1 ? 'hidden' : 'visible', transition: 'none'}}
                                >
                                    <Trash01Icon />
                                </Button>
                                {form.formState.errors.online_profile_links?.[index]?.link && (
                                    <InputHelpText
                                        error={form.formState.errors.online_profile_links[index]?.link?.message}
                                    />
                                )}
                            </StyledLinkField>
                        ))}
                        {fieldArray.fields.length < 10 && (
                            <Button
                                variant="tertiary"
                                shape="text"
                                style={{width: 'max-content'}}
                                onClick={() => fieldArray.append({link: ''})}
                            >
                                <PlusIcon />
                                {t('experiences:experience_about_yourself_step:add_another_profile')}
                            </Button>
                        )}
                        {form.formState.errors.online_profile_links?.root && (
                            <InputHelpText error={form.formState.errors.online_profile_links.root.message} />
                        )}
                    </Flexbox>
                    <Divider direction="horizontal" endSpacing={8} />
                </Flexbox>
            </Content>

            <Footer progressPercentage={getExperiencesStepProgressPercentage('about_you')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading || changeProfilePhotoIsPending}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                {changeStep.hasNextStep ? (
                    <Button
                        disabled={
                            changeStep.isBackLoading || changeStep.isContinueLoading || changeProfilePhotoIsPending
                        }
                        onClick={onSubmit}
                    >
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={changeStep.isBackLoading} onClick={onSubmit}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
