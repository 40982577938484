import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {httpUpdateStep} from '@/features/experiences-host-video/queries/experiences-host-video.http'
import {
    ExperiencesHostVideo,
    ExperiencesHostVideoRouteParams,
    UploadStepPayload
} from '@/features/experiences-host-video/types.ts'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'

interface UseUpdateStepArgs {
    params: ExperiencesHostVideoRouteParams
    mutationOptions?: Omit<
        UseMutationOptions<
            unknown,
            unknown,
            {
                status: UploadStepPayload['status']['key_name']
                step: UploadStepPayload['status']['step_key_name']
            }
        >,
        'mutationFn'
    >
}
export const useUpdateStep = ({params, mutationOptions}: UseUpdateStepArgs) => {
    const queryClient = useQueryClient()

    return useMutation<
        unknown,
        unknown,
        {status: UploadStepPayload['status']['key_name']; step: UploadStepPayload['status']['step_key_name']}
    >({
        mutationFn: ({status, step}) =>
            parseAxiosPromise({
                axiosPromise: httpUpdateStep({
                    urlParams: {...params, status, step},
                    params: {response_type: 'extended'}
                }),
                responseShape: ExperiencesHostVideo,
                onZodError: captureException
            }),
        ...mutationOptions,
        onSuccess: async (data, variables, context) => {
            await queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCES_HOST_VIDEO]})
            queryClient.setQueriesData(
                {
                    queryKey: [
                        QUERY_KEYS.EXPERIENCES_HOST_VIDEO,
                        params.hostCode,
                        params.hostPassword,
                        params.experiencesHostVideoId,
                        params.taskId
                    ]
                },
                data
            )
            mutationOptions?.onSuccess?.(data, variables, context)
        }
    })
}
