import {getStepProgressPercentage} from '@/features/host-submissions/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Footer,
    FieldTitle,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {useSubmitService} from '@/features/host-submissions/services/queries/useSubmitService'
import {errorHandler} from '@utilities/helpers.ts'
import {Service} from '@/features/host-submissions/services/types.ts'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useUpdateStepToOtherDetailsLegal} from '../../queries/useUpdateStepToOtherDetailsLegal'
import {useUpdateStepToOtherDetailsMoreDetails} from '../../queries/useUpdateStepToOtherDetailsMoreDetails'
import {REQUIRED_QUESTIONS_FOR_LEGAL_STEP} from '../other-details-legal-step/OtherDetailsLegalStep'
import {ConfirmSubmitEnhanceModal} from '@/features/host-submissions/components/confirm-submit-enhance-modal/ConfirmSubmitEnhanceModal'
import {StyledParagraph} from './style'

export const OtherDetailsTermsStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitToReviewModalOpen, setIsConfirmSubmitToReviewModalOpen] = useState(false)
    const updateStepToOtherDetailsLegal = useUpdateStepToOtherDetailsLegal<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateStepToOtherDetailsMoreDetails = useUpdateStepToOtherDetailsMoreDetails<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })

    const hasAllRequiredQuestions = REQUIRED_QUESTIONS_FOR_LEGAL_STEP.every(
        question => service.questions?.some(q => q.key_name === question)
    )

    const prevStepMutation = hasAllRequiredQuestions
        ? updateStepToOtherDetailsLegal
        : updateStepToOtherDetailsMoreDetails

    const submitService = useSubmitService({
        serviceId: service.id,
        options: {onError: errorHandler, onSuccess: () => setIsConfirmSubmitToReviewModalOpen(false)}
    })

    const [isGeneralTermsApproved, setIsGeneralTermsApproved] = useState(false)

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_other_details_terms:title')}</Title>
                </HeadingGroup>
                <Flexbox direction={'column'} gap={6}>
                    <Flexbox direction={'column'} gap={4}>
                        <FieldTitle>{t('services:step_other_details_terms:general_attestation')}</FieldTitle>
                        <Checkbox
                            id={'policy-1'}
                            checked={isGeneralTermsApproved}
                            onChange={event => setIsGeneralTermsApproved(event.currentTarget.checked)}
                            label={
                                <StyledParagraph>
                                    <Trans
                                        i18nKey={'services:step_other_details_terms:policy_general'}
                                        components={{
                                            ul: <ul />,
                                            li: <li />,
                                            0: (
                                                <a
                                                    style={{
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer',
                                                        fontWeight: 'bold'
                                                    }}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/1451"
                                                />
                                            ),
                                            1: (
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/2251"
                                                />
                                            ),
                                            2: (
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/2647"
                                                />
                                            )
                                        }}
                                    />
                                </StyledParagraph>
                            }
                        />
                    </Flexbox>

                    <Divider direction={'horizontal'} />

                    <Flexbox direction={'column'} gap={4}>
                        <FieldTitle>{t('services:step_other_details_terms:additional_terms')}</FieldTitle>
                        <StyledParagraph>
                            <Trans
                                i18nKey={'services:step_other_details_terms:policy_additional'}
                                components={[
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.airbnb.com/help/article/2855"
                                    />,
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.airbnb.com/help/article/2870"
                                    />,
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.airbnb.com/help/article/1451"
                                    />,
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.airbnb.com/help/article/2278"
                                    />,
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.airbnb.com/help/article/2908#13"
                                    />
                                ]}
                            />
                        </StyledParagraph>
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('terms')}>
                <Button
                    variant="tertiary"
                    disabled={prevStepMutation.isPending}
                    onClick={() => prevStepMutation.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation.isPending && <Spinner />}
                </Button>
                <Button
                    disabled={submitService.isPending || !isGeneralTermsApproved}
                    onClick={() => setIsConfirmSubmitToReviewModalOpen(true)}
                >
                    {t('services:submit_for_review')}
                    {submitService.isPending && <Spinner />}
                </Button>
            </Footer>

            {isConfirmSubmitToReviewModalOpen && (
                <ConfirmSubmitEnhanceModal
                    onClose={() => setIsConfirmSubmitToReviewModalOpen(false)}
                    isSendToReview={true}
                    onSubmit={() =>
                        submitService.mutate({
                            general_attestation: isGeneralTermsApproved,
                            // TODO: remove this once the backend is updated
                            is_terms_approved: true,
                            is_authorized_quality_safety_check: true
                        })
                    }
                    isLoading={submitService.isPending}
                />
            )}
        </>
    )
}
