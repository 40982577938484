import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import i18next from 'i18next'
import {ReassignTask} from '@/features/chatbot/components/reassign-task/ReassignTask.tsx'
import {ChatbotStep} from '@/features/chatbot/types.ts'
import {HttpGetServiceResponse} from '@/features/service/http/service.http'

export const hp34 = ({service}: {service: HttpGetServiceResponse | null}): ChatbotStep[] => {
    //const quoteStatus = service?.quote?.quote_status
    const taskStatus = service?.assignment?.task_status?.status
    const isSoftCanceled = taskStatus == 'soft_cancelled'
    /*const postProdStatus = service?.assignment?.task_status?.post_prod_status
    const shootBeyondSchedule = dayjs(service?.assignment?.to_shoot_at).isBefore(dayjs(new Date()))
    const isQuoteNotSigned = quoteStatus !== 'signed' && quoteStatus !== 'completed' && taskStatus != 'auto_cancelled'
    const showPreparingForPhotoshoot = quoteStatus !== 'completed' && taskStatus == 'scheduled'
    const isScheduled = taskStatus == 'scheduled'
    const isCompleted = taskStatus == 'completed'
    const isAssignmentAccepted = taskStatus == 'accepted'*/

    return [
        {
            id: 'HP34',
            options: [
                {
                    value: 'HP34.1',
                    label: i18next.t('chatbot:HP34:options:label_1'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP34.1',
                            source: 'User',
                            text: 'I don’t know how to get in touch with my assigned home consultant'
                        })
                        return 'HP34.1.1'
                    }
                },
                {
                    value: 'HP34.2',
                    label: i18next.t('chatbot:HP34:options:label_2'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP34.2',
                            source: 'User',
                            text: "After 48 hours I haven't been connected to a home consultant. What can I do?"
                        })
                        return 'HP34.2.1'
                    }
                },
                ...(isSoftCanceled
                    ? [
                          {
                              value: 'HP34.3',
                              label: i18next.t('chatbot:HP34:options:label_3'),
                              trigger: () => {
                                  useChatbotStore.getState().addStepToHistory({
                                      id: 'HP34.3',
                                      source: 'User',
                                      text: 'My assigned home consultant canceled the walkthrough. What should I do?'
                                  })
                                  return 'HP34.3.1'
                              }
                          }
                      ]
                    : []),
                {
                    value: 'HP34.4',
                    label: i18next.t('chatbot:HP34:options:label_4'),
                    trigger: () => {
                        useChatbotStore.getState().addStepToHistory({
                            id: 'HP34.4',
                            source: 'User',
                            text: "I can't find mutual availability with my home consultant. Find me a new home consultant."
                        })
                        return 'HP34.4.1'
                    }
                }
            ]
        },
        {
            id: 'HP34.1.1',
            message: i18next.t('chatbot:HP34:HP34.1_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP34.1.1',
                    source: 'Chatbot',
                    text: 'Contact the home consultant using the phone number and email displayed on the Airbnb Elevate page.'
                })
                return 'oq_message'
            }
        },
        {
            id: 'HP34.2.1',
            message: i18next.t('chatbot:HP34:HP34.2_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP34.2.1',
                    source: 'Chatbot',
                    text: "It's taking a little longer than expected to find a home consultant available in your area. Add dates to share your availability and increase likelihood of a match."
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP34.3.1',
            message: i18next.t('chatbot:HP34:HP34.3_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP34.3.1',
                    source: 'Chatbot',
                    text: 'We highly recommend that you or a staff member are present during the walkthrough as the home consultant may have questions regarding the home or amenities and their functionality. If no one is present, you are responsible for ensuring the home consultant has appropriate access details to the home in order to conduct the walkthrough.'
                })
                return 'oq_message'
            } // Final trigger of the flow's branch
        },
        {
            id: 'HP34.4.1',
            message: i18next.t('chatbot:HP34:HP34.4_message'),
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP34.4.1',
                    source: 'Chatbot',
                    text: "Let's try finding you a new home consultant. Please note that if we can't find a match, your walkthrough request will be waitlisted until a home consultant becomes available. Do you wish to proceed?"
                })
                return 'HP34.4.2'
            }
        },
        {
            id: 'HP34.4.2',
            options: [
                {
                    value: 'yes',
                    label: i18next.t('commons:yes'),
                    trigger: () => 'HP34.4.3'
                },
                {
                    value: 'no',
                    label: i18next.t('commons:no'),
                    trigger: () => 'oq_message'
                }
            ]
        },
        {
            id: 'HP34.4.3',
            component: <ReassignTask />,
            trigger: () => {
                useChatbotStore.getState().addStepToHistory({
                    id: 'HP21.2.1.4_send',
                    source: 'Chatbot',
                    text: 'Thank you! Our team will get back to you via email in the next 48 hours.' //copy of AssignNew return
                })
                return 'oq_message'
            },
            asMessage: true,
            replace: true
        }
    ]
}
