import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {
    httpGetServicesProposals,
    HttpServicesProposalsResponse
} from '@/features/promotional-services/http/promoServices.http'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'

export const useServicesProposals = (
    params: DashboardURLParams,
    options?: Omit<UseQueryOptions<HttpServicesProposalsResponse>, 'queryFn' | 'queryKey'>
) => {
    const query = useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SERVICES_PROPOSALS, params],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetServicesProposals(params),
                onZodError: captureException,
                responseShape: HttpServicesProposalsResponse
            })
    })

    return {
        experienceVideoServices: query.data?.quotes.filter(quote => quote.task_media.name == 'video') ?? [],
        photoServices: query.data?.quotes.filter(quote => quote.task_media.name == 'photo') ?? [],
        expPhotoService:
            query.data?.quotes.filter(
                quote =>
                    quote.task_media.name == 'photo' &&
                    (quote.task_category.name == 'Experience' || quote.task_category.name == 'M13-Photo')
            ) ?? [],
        matterportServices: query.data?.quotes.filter(quote => quote.task_media.name == 'matterport') ?? [],
        walkthroughServices: query.data?.quotes.filter(quote => quote.task_media.name == 'walkthrough') ?? [],
        targetedHost: {
            email: query.data?.email,
            first_name: query.data?.first_name,
            phone: query.data?.phone
        },
        ...query
    }
}
