import {QueryClient, UseInfiniteQueryResult} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    AMENITIES_AUTOCOMPLETE: 'amenities-autocomplete',
    ACCESSIBILITY_ROOMS: 'accessibility-rooms',
    APPROVAL_REQUEST_DETAILS: 'approval-request-details',
    APPROVAL_REQUESTS_LIST: 'approval-requests-list',
    CATEGORIES: 'categories',
    CATEGORIES_AVAILABILITY: 'categories-availability',
    CO_ALARM_HOST_INFO: 'co-alarm-host-info',
    CO_ALARM_LISTINGS: 'co-alarm-listings',
    COUNTRIES: 'countries',
    CITIES: 'cities',
    CO_ALARM_REQUEST_DETAILS: 'co-alarm-request-details',
    EXPERIENCES_VIDEO: 'experiences-video',
    EXPERIENCES_HOST_VIDEO: 'experiences-host-video',
    EXPERIENCES_HOST_VIDEO_FRIEND: 'experiences-host-video-friend',
    EXPERIENCES_HOST_VIDEO_REDIRECT: 'experiences-host-video-redirect',
    EXPERIENCES_HOST_VIDEO_DEVICES: 'experiences-host-video-devices',
    EXPERIENCES_HOST_VIDEO_QUIZ: 'experiences-host-video-quiz',
    EXPERIENCES_HOST_VIDEO_VOICEOVER: 'experiences-host-video-voiceover',
    EXPERIENCES_HOST_VIDEO_UPLOADED_FILES: 'experiences-host-video-uploaded-files',
    HOST_EXPERIENCE_PROPOSALS: 'host-experience-proposals',
    IN_PROGRESS_SERVICES: 'in-progress-services',
    LISTINGS_CATEGORIES: 'listings-categories',
    MATTERPORT_INFO: 'matterport-info',
    PHOTO_SERVICE_REQUESTS: 'photo-service-requests',
    ROOMS_AUTOCOMPLETE: 'rooms-autocomplete',
    SERVICE_DETAILS: 'service-details',
    SERVICES_PROPOSALS: 'services-proposals',
    SERVICE_CANCELLATION: 'service-cancellation-reasons',
    SERVICE_CANCELLATION_REASONS: 'service-cancellation-reasons',
    SERVICE_DECLINE_REASONS: 'service-decline-reasons',
    SERVICE: 'services',
    SERVICES: 'services',
    SERVICES_GALLERY: 'services-gallery',
    EXPERIENCE: 'experience',
    EXPERIENCES: 'experiences',
    EXPERIENCES_GALLERY: 'experiences-gallery',
    EXPERIENCE_COUNTRIES: 'experiences-countries',
    EXPERIENCES_ADDITIONAL_PHOTO: 'experiences-additional-photo'
} as const

export const MUTATION_KEYS = {
    SEND_REVIEWED_APPROVAL: 'send-approval-review',
    ACCEPT_QUOTE: 'accept-quote',
    ACCEPT_REQUESTED_SERVICE: 'accept-requested-service',
    ACCEPT_PORTRAIT: 'accept-portrait',
    ADD_CATEGORIES: 'add-categories',
    ADD_OFFERING: 'add-offering',
    DECLINE_QUOTE: 'decline-quote',
    DELETE_OFFERING: 'delete-offering',
    EDIT_PHONE_NUMBER: 'edit-phone-number',
    NOTIFICATION_PERMISSION: 'notification-permission',
    NOTIFICATION_PREFERENCES: 'notification-preferences',
    REMOVE_CATEGORY: 'remove-category',
    REQUEST_CO_ALARM: 'request-co-alarm',
    SEND_CO_ALARM_REQUEST: 'send-co-alarm-request',
    EDIT_CO_ALARM_REQUEST: 'edit-co-alarm-request',
    SERVICE_CANCELLATION: 'service-cancellation',
    SEND_FEEDBACK: 'send-feedback',
    SEND_HOST_DATES: 'send-host-dates',
    SERVICE_RESOURCES_LOG: 'service-resources-log',
    VALIDATE_OTP_CODE: 'validate-otp-code',
    BULK_UPLOAD_CLIPS: 'bulk-upload-clips',
    CONFIRM_UPLOAD_CLIPS: 'confirm-upload-clips',
    DELETE_VOICEOVER: 'delete-voiceover',
    SERVICES_PHOTO_PRESIGNED: 'services-photo-presigned',
    EXPERIENCES_PHOTO_PRESIGNED: 'experiences-photo-presigned',
    ADD_ACTIVITY: 'add-activity',
    DELETE_ACTIVITY: 'delete-activity'
} as const

//rq-helpers
export const infiniteQueryFetchNextPage = async (infiniteQuery: UseInfiniteQueryResult) => {
    if (infiniteQuery.hasNextPage && !infiniteQuery.isFetching) {
        await infiniteQuery.fetchNextPage()
    }
}
