import {
    Content,
    FieldTitle,
    HeadingGroup,
    Paragraph,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {Service} from '@/features/host-submissions/services/types.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {Divider} from '@/components/ui/divider/Divider'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import InputText from '@/components/commons/input-text/InputText.tsx'
import TextArea from '@/components/commons/textarea/TextArea.tsx'
import {StyledContent, StyledText} from './style'
import {useEffect, useMemo, useState} from 'react'
import {useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {Label} from '@/components/ui/label/Label'
import {ContinueEditModal} from '@/features/host-submissions/components/continue-edit-modal/ContinueEditModal'
import {errorHandler, raise} from '@/utilities/helpers'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useUpdateStepToEnhanceAboutServiceStep} from '@/features/host-submissions/services/queries/useUpdateStepToEnhanceAboutServiceStep'
import {useUpdateStepToEnhanceGalleryStep} from '@/features/host-submissions/services/queries/useUpdateStepToEnhanceGalleryStep'
import {useURLParsedParams} from '@/hooks/useURLParsedParams'
import {useGalleryPhoto} from '@/features/host-submissions/services/queries/useGalleryPhoto'
import {useUpdateEnhanceStep} from '@/features/host-submissions/services/queries/useUpdateEnhanceStep'
import {hasRelevantFieldChanges} from '@/features/host-submissions/services/utils'
import {Footer} from '@/features/host-submissions/components/atoms/Atoms'
import {RestoreFieldsBanner} from '@/features/host-submissions/components/restore-fields-banner/RestoreFieldsBanner'
import {ConfirmSubmitEnhanceModal} from '@/features/host-submissions/components/confirm-submit-enhance-modal/ConfirmSubmitEnhanceModal'
import {useSubmitHostApprovalService} from '../../queries/useSubmitHostApprovalService'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

const TITLE_MAX_LENGTH = 32
const DESCRIPTION_MAX_LENGTH = 90

const EnhanceAboutYouStepValidationSchema = z.object({
    host_experience_title: z.string().min(1, {message: i18n.t('errors:field_required')}),
    host_experience_description: z.string().min(1, {message: i18n.t('errors:field_required')}),
    host_education_title: z.string().min(1, {message: i18n.t('errors:field_required')}),
    host_education_description: z.string().min(1, {message: i18n.t('errors:field_required')}),
    host_career_highlight_title: z.string().min(1, {message: i18n.t('errors:field_required')}),
    host_career_highlight_description: z.string().min(1, {message: i18n.t('errors:field_required')})
})
export type EnhanceAboutYouStepValidationSchema = z.infer<typeof EnhanceAboutYouStepValidationSchema>

const getDefaultValuesEnhanceAboutYouForm = (service: Service) => {
    const defaultKeys = [
        'host_experience_title',
        'host_experience_description',
        'host_education_title',
        'host_education_description',
        'host_career_highlight_title',
        'host_career_highlight_description'
    ]

    return service.expertise?.question_answer.reduce(
        (previousValue, currentValue) => {
            if (defaultKeys.includes(currentValue.question_key_name)) {
                return {
                    ...previousValue,
                    [currentValue.question_key_name]: currentValue.answer
                }
            }
            return previousValue
        },
        {
            host_experience_title: '',
            host_experience_description: '',
            host_education_title: '',
            host_education_description: '',
            host_career_highlight_title: '',
            host_career_highlight_description: ''
        }
    )
}

export const EnhanceServiceAboutYouStep = ({service}: {service: Service}) => {
    const {t} = useTranslation()
    const [isEditMode, setIsEditMode] = useState(false)
    const [isContinueEditModalOpen, setIsContinueEditModalOpen] = useState(false)
    const [isConfirmSubmitToReviewModalOpen, setIsConfirmSubmitToReviewModalOpen] = useState(false)
    const [isFieldChanged, setIsFieldChanged] = useState(false)
    const [isPrevStepChanged, setIsPrevStepChanged] = useState(false)
    const [hasAdditionalPhotos, setHasAdditionalPhotos] = useState(false)

    const continueEditingServiceValue = sessionStorage.getItem('continueEditingServiceConfirmed')
    const isContinueEditingConfirmed = continueEditingServiceValue ? JSON.parse(continueEditingServiceValue) : false
    const urlParams = useURLParsedParams(z.object({id: z.coerce.number()}))
    const galleryQuery = useGalleryPhoto(urlParams.success ? urlParams.data.id : raise('Invalid urlParams'), true)
    const hasNewGalleryPhoto = service.gallery?.images.some(image => !image.parent_id)
    useEffect(() => {
        const images = galleryQuery.data || []
        const additionalPhotos = images.filter(img => img.is_host_approved == null || !!img.is_host_approved)

        if (additionalPhotos.length > 0) {
            setHasAdditionalPhotos(true)
        }
    }, [galleryQuery.data])

    const prevStepMutation = useUpdateStepToEnhanceAboutServiceStep({
        serviceId: service.id,
        options: {onError: errorHandler}
    })

    const updateStepToEnhanceGalleryStepMutation = useUpdateStepToEnhanceGalleryStep({
        serviceId: service.id,
        options: {onError: errorHandler}
    })

    const updateEnhanceStepSuccess = () => {
        if (hasAdditionalPhotos) {
            updateStepToEnhanceGalleryStepMutation.mutate()
        } else {
            setIsConfirmSubmitToReviewModalOpen(true)
        }
    }
    const updateEnhanceStepMutation = useUpdateEnhanceStep({
        serviceId: service.id,
        options: {onError: errorHandler, onSuccess: updateEnhanceStepSuccess}
    })

    const restoreDataMutation = useUpdateEnhanceStep({
        serviceId: service.id,
        options: {
            onError: errorHandler,
            onSuccess: data => {
                setIsFieldChanged(false)
                form.reset(getDefaultValuesEnhanceAboutYouForm(data))
            }
        }
    })
    const submitService = useSubmitHostApprovalService({
        serviceId: service.id,
        options: {onError: errorHandler, onSuccess: () => setIsConfirmSubmitToReviewModalOpen(false)}
    })
    const defaultValues = useMemo(() => getDefaultValuesEnhanceAboutYouForm(service), [service])
    const form = useForm<EnhanceAboutYouStepValidationSchema>({
        resolver: zodResolver(EnhanceAboutYouStepValidationSchema),
        defaultValues
    })

    useEffect(() => {
        const offeringsChanged = hasRelevantFieldChanges(service.offerings, [
            'improved_title',
            'improved_description',
            'main_photo'
        ])
        const expertiseChanged = service.expertise
            ? hasRelevantFieldChanges(
                  [service.expertise],
                  [
                      'host_experience_title',
                      'service_description',
                      'host_experience_description',
                      'host_education_title',
                      'host_education_description',
                      'host_career_highlight_title',
                      'host_career_highlight_description',
                      'service_title'
                  ]
              )
            : false

        setIsPrevStepChanged(offeringsChanged || expertiseChanged)
    }, [service])

    const [
        experienceTitleWatch,
        experienceDescriptionWatch,
        educationTitleWatch,
        educationDescriptionWatch,
        careerHighlightTitleWatch,
        careerHighlightDescriptionWatch
    ] = useWatch({
        control: form.control,
        name: [
            'host_experience_title',
            'host_experience_description',
            'host_education_title',
            'host_education_description',
            'host_career_highlight_title',
            'host_career_highlight_description'
        ]
    })

    const onSubmit = form.handleSubmit(data => {
        updateEnhanceStepMutation.mutate(data)
    })
    const offeringPreviousAnswerData = service.offerings.map(offering =>
        offering.question_answer.reduce(
            (acc, val) => {
                if (val.question_key_name == 'improved_title') {
                    return {...acc, improved_title: val.previous_answer ?? val.answer}
                }
                if (val.question_key_name == 'improved_description') {
                    return {...acc, improved_description: val.previous_answer ?? val.answer}
                }
                if (val.question_key_name == 'main_photo') {
                    return {
                        ...acc,
                        main_photo: val.previous_answer ?? val.answer
                    }
                }

                return acc
            },
            {
                improved_title: '',
                improved_description: '',
                main_photo: '',
                id: offering.id
            }
        )
    )

    const previousAnswerData = service.expertise?.question_answer.reduce(
        (acc, val) => {
            if (
                [
                    'host_experience_title',
                    'host_experience_description',
                    'host_education_title',
                    'host_education_description',
                    'host_career_highlight_title',
                    'host_career_highlight_description',
                    'service_title',
                    'service_description'
                ].includes(val.question_key_name)
            ) {
                return {...acc, [val.question_key_name]: val.previous_answer}
            }
            return acc
        },
        {
            host_experience_title: '',
            host_experience_description: '',
            host_education_title: '',
            host_education_description: '',
            host_career_highlight_title: '',
            host_career_highlight_description: '',
            service_title: '',
            service_description: '',
            offerings: offeringPreviousAnswerData
        }
    )

    const onRestoreValues = () => {
        restoreDataMutation.mutate(previousAnswerData)
    }
    return (
        <>
            <Content gap={6}>
                <HeadingGroup direction="column" gap={4}>
                    <Title>{t('host_review:enhance_your_service_step_title')}</Title>
                    <Paragraph>{t('host_review:enhance_your_service_step_paragraph')}</Paragraph>
                    {(isFieldChanged || isPrevStepChanged) && (
                        <RestoreFieldsBanner
                            onRestore={onRestoreValues}
                            isLoading={restoreDataMutation.isPending}
                            hideRestoreAction={true}
                        />
                    )}
                </HeadingGroup>

                <Flexbox gap={5} width="100%" direction="column">
                    <Flexbox justify="space-between" gap={2} width="100%">
                        <FieldTitle>{t(`services:enhance_your_qualifications_step:your_qualifications`)}</FieldTitle>
                        <Button
                            shape="text"
                            variant="tertiary"
                            onClick={async () => {
                                if (isEditMode) {
                                    const isValid = await form.trigger()
                                    !!isValid && setIsEditMode(false)
                                    const isFieldChanged =
                                        experienceTitleWatch != defaultValues.host_experience_title ||
                                        experienceDescriptionWatch != defaultValues.host_experience_description ||
                                        educationTitleWatch != defaultValues.host_education_title ||
                                        educationDescriptionWatch != defaultValues.host_education_description ||
                                        careerHighlightTitleWatch != defaultValues.host_career_highlight_title ||
                                        careerHighlightDescriptionWatch !=
                                            defaultValues.host_career_highlight_description

                                    setIsFieldChanged(isFieldChanged)
                                } else {
                                    if (!isFieldChanged && !isContinueEditingConfirmed) {
                                        setIsContinueEditModalOpen(true)
                                    } else {
                                        setIsEditMode(true)
                                    }
                                }
                            }}
                        >
                            {t(isEditMode ? 'commons:done' : 'commons:edit')}
                        </Button>
                    </Flexbox>

                    <StyledContent direction="column" gap={4} width="100%">
                        {isEditMode ? (
                            <InputText
                                type={'text'}
                                label={t('services:enhance_your_qualifications_step:experience')}
                                maxLength={TITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: TITLE_MAX_LENGTH - experienceTitleWatch.length
                                })}
                                errorMessage={form.formState.errors.host_experience_title?.message}
                                {...form.register('host_experience_title')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>{t('services:enhance_your_qualifications_step:experience')}</Label>
                                <StyledText>{experienceTitleWatch}</StyledText>
                                <InputHelpText error={form.formState.errors.host_experience_title?.message} />
                            </Flexbox>
                        )}

                        {isEditMode ? (
                            <TextArea
                                rows={3}
                                label={t('services:enhance_your_qualifications_step:experience_details')}
                                maxLength={DESCRIPTION_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: DESCRIPTION_MAX_LENGTH - experienceDescriptionWatch.length
                                })}
                                errorMessage={form.formState.errors.host_experience_description?.message}
                                {...form.register('host_experience_description')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>{t('services:enhance_your_qualifications_step:experience_details')}</Label>
                                <StyledText>{experienceDescriptionWatch}</StyledText>
                                <InputHelpText error={form.formState.errors.host_experience_description?.message} />
                            </Flexbox>
                        )}
                    </StyledContent>

                    <Divider direction="horizontal" />

                    <StyledContent direction="column" gap={4} width="100%">
                        {isEditMode ? (
                            <InputText
                                type={'text'}
                                label={t('services:enhance_your_qualifications_step:training_education')}
                                maxLength={TITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: TITLE_MAX_LENGTH - educationTitleWatch.length
                                })}
                                errorMessage={form.formState.errors.host_education_title?.message}
                                {...form.register('host_education_title')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>{t('services:enhance_your_qualifications_step:training_education')}</Label>
                                <StyledText>{educationTitleWatch}</StyledText>
                                <InputHelpText error={form.formState.errors.host_education_title?.message} />
                            </Flexbox>
                        )}

                        {isEditMode ? (
                            <TextArea
                                rows={3}
                                label={t('services:enhance_your_qualifications_step:training_education_details')}
                                maxLength={DESCRIPTION_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: DESCRIPTION_MAX_LENGTH - educationDescriptionWatch.length
                                })}
                                errorMessage={form.formState.errors.host_education_description?.message}
                                {...form.register('host_education_description')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>
                                    {t('services:enhance_your_qualifications_step:training_education_details')}
                                </Label>
                                <StyledText>{educationDescriptionWatch}</StyledText>
                                <InputHelpText error={form.formState.errors.host_education_description?.message} />
                            </Flexbox>
                        )}
                    </StyledContent>

                    <Divider direction="horizontal" />

                    <StyledContent direction="column" gap={4} width="100%">
                        {isEditMode ? (
                            <InputText
                                type={'text'}
                                label={t('services:enhance_your_qualifications_step:career_highlight')}
                                maxLength={TITLE_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: TITLE_MAX_LENGTH - careerHighlightTitleWatch.length
                                })}
                                errorMessage={form.formState.errors.host_career_highlight_title?.message}
                                {...form.register('host_career_highlight_title')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>{t('services:enhance_your_qualifications_step:career_highlight')}</Label>
                                <StyledText>{careerHighlightTitleWatch}</StyledText>
                                <InputHelpText error={form.formState.errors.host_career_highlight_title?.message} />
                            </Flexbox>
                        )}

                        {isEditMode ? (
                            <TextArea
                                rows={3}
                                label={t('services:enhance_your_qualifications_step:career_highlight_details')}
                                maxLength={DESCRIPTION_MAX_LENGTH}
                                helpText={t('commons:x_characters_available', {
                                    count: DESCRIPTION_MAX_LENGTH - careerHighlightDescriptionWatch.length
                                })}
                                errorMessage={form.formState.errors.host_career_highlight_description?.message}
                                {...form.register('host_career_highlight_description')}
                            />
                        ) : (
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <Label>{t('services:enhance_your_qualifications_step:career_highlight_details')}</Label>
                                <StyledText>{careerHighlightDescriptionWatch}</StyledText>
                                <InputHelpText
                                    error={form.formState.errors.host_career_highlight_description?.message}
                                />
                            </Flexbox>
                        )}
                    </StyledContent>
                </Flexbox>
            </Content>

            <Footer>
                <Button
                    variant="tertiary"
                    disabled={
                        updateStepToEnhanceGalleryStepMutation?.isPending ||
                        prevStepMutation?.isPending ||
                        updateEnhanceStepMutation?.isPending
                    }
                    onClick={() => prevStepMutation.mutate()}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>

                <Button
                    onClick={onSubmit}
                    disabled={
                        updateStepToEnhanceGalleryStepMutation?.isPending ||
                        prevStepMutation?.isPending ||
                        updateEnhanceStepMutation?.isPending ||
                        isEditMode
                    }
                >
                    {t('commons:continue')}
                    {(updateStepToEnhanceGalleryStepMutation?.isPending || updateEnhanceStepMutation?.isPending) && (
                        <Spinner />
                    )}
                </Button>
            </Footer>

            {isConfirmSubmitToReviewModalOpen && (
                <ConfirmSubmitEnhanceModal
                    onClose={() => setIsConfirmSubmitToReviewModalOpen(false)}
                    isSendToReview={isPrevStepChanged || isFieldChanged || !!hasNewGalleryPhoto}
                    onSubmit={() => submitService.mutate()}
                    isLoading={submitService.isPending}
                />
            )}

            {isContinueEditModalOpen && (
                <ContinueEditModal
                    onClose={() => {
                        setIsContinueEditModalOpen(false)
                    }}
                    onContinue={() => {
                        setIsEditMode(true)
                        setIsContinueEditModalOpen(false)
                        sessionStorage.setItem('continueEditingServiceConfirmed', 'true')
                    }}
                />
            )}
        </>
    )
}
