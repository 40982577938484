import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {httpUpdateLanguage} from '@/features/experiences-host-video/queries/experiences-host-video.http'
import {ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'

interface UseUpdateStepArgs {
    params: ExperiencesHostVideoRouteParams
    mutationOptions?: Omit<
        UseMutationOptions<
            unknown,
            unknown,
            {
                language: string
            }
        >,
        'mutationFn'
    >
}
export const useUpdateVoiceoverLanguage = ({params, mutationOptions}: UseUpdateStepArgs) => {
    return useMutation<unknown, unknown, {language: string}>({
        mutationFn: ({language}) =>
            httpUpdateLanguage({
                urlParams: params,
                payload: {language}
            }),
        ...mutationOptions
    })
}
