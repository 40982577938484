import {
    ExperiencesHostVideoActionCard,
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import toast from 'react-hot-toast'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {Link01Icon} from '@components/ui/icon'
import {copyTextToClipboard} from '@utilities/helpers.ts'
import {generatePath} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {StyledSelfFilmingButton} from '@/features/experiences-host-video/components/ready-to-film-get-help-step/style.ts'
import {useState} from 'react'
import {ConfirmSelfFilmingModal} from '@/features/experiences-host-video/components/confirm-self-filming-modal/ConfirmSelfFilmingModal.tsx'
import {Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'

export const ReadyToFilmGetHelpStep = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const [isConfirmSelfFilmingModalOpen, setIsConfirmSelfFilmingModalOpen] = useState(false)
    const backUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const continueUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const copyToClipboard = async () => {
        await copyTextToClipboard(
            `${window.location.origin}${generatePath(routes.EXPERIENCE_HOST_VIDEO_FRIEND.path, routeParams)}`
        )
        toast.success(t('experiences_host_video:link_copied_successfully'))
        await navigator.share({
            url: `${window.location.origin}${generatePath(routes.EXPERIENCE_HOST_VIDEO_FRIEND.path, routeParams)}`
        })
    }

    return (
        <>
            <ExperiencesHostVideoBody>
                <Flexbox direction="column" gap={2}>
                    <Title>{t('experiences_host_video:ready_to_film_get_help:title')}</Title>
                    <Paragraph>{t('experiences_host_video:ready_to_film_get_help:description')}</Paragraph>
                </Flexbox>
                <Flexbox direction="column" align="center" gap={4} width="100%">
                    <ExperiencesHostVideoActionCard onClick={copyToClipboard}>
                        <Link01Icon />
                        <Flexbox direction="column" gap={2}>
                            <h3>{t('experiences_host_video:ready_to_film_get_help:share_title')}</h3>
                            <p>{t('experiences_host_video:tap_to_share_link')}</p>
                        </Flexbox>
                    </ExperiencesHostVideoActionCard>
                    <StyledSelfFilmingButton onClick={() => setIsConfirmSelfFilmingModalOpen(true)}>
                        {t('experiences_host_video:ready_to_film_get_help:self_filming_button')}
                    </StyledSelfFilmingButton>
                </Flexbox>
            </ExperiencesHostVideoBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={backUpdateStepMutation.isPending || continueUpdateStepMutation.isPending}
                            variant="tertiary"
                            onClick={() =>
                                backUpdateStepMutation.mutate({status: 'ready-to-film', step: 'perspective'})
                            }
                        >
                            {t('commons:back')}
                            {backUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            onClick={() =>
                                continueUpdateStepMutation.mutate({
                                    status: 'ready-to-film',
                                    step: 'schedule'
                                })
                            }
                            disabled={continueUpdateStepMutation.isPending || backUpdateStepMutation.isPending}
                        >
                            {t('commons:continue')}
                            {continueUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>

                {isConfirmSelfFilmingModalOpen && (
                    <ConfirmSelfFilmingModal onClose={() => setIsConfirmSelfFilmingModalOpen(false)} />
                )}
            </ExperiencesHostVideoFooter>
        </>
    )
}
