import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    AlertBanner,
    Content,
    Footer,
    HeadingGroup,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC} from 'react'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    StyledGalleryItemContainer,
    StyledGalleryItemImg,
    StyledGalleryGrid
} from '@/features/host-submissions/experiences/components/declined-gallery-photos/style'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {UploadedMedia} from '../../types'
import {getExperiencesStepProgressPercentage} from '../../utils'

export const DeclinedGalleryPhotos: FC<{
    declinedPhoto: UploadedMedia[]
    onContinue: () => void
    onBack: () => void
    isBackLoading: boolean
}> = ({declinedPhoto, onContinue, onBack, isBackLoading}) => {
    const {t} = useTranslation()

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:experience_gallery_step:title')}</Title>

                    <AlertBanner
                        title={t('experiences:experience_gallery_step:update_required_title')}
                        paragraph={t('experiences:gallery_banner')}
                    />
                </HeadingGroup>

                <StyledGalleryGrid>
                    {declinedPhoto.map((item, index) => (
                        <Flexbox direction="column" gap={2} width="100%" height="100%" key={index}>
                            <StyledGalleryItemContainer>
                                <StyledGalleryItemImg src={item.url} alt={'img'} />
                            </StyledGalleryItemContainer>

                            <FlagsAlertText flags={item?.parentFlags} title={t('commons:photo')} />
                        </Flexbox>
                    ))}
                </StyledGalleryGrid>
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('gallery')}>
                <Button variant="tertiary" disabled={isBackLoading} onClick={onBack}>
                    {t('commons:back')}
                    {isBackLoading && <Spinner />}
                </Button>

                <Button disabled={isBackLoading} onClick={onContinue}>
                    {t('experiences:enhance_gallery_step:upload_more_photo_title')}
                </Button>
            </Footer>
        </>
    )
}
