import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {errorHandler} from '@/utilities/helpers'
import {httpDeletePhotos, HttpDeletePhotosOptions} from '../http/experiences.http'

interface UseDeletePhotosProps {
    experienceId: number
    galleryId: number
    mutationOptions?: Omit<UseMutationOptions<unknown, unknown, HttpDeletePhotosOptions['params']>, 'mutationFn'>
}

export const useDeletePhotos = ({experienceId, galleryId, mutationOptions}: UseDeletePhotosProps) => {
    const queryClient = useQueryClient()
    return useMutation<unknown, unknown, HttpDeletePhotosOptions['params']>({
        mutationFn: params => httpDeletePhotos({params, urlParams: {experienceId, galleryId}}),
        onError: errorHandler,
        ...mutationOptions,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            mutationOptions?.onSuccess?.(data, variables, context)
        }
    })
}
