import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {httpGetGalleryImages} from '@/features/host-submissions/services/http/services.http'
import {UploadedMedia} from '@/features/host-submissions/services/types'

export const useGalleryPhoto = (
    serviceId: number,
    is_host_approval_needed: boolean,
    options?: Omit<UseQueryOptions<UploadedMedia[]>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.SERVICES_GALLERY, serviceId, is_host_approval_needed],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetGalleryImages({
                    urlParams: {serviceId},
                    params: {response_type: 'extended', is_host_approval_needed}
                }),
                responseShape: UploadedMedia.array(),
                onZodError: captureException
            })
    })
