import {ActionCard} from '@/features/service-actions/components/action-card/ActionCard.tsx'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import {useServiceDetails} from '@/features/service/queries/useServiceDetails'
import {MatterportProcess} from '@/features/matterport/components/MatterportProcess.tsx'
import {useMatterportStore} from '@/features/matterport/store/MatterportStore.tsx'
import {FC, useEffect} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import {HeartSquareIcon} from '@components/ui/icon'
import {httpClickAction} from '@/features/service-actions/http/actions.http'
import {ServiceAction} from '@/features/service-actions/types.ts'

export const MatterportImagesAction: FC<{isCompleted: boolean; actionId: ServiceAction['id']}> = ({
    isCompleted,
    actionId
}) => {
    const {t} = useTranslation()
    const isMatterportModalVisible = useMatterportStore(state => state.modalVisible)
    const showMatterportModal = useMatterportStore(state => state.showModal)
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const serviceQuery = useServiceDetails(
        {
            urlParams,
            params: {response_type: 'extended'}
        },
        {retry: false}
    )

    useEffect(() => {
        showMatterportModal()
    }, [])

    if (!serviceQuery.data?.task_matterport) {
        return false
    }

    const clickAction = () => {
        showMatterportModal()
        httpClickAction({...urlParams, actionId})
    }

    return (
        <>
            <ActionCard
                isCompleted={isCompleted}
                onClick={isCompleted ? undefined : clickAction}
                icon={HeartSquareIcon}
                paragraph={t('service_details:actions:matterport_images:paragraph')}
                title={t('service_details:actions:matterport_images:title')}
            />
            {isMatterportModalVisible && (
                <MatterportProcess
                    urlParams={{...urlParams, taskMatterportId: serviceQuery.data.task_matterport.id}}
                    entity={serviceQuery.data.entity}
                />
            )}
        </>
    )
}

MatterportImagesAction.displayName = 'MatterportImagesAction'
