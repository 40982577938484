import {create} from 'zustand'
import {HttpGetServiceResponse} from '@/features/service/http/service.http'
import {CancelStep} from '@/features/service-cancel/types.ts'

type CancelServiceStoreState = {
    modalVisible: boolean
    step: CancelStep
    service: HttpGetServiceResponse | null
}

type CancelServiceStoreActions = {
    cancellationServiceModalToggle: (quote?: HttpGetServiceResponse) => void
    changeStep: (nextStep: CancelStep) => void
}

export const useServiceCancellationStore = create<CancelServiceStoreState & CancelServiceStoreActions>()(set => ({
    modalVisible: false,
    step: 'warning',
    service: null,
    changeStep: nextStep => set({step: nextStep}),
    cancellationServiceModalToggle: service =>
        set(state => {
            if (state.modalVisible) {
                return {modalVisible: false, service: null, step: 'warning'}
            } else {
                return {modalVisible: true, service: service}
            }
        })
}))
