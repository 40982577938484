import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {httpGetGalleryImages} from '../http/experiences.http'
import {UploadedMedia} from '../types'

export const useGalleryPhoto = ({
    experienceId,
    galleryId,
    options
}: {
    experienceId: number
    galleryId: number
    options?: Omit<UseQueryOptions<UploadedMedia[]>, 'queryFn' | 'queryKey'>
}) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.EXPERIENCES_GALLERY, experienceId, galleryId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetGalleryImages({
                    urlParams: {experienceId, galleryId},
                    params: {responseType: 'extended'}
                }),
                responseShape: UploadedMedia.array(),
                onZodError: captureException
            })
    })
