import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {FieldDescription, FieldTitle} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTheme} from 'styled-components'
import Avatar from '@components/ui/avatar/Avatar.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {errorHandler, raise} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'
import {useAsync} from '@hooks/useAsync.ts'
import {useChangeProfilePhoto} from '@/features/host-submissions/queries/useChangeProfilePhoto'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {ParentFlag} from '@/features/host-submissions/types'
import {FlagsAlertText} from '../flags-alert-text/FlagsAlertText'
import {StyledInvisibleInputFile} from './style'
import {ExperiencesFlag} from '@/features/host-submissions/experiences/types'

export const ChangeProfilePhoto: FC<{
    profilePhotoFlags?: ParentFlag[] | ExperiencesFlag[]
    onSuccess: ({url}: {url: string}) => void
    photoUrl: string | null
    isSubmitted: boolean
    setIsPending: Dispatch<SetStateAction<boolean>>
    errorMessage?: string
}> = ({profilePhotoFlags, onSuccess, isSubmitted, photoUrl, setIsPending, errorMessage}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    const user = useAuthStore(store => store.user)
    const invisibleInputRef = useRef<HTMLInputElement>(null)

    const changeProfilePhotoMutation = useChangeProfilePhoto({
        hostId: user?.id ?? raise('userId is nullish'),
        options: {onError: errorHandler, onSuccess}
    })

    useEffect(() => {
        setIsPending(changeProfilePhotoMutation.isPending)
    }, [changeProfilePhotoMutation.isPending])

    const updateFreelancerProfilePhotoAsync = useAsync()

    const uploadImage = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] ?? raise('file is nullish')
        if (file.size > 5_000_000) {
            toast.error(t('errors:upload_limit_exceed', {count: 5}))
        } else {
            const formData = new FormData()
            formData.append('image', file)
            formData.append('type', 'host-profile-photo')
            changeProfilePhotoMutation.mutate(formData)
        }
    }

    return (
        <>
            <Flexbox direction="column" gap={4}>
                <Flexbox direction="column" gap={1.5}>
                    <FieldTitle>{t('services:step_host_expertise_about_yourself:question1_title')}</FieldTitle>
                    <FieldDescription>
                        {t('services:step_host_expertise_about_yourself:question1_list_title')}
                    </FieldDescription>
                    <ul style={{marginLeft: theme.spacing * 4.5, listStyle: 'unset'}}>
                        <li>
                            <FieldDescription>
                                {t('services:step_host_expertise_about_yourself:question1_list1')}
                            </FieldDescription>
                        </li>
                        <li>
                            <FieldDescription>
                                {t('services:step_host_expertise_about_yourself:question1_list2')}
                            </FieldDescription>
                        </li>
                        <li>
                            <FieldDescription>
                                {t('services:step_host_expertise_about_yourself:question1_list3')}
                            </FieldDescription>
                        </li>
                    </ul>

                    <FlagsAlertText
                        flags={profilePhotoFlags}
                        title={t('services:step_host_expertise_about_yourself:question1_title')}
                    />
                </Flexbox>
                <Flexbox align="center">
                    <Avatar
                        name={`${user?.first_name} ${user?.last_name}`}
                        imageUrl={photoUrl ?? user?.picture_url ?? ''}
                        size="xl"
                    />
                    <Button
                        variant="tertiary"
                        onClick={() => invisibleInputRef.current?.click()}
                        disabled={changeProfilePhotoMutation.isPending || updateFreelancerProfilePhotoAsync.isLoading}
                    >
                        {user?.picture_url ? t('commons:edit') : t('commons:upload')}
                        {(changeProfilePhotoMutation.isPending || updateFreelancerProfilePhotoAsync.isLoading) && (
                            <Spinner />
                        )}
                    </Button>
                </Flexbox>
                {((isSubmitted && !photoUrl && !user?.picture_url) || errorMessage) && (
                    <InputHelpText error={errorMessage ?? t('errors:field_required')} />
                )}
                <FieldDescription>
                    {t('services:step_host_expertise_about_yourself:upload_description_paragraph_1')}
                </FieldDescription>
                <FieldDescription>
                    {t('services:step_host_expertise_about_yourself:upload_description_paragraph_2')}
                </FieldDescription>
            </Flexbox>

            <StyledInvisibleInputFile
                disabled={changeProfilePhotoMutation.isPending || updateFreelancerProfilePhotoAsync.isLoading}
                type="file"
                ref={invisibleInputRef}
                accept="image/*"
                value=""
                onChange={uploadImage}
                multiple={false}
            />
        </>
    )
}
