import {forwardRef, PropsWithChildren, ReactNode} from 'react'
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu'
import {
    StyledDropdownActionComponent,
    StyledDropdownActionText,
    StyledDropdownMenuContent,
    StyledDropdownMenuItem,
    StyledDropdownMenuTrigger,
    StyledDropdownSeparator
} from '@components/ui/dropdown-menu/style.ts'
import {DefaultNamespace} from 'i18next'

export type DropdownMenuItem = {
    text?: string | DefaultNamespace
    component: ReactNode
    icon?: ReactNode
    isDanger?: boolean
    disabled?: boolean
    withSeparator?: boolean
    onClickCb?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

type DropdownMenuProps = {
    rootProps?: RDropdownMenu.DropdownMenuProps
    triggerProps?: RDropdownMenu.DropdownMenuTriggerProps
    portalProps?: RDropdownMenu.DropdownMenuPortalProps
    contentProps?: RDropdownMenu.DropdownMenuContentProps
    itemProps?: RDropdownMenu.DropdownMenuItemProps
    defaultOpen?: boolean
    sideOffset?: number
    trigger: ReactNode
    align?: 'start' | 'center' | 'end'
    actions: DropdownMenuItem[]
} & PropsWithChildren

const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
    (
        {
            trigger,
            actions,
            align = 'end',
            sideOffset = 0,
            defaultOpen,
            rootProps,
            triggerProps,
            portalProps,
            contentProps
        },
        ref
    ) => {
        return (
            <RDropdownMenu.Root {...rootProps} defaultOpen={defaultOpen}>
                <StyledDropdownMenuTrigger {...triggerProps}>{trigger}</StyledDropdownMenuTrigger>
                <RDropdownMenu.Portal {...portalProps}>
                    <StyledDropdownMenuContent ref={ref} sideOffset={sideOffset} {...contentProps} align={align}>
                        {actions?.map((action, index) => (
                            <div key={index}>
                                <StyledDropdownMenuItem
                                    key={index}
                                    onClick={!action.disabled ? e => action.onClickCb?.(e) : undefined}
                                    disabled={action.disabled}
                                    isdanger={action.isDanger}
                                >
                                    {action.icon}
                                    {action.text && <StyledDropdownActionText>{action.text}</StyledDropdownActionText>}
                                    {action.component && (
                                        <StyledDropdownActionComponent>
                                            {action.component}
                                        </StyledDropdownActionComponent>
                                    )}
                                </StyledDropdownMenuItem>
                                {action.withSeparator && <StyledDropdownSeparator />}
                            </div>
                        ))}
                    </StyledDropdownMenuContent>
                </RDropdownMenu.Portal>
            </RDropdownMenu.Root>
        )
    }
)
export default DropdownMenu
DropdownMenu.displayName = 'DropdownMenu'
