import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps
} from '@/features/host-submissions/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18n, {Trans, useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldTitle,
    Title,
    Footer,
    AlertBanner,
    FieldDescription
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Service} from '@/features/host-submissions/services/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import InputText from '@components/commons/input-text/InputText.tsx'
import TextArea from '@components/commons/textarea/TextArea.tsx'
import {useForm, useWatch} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {AnswerItem} from '@components/commons/answer-item/AnswerItem.tsx'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {useUpdateStepToHostExpertiseExpertise} from '@/features/host-submissions/services/queries/useUpdateStepToHostExpertiseExpertise'
import {errorHandler} from '@utilities/helpers.ts'
import {useUpdateStepToGetStarted} from '@/features/host-submissions/services/queries/useUpdateStepToGetStarted'
import {ConfirmSubmitModal} from '@/features/host-submissions/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {FlagsAlertText} from '../../../components/flags-alert-text/FlagsAlertText'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {generatePath, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants'

const SUMMARY_MIN_LENGTH = 150
const SUMMARY_MAX_LENGTH = 800
const HIGHLIGHTS_MAX_LENGTH = 150

const ValidationSchema = z.object({
    business_type: z.enum(['individual', 'business']),
    experience_years: z
        .string()
        .regex(/^[1-9]\d*$/, {message: i18n.t('errors:invalid_positive_number')})
        .max(2, {message: i18n.t('services:step_host_expertise_career:invalid_year_number')}),
    summary: z
        .string({required_error: i18n.t('errors:field_required')})
        .min(SUMMARY_MIN_LENGTH, {
            message: i18n.t('errors:field_min_character', {count: SUMMARY_MIN_LENGTH})
        })
        .max(SUMMARY_MAX_LENGTH),
    highlights: z.string().min(1, {message: i18n.t('errors:field_required')})
})
type ValidationSchema = z.infer<typeof ValidationSchema>

export const HostExpertiseCareerStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const businessTypeFlags = updatesRequiredSteps.career.find(field => field.questionKeyName == 'business_type')?.flags
    const experienceYearsFlags = updatesRequiredSteps.career.find(field => field.questionKeyName == 'experience_years')
        ?.flags
    const summaryFlags = updatesRequiredSteps.career.find(field => field.questionKeyName == 'summary')?.flags
    const highlightsFlags = updatesRequiredSteps.career.find(field => field.questionKeyName == 'highlights')?.flags
    const flagsCount = updatesRequiredSteps.career.flatMap(field => field.flags).length
    const updateStepToGetStartedMutation = useUpdateStepToGetStarted({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {
            onError: errorHandler,
            onSuccess: data =>
                !data.is_visible_to_host &&
                service.experience_submission_id &&
                navigate(
                    generatePath(routes.EXPERIENCE.path, {
                        id: service.experience_submission_id
                    })
                )
        }
    })
    const updateStepToHostExpertiseExpertiseMutation = useUpdateStepToHostExpertiseExpertise<false>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'career', updatesRequiredSteps}).prev?.()
            : updateStepToGetStartedMutation
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'career'>({service, currentStep: 'career', updatesRequiredSteps}).next?.()
            : updateStepToHostExpertiseExpertiseMutation

    const form = useForm<ValidationSchema>({
        resolver: zodResolver(ValidationSchema),
        defaultValues: service.expertise?.question_answer.reduce(
            (previousValue, currentValue) => {
                if (currentValue.question_key_name == 'business_type' && currentValue.answer) {
                    return {...previousValue, business_type: currentValue.answer}
                }
                if (currentValue.question_key_name == 'experience_years' && currentValue.answer) {
                    return {...previousValue, experience_years: currentValue.answer}
                }
                if (currentValue.question_key_name == 'summary' && currentValue.answer) {
                    return {...previousValue, summary: currentValue.answer}
                }
                if (currentValue.question_key_name == 'highlights' && currentValue.answer) {
                    return {...previousValue, highlights: currentValue.answer}
                }
                return previousValue
            },
            {
                highlights: '',
                summary: '',
                experience_years: ''
            }
        )
    })
    const highlightsWatch = useWatch({control: form.control, name: 'highlights'})
    const summaryWatch = useWatch({control: form.control, name: 'summary'})

    const onSubmit = form.handleSubmit(formValues => nextStepMutation?.mutate(formValues))

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:about_you')}</Title>
                    <Paragraph>{t('services:tell_about_experience')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('services:x_items_improve', {count: flagsCount})}
                            paragraph={t('services:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={6} align="stretch">
                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_host_expertise_career:question1_title')}</FieldTitle>

                            <FlagsAlertText
                                flags={experienceYearsFlags}
                                title={t('services:step_host_expertise_career:question1_title')}
                            />
                        </Flexbox>
                        <InputText
                            type={'number'}
                            inputMode={'numeric'}
                            label={t('services:step_host_expertise_career:question1_label')}
                            placeholder={t('services:step_host_expertise_career:question1_placeholder')}
                            errorMessage={form.formState.errors.experience_years?.message}
                            {...form.register('experience_years')}
                        />
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>
                                <Trans
                                    i18nKey="services:step_host_expertise_career:question2_title"
                                    components={{
                                        a: (
                                            <a
                                                target="_blank"
                                                href="https://www.airbnb.com/help/article/1321#:~:text=How%20to%20declare%20your%20status,details%20in%20your%20Account%20details."
                                            />
                                        )
                                    }}
                                />
                            </FieldTitle>

                            <FlagsAlertText
                                flags={businessTypeFlags}
                                title={
                                    <Trans
                                        i18nKey="services:step_host_expertise_career:question2_title"
                                        components={{
                                            a: (
                                                <a
                                                    target="_blank"
                                                    href="https://www.airbnb.com/help/article/1321#:~:text=How%20to%20declare%20your%20status,details%20in%20your%20Account%20details."
                                                />
                                            )
                                        }}
                                    />
                                }
                            />
                        </Flexbox>
                        <Flexbox direction="column" gap={3} align="stretch">
                            <AnswerItem
                                type="radio"
                                value="individual"
                                label={t('services:step_host_expertise_career:question2_option1')}
                                {...form.register('business_type')}
                            />
                            <AnswerItem
                                type="radio"
                                value="business"
                                label={t('services:step_host_expertise_career:question2_option2')}
                                {...form.register('business_type')}
                            />
                            {form.formState.errors.business_type && (
                                <InputHelpText error={t('services:select_option_error')} />
                            )}
                        </Flexbox>
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_host_expertise_career:question3_title')}</FieldTitle>
                            <FieldDescription>
                                {t('services:step_host_expertise_career:question3_description')}
                            </FieldDescription>
                            <FlagsAlertText
                                flags={summaryFlags}
                                title={t('services:step_host_expertise_career:question3_title')}
                            />
                        </Flexbox>
                        <TextArea
                            rows={6}
                            maxLength={SUMMARY_MAX_LENGTH}
                            helpText={t('services:x_characters_available', {
                                count: SUMMARY_MAX_LENGTH - summaryWatch.length
                            })}
                            placeholder={t('services:step_host_expertise_career:question3_placeholder')}
                            errorMessage={
                                form.formState.errors.summary?.message ||
                                (summaryWatch.length > SUMMARY_MAX_LENGTH
                                    ? t('errors:characters_over_limit', {
                                          count: summaryWatch.length - SUMMARY_MAX_LENGTH
                                      })
                                    : '')
                            }
                            {...form.register('summary')}
                        />
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_host_expertise_career:question4_title')}</FieldTitle>
                            <FieldDescription>
                                {t('services:step_host_expertise_career:question4_description')}
                            </FieldDescription>
                            <FlagsAlertText
                                flags={highlightsFlags}
                                title={t('services:step_host_expertise_career:question4_title')}
                            />
                        </Flexbox>
                        <TextArea
                            rows={6}
                            maxLength={HIGHLIGHTS_MAX_LENGTH}
                            helpText={t('services:x_characters_available', {
                                count: HIGHLIGHTS_MAX_LENGTH - highlightsWatch.length
                            })}
                            placeholder={t('services:step_host_expertise_career:question4_placeholder')}
                            errorMessage={form.formState.errors.highlights?.message}
                            {...form.register('highlights')}
                        />
                    </Flexbox>
                    <Divider direction="horizontal" />
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('career')}>
                <Button
                    variant="tertiary"
                    disabled={prevStepMutation?.isPending || nextStepMutation?.isPending}
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button disabled={prevStepMutation?.isPending || nextStepMutation.isPending} onClick={onSubmit}>
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={prevStepMutation?.isPending} onClick={() => setIsConfirmSubmitOpen(true)}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'career'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={form.getValues()}
                />
            )}
        </>
    )
}
