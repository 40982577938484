import {
    StyledGalleryActionButton,
    StyledGalleryItemContainer,
    StyledGalleryItemImg
} from '@/features/host-submissions/components/additional-photo-item/style'
import {Trash01Icon} from '@/components/ui/icon'

export const AdditionalPhotoItem = ({image, onDelete}: {image: {url: string}; onDelete: () => void}) => {
    return (
        <StyledGalleryItemContainer>
            <StyledGalleryItemImg src={image.url} alt={'img'} />

            <StyledGalleryActionButton variant={'ghost'} shape={'square'} onClick={onDelete}>
                <Trash01Icon />
            </StyledGalleryActionButton>
        </StyledGalleryItemContainer>
    )
}
