import {useTranslation} from '@/translations/i18n.tsx'
import {FC} from 'react'
import {
    StyledCoverLabel,
    StyledGalleryActionButton,
    StyledPhotoUploaderFloatingItem,
    StyledPreviewImageWrapper,
    StyledSelectIconWrapper
} from './style'
import {PreviewImage} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {AlertTriangleIcon, CheckIcon, RefreshCcw02Icon} from '@/components/ui/icon'
import {CircularProgressBar} from '@/features/experiences-host-video/components/circular-progress-bar/CircularProgressBar'
import {GalleryItem} from '@/features/host-submissions/types'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {useGalleryUpload} from '@/features/host-submissions/services/store/servicesGallery'
import {BulkPresignedPhotosResponse, useBulkPresignedPhotos} from '../../queries/useBulkPresignedPhotos'
import {Spinner} from '@/components/ui/spinner/Spinner'

export const ExperienceGalleryImageItem: FC<{
    imageItem: GalleryItem
    isSelected: boolean
    onSelect?: () => void
    experienceId: number
    galleryId: number
    onSuccessRetry: (data: BulkPresignedPhotosResponse) => void
}> = ({imageItem, isSelected, onSelect, experienceId, galleryId, onSuccessRetry}) => {
    const {t} = useTranslation()
    const removeGalleryItem = useGalleryUpload(store => store.removeGalleryItem)
    const setAcceptedFiles = useGalleryUpload(store => store.setAcceptedFiles)
    const uploadProgressValues = useGalleryUpload(store => store.uploadProgressValues)
    const bulkPresignedRetryQuery = useBulkPresignedPhotos({
        urlParams: {experienceId, galleryId},
        options: {onSuccess: onSuccessRetry}
    })

    const onRetry = async () => {
        if (imageItem.blob) {
            setAcceptedFiles([imageItem.blob])
            await bulkPresignedRetryQuery.mutateAsync({medias: [imageItem.blob.name]})
            removeGalleryItem(imageItem.id)
        }
    }

    return (
        <Flexbox direction="column" gap={2} width="100%" height="100%">
            <StyledPreviewImageWrapper $isSelected={isSelected} onClick={onSelect}>
                <PreviewImage
                    hasLowTransparency={imageItem.status == 'pending'}
                    src={imageItem.blob ? URL.createObjectURL(imageItem.blob) : imageItem.src}
                />

                {isSelected && (
                    <StyledSelectIconWrapper align="center" justify="center">
                        <CheckIcon />
                    </StyledSelectIconWrapper>
                )}

                {imageItem?.isCover && <StyledCoverLabel> {t('experiences:cover')}</StyledCoverLabel>}
                {imageItem.status == 'error' && (
                    <StyledGalleryActionButton
                        disabled={bulkPresignedRetryQuery.isPending}
                        variant={'ghost'}
                        shape={'square'}
                        onClick={e => {
                            e.stopPropagation()
                            onRetry()
                        }}
                    >
                        {bulkPresignedRetryQuery.isPending ? <Spinner /> : <RefreshCcw02Icon />}
                    </StyledGalleryActionButton>
                )}
                <StyledPhotoUploaderFloatingItem>
                    {imageItem.status == 'pending' && (
                        <CircularProgressBar progress={uploadProgressValues[imageItem.id] || 0} />
                    )}
                    {imageItem.status == 'error' && (
                        <>
                            <Flexbox direction={'column'} gap={2} align={'center'} style={{textAlign: 'center'}}>
                                <AlertTriangleIcon />
                                {t('errors:default')}
                            </Flexbox>
                        </>
                    )}
                </StyledPhotoUploaderFloatingItem>
            </StyledPreviewImageWrapper>

            <FlagsAlertText flags={imageItem?.flags} title={t('commons:photo')} />
        </Flexbox>
    )
}
