import {useTranslation} from '@/translations/i18n.tsx'
import {FC} from 'react'
import {GalleryItem} from '@/features/host-submissions/types'
import {
    StyledPhotoUploaderFloatingItem,
    StyledPreviewImageWrapper,
    StyledSelectIconWrapper
} from '@/components/commons/gallery-image-item/style'
import {PreviewImage} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {AlertTriangleIcon, CheckIcon} from '@/components/ui/icon'
import {CircularProgressBar} from '@/features/experiences-host-video/components/circular-progress-bar/CircularProgressBar'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'

export const GalleryImageItem: FC<{
    imageItem: GalleryItem
    isSelected: boolean
    onSelect?: () => void
}> = ({imageItem, isSelected, onSelect}) => {
    const {t} = useTranslation()

    return (
        <Flexbox direction="column" gap={2} width="100%" height="100%">
            <StyledPreviewImageWrapper $isSelected={isSelected} onClick={onSelect}>
                <PreviewImage
                    hasLowTransparency={imageItem.status == 'pending'}
                    src={imageItem.blob ? URL.createObjectURL(imageItem.blob) : imageItem.src}
                />

                {isSelected && (
                    <StyledSelectIconWrapper align="center" justify="center">
                        <CheckIcon />
                    </StyledSelectIconWrapper>
                )}

                <StyledPhotoUploaderFloatingItem>
                    {imageItem.status == 'pending' && <CircularProgressBar progress={imageItem.progressValue ?? 0} />}
                    {imageItem.status == 'error' && (
                        <Flexbox direction={'column'} gap={2} align={'center'} style={{textAlign: 'center'}}>
                            <AlertTriangleIcon />
                            {t('errors:default')}
                        </Flexbox>
                    )}
                </StyledPhotoUploaderFloatingItem>
            </StyledPreviewImageWrapper>

            <FlagsAlertText flags={imageItem?.flags} title={t('commons:photo')} />
        </Flexbox>
    )
}
