import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    IntroImage,
    IntroStepTitle
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import planYourShootImage from '@assets/images/experiences-host-video/planyourshoot.png'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar'
import {Button} from '@components/ui/button/Button.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {Spinner} from '@components/ui/spinner/Spinner'
import toast from 'react-hot-toast'
import {Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'

export const PreparationIntroStep = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()

    const continueUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })

    return (
        <>
            <ExperiencesHostVideoBody gap={14}>
                <IntroImage src={planYourShootImage} alt="step image" />
                <Flexbox direction="column" gap={4}>
                    <IntroStepTitle>{t('experiences_host_video:step_x', {stepNumber: 1})}</IntroStepTitle>
                    <Title>{t('experiences_host_video:plan_shoot')}</Title>
                    <Paragraph>{t('experiences_host_video:preparation_intro:description')}</Paragraph>
                </Flexbox>
            </ExperiencesHostVideoBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Flexbox justify="end" width="100%">
                        <Button
                            onClick={() =>
                                continueUpdateStepMutation.mutate({
                                    status: 'preparation',
                                    step: 'require-prepping-space'
                                })
                            }
                            disabled={continueUpdateStepMutation.isPending}
                        >
                            {t('commons:continue')}
                            {continueUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
