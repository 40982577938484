import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {httpShowExperiencesHostVideoFriend} from '@/features/experiences-host-video/queries/experiences-host-video.http'
import {captureException} from '@sentry/react'
import {ExperiencesHostVideo, ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'

interface UseUpdateStepArgs {
    urlParams: ExperiencesHostVideoRouteParams
    queryOptions?: Omit<UseQueryOptions<unknown, unknown, ExperiencesHostVideo>, 'queryKey' | 'queryFn'>
}
export const useShowExperiencesHostVideoFriend = ({urlParams, queryOptions}: UseUpdateStepArgs) =>
    useQuery<unknown, unknown, ExperiencesHostVideo>({
        queryKey: [
            QUERY_KEYS.EXPERIENCES_HOST_VIDEO_FRIEND,
            urlParams.hostCode,
            urlParams.hostPassword,
            urlParams.experiencesHostVideoId,
            urlParams.taskId
        ],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpShowExperiencesHostVideoFriend({urlParams, params: {response_type: 'extended'}}),
                responseShape: ExperiencesHostVideo,
                onZodError: captureException
            }),
        ...queryOptions
    })
