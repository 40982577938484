import {useAuthStore} from '@/store/AuthStore.tsx'
import {Navigate} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {Helmet} from 'react-helmet'
import {AirbnbSSOWidget} from '@/features/sso/components/airbnb-sso-widget/AirbnbSSOWidget'
import {useTranslation} from '@/translations/i18n.tsx'
import {AirbnbIcon} from '@components/ui/icon'
import {useTheme} from 'styled-components'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {z} from 'zod'
import {StyledServicesDescription} from './style'
import Container from '@/components/ui/container/Container'

export const ServicesLogin = () => {
    const {t} = useTranslation()
    const theme = useTheme()
    const accessToken = useAuthStore(store => store.accessToken)
    const {searchParams} = useParsedSearchParams(z.object({referral_id: z.string().optional()}))
    const redirectUrl = searchParams.referral_id
        ? `${routes.SERVICES.path}?referral_id=${searchParams.referral_id}`
        : routes.SERVICES.path

    if (accessToken) {
        return <Navigate to={redirectUrl} replace />
    }

    return (
        <Container>
            <Helmet title={t('sso_login:page_title_meta')} />

            <AirbnbSSOWidget
                serviceIcon={<AirbnbIcon size={64} fill={theme.palette.primary['600']} />}
                title={t('sso_login:connect_to_airbnb')}
                subtitle={<StyledServicesDescription>{t('sso_login:services_subtitle')}</StyledServicesDescription>}
                redirectUrl={redirectUrl}
            />
        </Container>
    )
}
