import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import styled, {css} from 'styled-components'

export const StyledAnswerItem = styled(AnswerItem)(
    ({theme: {palette, typography}}) => css`
        width: 100%;
        & span {
            font-weight: 400;
            color: ${palette.neutral[600]};
            ${typography.textSm}
        }
    `
)
