import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {Service} from '@/features/host-submissions/services/types.ts'
import {
    httpUpdateStepToLocation,
    HttpUpdateStepToLocationOptions
} from '@/features/host-submissions/services/http/services.http'
import {QUERY_KEYS} from '@/queryClient.ts'

export const useUpdateStepToLocation = <IsBack extends boolean>({
    serviceId,
    expertiseId,
    options
}: {
    serviceId: number
    expertiseId: number
    options?: Omit<
        UseMutationOptions<Service, unknown, HttpUpdateStepToLocationOptions<IsBack>['payload']>,
        'mutationFn'
    >
}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpUpdateStepToLocation({
                    urlParams: {serviceId, expertiseId},
                    params: {response_type: 'extended'},
                    payload
                }),
                responseShape: Service,
                onZodError: captureException
            }),
        ...options,
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.SERVICE, serviceId]})
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE, serviceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
