import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Content, HeadingGroup, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useEffect, useState} from 'react'
import {GalleryItem} from '@/features/host-submissions/types'
import {useGalleryUpload} from '@/features/host-submissions/services/store/servicesGallery.ts'
import {Image01Icon, PlusIcon} from '@components/ui/icon'
import {useDropzone} from 'react-dropzone'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {errorHandler, raise} from '@utilities/helpers.ts'
import {httpUploadFile} from '@services/upload.http.ts'
import toast from 'react-hot-toast'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {MAX_FILE_TO_UPLOAD} from '@/features/host-submissions/services/components/gallery-step/GalleryStep'
import {
    StyledFooterText,
    StyledGalleryDropzone,
    StyledGalleryGrid,
    StyledGalleryTrigger
} from '@/features/host-submissions/experiences/components/enhance-upload-photo-step/style'
import {Footer} from '@/features/host-submissions/components/atoms/Atoms'
import {ConfirmSubmitEnhanceModal} from '@/features/host-submissions/components/confirm-submit-enhance-modal/ConfirmSubmitEnhanceModal'
import {ExperienceExtended} from '../../types'
import {BulkPresignedPhotosResponse, useBulkPresignedPhotos} from '../../queries/useBulkPresignedPhotos'
import {httpConfirmUploadPhoto} from '../../http/experiences.http'
import {ExperienceGalleryImageItem} from '../experience-gallery-image-item/ExperienceGalleryImageItem'
import {useSubmitHostApproval} from '../../queries/useSubmitHostApproval'

export const EnhanceUploadPhotoStep: FC<{
    experience: ExperienceExtended
    onBack: () => void
    minimumImageRequired: number
}> = ({experience, onBack, minimumImageRequired}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitToReviewModalOpen, setIsConfirmSubmitToReviewModalOpen] = useState(false)

    const gallery = useGalleryUpload(store => store.gallery)
    const acceptedFiles = useGalleryUpload(store => store.acceptedFiles)
    const setAcceptedFiles = useGalleryUpload(store => store.setAcceptedFiles)
    const setGalleryItemProgressValue = useGalleryUpload(store => store.setGalleryItemProgressValue)
    const addGalleryItems = useGalleryUpload(store => store.addGalleryItems)
    const setGalleryItemStatus = useGalleryUpload(store => store.setGalleryItemStatus)
    const setGalleryItemSrc = useGalleryUpload(store => store.setGalleryItemSrc)
    const galleryCounter = useGalleryUpload(store => store.galleryCounter)
    const clearGallery = useGalleryUpload(store => store.clearGallery)

    const submitExperienceMutation = useSubmitHostApproval({
        experienceId: experience.id,
        options: {onError: errorHandler}
    })

    useEffect(() => {
        return () => clearGallery()
    }, [])

    const onSuccess = (data: BulkPresignedPhotosResponse) => {
        const galleryItems: GalleryItem[] = data.map((value, index) => ({
            id: value.media.id,
            progressValue: 0,
            status: 'pending',
            blob: acceptedFiles[index],
            src: URL.createObjectURL(acceptedFiles[index]),
            presignedUrl: value.presignedUrl
        }))
        addGalleryItems(galleryItems)
        setAcceptedFiles([])
        void uploadFiles(galleryItems)
    }

    const bulkPresignedQuery = useBulkPresignedPhotos({
        urlParams: {experienceId: experience.id, galleryId: experience?.gallery?.id ?? raise('galleryId is nullish')},
        options: {
            onSuccess: onSuccess
        }
    })

    const dropzoneState = useDropzone({
        disabled: bulkPresignedQuery.isPending,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        maxFiles: MAX_FILE_TO_UPLOAD,
        multiple: true,
        onDropAccepted: acceptedFiles => {
            setAcceptedFiles(acceptedFiles)
            bulkPresignedQuery.mutate({medias: acceptedFiles.map(file => file.name)})
        },
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > MAX_FILE_TO_UPLOAD) {
                return toast.error(t('errors:dropzone_max_file', {count: MAX_FILE_TO_UPLOAD}))
            }
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    const uploadFile = async (galleryItem: GalleryItem, uploadNextGroup: () => void) => {
        try {
            await httpUploadFile({
                url: galleryItem.presignedUrl || raise('the presignedUrl is undefined'),
                payload: galleryItem.blob || raise('the file is undefined'),
                onUploadProgress: progressEvent =>
                    setGalleryItemProgressValue(
                        galleryItem.id,
                        progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100
                    )
            })
            const response = await httpConfirmUploadPhoto({
                params: {responseType: 'extended'},
                urlParams: {
                    galleryId: experience?.gallery?.id ?? raise('galleryId is nullish'),
                    experienceId: experience.id,
                    mediaId: galleryItem.id
                }
            })
            setGalleryItemSrc(galleryItem.id, response.data.url)
            setGalleryItemStatus(galleryItem.id, 'success')
        } catch (error) {
            setGalleryItemStatus(galleryItem.id, 'error')
        } finally {
            uploadNextGroup()
        }
    }

    const uploadFiles = (galleryItems: GalleryItem[]) => {
        let filesQueueIndex = 0
        const firstGroupSize = 6
        const othersGroupSize = 3
        let queue = [galleryItems.slice(0, firstGroupSize)]

        for (let index = firstGroupSize; index < galleryItems.length; index += othersGroupSize) {
            queue = queue.concat([galleryItems.slice(index, index + othersGroupSize)])
        }
        const filesQueue = queue

        const uploadFilesGroup = (galleryItems: GalleryItem[]) => {
            galleryItems.map(
                item =>
                    void uploadFile(item, () => {
                        const nextFilesQueue = filesQueue[++filesQueueIndex]
                        if (nextFilesQueue) {
                            uploadFilesGroup(nextFilesQueue)
                        }
                    })
            )
        }

        uploadFilesGroup(filesQueue[0])
    }

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:enhance_gallery_step:upload_more_photo_title')}</Title>
                </HeadingGroup>
                {gallery.length > 0 ? (
                    <>
                        <Flexbox justify={'space-between'} gap={2} align={'center'}>
                            {minimumImageRequired &&
                                (galleryCounter >= minimumImageRequired
                                    ? t('experiences:experience_gallery_step:x_added', {count: galleryCounter})
                                    : t('experiences:experience_gallery_step:upload_at_last_x', {
                                          count: minimumImageRequired - galleryCounter
                                      }))}
                            <Dropzone state={dropzoneState}>
                                <Button variant={'secondary'} disabled={bulkPresignedQuery.isPending}>
                                    {bulkPresignedQuery.isPending ? <Spinner /> : <PlusIcon />}
                                    {t('services:step_gallery:upload_photos')}
                                </Button>
                            </Dropzone>
                        </Flexbox>
                        <StyledGalleryGrid>
                            {gallery.map((item, index) => (
                                <ExperienceGalleryImageItem
                                    key={index}
                                    imageItem={item}
                                    isSelected={false}
                                    onSuccessRetry={onSuccess}
                                    experienceId={experience.id}
                                    galleryId={experience?.gallery?.id ?? raise('galleryId is nullish')}
                                />
                            ))}
                        </StyledGalleryGrid>
                    </>
                ) : (
                    <>
                        <StyledGalleryDropzone state={dropzoneState}>
                            <StyledGalleryTrigger
                                direction="column"
                                gap={1}
                                align="center"
                                justify="center"
                                width={'100%'}
                            >
                                <Flexbox direction="column" gap={4} align="center" justify="center" width={'100%'}>
                                    {bulkPresignedQuery.isPending ? <Spinner size={32} /> : <Image01Icon size={32} />}
                                    <h3>{t('experiences:experience_gallery_step:dropzone_label')}</h3>
                                </Flexbox>

                                <p>
                                    {t('experiences:experience_gallery_step:at_least_x', {
                                        count: minimumImageRequired
                                    })}
                                </p>
                            </StyledGalleryTrigger>
                        </StyledGalleryDropzone>
                    </>
                )}

                <StyledFooterText>{t('experiences:photo_legal_disclaimer')}</StyledFooterText>
            </Content>
            <Footer>
                <Button
                    variant="tertiary"
                    disabled={gallery.some(item => item.status == 'pending') || bulkPresignedQuery.isPending}
                    onClick={onBack}
                >
                    {t('commons:back')}
                </Button>

                <Button
                    onClick={() => setIsConfirmSubmitToReviewModalOpen(true)}
                    disabled={galleryCounter < minimumImageRequired || gallery.some(item => item.status == 'pending')}
                >
                    {t('commons:confirm')}
                </Button>
            </Footer>

            {isConfirmSubmitToReviewModalOpen && (
                <ConfirmSubmitEnhanceModal
                    onClose={() => setIsConfirmSubmitToReviewModalOpen(false)}
                    isSendToReview={true}
                    onSubmit={() => submitExperienceMutation.mutate(undefined)}
                    isLoading={submitExperienceMutation.isPending}
                />
            )}
        </>
    )
}
