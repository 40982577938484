import {Modal} from '@components/ui/modal/Modal.tsx'
import {ModalFooter, ModalHeader, ModalParagraph, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {FC} from 'react'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {StyledBody, StyledTitle} from '@/features/host-submissions/components/continue-edit-modal/style'

export const ContinueEditModal: FC<{onClose: () => void; onContinue: () => void}> = ({onClose, onContinue}) => {
    const {t} = useTranslation()

    return (
        <Modal width={360}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
            </ModalHeader>
            <StyledBody>
                <StyledTitle>{t('services:enhance_your_service_step:continue_edit_modal_title')}</StyledTitle>
                <ModalParagraph>
                    {t('services:enhance_your_service_step:continue_edit_modal_description')}
                </ModalParagraph>
            </StyledBody>
            <ModalFooter justify="space-between">
                <Button variant="tertiary" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
                <Button variant="primary" onClick={onContinue}>
                    {t('services:enhance_your_service_step:continue_edit_modal_cta')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
