import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    FieldTitle,
    FieldDescription,
    AlertBanner,
    HeadingGroup,
    Paragraph
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '@/features/host-submissions/experiences/utils'
import {ExperienceExtended, StepKeyName} from '@/features/host-submissions/experiences/types'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {formatCurrency} from '@/utilities/helpers'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {Divider} from '@/components/ui/divider/Divider'
import {Label} from '@/components/ui/label/Label'
import {Toggle} from '@/components/ui/toggle/Toggle'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {StyledInputPrice} from '@/features/host-submissions/services/components/offering-form-modal/style'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'

const PRICE_LIMIT = 999999

const PricingStepValidationSchema = z
    .object({
        guest_individual_price: z
            .number({required_error: i18n.t('errors:field_required')})
            .positive()
            .max(PRICE_LIMIT, {
                message: i18n.t('services:step_offerings:price_limit', {value: formatCurrency(PRICE_LIMIT)})
            })
            .multipleOf(0.01, {message: i18n.t('services:step_offerings:price_decimal_error')}),
        currency: z
            .string({required_error: i18n.t('errors:field_required')})
            .min(1, {message: i18n.t('errors:field_required')}),
        instance_private_groups: z.boolean().default(false),
        group_minimum_price: z
            .number({required_error: i18n.t('errors:field_required')})
            .positive()
            .max(PRICE_LIMIT, {
                message: i18n.t('services:step_offerings:price_limit', {value: formatCurrency(PRICE_LIMIT)})
            })
            .multipleOf(0.01, {message: i18n.t('services:step_offerings:price_decimal_error')})
            .optional(),
        always_require_minimum_price: z.boolean().default(false)
    })
    .superRefine((values, context) => {
        if (values.instance_private_groups && !values.group_minimum_price) {
            context.addIssue({
                code: 'custom',
                path: ['group_minimum_price'],
                message: i18n.t('errors:field_required')
            })
        }
    })
export type PricingStepValidationSchema = z.infer<typeof PricingStepValidationSchema>

export const ExperiencePricingStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)

    const flagsCount = updatesRequiredSteps.pricing.flatMap(field => field.flags).length
    const guestIndividualPriceFlags = updatesRequiredSteps.pricing.find(
        field => field.questionKeyName == 'guest_individual_price'
    )?.flags
    const groupMinimumPriceFlags = updatesRequiredSteps.pricing.find(
        field => field.questionKeyName == 'group_minimum_price'
    )?.flags
    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.itinerary,
        currentStep: StepKeyName.enum.pricing,
        nextStep: StepKeyName.enum.discounts,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })

    const defaultValues = makeExperiencesFormDefaultValues(experience).pricing

    const form = useForm<PricingStepValidationSchema>({
        resolver: zodResolver(PricingStepValidationSchema),
        defaultValues
    })

    const currency = useWatch({control: form.control, name: 'currency'})
    const groupPricingEnabled = useWatch({control: form.control, name: 'instance_private_groups'})

    const onSubmit = form.handleSubmit(data => {
        changeStep.handleChangeStep(false, data)
    })

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:experience_pricing_step:title')}</Title>
                    <Paragraph>{t('experiences:experience_pricing_step:paragraph')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('commons:x_items_improve', {count: flagsCount})}
                            paragraph={t('experiences:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>

                <Flexbox direction="column" gap={6} width="100%">
                    <Flexbox direction="column" gap={4} width="100%">
                        <Flexbox direction="column" gap={1.5} width="100%">
                            <FieldTitle>{t('experiences:experience_pricing_step:price_per_guest')}</FieldTitle>
                            <FieldDescription>
                                {t('experiences:experience_pricing_step:price_per_guest_hint')}
                            </FieldDescription>
                            <FlagsAlertText
                                flags={guestIndividualPriceFlags}
                                title={t('experiences:experience_pricing_step:price_per_guest')}
                            />
                        </Flexbox>

                        <StyledInputPrice
                            type="number"
                            errorMessage={form.formState.errors.guest_individual_price?.message}
                            typeIcon={<span>{currency}</span>}
                            inputMode="decimal"
                            step="0.01"
                            onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                            {...form.register('guest_individual_price', {
                                setValueAs: value =>
                                    value == '' || value == 0 || value == null ? undefined : parseFloat(value)
                            })}
                        />
                    </Flexbox>

                    <Divider direction="horizontal" />

                    <Flexbox gap={4} direction="column" width="100%">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('experiences:experience_pricing_step:price_private_group')}</FieldTitle>
                            <FieldDescription>
                                {t('experiences:experience_pricing_step:price_private_group_hint')}
                            </FieldDescription>

                            <FlagsAlertText
                                flags={groupMinimumPriceFlags}
                                title={t('experiences:experience_pricing_step:price_private_group')}
                            />
                        </Flexbox>

                        <Flexbox gap={4}>
                            <Controller
                                control={form.control}
                                name="instance_private_groups"
                                render={({field}) => (
                                    <Toggle
                                        value={field.value}
                                        role="switch"
                                        aria-checked={field.value}
                                        aria-label={t('experiences:experience_pricing_step:switch_label')}
                                        onChange={event => {
                                            field.onChange(event)
                                            form.resetField('group_minimum_price', undefined)
                                            form.setValue('group_minimum_price', undefined)
                                            form.clearErrors(['group_minimum_price'])
                                        }}
                                    />
                                )}
                            />
                            <Label>{t('experiences:experience_pricing_step:switch_label')}</Label>
                        </Flexbox>

                        {groupPricingEnabled && (
                            <>
                                <Flexbox direction="column" gap={1.5} width="100%">
                                    <StyledInputPrice
                                        type="number"
                                        step="0.01"
                                        label={t('experiences:experience_pricing_step:minimum_price')}
                                        errorMessage={form.formState.errors.group_minimum_price?.message}
                                        inputMode="decimal"
                                        typeIcon={<span>{currency}</span>}
                                        onKeyDown={evt =>
                                            ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                                        }
                                        {...form.register('group_minimum_price', {
                                            setValueAs: value =>
                                                value == '' || value == 0 || value == null
                                                    ? undefined
                                                    : parseFloat(value)
                                        })}
                                    />

                                    <InputHelpText
                                        helpText={t('experiences:experience_pricing_step:minimum_price_hint')}
                                    />
                                </Flexbox>

                                <Checkbox
                                    id="always_require_minimum_price"
                                    label={
                                        <Flexbox direction="column">
                                            <Label>
                                                {t('experiences:experience_pricing_step:require_minimum_price')}
                                            </Label>
                                            <InputHelpText
                                                helpText={t(
                                                    'experiences:experience_pricing_step:private_groups_default'
                                                )}
                                            />
                                        </Flexbox>
                                    }
                                    {...form.register('always_require_minimum_price')}
                                />
                                <Divider direction="horizontal" startSpacing={2} endSpacing={2} />
                            </>
                        )}
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('pricing')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>

                {changeStep.hasNextStep ? (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button onClick={onSubmit}>{t('commons:confirm')}</Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
