import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Content, Title, Paragraph, HeadingGroup} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useEffect, useMemo, useState} from 'react'
import {ExperienceExtended, StepKeyName, UploadedMedia} from '@/features/host-submissions/experiences/types'
import {Footer} from '@/features/host-submissions/components/atoms/Atoms'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {ConfirmSubmitEnhanceModal} from '@/features/host-submissions/components/confirm-submit-enhance-modal/ConfirmSubmitEnhanceModal'
import {errorHandler, raise} from '@/utilities/helpers'
import {hasRelevantFieldChanges} from '../../utils'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {MINIMUM_IMAGES_REQUIRED} from '../experience-gallery-step/ExperienceGalleryStep'
import {StyledEmptyState, StyledGalleryGrid} from './style'
import {ImageXIcon} from '@/components/ui/icon'
import {AdditionalPhotoItem} from '@/features/host-submissions/components/additional-photo-item/AdditionalPhotoItem'
import {useDeclineAdditionalPhotos} from '../../queries/useDeclineAdditionalPhotos'
import {useAcceptAdditionalPhotos} from '../../queries/useAcceptAdditionalPhotos'
import {EnhanceUploadPhotoStep} from '../enhance-upload-photo-step/EnhanceUploadPhotoStep'
import {useGetAdditionalPhotos} from '../../queries/useGetAdditionalPhotos'
import {useSubmitHostApproval} from '../../queries/useSubmitHostApproval'
import {StyledFooterText} from '../enhance-upload-photo-step/style'

export const EnhanceGalleryStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitToReviewModalOpen, setIsConfirmSubmitToReviewModalOpen] = useState(false)
    const [selectedPhotos, setSelectedPhotos] = useState<UploadedMedia[]>([])
    const [deletedPhotos, setDeletedPhotos] = useState<UploadedMedia[]>([])

    const additionalPhotosQuery = useGetAdditionalPhotos({
        experienceId: experience.id,
        galleryId: experience?.gallery?.id ?? raise('galleryId is nullish')
    })

    const [isUploadMorePhotoShown, setIsUploadMorePhotoShown] = useState(false)

    useEffect(() => {
        const images = additionalPhotosQuery.data || []

        setSelectedPhotos(images ?? [])
    }, [additionalPhotosQuery.data])

    const approvedGalleryPhotos =
        experience.gallery?.images.filter(
            img => !img?.parentFlags?.length && (img?.isHostApproved == null || !!img?.isHostApproved)
        ) ?? []

    const totalImages = approvedGalleryPhotos.length

    const submitExperienceMutation = useSubmitHostApproval({
        experienceId: experience.id,
        options: {onError: errorHandler}
    })

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.enhance_about_you,
        currentStep: StepKeyName.enum.enhance_gallery
    })

    const isPrevStepChanged = useMemo(() => {
        const remappedActivities = experience.itineraries
            ? experience.itineraries?.map(activity => activity.answers)
            : []
        const activitiesChanged = hasRelevantFieldChanges(remappedActivities, [
            'improved_title',
            'improved_description',
            'main_photo_url'
        ])
        const answersChanged = experience
            ? hasRelevantFieldChanges(
                  [experience.expertise.answers],
                  [
                      'improved_host_experience_title',
                      'improved_host_expertise_description',
                      'improved_host_recognition_title',
                      'improved_host_recognition_subtitle',
                      'improved_experience_title',
                      'improved_experience_description'
                  ]
              )
            : false

        const hasNewGalleryPhoto = experience.gallery?.images.some(image => !image?.parentId)
        return activitiesChanged || answersChanged || !!hasNewGalleryPhoto
    }, [experience])
    const updateEnhanceStepSuccess = () => {
        if (totalImages < MINIMUM_IMAGES_REQUIRED) {
            setIsUploadMorePhotoShown(true)
        } else {
            setIsConfirmSubmitToReviewModalOpen(true)
        }
    }

    const declineAdditionalPhotosMutation = useDeclineAdditionalPhotos({
        experienceId: experience.id,
        galleryId: experience?.gallery?.id ?? raise('galleryId is nullish'),
        mutationOptions: {onError: errorHandler, onSuccess: updateEnhanceStepSuccess}
    })

    const acceptAdditionalPhotosMutation = useAcceptAdditionalPhotos({
        experienceId: experience.id,
        galleryId: experience?.gallery?.id ?? raise('galleryId is nullish'),
        mutationOptions: {
            onError: errorHandler,
            onSuccess: () =>
                deletedPhotos.length > 0
                    ? declineAdditionalPhotosMutation.mutate({images: deletedPhotos.map(img => img.id)})
                    : updateEnhanceStepSuccess()
        }
    })

    const onContinue = () => {
        if (selectedPhotos.length > 0) {
            acceptAdditionalPhotosMutation.mutate({images: selectedPhotos.map(img => img.id)})
        } else if (deletedPhotos.length > 0) {
            declineAdditionalPhotosMutation.mutate({images: deletedPhotos.map(img => img.id)})
        } else {
            updateEnhanceStepSuccess()
        }
    }

    const onDelete = (id: number) => {
        setSelectedPhotos(prevSelected => prevSelected.filter(photo => photo.id !== id))

        setDeletedPhotos(prevDeleted => {
            if (prevDeleted.some(photo => photo.id === id)) return prevDeleted
            const photoToAdd = selectedPhotos.find(photo => photo.id === id)
            return photoToAdd ? [...prevDeleted, photoToAdd] : prevDeleted
        })
    }

    if (additionalPhotosQuery.isLoading)
        return (
            <Flexbox align="center" justify="center">
                <Spinner size={32} />
            </Flexbox>
        )
    return isUploadMorePhotoShown ? (
        <EnhanceUploadPhotoStep
            experience={experience}
            onBack={() => setIsUploadMorePhotoShown(false)}
            minimumImageRequired={MINIMUM_IMAGES_REQUIRED - totalImages}
        />
    ) : (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:enhance_gallery_step:title')}</Title>
                    <Paragraph>{t('experiences:enhance_gallery_step:description')}</Paragraph>
                </HeadingGroup>

                {selectedPhotos.length > 0 ? (
                    <StyledGalleryGrid>
                        {selectedPhotos.map(item => (
                            <AdditionalPhotoItem key={item.id} image={item} onDelete={() => onDelete(item.id)} />
                        ))}
                    </StyledGalleryGrid>
                ) : (
                    <StyledEmptyState direction="column" gap={3} align="center" justify="center" width={'100%'}>
                        <ImageXIcon size={32} />
                        <p>{t('experiences:enhance_gallery_step:empty_state_selected_photo')}</p>
                    </StyledEmptyState>
                )}

                <StyledFooterText>{t('experiences:photo_legal_disclaimer')}</StyledFooterText>
            </Content>
            <Footer>
                <Button
                    variant="tertiary"
                    disabled={
                        changeStep.isBackLoading ||
                        acceptAdditionalPhotosMutation.isPending ||
                        declineAdditionalPhotosMutation.isPending
                    }
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>

                <Button
                    onClick={onContinue}
                    disabled={
                        changeStep.isBackLoading ||
                        acceptAdditionalPhotosMutation.isPending ||
                        declineAdditionalPhotosMutation.isPending
                    }
                >
                    {t('commons:continue')}
                    {(acceptAdditionalPhotosMutation.isPending || declineAdditionalPhotosMutation.isPending) && (
                        <Spinner />
                    )}
                </Button>
            </Footer>

            {isConfirmSubmitToReviewModalOpen && (
                <ConfirmSubmitEnhanceModal
                    onClose={() => setIsConfirmSubmitToReviewModalOpen(false)}
                    isSendToReview={isPrevStepChanged}
                    onSubmit={() => submitExperienceMutation.mutate(undefined)}
                    isLoading={submitExperienceMutation.isPending}
                />
            )}
        </>
    )
}
