import styled, {css} from 'styled-components'

export const StyledList = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: ${spacing * 3}px;
        width: 100%;
    `
)

export const StyledLinkField = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        gap: ${spacing}px;
        align-items: flex-end;
    `
)
