import {useChatbotStore} from '@/features/chatbot/store/chatbotStore.ts'
import {chatHighlights} from '@/features/chatbot/utils.tsx'
import {useEffect} from 'react'
import {httpSubmitLog} from '@/features/chatbot/http/submitLog.http'
import {errorHandler, formatLocaleDate} from '@utilities/helpers.ts'
import {AxiosError} from 'axios'

export const SubmitLog = () => {
    const service = useChatbotStore(state => state.service)
    const chatHistory = useChatbotStore(state => state.chatHistory)
    const entity = service?.entity
    const flow = chatHistory.map(({id}) => id).join(', ') // whole conversation (ids)
    const recap = chatHistory.map(({message}) => message).join(' --- ') // whole conversation (messages)
    const highlights = chatHighlights(chatHistory) // A string containing the highlights of the session: all actions taken via API calls
    // A boolean indicating if a ticket has been opened in this session or not
    const isTicketOpen =
        flow.includes('HP0_CONFIRMATION') ||
        flow.includes('HP4.1.3') ||
        flow.includes('HP8_2_send') ||
        flow.includes('HP8_3_send') ||
        flow.includes('HP8_4_send') ||
        flow.includes('HP9_sent') ||
        flow.includes('HP10.3') ||
        flow.includes('HP11_submit') ||
        flow.includes('HP31_ticket')

    useEffect(() => {
        onSubmitLogResults()
    }, [])

    const onSubmitLogResults = async () => {
        if (!service) return
        try {
            await httpSubmitLog({
                code: String(entity?.code),
                password: String(entity?.password),
                media: Number(service?.task_media.id),
                data: {
                    flow,
                    recap,
                    highlights,
                    ticket: isTicketOpen,
                    thread_id: `${service?.quote.id}-${formatLocaleDate(new Date(), 'lll')}`
                }
            })
        } catch (e) {
            errorHandler(e as AxiosError<{message: string}>)
        }
    }
    return <div />
}

SubmitLog.displayName = 'SubmitLog'
