import {useMutation, useQueryClient} from '@tanstack/react-query'
import {MUTATION_KEYS, QUERY_KEYS} from '@/queryClient'
import {httpCancelService} from '@/features/service-cancel/http/serviceCancellation.http'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons'

type useServiceCancellationProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useServiceCancellation = (options?: useServiceCancellationProps) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationKey: [MUTATION_KEYS.SERVICE_CANCELLATION],
        mutationFn: httpCancelService,
        onError: options?.onError,
        onSuccess: async () => {
            options?.onSuccess?.()
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE_DETAILS]})
        }
    })
}
