import {ButtonArea} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    StyledAlertIconWrapper,
    StyledEditAlertWrapper
} from '@/features/host-submissions/components/restore-fields-banner/style'
import {AlertCircleIcon} from '@/components/ui/icon'
import {Spinner} from '@/components/ui/spinner/Spinner'

export const RestoreFieldsBanner = ({
    onRestore,
    isLoading,
    hideRestoreAction = false
}: {
    onRestore: () => void
    isLoading: boolean
    hideRestoreAction?: boolean
}) => {
    const {t} = useTranslation()

    return (
        <ButtonArea padding={4} fullWidth borderRadius={12} style={{cursor: 'unset'}}>
            <Flexbox gap={3}>
                <StyledAlertIconWrapper justify="center" align="center">
                    <AlertCircleIcon size={20} />
                </StyledAlertIconWrapper>

                <StyledEditAlertWrapper gap={2} direction="column">
                    <Flexbox gap={1} direction="column">
                        <h4>{t('host_review:restore_fields_banner:title')}</h4>
                        <p>{t('host_review:restore_fields_banner:note')}</p>
                    </Flexbox>

                    {!hideRestoreAction && (
                        <Button shape="text" variant="tertiary" onClick={onRestore}>
                            {t('host_review:restore_fields_banner:action')}
                            {isLoading && <Spinner />}
                        </Button>
                    )}
                </StyledEditAlertWrapper>
            </Flexbox>
        </ButtonArea>
    )
}
