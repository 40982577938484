import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps
} from '@/features/host-submissions/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldTitle,
    Footer,
    Title,
    AlertBanner,
    FieldDescription
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Service} from '@/features/host-submissions/services/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import TextArea from '@components/commons/textarea/TextArea.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {z} from 'zod'
import {useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {useUpdateStepToHostExpertiseCareer} from '@/features/host-submissions/services/queries/useUpdateStepToHostExpertiseCareer'
import {useUpdateStepToHostExpertiseAboutYourself} from '@/features/host-submissions/services/queries/useUpdateStepToHostExpertiseAboutYourself'
import {errorHandler} from '@utilities/helpers.ts'
import InputText from '@components/commons/input-text/InputText.tsx'
import {ConfirmSubmitModal} from '@/features/host-submissions/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {FlagsAlertText} from '../../../components/flags-alert-text/FlagsAlertText'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

const INSPIRATION_MAX_LENGTH = 60
const BACKGROUND_MAX_LENGTH = 150

const ValidationSchema = z.object({
    inspiration: z
        .string()
        .min(1, {message: i18n.t('errors:field_required')})
        .max(INSPIRATION_MAX_LENGTH),
    background: z
        .string()
        .min(1, {message: i18n.t('errors:field_required')})
        .max(BACKGROUND_MAX_LENGTH)
})
type ValidationSchema = z.infer<typeof ValidationSchema>

export const HostExpertiseExpertiseStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const inspirationFlags = updatesRequiredSteps.expertise.find(field => field.questionKeyName == 'inspiration')?.flags
    const backgroundFlags = updatesRequiredSteps.expertise.find(field => field.questionKeyName == 'background')?.flags
    const flagsCount = updatesRequiredSteps.expertise.flatMap(field => field.flags).length
    const updateStepToHostExpertiseCareerMutation = useUpdateStepToHostExpertiseCareer<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateStepToHostExpertiseAboutYourselfMutation = useUpdateStepToHostExpertiseAboutYourself<false>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'expertise', updatesRequiredSteps}).prev?.()
            : updateStepToHostExpertiseCareerMutation
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'expertise'>({service, currentStep: 'expertise', updatesRequiredSteps}).next?.()
            : updateStepToHostExpertiseAboutYourselfMutation

    const form = useForm<ValidationSchema>({
        resolver: zodResolver(ValidationSchema),
        defaultValues: service.expertise?.question_answer.reduce(
            (previousValue, currentValue) => {
                if (currentValue.question_key_name == 'inspiration' && currentValue.answer) {
                    return {...previousValue, inspiration: currentValue.answer}
                }
                if (currentValue.question_key_name == 'background' && currentValue.answer) {
                    return {...previousValue, background: currentValue.answer}
                }
                return previousValue
            },
            {
                inspiration: '',
                background: ''
            }
        )
    })
    const [inspirationWatch, backgroundWatch] = useWatch({
        control: form.control,
        name: ['inspiration', 'background']
    })

    const onSubmit = form.handleSubmit(formValues => nextStepMutation?.mutate(formValues))

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:about_you')}</Title>
                    <Paragraph>{t('services:tell_about_experience')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('services:x_items_improve', {count: flagsCount})}
                            paragraph={t('services:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={6} align="stretch">
                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_host_expertise_expertise:question1_title')}</FieldTitle>
                            <FieldDescription>
                                {t('services:step_host_expertise_expertise:question1_description')}
                            </FieldDescription>
                            <FlagsAlertText
                                flags={inspirationFlags}
                                title={t('services:step_host_expertise_expertise:question1_title')}
                            />
                        </Flexbox>
                        <InputText
                            type={'text'}
                            maxLength={INSPIRATION_MAX_LENGTH}
                            helpText={t('services:x_characters_available', {
                                count: INSPIRATION_MAX_LENGTH - inspirationWatch.length
                            })}
                            placeholder={t('services:step_host_expertise_expertise:question1_placeholder')}
                            errorMessage={
                                form.formState.errors.inspiration?.message ||
                                (inspirationWatch.length > INSPIRATION_MAX_LENGTH
                                    ? t('errors:characters_over_limit', {
                                          count: inspirationWatch.length - INSPIRATION_MAX_LENGTH
                                      })
                                    : '')
                            }
                            {...form.register('inspiration')}
                        />
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} align="stretch">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('services:step_host_expertise_expertise:question2_title')}</FieldTitle>
                            <FieldDescription>
                                {t('services:step_host_expertise_expertise:question2_description')}
                            </FieldDescription>
                            <FlagsAlertText
                                flags={backgroundFlags}
                                title={t('services:step_host_expertise_expertise:question2_title')}
                            />
                        </Flexbox>
                        <TextArea
                            rows={6}
                            maxLength={BACKGROUND_MAX_LENGTH}
                            helpText={t('services:x_characters_available', {
                                count: BACKGROUND_MAX_LENGTH - backgroundWatch.length
                            })}
                            placeholder={t('services:step_host_expertise_expertise:question2_placeholder')}
                            errorMessage={
                                form.formState.errors.background?.message ||
                                (backgroundWatch.length > BACKGROUND_MAX_LENGTH
                                    ? t('errors:characters_over_limit', {
                                          count: backgroundWatch.length - BACKGROUND_MAX_LENGTH
                                      })
                                    : '')
                            }
                            {...form.register('background')}
                        />
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('expertise')}>
                <Button
                    variant="tertiary"
                    disabled={nextStepMutation?.isPending || prevStepMutation?.isPending}
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button disabled={nextStepMutation.isPending || prevStepMutation?.isPending} onClick={onSubmit}>
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={prevStepMutation?.isPending} onClick={() => setIsConfirmSubmitOpen(true)}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'expertise'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={form.getValues()}
                />
            )}
        </>
    )
}
