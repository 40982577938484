import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {httpAcceptAdditionalPhotos} from '@/features/host-submissions/services/http/services.http'
import {QUERY_KEYS} from '@/queryClient.ts'

interface UseDeletePhotos {
    serviceId: number
    mutationOptions?: Omit<UseMutationOptions<unknown, unknown, {images: number[]}>, 'mutationFn'>
}

export const useAcceptAdditionalPhotos = ({serviceId, mutationOptions}: UseDeletePhotos) => {
    const queryClient = useQueryClient()
    return useMutation<unknown, unknown, {images: number[]}>({
        mutationFn: ({images}: {images: number[]}) =>
            httpAcceptAdditionalPhotos({
                urlParams: {serviceId},
                params: {
                    response_type: 'extended'
                },
                payload: {images}
            }),
        ...mutationOptions,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICES_GALLERY]})
            mutationOptions?.onSuccess?.(data, variables, context)
        }
    })
}
