import {z} from 'zod'
import {Countries} from 'country-to-currency'
import {GalleryItem, ParentFlag, ServiceStatus} from '@/features/host-submissions/types'
import {TransportingGuestsMode} from '../experiences/types'

export const UploadedMedia = z.object({
    id: z.number(),
    parent_flags: z.array(ParentFlag),
    url: z.string().url(),
    is_host_approved: z.boolean().nullable().optional(),
    parent_id: z.number().nullable().optional()
})
export type UploadedMedia = z.infer<typeof UploadedMedia>

export const OfferingTypes = z.enum(['standard', 'basic', 'luxe', 'exclusive', 'other'])
export type OfferingTypes = z.infer<typeof OfferingTypes>

export const OfferingQuestionKeyName = z.enum([
    'currency',
    'description',
    'duration',
    'price_per_guest',
    'title',
    'main_photo',
    'price_per_group',
    'meeting_point',
    'improved_description',
    'improved_title'
])
export type OfferingQuestionKeyName = z.infer<typeof OfferingQuestionKeyName>

export const Offering = z.object({
    id: z.number().positive(),
    main_photo_url: z.string().url().nullable(),
    type: OfferingTypes,
    meeting_point: z.array(z.enum(['they_travel_to_you', 'you_travel_to_them'])).nullable(),
    question_answer: z
        .object({
            id: z.number().positive(),
            answer: z.string(),
            previous_answer: z.string().nullable(),
            parent_flags: z.array(ParentFlag),
            question_key_name: OfferingQuestionKeyName,
            gallery_image: z
                .object({
                    id: GalleryItem.shape['id'],
                    url: z.string().url()
                })
                .nullable()
        })
        .array()
})
export type Offering = z.infer<typeof Offering>

const ServiceBase = z.object({
    address: z.string().nullable(),
    country: z
        .custom<Countries>(value => {
            return typeof value === 'string'
        })
        .nullable(),
    city: z.string().nullable(),
    created_at: z.string().min(1),
    zip_code: z.string().nullable(),
    state: z.string().nullable(),
    latitude: z.string().nullable(),
    longitude: z.string().nullable(),
    id: z.number().int().positive(),
    status: ServiceStatus,
    minimum_image_required: z.number().nullable(),
    is_upgrade: z.boolean().optional(),
    experience_submission_id: z.number().nullable(),
    is_visible_to_host: z.coerce.boolean()
})

export const ServiceRegular = ServiceBase.extend({
    title: z.string().nullable()
})

export const Service = ServiceBase.extend({
    submission_places: z.array(
        z.object({
            place: z.object({
                id: z.number().positive().int(),
                name: z.string(),
                airbnb_place_id: z.string()
            })
        })
    ),
    submission_cities: z.array(
        z.object({
            city: z.object({
                id: z.number().positive().int(),
                name: z.string(),
                country: z.custom<Countries>(value => {
                    return typeof value === 'string'
                }),
                country_iso: z.string(),
                state: z.string().nullable()
            })
        })
    ),
    geo: z
        .object({
            country: z.custom<Countries>(value => {
                return typeof value === 'string'
            })
        })
        .nullable(),
    category: z
        .object({
            key_name: z.enum([
                'photography',
                'boudoir_photography',
                'private_chef',
                'prepared_meals',
                'catering',
                'personal_training',
                'massage',
                'spa_treatments',
                'haircare',
                'makeup',
                'nails',
                'generic' //TODO remove 'generic' when the category step is enabled again
            ])
        })
        .nullable(),
    questions: z.array(
        z.object({
            key_name: z.enum([
                'experience_years',
                'summary',
                'highlights',
                'hosting_modality',
                'co_hosts',
                'inspiration',
                'background',
                'accolades',
                'category',
                'career',
                'expertise',
                'background',
                'about_yourself',
                'location',
                'location_notes',
                'age_limit',
                'profile_photo',
                'age',
                'bring_anything',
                'what_bring',
                'language',
                'main_language',
                'extra_languages',
                'group_min_size',
                'group_max_size',
                'main_language',
                'group_min_size',
                'group_max_size',
                'host_expertise',
                'host_experience_title',
                'service_description',
                'host_experience_description',
                'host_education_title',
                'host_education_description',
                'host_career_highlight_title',
                'host_career_highlight_description',
                'service_title',
                'business_type',
                'links',
                'extra_languages',
                'cities',
                'cancellation_policy',
                'host_serve_food',
                'host_serve_alcohol',
                'service_in_national_park',
                'host_transports_guests',
                'host_transporting_guests_method',

                //
                'currency',
                'description',
                'duration',
                'price_per_guest',
                'title',
                'main_photo',
                'price_per_group',
                'meeting_point',
                'improved_description',
                'improved_title'
            ])
        })
    ),
    expertise: z.object({
        id: z.number().positive(),
        question_answer: z.array(
            z
                .object({
                    parent_flags: z.array(ParentFlag)
                })
                .and(
                    z
                        .object({
                            answer: z.string(),
                            previous_answer: z.string().nullable(),
                            question_key_name: z.enum([
                                'experience_years',
                                'summary',
                                'highlights',
                                'hosting_modality',
                                'co_hosts',
                                'inspiration',
                                'background',
                                'accolades',
                                'category',
                                'career',
                                'expertise',
                                'background',
                                'about_yourself',
                                'location',
                                'location_notes',
                                'age_limit',
                                'profile_photo',
                                'age',
                                'bring_anything',
                                'what_bring',
                                'language',
                                'main_language',
                                'extra_languages',
                                'group_min_size',
                                'group_max_size',
                                'main_language',
                                'group_min_size',
                                'group_max_size',
                                'host_expertise',
                                'host_experience_title',
                                'service_description',
                                'host_experience_description',
                                'host_education_title',
                                'host_education_description',
                                'host_career_highlight_title',
                                'host_career_highlight_description',
                                'service_title',
                                'host_serve_alcohol',
                                'host_serve_food',
                                'service_in_national_park',
                                'host_transports_guests',
                                'host_transporting_guests_method'
                            ])
                        })
                        .or(
                            z.object({
                                answer: z.enum(['individual', 'business']),
                                previous_answer: z.enum(['individual', 'business']).nullable(),
                                question_key_name: z.literal('business_type')
                            })
                        )
                        .or(
                            z.object({
                                answer: z.string().array(),
                                previous_answer: z.string().nullable(),
                                question_key_name: z.enum([
                                    'links',
                                    'extra_languages',
                                    'cities',
                                    'host_transporting_guests_method'
                                ])
                            })
                        )
                        .or(
                            z.object({
                                answer: z.enum(['24_hours', '7_days']),
                                previous_answer: z.enum(['24_hours', '7_days']).nullable(),
                                question_key_name: z.literal('cancellation_policy')
                            })
                        )
                        .or(
                            z.object({
                                answer: TransportingGuestsMode.array(),
                                previous_answer: TransportingGuestsMode.array().nullable(),
                                question_key_name: z.literal('host_transporting_guests_method')
                            })
                        )
                )
        )
    }),
    step: z
        .object({
            step: z.enum(['get_started', 'offerings', 'service_location', 'gallery', 'suggestions']),
            sub_step: z.null()
        })
        .or(
            z.object({
                step: z.literal('host_expertise'),
                sub_step: z.enum(['category', 'career', 'expertise', 'about_yourself'])
            })
        )
        .or(
            z.object({
                step: z.literal('other_details'),
                sub_step: z.enum(['about_guests', 'more_details', 'legal', 'terms'])
            })
        )
        .or(
            z.object({
                step: z.literal('enhance'),
                sub_step: z.enum(['about_service', 'about_you', 'gallery'])
            })
        ),
    host: z.object({
        profile_photo_url: z.string().url().nullable(),
        airbnb_verified_phone: z.string().min(1).nullable()
    }),
    offerings: z.array(Offering),
    gallery: z
        .object({
            parent_flags: z.array(ParentFlag),
            images: UploadedMedia.array()
        })
        .nullable()
})
export type Service = z.infer<typeof Service>

export type Coordinates = {
    latitude: string
    longitude: string
}

export const City = z.object({
    id: z.number().positive().int(),
    name: z.string(),
    state: z.string().nullable(),
    country: z.string()
})
export type City = z.infer<typeof City>

export const Country = z.object({
    country: z.string(),
    country_iso: z.string()
})
export type Country = z.infer<typeof Country>

export const Place = z.object({
    placeId: z.string(),
    name: z.string()
})
export type Place = z.infer<typeof Place>
