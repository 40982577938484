import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {ExperienceExtended, StepKeyName} from '../types'
import {httpUpdateStep, httpEditExperienceOptions, EditExperienceAnswers} from '../http/experiences.http'

export const useEditExperience = <Step extends keyof EditExperienceAnswers>({
    experienceId,
    options
}: {
    experienceId: number
    step: StepKeyName
    options?: Omit<
        UseMutationOptions<ExperienceExtended, unknown, httpEditExperienceOptions<Step>['payload']>,
        'mutationFn'
    >
}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: payload =>
            httpUpdateStep<Step>({
                urlParams: {experienceId},
                params: {responseType: 'extended'},
                payload
            }),
        ...options,
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
