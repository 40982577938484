import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledGalleryGrid = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: ${spacing * 4}px;
        ${mediaQueries.m} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    `
)

export const StyledEmptyState = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        border: 1px solid ${palette.neutral['300']};
        padding: ${spacing * 4}px;
        border-radius: 16px;
        height: 180px;
        text-align: center;
        & svg {
            fill: ${palette.neutral['600']};
        }

        & p {
            font-weight: 500;
            max-width: 270px;
        }
    `
)
