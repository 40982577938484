import {getStepProgressPercentage} from '@/features/host-submissions/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    Footer,
    FieldTitle,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useWatch, useForm} from 'react-hook-form'
import {errorHandler} from '@utilities/helpers.ts'
import {useUpdateStepToOtherDetailsMoreDetails} from '@/features/host-submissions/services/queries/useUpdateStepToOtherDetailsMoreDetails'
import {Service} from '@/features/host-submissions/services/types.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useUpdateStepToOtherDetailsTerms} from '../../queries/useUpdateStepToOtherDetailsTerms'
import {z} from 'zod'
import {TransportingGuestsMode} from '@/features/host-submissions/experiences/types'
import {zodResolver} from '@hookform/resolvers/zod'
import {StyledList} from './style'
import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Divider} from '@/components/ui/divider/Divider'
import {useUpdateStepToGallery} from '../../queries/useUpdateStepToGallery'

export const REQUIRED_QUESTIONS_FOR_LEGAL_STEP = [
    'host_serve_food',
    'host_serve_alcohol',
    'service_in_national_park',
    'host_transports_guests',
    'host_transporting_guests_method'
]

export const ServiceLegalStepValidationSchema = z
    .object({
        host_serve_food: z.enum(['yes', 'no'], {
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        host_serve_alcohol: z.enum(['yes', 'no'], {
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        service_in_national_park: z.enum(['yes', 'no'], {
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        host_transports_guests: z.enum(['yes', 'no'], {
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        host_transporting_guests_method: TransportingGuestsMode.array().default([])
    })
    .superRefine((arg, context) => {
        if (arg.host_transports_guests == 'yes' && !arg.host_transporting_guests_method?.length) {
            context.addIssue({
                code: 'custom',
                path: ['host_transporting_guests_method'],
                message: i18n.t('errors:field_required')
            })
        }
    })

export type ServiceLegalStepValidationSchema = z.infer<typeof ServiceLegalStepValidationSchema>

export const OtherDetailsLegalStep: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const updateStepToGalleryMutation = useUpdateStepToGallery({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateStepToOtherDetailsMoreDetails = useUpdateStepToOtherDetailsMoreDetails<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateStepToOtherDetailsTherms = useUpdateStepToOtherDetailsTerms({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })

    const prevStepMutation = service?.is_upgrade ? updateStepToGalleryMutation : updateStepToOtherDetailsMoreDetails

    const defaultValues = service.expertise?.question_answer.reduce((previousValue, currentValue) => {
        if (currentValue.question_key_name == 'host_serve_food' && currentValue.answer) {
            return {...previousValue, [currentValue.question_key_name]: currentValue.answer}
        }
        if (currentValue.question_key_name == 'host_serve_alcohol' && currentValue.answer) {
            return {...previousValue, [currentValue.question_key_name]: currentValue.answer}
        }
        if (currentValue.question_key_name == 'service_in_national_park' && currentValue.answer) {
            return {...previousValue, [currentValue.question_key_name]: currentValue.answer}
        }
        if (currentValue.question_key_name == 'host_transports_guests' && currentValue.answer) {
            return {...previousValue, [currentValue.question_key_name]: currentValue.answer}
        }
        if (currentValue.question_key_name == 'host_transporting_guests_method' && currentValue.answer) {
            return {...previousValue, [currentValue.question_key_name]: currentValue.answer}
        }
        return previousValue
    }, {})
    const form = useForm<ServiceLegalStepValidationSchema>({
        resolver: zodResolver(ServiceLegalStepValidationSchema),
        mode: 'all',
        reValidateMode: 'onSubmit',
        defaultValues
    })

    const [isTransportingGuestsWatch] = useWatch({
        control: form.control,
        name: ['host_transports_guests']
    })
    const onSubmit = form.handleSubmit(data => {
        const dataToSend = {
            ...data,
            host_transporting_guests_method:
                data.host_transports_guests == 'yes' ? data.host_transporting_guests_method : undefined
        }
        updateStepToOtherDetailsTherms?.mutate(dataToSend)
    })

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_other_details_more_details:title')}</Title>
                    <Paragraph>{t('services:services_legal_step:description')}</Paragraph>
                </HeadingGroup>

                {/* food */}
                <Flexbox direction="column" gap={4} align="stretch">
                    <FieldTitle>{t('services:services_legal_step:question1_label')}</FieldTitle>
                    <StyledList>
                        <AnswerItem
                            type="radio"
                            label={
                                <Flexbox align="center" justify="center" gap={2}>
                                    {t('commons:yes')}
                                </Flexbox>
                            }
                            value={'yes'}
                            {...form.register('host_serve_food')}
                        />
                        <AnswerItem
                            type="radio"
                            label={
                                <Flexbox align="center" justify="center" gap={2}>
                                    {t('commons:no')}
                                </Flexbox>
                            }
                            value={'no'}
                            {...form.register('host_serve_food')}
                        />
                    </StyledList>

                    {form.formState.errors.host_serve_food && (
                        <InputHelpText error={form.formState.errors.host_serve_food?.message} />
                    )}
                </Flexbox>

                <Divider direction="horizontal" />

                {/* alcohol */}
                <Flexbox direction="column" gap={4} align="stretch">
                    <FieldTitle>{t('services:services_legal_step:question2_label')}</FieldTitle>
                    <StyledList>
                        <AnswerItem
                            type="radio"
                            label={
                                <Flexbox align="center" justify="center" gap={2}>
                                    {t('commons:yes')}
                                </Flexbox>
                            }
                            value={'yes'}
                            {...form.register('host_serve_alcohol')}
                        />
                        <AnswerItem
                            type="radio"
                            label={
                                <Flexbox align="center" justify="center" gap={2}>
                                    {t('commons:no')}
                                </Flexbox>
                            }
                            value={'no'}
                            {...form.register('host_serve_alcohol')}
                        />
                    </StyledList>

                    {form.formState.errors.host_serve_alcohol && (
                        <InputHelpText error={form.formState.errors.host_serve_alcohol?.message} />
                    )}
                </Flexbox>

                <Divider direction="horizontal" />

                {/* Is national park */}
                <Flexbox direction="column" gap={4} align="stretch">
                    <FieldTitle>{t('services:services_legal_step:question3_label')}</FieldTitle>
                    <StyledList>
                        <AnswerItem
                            type="radio"
                            label={
                                <Flexbox align="center" justify="center" gap={2}>
                                    {t('commons:yes')}
                                </Flexbox>
                            }
                            value={'yes'}
                            {...form.register('service_in_national_park')}
                        />
                        <AnswerItem
                            type="radio"
                            label={
                                <Flexbox align="center" justify="center" gap={2}>
                                    {t('commons:no')}
                                </Flexbox>
                            }
                            value={'no'}
                            {...form.register('service_in_national_park')}
                        />
                    </StyledList>
                    {form.formState.errors.service_in_national_park && (
                        <InputHelpText error={form.formState.errors.service_in_national_park?.message} />
                    )}
                </Flexbox>

                <Divider direction="horizontal" />

                {/* Transport */}
                <Flexbox direction="column" gap={4} align="stretch">
                    <FieldTitle>{t('experiences:experience_legal_step:transporting_question1_label')}</FieldTitle>
                    <StyledList>
                        <AnswerItem
                            type="radio"
                            label={
                                <Flexbox align="center" justify="center" gap={2}>
                                    {t('commons:yes')}
                                </Flexbox>
                            }
                            value={'yes'}
                            {...form.register('host_transports_guests')}
                        />
                        <AnswerItem
                            type="radio"
                            label={
                                <Flexbox align="center" justify="center" gap={2}>
                                    {t('commons:no')}
                                </Flexbox>
                            }
                            value={'no'}
                            {...form.register('host_transports_guests', {
                                onChange: () => form.resetField('host_transporting_guests_method', {defaultValue: []})
                            })}
                        />
                    </StyledList>

                    {form.formState.errors.host_transports_guests && (
                        <InputHelpText error={form.formState.errors.host_transports_guests?.message} />
                    )}
                </Flexbox>

                {isTransportingGuestsWatch == 'yes' && (
                    <Flexbox direction="column" gap={4} align="stretch">
                        <FieldTitle>{t('experiences:experience_legal_step:transporting_question2_label')}</FieldTitle>
                        <StyledList>
                            <AnswerItem
                                type="checkbox"
                                value={'car'}
                                label={
                                    <Flexbox align="center" justify="center" gap={2}>
                                        {t('experiences:experience_legal_step:transporting_answers:car')}
                                    </Flexbox>
                                }
                                {...form.register('host_transporting_guests_method', {
                                    setValueAs: value => (Array.isArray(value) ? value : [])
                                })}
                            />
                            <AnswerItem
                                type="checkbox"
                                value={'boat'}
                                label={
                                    <Flexbox align="center" justify="center" gap={2}>
                                        {t('experiences:experience_legal_step:transporting_answers:boat')}
                                    </Flexbox>
                                }
                                {...form.register('host_transporting_guests_method', {
                                    setValueAs: value => (Array.isArray(value) ? value : [])
                                })}
                            />

                            <AnswerItem
                                type="checkbox"
                                value={'plane'}
                                label={
                                    <Flexbox align="center" justify="center" gap={2}>
                                        {t('experiences:experience_legal_step:transporting_answers:plane')}
                                    </Flexbox>
                                }
                                {...form.register('host_transporting_guests_method', {
                                    setValueAs: value => (Array.isArray(value) ? value : [])
                                })}
                            />
                            <AnswerItem
                                type="checkbox"
                                value={'motorcycle'}
                                label={
                                    <Flexbox align="center" justify="center" gap={2}>
                                        {t('experiences:experience_legal_step:transporting_answers:motorcycle')}
                                    </Flexbox>
                                }
                                {...form.register('host_transporting_guests_method', {
                                    setValueAs: value => (Array.isArray(value) ? value : [])
                                })}
                            />
                        </StyledList>

                        {form.formState.errors.host_transporting_guests_method && (
                            <InputHelpText error={form.formState.errors.host_transporting_guests_method?.message} />
                        )}
                    </Flexbox>
                )}
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('legal')}>
                <Button
                    variant="tertiary"
                    disabled={prevStepMutation.isPending}
                    onClick={() => prevStepMutation.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation.isPending && <Spinner />}
                </Button>
                <Button
                    disabled={prevStepMutation.isPending || updateStepToOtherDetailsTherms?.isPending}
                    onClick={onSubmit}
                >
                    {t('commons:continue')}
                    {updateStepToOtherDetailsTherms.isPending && <Spinner />}
                </Button>
            </Footer>
        </>
    )
}
