import {useTranslation} from '@/translations/i18n.tsx'
import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {handleCloseSlidingModal, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useFormContext, useWatch} from 'react-hook-form'
import {
    SettingsPrimaryLanguageSelectBody,
    SettingsPrimaryLanguageSlidingModal,
    SettingsPrimaryLanguageSlidingModalHeader
} from '@/features/host-submissions/experiences/components/settings-primary-language-select/style'
import {XCloseIcon} from '@components/ui/icon'
import {ModalFooter, ModalTitle} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SettingsAdditionalLanguageCheckboxLabel} from '@/features/host-submissions/experiences/components/settings-secondary-language-select/style'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {FieldDescription} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {ExperienceSettingsValidationSchema} from '../experience-settings-step/ExperienceSettingsStep'
import {EXPERIENCES_SUPPORTED_LANGUAGES} from '@/utilities/constants/countries'

export const SettingsSecondaryLanguageSelect: FC = () => {
    const {t} = useTranslation()
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const form = useFormContext<ExperienceSettingsValidationSchema>()

    const selectedPrimaryLanguage = form.getValues('main_language')
    const secondaryLanguagesFormValue = useWatch({control: form.control, name: 'additional_languages'})

    const filteredAppLanguages = useMemo(
        () => EXPERIENCES_SUPPORTED_LANGUAGES.filter(item => item.value != selectedPrimaryLanguage),
        [selectedPrimaryLanguage]
    )

    const additionalLanguage =
        filteredAppLanguages.filter(
            item => secondaryLanguagesFormValue && secondaryLanguagesFormValue.includes(item.value)
        ) || []
    const [secondaryLanguageCode, setSecondaryLanguageCode] = useState(secondaryLanguagesFormValue || [])

    useEffect(() => {
        setSecondaryLanguageCode(secondaryLanguagesFormValue || [])
    }, [secondaryLanguagesFormValue])

    const onClickConfirm = () => {
        form.setValue('additional_languages', secondaryLanguageCode)
        handleCloseSlidingModal(setSlidingModalState)
    }
    const onClose = () => {
        handleCloseSlidingModal(setSlidingModalState)
        setSecondaryLanguageCode(secondaryLanguagesFormValue || [])
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
            setSecondaryLanguageCode([...secondaryLanguageCode, event.currentTarget.value])
        } else {
            setSecondaryLanguageCode([...secondaryLanguageCode.filter(value => value != event.currentTarget.value)])
        }
    }

    return (
        <>
            {additionalLanguage.length > 0 ? (
                <Flexbox direction={'column'} gap={2}>
                    <FieldDescription>
                        {t('experiences:settings_step:add_language')}{' '}
                        {additionalLanguage.map(language => language.label).join(', ')}
                    </FieldDescription>
                    <Button variant={'tertiary'} shape={'text'} onClick={() => setSlidingModalState('open')}>
                        {t('commons:edit')}
                    </Button>
                </Flexbox>
            ) : (
                <Button variant={'tertiary'} shape={'text'} onClick={() => setSlidingModalState('open')}>
                    {t('experiences:settings_step:add_language')}
                </Button>
            )}
            {slidingModalState != 'closed' && (
                <SettingsPrimaryLanguageSlidingModal slidingModalState={slidingModalState} onOverlayClick={onClose}>
                    <SettingsPrimaryLanguageSlidingModalHeader>
                        <Button shape="square" onClick={onClose} variant={'ghost'} size={'sm'}>
                            <XCloseIcon />
                        </Button>
                        <ModalTitle>{t('experiences:settings_step:select_additional_language')}</ModalTitle>
                    </SettingsPrimaryLanguageSlidingModalHeader>
                    <SettingsPrimaryLanguageSelectBody direction={'column'} gap={4}>
                        {filteredAppLanguages.map(language => (
                            <SettingsAdditionalLanguageCheckboxLabel key={language.value}>
                                <Checkbox
                                    id={language.value}
                                    checked={secondaryLanguageCode.includes(language.value)}
                                    value={language.value}
                                    onChange={onChange}
                                />
                                {language.label}
                            </SettingsAdditionalLanguageCheckboxLabel>
                        ))}
                    </SettingsPrimaryLanguageSelectBody>
                    <ModalFooter justify={'space-between'}>
                        <Button onClick={onClose} variant={'tertiary'}>
                            {t('commons:close')}
                        </Button>
                        <Button onClick={onClickConfirm}>{t('commons:confirm')}</Button>
                    </ModalFooter>
                </SettingsPrimaryLanguageSlidingModal>
            )}
        </>
    )
}
