import {getStepProgressPercentage} from '@/features/host-submissions/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    FieldTitle,
    Title,
    Footer
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Checkbox} from '@components/commons/checkbox/ChekBox.tsx'
import {ExperienceExtended, StepKeyName} from '@/features/host-submissions/experiences/types'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useSubmitExperience} from '../../queries/useSubmitExperience'
import {errorHandler} from '@/utilities/helpers'
import {Divider} from '@/components/ui/divider/Divider'
import {StyledListItem} from './style'
import {makeExperiencesFormDefaultValues} from '../../utils'
import {ConfirmSubmitEnhanceModal} from '@/features/host-submissions/components/confirm-submit-enhance-modal/ConfirmSubmitEnhanceModal'

export const ExperiencesTermsStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = useState(false)
    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.legal,
        currentStep: StepKeyName.enum.terms
    })
    const submitExperience = useSubmitExperience({
        experienceId: experience.id,
        options: {onError: errorHandler, onSuccess: () => setIsConfirmSubmitModalOpen(false)}
    })

    const [isGeneralTermsApproved, setIsGeneralTermsApproved] = useState(false)
    const [isAdditionalTermsApproved, setIsAdditionalTermsApproved] = useState(false)
    const legalAnswers = makeExperiencesFormDefaultValues(experience).legal

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:terms_step:title')}</Title>
                    <Paragraph>
                        <Trans
                            i18nKey={'experiences:terms_step:description'}
                            components={{
                                a: (
                                    <a
                                        style={{textDecoration: 'underline', cursor: 'pointer'}}
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.airbnb.com/help/article/2855"
                                    />
                                )
                            }}
                        />
                    </Paragraph>
                </HeadingGroup>

                <Flexbox direction={'column'} gap={4}>
                    <FieldTitle>{t('services:step_other_details_terms:general_attestation')}</FieldTitle>
                    <Checkbox
                        id={'policy-1'}
                        checked={isGeneralTermsApproved}
                        onChange={event => setIsGeneralTermsApproved(event.currentTarget.checked)}
                        label={
                            <Flexbox direction={'column'} gap={4}>
                                <p>{t('experiences:terms_step:policy_1')}</p>
                                <p>{t('experiences:terms_step:policy_1_paragraph_2')}</p>
                                <p>{t('experiences:terms_step:policy_1_paragraph_3')}</p>
                                <Flexbox direction={'column'}>
                                    <StyledListItem>
                                        <Trans
                                            i18nKey={'experiences:terms_step:policy_1_paragraph_4'}
                                            components={[
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/2251"
                                                />,
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/2647"
                                                />
                                            ]}
                                        />
                                    </StyledListItem>
                                    <StyledListItem>
                                        <Trans
                                            i18nKey={'experiences:terms_step:policy_1_paragraph_5'}
                                            components={[
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/2252"
                                                />
                                            ]}
                                        />
                                    </StyledListItem>

                                    <StyledListItem>
                                        <Trans
                                            i18nKey={'experiences:terms_step:policy_1_paragraph_6'}
                                            components={[
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/2255"
                                                />
                                            ]}
                                        />
                                    </StyledListItem>

                                    <StyledListItem>
                                        <Trans
                                            i18nKey={'experiences:terms_step:policy_1_paragraph_7'}
                                            components={[
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/2254"
                                                />
                                            ]}
                                        />
                                    </StyledListItem>

                                    {legalAnswers?.host_transports_guests &&
                                        legalAnswers.host_transporting_guests_method && (
                                            <StyledListItem>
                                                <Trans
                                                    i18nKey={'experiences:terms_step:policy_1_paragraph_8'}
                                                    values={{
                                                        transporting_type: legalAnswers.host_transporting_guests_method
                                                            .filter(Boolean)
                                                            .map(method =>
                                                                t(
                                                                    `experiences:experience_legal_step:transporting_answers:${method}`
                                                                )
                                                            )
                                                            .join(', ')
                                                            .toLocaleLowerCase()
                                                    }}
                                                    components={[
                                                        <a
                                                            style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href="https://www.airbnb.com/help/article/2256"
                                                        />
                                                    ]}
                                                />
                                            </StyledListItem>
                                        )}

                                    <StyledListItem>
                                        <Trans
                                            i18nKey={'experiences:terms_step:policy_1_paragraph_9'}
                                            components={[
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/2578"
                                                />
                                            ]}
                                        />
                                    </StyledListItem>
                                    <StyledListItem>{t('experiences:terms_step:policy_1_paragraph_10')}</StyledListItem>

                                    <StyledListItem>
                                        <Trans
                                            i18nKey={'experiences:terms_step:policy_1_paragraph_11'}
                                            components={[
                                                <a
                                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href="https://www.airbnb.com/help/article/1728"
                                                />
                                            ]}
                                        />
                                    </StyledListItem>
                                </Flexbox>
                            </Flexbox>
                        }
                    />

                    <Divider direction={'horizontal'} />

                    <Flexbox direction={'column'} gap={4}>
                        <FieldTitle>{t('experiences:terms_step:additional_terms')}</FieldTitle>
                        <Checkbox
                            id={'policy-2'}
                            checked={isAdditionalTermsApproved}
                            onChange={event => setIsAdditionalTermsApproved(event.currentTarget.checked)}
                            label={
                                <Trans
                                    i18nKey={'experiences:terms_step:policy_2'}
                                    components={[
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.airbnb.com/help/article/2870"
                                        />,
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.airbnb.com/help/article/1451"
                                        />,
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.airbnb.com/help/article/2278/experiences-guest-refund-policy"
                                        />,
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://www.airbnb.com/help/article/2908#13"
                                        />
                                    ]}
                                />
                            }
                        />
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('terms')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || submitExperience.isPending}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>

                <Button
                    disabled={
                        changeStep.isBackLoading ||
                        submitExperience.isPending ||
                        !isGeneralTermsApproved ||
                        !isAdditionalTermsApproved
                    }
                    onClick={() => setIsConfirmSubmitModalOpen(true)}
                >
                    {t('commons:submit_for_review')}
                    {submitExperience.isPending && <Spinner />}
                </Button>
            </Footer>

            {isConfirmSubmitModalOpen && (
                <ConfirmSubmitEnhanceModal
                    isSendToReview={true}
                    onClose={() => setIsConfirmSubmitModalOpen(false)}
                    onSubmit={() =>
                        submitExperience.mutate({
                            isGeneralAttestationApproved: isGeneralTermsApproved,
                            isTermsPoliciesApproved: isAdditionalTermsApproved
                        })
                    }
                    isLoading={submitExperience.isPending}
                />
            )}
        </>
    )
}
