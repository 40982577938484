import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpHostApprovalSubmit} from '../http/experiences.http'
import {ExperienceExtended} from '../types'

export const useSubmitHostApproval = ({
    experienceId,
    options
}: {
    experienceId: number
    options?: Omit<UseMutationOptions<ExperienceExtended, unknown>, 'mutationFn'>
}) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () =>
            httpHostApprovalSubmit({
                urlParams: {experienceId},
                params: {responseType: 'extended'}
            }),
        ...options,
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
