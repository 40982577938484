import {z} from 'zod'

const boolEnvvar = z
    .string()
    .optional()
    .transform(s => s == 'true')

export const envVars = z
    .object({
        VITE_APP_TITLE: z.string().optional(),
        VITE_API_ENDPOINT: z.string().url(),
        VITE_API_ENDPOINT_NODE: z.string().url(),
        VITE_AIRBNB_APP_ID: z.string(),
        VITE_AIRBNB_DATA_SCOPE: z.string(),
        VITE_AIRBNB_DATA_REDIRECT_URI: z.string().url(),
        VITE_GOOGLE_API_KEY: z.string(),
        IS_SENTRY_ENABLED: boolEnvvar,
        SENTRY_ORG: z.string().email().optional(),
        SENTRY_PROJ: z.string().optional(),
        SENTRY_AUTH: z.string().optional(),
        VITE_MIXPANEL_TOKEN: z.string(),
        VITE_MIXPANEL_DEBUG_ENABLED: boolEnvvar
    })
    .parse(import.meta.env)
