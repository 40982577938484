import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import toast from 'react-hot-toast'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar'
import {StyledBinaryChoiceItem} from './style'
import {Gift01Icon, Star06Icon} from '@/components/ui/icon'
import {useEffect, useState} from 'react'
import {useShowExperiencesHostVideo} from '../../services/useShowExperiencesHostVideo'
import {useSelectQuestionAnswer} from '../../services/useSelectQuestionAnswer'
import {Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'

export const PreparationTakeAnythingHomeStep = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams: routeParams})

    const questions = showExperiencesHostVideoQuery?.data?.questions.filter(
        question => question.question_answer.question.key_name === 'taking_anything_home'
    )

    const answer = showExperiencesHostVideoQuery?.data?.answers.find(
        answer => answer.question_answer.question.key_name == 'taking_anything_home'
    )
    const [selectedChoice, setSelectedChoice] = useState<'yes' | 'no'>()

    const backUpdateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const selectQuestionAnswerMutation = useSelectQuestionAnswer({
        params: routeParams
    })

    const continueUpdateStepMutation = useUpdateStep({
        params: routeParams
    })

    useEffect(() => {
        if (showExperiencesHostVideoQuery.data && answer) {
            setSelectedChoice(answer.question_answer.answer.key_name == 'yes' ? 'yes' : 'no')
        }
    }, [showExperiencesHostVideoQuery.data])

    const handlerContinue = async () => {
        const questionAnswerId = questions?.find(question => question.question_answer.answer.key_name == selectedChoice)
            ?.id
        try {
            if (questionAnswerId) {
                if (!answer || answer?.question_answer.answer.key_name != selectedChoice) {
                    await selectQuestionAnswerMutation.mutateAsync({
                        questionAnswerId
                    })
                }
                await continueUpdateStepMutation.mutateAsync({
                    status: 'preparation',
                    step: 'get-ready-to-shoot'
                })
            }
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <>
            <ExperiencesHostVideoBody>
                <Flexbox direction="column" gap={4}>
                    <Title>{t('experiences_host_video:take_anything_home:title')}</Title>
                    <Paragraph>{t('experiences_host_video:take_anything_home:description')}</Paragraph>
                </Flexbox>

                <Flexbox width="100%" gap={3}>
                    <StyledBinaryChoiceItem
                        gap={2}
                        direction="column"
                        width="100%"
                        onClick={() => setSelectedChoice('yes')}
                        $isActive={selectedChoice == 'yes'}
                    >
                        <Gift01Icon />
                        <p>{t('experiences_host_video:take_anything_home:yes')}</p>
                    </StyledBinaryChoiceItem>
                    <StyledBinaryChoiceItem
                        gap={2}
                        direction="column"
                        width="100%"
                        onClick={() => setSelectedChoice('no')}
                        $isActive={selectedChoice == 'no'}
                    >
                        <Star06Icon />
                        <p>{t('experiences_host_video:take_anything_home:no')}</p>
                    </StyledBinaryChoiceItem>
                </Flexbox>
            </ExperiencesHostVideoBody>

            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Flexbox justify="space-between" width="100%">
                        <Button
                            disabled={backUpdateStepMutation.isPending || continueUpdateStepMutation.isPending}
                            variant="tertiary"
                            onClick={() => backUpdateStepMutation.mutate({status: 'preparation', step: 'highlights'})}
                        >
                            {t('commons:back')}
                            {backUpdateStepMutation.isPending && <Spinner />}
                        </Button>
                        <Button
                            onClick={handlerContinue}
                            disabled={
                                continueUpdateStepMutation.isPending ||
                                backUpdateStepMutation.isPending ||
                                !selectedChoice ||
                                selectQuestionAnswerMutation.isPending
                            }
                        >
                            {t('commons:continue')}
                            {(continueUpdateStepMutation.isPending || selectQuestionAnswerMutation.isPending) && (
                                <Spinner />
                            )}
                        </Button>
                    </Flexbox>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
