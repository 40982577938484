import {useTranslation} from '@/translations/i18n.tsx'
import {ReactNode, useState} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Label} from '@/components/ui/label/Label'
import {Button} from '@/components/ui/button/Button'
import {StyledContent, StyledText} from '@/features/host-submissions/components/edit-field-section/style'
import {DefaultNamespace} from 'i18next'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

interface EditFieldSectionProps {
    label: ReactNode
    text: ReactNode
    onEdit: () => void
    onConfirm: () => void
    editableComponents: ReactNode
    disabled?: boolean
    checkIfFormValid: () => Promise<boolean>
    errorMessage?: string | DefaultNamespace
}
export const EditFieldSection = ({
    label,
    text,
    editableComponents,
    checkIfFormValid,
    disabled = false,
    onEdit,
    onConfirm,
    errorMessage
}: EditFieldSectionProps) => {
    const {t} = useTranslation()
    const [isEditMode, setIsEditMode] = useState(false)

    return (
        <StyledContent gap={1.5} width="100%" direction="column" $disabled={disabled && !isEditMode}>
            <Flexbox justify="space-between" gap={2} width="100%">
                <Label>{label}</Label>
                <Button
                    disabled={disabled && !isEditMode}
                    shape="text"
                    variant="tertiary"
                    onClick={async () => {
                        if (isEditMode) {
                            const isFormValid = await checkIfFormValid()
                            if (isFormValid) {
                                onConfirm()
                                setIsEditMode(false)
                            }
                        } else {
                            onEdit()
                            setIsEditMode(true)
                        }
                    }}
                >
                    {t(isEditMode ? 'commons:done' : 'commons:edit')}
                </Button>
            </Flexbox>

            {isEditMode ? (
                editableComponents
            ) : (
                <Flexbox direction="column" gap={1.5}>
                    <StyledText>{text}</StyledText>
                    {errorMessage && <InputHelpText error={errorMessage} />}
                </Flexbox>
            )}
        </StyledContent>
    )
}
