import {
    ExperiencesHostVideoActionCard,
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter,
    ExperiencesHostVideoPageLayout
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {ExperiencesHostVideoHeader} from '@/features/experiences-host-video/components/experiences-host-video-header/ExperiencesHostVideoHeader.tsx'
import {ExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/types.ts'
import {FC, useEffect, useState} from 'react'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon, AnnotationQuestionIcon, VideoRecorderIcon} from '@components/ui/icon'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {useTheme} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {QuizModal} from '@/features/experiences-host-video/components/quiz-modal/QuizModal.tsx'
import {RequirementsPerspectiveModal} from '@/features/experiences-host-video/components/requirements-perspective-modal/RequirementsPerspectiveModal.tsx'
import {FriendOnboardingModal} from '@/features/experiences-host-video/components/friend-onboarding-modal/FriendOnboardingModal.tsx'
import {StyledChecklistTitle} from '@pages/experiences-host-video-friend/style.ts'
import {Checklist} from '@/features/experiences-host-video/components/checklist/Checklist.tsx'
import toast from 'react-hot-toast'
import {
    httpCompleteFriendQuiz,
    httpStoreFriendQuiz
} from '@/features/experiences-host-video/queries/experiences-host-video.http'
import {useAsync} from '@hooks/useAsync.ts'
import {useUpdateFriendStep} from '@/features/experiences-host-video/services/useUpdateFriendStep.ts'
import {useShowExperiencesHostVideoFriend} from '@/features/experiences-host-video/services/useShowExperiencesHostVideoFriend.ts'
import {useShowFriendQuiz} from '@/features/experiences-host-video/services/useShowFriendQuiz.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useFriendQuizAnswer} from '@/features/experiences-host-video/services/useFriendQuizAnswer.ts'
import {UploadClipsIntroFriend} from '@/features/experiences-host-video/components/upload-clips-intro-friend/UploadClipsIntroFriend.tsx'
import {UploadClipsFriend} from '@/features/experiences-host-video/components/upload-clips-friend/UploadClipsFriend.tsx'
import {Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'

export const ExperiencesHostVideoFriendRoute = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(ExperiencesHostVideoRouteParams)

    if (!urlParams.success) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader hasBackButton />
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:invalid_url')} />
            </ExperiencesHostVideoPageLayout>
        )
    }

    return <ExperiencesHostVideoFriendContent urlParams={urlParams.data} />
}

const ExperiencesHostVideoFriendContent: FC<{urlParams: ExperiencesHostVideoRouteParams}> = ({urlParams}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [quizSlidingModalState, setQuizSlidingModalState] = useState<SlidingModalState>('closed')
    const [requirementsPerspectiveSlidingModalState, setRequirementsPerspectiveSlidingModalState] =
        useState<SlidingModalState>('closed')
    const [onboardingSlidingModalState, setOnboardingSlidingModalState] = useState<SlidingModalState>('closed')
    const showExperiencesHostVideoFriendQuery = useShowExperiencesHostVideoFriend({urlParams})
    const updateStepMutation = useUpdateFriendStep({
        params: urlParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })
    const [quizId, setQuizId] = useState<number>()
    const storeQuiz = useAsync()
    const status = showExperiencesHostVideoFriendQuery.data?.status.key_name
    const step = showExperiencesHostVideoFriendQuery.data?.status.step_key_name
    const routeParams = useExperiencesHostVideoRouteParams()
    const quizQuery = useShowFriendQuiz({
        urlParams: {...routeParams, quizId: quizId},
        queryOptions: {enabled: !!quizId && quizSlidingModalState != 'closed'}
    })

    const friendQuizAnswerMutation = useFriendQuizAnswer({
        params: {...routeParams, quizId: quizId},
        mutationOptions: {
            onError: () => toast.error(t('errors:default'))
        }
    })

    useEffect(() => {
        if (
            showExperiencesHostVideoFriendQuery.isSuccess &&
            !showExperiencesHostVideoFriendQuery.data.has_friend_completed_onboarding
        ) {
            setOnboardingSlidingModalState('open')
        }
    }, [showExperiencesHostVideoFriendQuery.isSuccess])

    if (showExperiencesHostVideoFriendQuery.isPending) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <ExperiencesHostVideoBody justify="center" align="center">
                    <Spinner size={32} />
                </ExperiencesHostVideoBody>
            </ExperiencesHostVideoPageLayout>
        )
    }
    if (showExperiencesHostVideoFriendQuery.isError) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
            </ExperiencesHostVideoPageLayout>
        )
    }
    if (
        !(
            (status == 'ready_to_film' && step == 'get_help') ||
            (status == 'ready_to_film' && step == 'schedule') ||
            (status == 'upload' && step == 'intro') ||
            (status == 'upload' && step == 'video_upload')
        )
    ) {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <ErrorBox icon={<AlertCircleIcon />} title={t('experiences_host_video:friend_unavailable')} />
            </ExperiencesHostVideoPageLayout>
        )
    }
    if (status == 'upload' && step == 'intro') {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <UploadClipsIntroFriend />
            </ExperiencesHostVideoPageLayout>
        )
    }
    if (status == 'upload' && step == 'video_upload') {
        return (
            <ExperiencesHostVideoPageLayout>
                <ExperiencesHostVideoHeader />
                <UploadClipsFriend />
            </ExperiencesHostVideoPageLayout>
        )
    }

    const startQuiz = async () => {
        try {
            const response = await storeQuiz.run(
                httpStoreFriendQuiz({
                    urlParams: urlParams,
                    params: {user_type: 'friend', response_type: 'extended'}
                })
            )
            setQuizId(response.data.id)
            setQuizSlidingModalState('open')
        } catch (error) {
            toast.error(t('errors:default'))
        }
    }

    return (
        <ExperiencesHostVideoPageLayout>
            <ExperiencesHostVideoHeader />
            <div>
                <ExperiencesHostVideoBody>
                    <Flexbox direction="column" gap={2}>
                        <Title>{t('experiences_host_video:get_ready_to_film')}</Title>
                        <Paragraph>{t('experiences_host_video:get_ready_to_film_description')}</Paragraph>
                    </Flexbox>
                    <Flexbox direction="column" gap={4}>
                        <ExperiencesHostVideoActionCard
                            onClick={() => setRequirementsPerspectiveSlidingModalState('open')}
                        >
                            <VideoRecorderIcon />
                            <Flexbox direction="column" gap={1}>
                                <h3>{t('experiences_host_video:requirements:title')}</h3>
                                <p>{t('experiences_host_video:requirements:cta_description')}</p>
                            </Flexbox>
                        </ExperiencesHostVideoActionCard>
                        <ExperiencesHostVideoActionCard disabled={storeQuiz.isLoading} onClick={startQuiz}>
                            {storeQuiz.isLoading ? <Spinner /> : <AnnotationQuestionIcon />}
                            <Flexbox direction="column" gap={1}>
                                <h3>{t('experiences_host_video:take_a_quiz')}</h3>
                                <p>{t('experiences_host_video:take_a_quiz_description')}</p>
                            </Flexbox>
                        </ExperiencesHostVideoActionCard>
                    </Flexbox>
                </ExperiencesHostVideoBody>
                <Divider
                    height="8px"
                    startSpacing={0}
                    endSpacing={0}
                    direction="horizontal"
                    background={theme.palette.neutral['200']}
                />
                <ExperiencesHostVideoBody>
                    <StyledChecklistTitle>{t('experiences_host_video:checklist')}</StyledChecklistTitle>
                    <Checklist haveCheckbox answers={showExperiencesHostVideoFriendQuery.data.answers} />
                </ExperiencesHostVideoBody>
            </div>
            <ExperiencesHostVideoFooter>
                <Button
                    fullWidth
                    disabled={updateStepMutation.isPending}
                    onClick={() => updateStepMutation.mutate({status: 'upload', step: 'intro'})}
                >
                    {t('commons:upload')}
                </Button>
            </ExperiencesHostVideoFooter>

            {onboardingSlidingModalState != 'closed' && (
                <FriendOnboardingModal
                    answers={showExperiencesHostVideoFriendQuery.data.answers}
                    slidingModalState={onboardingSlidingModalState}
                    setSlidingModalState={setOnboardingSlidingModalState}
                />
            )}
            {requirementsPerspectiveSlidingModalState != 'closed' && (
                <RequirementsPerspectiveModal
                    userType="friend"
                    slidingModalState={requirementsPerspectiveSlidingModalState}
                    setSlidingModalState={setRequirementsPerspectiveSlidingModalState}
                />
            )}
            {!!quizId && quizSlidingModalState != 'closed' && (
                <QuizModal
                    completePromise={httpCompleteFriendQuiz}
                    quizAnswerMutation={friendQuizAnswerMutation}
                    quizQuery={quizQuery}
                    slidingModalState={quizSlidingModalState}
                    setSlidingModalState={setQuizSlidingModalState}
                />
            )}
        </ExperiencesHostVideoPageLayout>
    )
}
