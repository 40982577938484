import {useAuthStore} from '@/store/AuthStore'
import {useCategoriesRequestStore} from '../../store/CategoriesRequestStore'
import {CategoryRequestOperation} from '../../types'
import {AddCategoriesRequest} from './AddCategoriesRequest'
import {RemoveCategoryRequest} from './RemoveCategoryRequest'

export const CategoriesRequest = () => {
    const user = useAuthStore(store => store.user)
    const listingId = useCategoriesRequestStore(store => store.listingId)
    const requestOperation = useCategoriesRequestStore(store => store.requestOperation)

    return (
        <>
            {user && listingId && requestOperation === CategoryRequestOperation.Enum.add && (
                <AddCategoriesRequest listingId={listingId} hostCode={user.code} hostPassword={user.password} />
            )}

            {user && listingId && requestOperation === CategoryRequestOperation.Enum.remove && (
                <RemoveCategoryRequest listingId={listingId} hostCode={user.code} hostPassword={user.password} />
            )}
        </>
    )
}
