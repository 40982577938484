import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    StyledCategoryRequestItem,
    StyledCategoryRequestItemDate,
    StyledCategoryRequestItemLabel,
    StyledCategoryRequestItemStatus,
    StyledPoiMessage
} from './style'
import dayjs from 'dayjs'
import {CategoriesDictionary, CategoryDictionaryKey} from '../../utils'
import {TKey, useTranslation} from '@/translations/i18n.tsx'
import {FC, Fragment} from 'react'
import {AvailabilityCategoryStatus, CategoryRequestOperation, CategoryRequestStatus, RemappedRequest} from '../../types'
import {CheckIcon, ClockIcon, XCloseIcon} from '@components/ui/icon'

interface CategoryRequestContentItemProps {
    label: AvailabilityCategoryStatus | CategoryRequestOperation | CategoryRequestStatus
    date: string
    requests: RemappedRequest[]
}

export const CategoryRequestContentItem: FC<CategoryRequestContentItemProps> = ({label, date, requests}) => {
    const {t} = useTranslation()

    return (
        <>
            <Flexbox width={'100%'} justify={'space-between'}>
                {/*TODO: remove as TKey*/}
                <StyledCategoryRequestItemLabel>
                    {t(`categories:requests:${label}` as TKey)}
                </StyledCategoryRequestItemLabel>

                <StyledCategoryRequestItemDate>
                    {t(`categories:requests:date`, {date: dayjs(new Date(date)).format('LL')})}
                </StyledCategoryRequestItemDate>
            </Flexbox>
            <Flexbox direction="column" gap={2} width={'100%'} align={'stretch'}>
                {requests.map(category => (
                    <Fragment key={category.id}>
                        <StyledCategoryRequestItem justify={'space-between'} align={'center'}>
                            <Flexbox gap={2}>
                                {CategoriesDictionary[category.name as CategoryDictionaryKey]?.icon}
                                <p>
                                    {t(
                                        CategoriesDictionary[category.name as CategoryDictionaryKey]?.label ??
                                            category.name
                                    )}
                                </p>
                            </Flexbox>

                            <StyledCategoryRequestItemStatus
                                justify={'space-between'}
                                gap={1}
                                status={category?.status}
                                align={'center'}
                            >
                                {(category.status == 'requested' ||
                                    category.status == 'approved_unacceptable_photos' ||
                                    category.status == 'approved') && <ClockIcon />}

                                {category.status == 'completed' && <CheckIcon />}
                                {category.status == 'escalated' && <CheckIcon />}

                                {category.status == 'denied' && <XCloseIcon />}

                                <span>
                                    {category.status ? t(`categories:requests:status:${category.status}`) : '-'}
                                </span>
                            </StyledCategoryRequestItemStatus>
                        </StyledCategoryRequestItem>
                        {category.status == 'completed' && Boolean(category.poi) && (
                            <StyledPoiMessage>{t('categories:requests:poi_category_message')}</StyledPoiMessage>
                        )}
                    </Fragment>
                ))}
            </Flexbox>
        </>
    )
}
