import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {httpGetServices, HttpGetServicesOptionsProps} from '@/features/host-submissions/services/http/services.http'
import {z} from 'zod'
import {ServiceRegular} from '@/features/host-submissions/services/types.ts'

export const useServices = (params: HttpGetServicesOptionsProps) =>
    useQuery({
        queryKey: [QUERY_KEYS.SERVICES],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetServices(params),
                responseShape: z.array(ServiceRegular),
                onZodError: captureException
            })
    })
