import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {errorHandler} from '@/utilities/helpers'
import {httpSetGalleryCover} from '../http/experiences.http'
import {QUERY_KEYS} from '@/queryClient'

interface UseSetGalleryCoverProps {
    experienceId: number
    galleryId: number
    imageId: number
    mutationOptions?: Omit<UseMutationOptions<unknown, unknown>, 'mutationFn'>
}

export const useSetGalleryCover = ({experienceId, galleryId, imageId, mutationOptions}: UseSetGalleryCoverProps) => {
    const queryClient = useQueryClient()
    return useMutation<unknown, unknown>({
        mutationFn: () =>
            httpSetGalleryCover({
                params: {responseType: 'extended'},
                urlParams: {experienceId, galleryId, imageId},
                payload: {isCover: true}
            }),
        onError: errorHandler,
        ...mutationOptions,
        onSuccess: (data, variables, context) => {
            void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            mutationOptions?.onSuccess?.(data, variables, context)
        }
    })
}
