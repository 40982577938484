import styled, {css} from 'styled-components'

export const StyledDescription = styled.span(
    ({theme: {typography}}) => css`
        ${typography.textMd};
        font-weight: 400;
        & a {
            text-decoration: underline;
            cursor: pointer;
        }
    `
)
