import {useTranslation} from '@/translations/i18n.tsx'
import {FC, useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {handleCloseSlidingModal, SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {useFormContext} from 'react-hook-form'
import {
    SettingsPrimaryLanguageButton,
    SettingsPrimaryLanguageSelectBody,
    SettingsPrimaryLanguageSlidingModal,
    SettingsPrimaryLanguageSlidingModalHeader
} from '@/features/host-submissions/experiences/components/settings-primary-language-select/style'
import {Radio} from '@components/commons/radio/Radio.tsx'
import {ChevronRightIcon, XCloseIcon} from '@components/ui/icon'
import {ModalFooter, ModalTitle} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {ExperienceSettingsValidationSchema} from '../experience-settings-step/ExperienceSettingsStep'
import {EXPERIENCES_SUPPORTED_LANGUAGES} from '@/utilities/constants/countries'

export const SettingsPrimaryLanguageSelect: FC = () => {
    const {t} = useTranslation()
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')
    const form = useFormContext<ExperienceSettingsValidationSchema>()
    const selectedLanguage = EXPERIENCES_SUPPORTED_LANGUAGES.find(
        language => language.value == form.getValues('main_language')
    )
    const secondaryLanguages = form.getValues('additional_languages')
    const [primaryLanguageCode, setPrimaryLanguageCode] = useState(selectedLanguage?.value)

    const onClickConfirm = () => {
        if (primaryLanguageCode) {
            form.setValue('main_language', primaryLanguageCode)
            if (secondaryLanguages) {
                form.setValue('additional_languages', [])
            }
            handleCloseSlidingModal(setSlidingModalState)
        }
    }
    const onClose = () => {
        setPrimaryLanguageCode(selectedLanguage?.value)
        handleCloseSlidingModal(setSlidingModalState)
    }

    return (
        <>
            <SettingsPrimaryLanguageButton variant={'secondary'} onClick={() => setSlidingModalState('open')} fullWidth>
                {selectedLanguage?.label}
                <ChevronRightIcon />
            </SettingsPrimaryLanguageButton>
            {slidingModalState != 'closed' && (
                <SettingsPrimaryLanguageSlidingModal slidingModalState={slidingModalState} onOverlayClick={onClose}>
                    <SettingsPrimaryLanguageSlidingModalHeader>
                        <Button shape="square" onClick={onClose} variant={'ghost'} size={'sm'}>
                            <XCloseIcon />
                        </Button>
                        <ModalTitle>{t('experiences:settings_step:select_primary_language')}</ModalTitle>
                    </SettingsPrimaryLanguageSlidingModalHeader>
                    <SettingsPrimaryLanguageSelectBody direction={'column'} gap={4} align={'stretch'}>
                        {EXPERIENCES_SUPPORTED_LANGUAGES.map(language => (
                            <Radio
                                key={language.value}
                                checked={language.value == primaryLanguageCode}
                                name={'primary-language'}
                                label={language.label}
                                value={language.value}
                                onChange={() => setPrimaryLanguageCode(language.value)}
                            />
                        ))}
                    </SettingsPrimaryLanguageSelectBody>
                    <ModalFooter justify={'space-between'}>
                        <Button onClick={onClose} variant={'tertiary'}>
                            {t('commons:close')}
                        </Button>
                        <Button onClick={onClickConfirm}>{t('commons:confirm')}</Button>
                    </ModalFooter>
                </SettingsPrimaryLanguageSlidingModal>
            )}
        </>
    )
}
