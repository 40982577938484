import {useTranslation} from '@/translations/i18n.tsx'
import {ServiceResource} from '@/features/service-resources/types.ts'
import {StyledServiceResourceCard} from '@/features/service-resources/components/style.ts'
import {ServiceResourcesInfo} from '@/features/service-resources/utils.ts'
import {FC} from 'react'
import {useServiceResourcesLog} from '@/features/service-resources/queries/useServiceResourcesLog'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'

export const ServiceResourceCard: FC<{resource: ServiceResource}> = ({resource}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const {mutate: logResourceClick} = useServiceResourcesLog()

    return (
        <a
            key={resource.id}
            href={ServiceResourcesInfo[resource.key_name].link}
            target={'_blank'}
            onClick={() =>
                logResourceClick({
                    hostCode: urlParams.hostCode,
                    hostPassword: urlParams.hostPassword,
                    quoteId: urlParams.quoteId,
                    tipId: resource.id
                })
            }
        >
            <StyledServiceResourceCard direction="column" gap={3}>
                <img src={ServiceResourcesInfo[resource.key_name].image} alt={resource.key_name} />
                <p> {t(ServiceResourcesInfo[resource.key_name].linkText)} </p>
            </StyledServiceResourceCard>
        </a>
    )
}

ServiceResourceCard.displayName = 'ServiceResourceCard'
