import dayjs, {ConfigType} from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import i18n from '@/translations/i18n.tsx'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import {EntityMeta, TaskMedia, TaskResource} from '@/features/service/types.ts'
import {ServiceType} from '@/types/commons.ts'
import i18next from 'i18next'
import toast from 'react-hot-toast'
import {AxiosPromise, isAxiosError} from 'axios'
import {z, ZodError, ZodSchema} from 'zod'

dayjs.extend(LocalizedFormat)

export function atLeastTwoNonEmpty<T>(...arrays: T[][]): boolean {
    const nonEmptyCount = arrays.reduce((count, arr) => (arr.length > 0 ? count + 1 : count), 0)
    return nonEmptyCount >= 2
}

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() === value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() === option.value.toString()))
}

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

/*date time helpers*/
export const formatLocaleDate = (date: NonNullable<ConfigType>, formatType?: string) =>
    dayjs(date)
        .locale(i18n.language)
        .format(formatType || 'll')

export const isPasteDate = (date: string): boolean => dayjs(date)?.valueOf() < dayjs(new Date()).valueOf()

export const getTimezone = (): string => {
    dayjs.extend(utc)
    dayjs.extend(timezone)

    return dayjs.tz.guess()
}

//events helpers
export const subscribe = (eventName: string, listener: EventListenerOrEventListenerObject) => {
    document.addEventListener(eventName, listener)
}

export const unsubscribe = (eventName: string, listener: EventListenerOrEventListenerObject) => {
    document.removeEventListener(eventName, listener)
}

export const publish = (eventName: string, data: Record<string, string>) => {
    const event = new CustomEvent(eventName, data)
    document.dispatchEvent(event)
}

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

export const formatNumber = (number: number, options: Intl.NumberFormatOptions) =>
    Intl.NumberFormat(i18n.language, options).format(number)

export const formatLocation = (city: string | null, country: string) => (city ? `${city} (${country})` : country)

export const getDuration = (entityMeta: EntityMeta) => {
    const duration = entityMeta.find(metaItem => metaItem.attribute == 'Duration')?.value
    return duration ? Number(duration) : null
}

export const formatDurationFromMinutes = (duration: number) => {
    const hours = Math.floor(duration / 60)
    const minutes = Math.floor(duration % 60)

    if (hours >= 1 && minutes >= 1) {
        return i18next.t('commons:hours', {
            hours: `${hours}:${formatNumber(minutes, {minimumIntegerDigits: 2})}`,
            count: hours
        })
    }
    if (hours >= 1) {
        return i18next.t('commons:hours', {hours, count: hours})
    }

    return i18next.t('commons:minutes', {minutes, count: minutes})
}

export const getServiceType = (taskMedia: TaskMedia['name'], taskCategory: TaskResource['name']): ServiceType => {
    if (taskMedia == 'matterport') {
        return 'matterport'
    }
    if (taskMedia == 'walkthrough') {
        return 'walkthrough'
    }
    if (taskCategory == 'M13-Photo' && taskMedia == 'photo') {
        return 'experience_photo'
    }
    if ((taskCategory == 'Experiences' && taskMedia == 'video') || taskMedia == 'video') {
        return 'experience_video'
    }
    return 'home'
}

/**
 * Generic errorHandler function
 * @param error
 */
export const errorHandler = (error: unknown) => {
    if (isAxiosError(error)) {
        const message =
            error.response?.data.message && error.status != 500
                ? i18next.t(`errors:${error.response.data.message}`)
                : i18next.t('errors:default')
        return toast.error(message)
    }

    toast.error(i18next.t('errors:default'))
    return error
}

/**
 * This function parses the api response using the provided shape and returns the response data or the ZodError if the parse fails
 * */
export const parseAxiosPromise = <T extends ZodSchema>({
    axiosPromise,
    responseShape,
    onZodError
}: {
    axiosPromise: AxiosPromise
    responseShape: T
    onZodError?: (error: ZodError) => void
}): Promise<z.infer<T>> =>
    axiosPromise.then(response => {
        const safeParsedResponseData = responseShape.safeParse(response.data)
        if (!safeParsedResponseData.success) {
            console.error(safeParsedResponseData.error.message + '\n\n\n' + JSON.stringify(response.data))
            onZodError?.(safeParsedResponseData.error)
            throw new Error(safeParsedResponseData.error.message + '\n\n\n' + JSON.stringify(response.data))
        }

        return safeParsedResponseData.data
    })

/**
 * This function parses the api response using the provided shape and returns the response data or the ZodError if the parse fails
 */
export const parseResponseData = <TResponseData extends ZodSchema>({
    responseData,
    responseShape,
    onZodError
}: {
    responseData: unknown
    responseShape: TResponseData
    onZodError?: (error: ZodError) => void
}): z.infer<TResponseData> => {
    const safeParsedResponseData = responseShape.safeParse(responseData)
    if (!safeParsedResponseData.success) {
        console.error(safeParsedResponseData.error.message)
        onZodError?.(safeParsedResponseData.error)
        throw new Error(safeParsedResponseData.error.message)
    }
    return safeParsedResponseData.data
}
/**
 * Function that throws an error, it's useful to prevent impossible states in the application.
 * Example: const id = params.id ?? raise('No id provided'), in this case id is a string instead of string | undefined
 * @param error
 */
export const raise = (error: string): never => {
    throw new Error(error)
}

/**
 * Function that given an amount and currency will return the properly formatted string
 * @param amount
 * @param currency
 */
export const formatCurrency = (amount = 0, currency?: string) =>
    new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency ?? 'USD'
    }).format(amount)

export const ObjectKeys = Object.keys as <T extends object>(object: T) => Array<keyof T>
export const ObjectValues = Object.values as <T extends object>(object: T) => Array<T[keyof T]>
export const ObjectEntries = Object.entries as <T extends object>(
    object: T
) => Array<{[K in keyof T]: [K, T[K]]}[keyof T]>

export const copyTextToClipboard = async (text: string) => {
    if (!text) return

    if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text)
    } else {
        document.execCommand('copy', true, text)
    }
}

export const checkIsExperiencesRoute = (pathname: string) => {
    return pathname.startsWith('/experiences')
}

export const checkIsServicesRoute = (pathname: string) => {
    return pathname.startsWith('/offerings')
}
