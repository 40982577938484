import {
    handleCloseSlidingModal,
    SlidingModalCloseButton,
    SlidingModalState
} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {Dispatch, FC, ReactNode, SetStateAction, useEffect, useState} from 'react'
import {
    StyledModalHeader,
    StyledSlidingModal,
    StyledPhotoGrid
} from '@/features/host-submissions/experiences/components/upload-activity-photo-modal/style'
import {useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ModalBody, ModalFooter} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {GalleryImageItem} from '@/components/commons/gallery-image-item/GalleryImageItem'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {PlusIcon} from '@/components/ui/icon'
import {useDropzone} from 'react-dropzone'
import {Dropzone} from '@components/ui/dropzone/Dropzone.tsx'
import {raise} from '@utilities/helpers.ts'
import {httpUploadFile} from '@services/upload.http.ts'
import toast from 'react-hot-toast'
import {FieldTitle} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
import {QUERY_KEYS} from '@/queryClient'
import {useQueryClient} from '@tanstack/react-query'
import {GalleryItem} from '@/features/host-submissions/types'
import {useGalleryUpload} from '@/features/host-submissions/services/store/servicesGallery'
import {MAX_FILE_TO_UPLOAD} from '@/features/host-submissions/services/components/gallery-step/GalleryStep'
import {useGalleryPhoto} from '../../queries/useGalleryPhoto'
import {BulkPresignedPhotosResponse, useBulkPresignedPhotos} from '../../queries/useBulkPresignedPhotos'
import {httpConfirmUploadPhoto} from '../../http/experiences.http'

export const UploadActivityPhotoModal: FC<{
    slidingModalState: SlidingModalState
    setSlidingModalState: Dispatch<SetStateAction<SlidingModalState>>
    title: ReactNode
    activityMainPhoto: GalleryItem | null
    setMainPhotoValue: (mainPhoto: GalleryItem | null) => void
    experienceId: number
    galleryId: number
}> = ({
    slidingModalState,
    setSlidingModalState,
    title,
    galleryId,
    activityMainPhoto,
    setMainPhotoValue,
    experienceId
}) => {
    const {t} = useTranslation()
    const queryClient = useQueryClient()

    const galleryQuery = useGalleryPhoto({
        experienceId,
        galleryId
    })
    const gallery = useGalleryUpload(store => store.gallery)
    const [selectedMainPhoto, setSelectedMainPhoto] = useState<GalleryItem | null>(activityMainPhoto)
    const acceptedFiles = useGalleryUpload(store => store.acceptedFiles)
    const addGalleryItems = useGalleryUpload(store => store.addGalleryItems)
    const clearGallery = useGalleryUpload(store => store.clearGallery)
    const setAcceptedFiles = useGalleryUpload(store => store.setAcceptedFiles)
    const setGalleryItemProgressValue = useGalleryUpload(store => store.setGalleryItemProgressValue)
    const setGalleryItemStatus = useGalleryUpload(store => store.setGalleryItemStatus)
    const setGalleryItemSrc = useGalleryUpload(store => store.setGalleryItemSrc)

    const onSuccess = (data: BulkPresignedPhotosResponse) => {
        const galleryItems: GalleryItem[] = data.map((value, index) => ({
            id: value.media.id,
            progressValue: 0,
            status: 'pending',
            blob: acceptedFiles[index],
            src: URL.createObjectURL(acceptedFiles[index]),
            presignedUrl: value.presignedUrl
        }))

        addGalleryItems(galleryItems)
        setAcceptedFiles([])
        void uploadFiles(galleryItems)
    }

    const bulkPresignedQuery = useBulkPresignedPhotos({
        urlParams: {experienceId, galleryId},
        options: {
            onSuccess: onSuccess
        }
    })

    const dropzoneState = useDropzone({
        disabled: bulkPresignedQuery.isPending,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/png': ['.png']
        },
        maxFiles: MAX_FILE_TO_UPLOAD,
        multiple: true,
        onDropAccepted: acceptedFiles => {
            setAcceptedFiles(acceptedFiles)
            bulkPresignedQuery.mutate({medias: acceptedFiles.map(file => file.name)})
        },
        onDropRejected: rejectedFiles => {
            if (rejectedFiles.length > MAX_FILE_TO_UPLOAD) {
                return toast.error(t('errors:dropzone_max_file', {count: MAX_FILE_TO_UPLOAD}))
            }
            console.error('Rejected files: ', rejectedFiles)
        }
    })

    const uploadFile = async (galleryItem: GalleryItem, uploadNextGroup: () => void) => {
        try {
            await httpUploadFile({
                url: galleryItem.presignedUrl || raise('the presignedUrl is undefined'),
                payload: galleryItem.blob || raise('the file is undefined'),
                onUploadProgress: progressEvent =>
                    setGalleryItemProgressValue(
                        galleryItem.id,
                        progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 100
                    )
            })
            const response = await httpConfirmUploadPhoto({
                params: {responseType: 'extended'},
                urlParams: {
                    galleryId,
                    experienceId,
                    mediaId: galleryItem.id
                }
            })

            setSelectedMainPhoto({
                id: galleryItem.id,
                progressValue: 0,
                status: 'success',
                src: response.data.url
            })
            queryClient.invalidateQueries({queryKey: [QUERY_KEYS.EXPERIENCE, experienceId]})
            setGalleryItemSrc(galleryItem.id, response.data.url)
            setGalleryItemStatus(galleryItem.id, 'success')
        } catch (error) {
            setGalleryItemStatus(galleryItem.id, 'error')
        } finally {
            uploadNextGroup()
        }
    }

    const uploadFiles = (galleryItems: GalleryItem[]) => {
        let filesQueueIndex = 0
        const firstGroupSize = 6
        const othersGroupSize = 3
        let queue = [galleryItems.slice(0, firstGroupSize)]

        for (let index = firstGroupSize; index < galleryItems.length; index += othersGroupSize) {
            queue = queue.concat([galleryItems.slice(index, index + othersGroupSize)])
        }
        const filesQueue = queue

        const uploadFilesGroup = (galleryItems: GalleryItem[]) => {
            galleryItems.map(
                item =>
                    void uploadFile(item, () => {
                        const nextFilesQueue = filesQueue[++filesQueueIndex]
                        if (nextFilesQueue) {
                            uploadFilesGroup(nextFilesQueue)
                        }
                    })
            )
        }

        uploadFilesGroup(filesQueue[0])
    }

    useEffect(() => {
        const images = galleryQuery.data || []
        const approved = images.filter(img => !img?.parentFlags || !img?.parentFlags.length).reverse()

        addGalleryItems(
            approved.map(media => ({
                id: media.id,
                src: media.url,
                status: 'success',
                flags: media.parentFlags
            }))
        )

        return () => clearGallery()
    }, [galleryQuery.data])

    if (galleryQuery.isPending) {
        return (
            <StyledSlidingModal slidingModalState={slidingModalState}>
                <StyledModalHeader>
                    <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                    {title}
                </StyledModalHeader>
                <ModalBody justify="center" align="center">
                    <Spinner size={32} />
                </ModalBody>
            </StyledSlidingModal>
        )
    }

    return (
        <StyledSlidingModal slidingModalState={slidingModalState}>
            <StyledModalHeader>
                <SlidingModalCloseButton setSlidingModalState={setSlidingModalState} />
                {title}
            </StyledModalHeader>

            <ModalBody>
                <Flexbox justify={'space-between'} gap={2} align={'center'} width={'100%'}>
                    <FieldTitle>{t('commons:choose_photo')}</FieldTitle>
                    <Dropzone state={dropzoneState}>
                        <Button variant={'secondary'} disabled={bulkPresignedQuery.isPending}>
                            {bulkPresignedQuery.isPending ? <Spinner /> : <PlusIcon />}
                            {t('commons:add_photos')}
                        </Button>
                    </Dropzone>
                </Flexbox>
                <StyledPhotoGrid>
                    {gallery.map(image => {
                        const isSelected = image.id == selectedMainPhoto?.id
                        return (
                            <GalleryImageItem
                                imageItem={image}
                                key={image.id}
                                onSelect={() =>
                                    setSelectedMainPhoto({
                                        src: image.src ?? image.presignedUrl ?? '',
                                        id: image.id,
                                        status: image.status
                                    })
                                }
                                isSelected={isSelected}
                            />
                        )
                    })}
                </StyledPhotoGrid>
            </ModalBody>

            <ModalFooter>
                <Flexbox align="center" justify="space-between" width="100%">
                    <Button variant="tertiary" onClick={() => handleCloseSlidingModal(setSlidingModalState)}>
                        {t('commons:cancel')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            selectedMainPhoto && setMainPhotoValue(selectedMainPhoto)
                            handleCloseSlidingModal(setSlidingModalState)
                        }}
                        disabled={
                            (gallery.some(item => item.status == 'pending') && !selectedMainPhoto) ||
                            galleryQuery.isPending
                        }
                    >
                        {t('commons:confirm')}
                    </Button>
                </Flexbox>
            </ModalFooter>
        </StyledSlidingModal>
    )
}
