import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledPhotoGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: ${spacing * 4}px;
        width: 100%;
    `
)

export const StyledPhotoUploaderFloatingItem = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
`

export const StyledPreviewImageWrapper = styled.div<{$isSelected: boolean}>(
    ({theme: {palette}, $isSelected}) => css`
        position: relative;
        width: 100%;
        cursor: pointer;
        & img {
            width: 100%;
            ${$isSelected &&
            css`
                border-radius: 8px;
                border: 2px solid ${palette.neutral[900]};
            `}
        }
    `
)

export const StyledSelectIconWrapper = styled(Flexbox)(
    ({theme: {spacing, shadows, palette, opacities}}) => css`
        border-radius: 50%;
        background: ${palette.neutral.white}${opacities[90]};
        padding: ${spacing * 2}px;
        box-shadow: ${shadows.xs};
        position: absolute;
        right: ${spacing * 2}px;
        top: ${spacing * 2}px;
    `
)

export const StyledGalleryActionButton = styled(Button)(
    ({theme: {spacing, palette, opacities}}) => css`
        position: absolute;
        background: ${palette.neutral.white}${opacities[80]};
        top: ${spacing * 2}px;
        right: ${spacing * 2}px;
        z-index: 1;
    `
)

export const StyledCoverLabel = styled.span(
    ({theme: {spacing, palette, typography}}) => css`
        color: ${palette.neutral[700]};
        ${typography.textSm}
        font-weight: 500;
        position: absolute;
        bottom: ${spacing * 4}px;
        left: ${spacing * 4}px;
        z-index: 1;
        border-radius: 999px;
        border: 1px solid ${palette.neutral[200]};
        background: ${palette.neutral[50]};
        padding: 0 ${spacing * 2}px;
    `
)
