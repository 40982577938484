import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    HeadingGroup,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '@/features/host-submissions/experiences/utils'
import {ConfirmSubmitModal} from '@/features/host-submissions/experiences/components/confirm-submit-modal/ConfirmSubmitModal'
import {ExperienceExtended, StepKeyName} from '@/features/host-submissions/experiences/types'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import InputText from '@/components/commons/input-text/InputText'
import {z} from 'zod'
import {useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import TextArea from '@/components/commons/textarea/TextArea'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {InputContent} from '../atoms/Atoms'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'

const NAME_MAX_LENGTH = 100
const EXPERIENCE_MAX_LENGTH = 300

export const ExperienceStepValidationSchema = z.object({
    experience_title: z.string().min(1, {message: i18n.t('errors:field_required')}),
    experience_description: z.string().min(1, {message: i18n.t('errors:field_required')})
})
export type ExperienceStepValidationSchema = z.infer<typeof ExperienceStepValidationSchema>

export const ExperienceStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)
    const flagsCount = updatesRequiredSteps.experience.flatMap(field => field.flags).length
    const titleFlags = updatesRequiredSteps.experience.find(field => field.questionKeyName == 'experience_title')?.flags
    const descriptionFlags = updatesRequiredSteps.experience.find(
        field => field.questionKeyName == 'experience_description'
    )?.flags

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.gallery,
        currentStep: StepKeyName.enum.experience,
        nextStep: StepKeyName.enum.itinerary,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })

    const defaultValues = makeExperiencesFormDefaultValues(experience).experience

    const form = useForm<ExperienceStepValidationSchema>({
        resolver: zodResolver(ExperienceStepValidationSchema),
        defaultValues
    })
    const [nameWatch, experienceWatch] = useWatch({
        control: form.control,
        name: ['experience_title', 'experience_description']
    })

    const onSubmit = form.handleSubmit(data => {
        changeStep.handleChangeStep(false, data)
    })

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:experience_step:title')}</Title>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('commons:x_items_improve', {count: flagsCount})}
                            paragraph={t('experiences:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>

                <Flexbox direction="column" gap={4} align="stretch">
                    <InputContent direction="column" gap={1.5}>
                        <InputText
                            type={'text'}
                            maxLength={NAME_MAX_LENGTH}
                            helpText={t('commons:x_characters_available', {
                                count: NAME_MAX_LENGTH - nameWatch.length
                            })}
                            label={t('experiences:experience_step:question1_label')}
                            placeholder={t('experiences:experience_step:question1_placeholder')}
                            errorMessage={form.formState.errors.experience_title?.message}
                            {...form.register('experience_title')}
                        />
                        <FlagsAlertText flags={titleFlags} title={t('experiences:experience_step:question1_label')} />
                    </InputContent>

                    <InputContent direction="column" gap={1.5}>
                        <TextArea
                            rows={6}
                            maxLength={EXPERIENCE_MAX_LENGTH}
                            helpText={t('commons:x_characters_available', {
                                count: EXPERIENCE_MAX_LENGTH - experienceWatch.length
                            })}
                            label={t('experiences:experience_step:question2_label')}
                            placeholder={t('experiences:experience_step:question2_placeholder')}
                            errorMessage={form.formState.errors.experience_description?.message}
                            {...form.register('experience_description')}
                        />
                        <FlagsAlertText
                            flags={descriptionFlags}
                            title={t('experiences:experience_step:question2_label')}
                        />
                    </InputContent>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('experience')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                {changeStep.hasNextStep ? (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
