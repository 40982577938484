import {useTranslation} from '@/translations/i18n.tsx'
import {Content, HeadingGroup, Footer} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC} from 'react'
import {
    StyledButton,
    StyledVideo,
    StyledTitle,
    StyledParagraph,
    StyledDescription,
    StyledNote
} from '@/features/host-submissions/experiences/components/get-started-step/style.ts'
import Logo from '@/assets/videos/airbnb_logo.mp4'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useChangeStep} from '../../hooks/useChangeStep'
import {ExperienceExtended, StepKeyName} from '../../types'

export const GetStartedStep: FC<{experience: ExperienceExtended}> = ({experience}) => {
    const {t} = useTranslation()
    const changeStep = useChangeStep({
        experience,
        currentStep: StepKeyName.enum.home,
        nextStep: StepKeyName.enum.category
    })
    const isUpgrade = experience.isUpgrade
    return (
        <>
            <Content gap={0}>
                <StyledVideo src={Logo} autoPlay muted playsInline />
                <Flexbox direction="column" gap={6}>
                    <HeadingGroup direction="column" align="center" gap={4} width="100%">
                        <StyledTitle>
                            {t(
                                isUpgrade
                                    ? 'experiences:step_get_started:title_update'
                                    : 'experiences:step_get_started:title'
                            )}
                        </StyledTitle>
                        <StyledDescription>
                            {t(
                                isUpgrade
                                    ? 'experiences:step_get_started:description_update'
                                    : 'experiences:step_get_started:description'
                            )}
                        </StyledDescription>
                    </HeadingGroup>
                    <Flexbox direction="column" gap={4}>
                        <StyledParagraph>
                            <span>1</span>
                            <p>
                                {t(
                                    isUpgrade
                                        ? 'experiences:step_get_started:paragraph1_update'
                                        : 'experiences:step_get_started:paragraph1'
                                )}
                            </p>
                        </StyledParagraph>
                        <StyledParagraph>
                            <span>2</span>
                            {t(
                                isUpgrade
                                    ? 'experiences:step_get_started:paragraph2_update'
                                    : 'experiences:step_get_started:paragraph2'
                            )}
                        </StyledParagraph>
                        <StyledParagraph>
                            <span>3</span>
                            {t(
                                isUpgrade
                                    ? 'experiences:step_get_started:paragraph4_update'
                                    : 'experiences:step_get_started:paragraph3'
                            )}
                        </StyledParagraph>
                        {isUpgrade && (
                            <StyledParagraph>
                                <span>4</span>
                                {t('experiences:step_get_started:paragraph3_update')}
                            </StyledParagraph>
                        )}
                    </Flexbox>

                    <StyledNote>
                        {t(isUpgrade ? 'host_review:get_started_footer_update' : 'services:step_get_started:footer')}
                    </StyledNote>
                </Flexbox>
            </Content>
            <Footer>
                <Flexbox width={'100%'} justify={'center'}>
                    <StyledButton
                        fullWidth
                        size="lg"
                        disabled={changeStep.isContinueLoading}
                        onClick={() => changeStep.handleChangeStep(false)}
                    >
                        {t('commons:get_started')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </StyledButton>
                </Flexbox>
            </Footer>
        </>
    )
}
