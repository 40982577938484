import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    FieldTitle,
    FieldDescription,
    HeadingGroup,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {z} from 'zod'
import {Controller, useFieldArray, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {ActivityLevel, ExperienceExtended, SkillLevel, StepKeyName} from '../../types'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {Divider} from '@/components/ui/divider/Divider'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import InputText from '@/components/commons/input-text/InputText'
import {PlusIcon, Trash01Icon} from '@/components/ui/icon'
import {DefaultSelector} from '../atoms/Atoms'
import {StyledLinkField, StyledList} from './style'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '../../utils'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'

const activityLevelOptions = ActivityLevel.options.map(level => level)

const skillLevelOptions = SkillLevel.options.map(level => level)

const GuestRequirementsStepValidationSchema = z
    .object({
        parents_kids_under_2: z.boolean().default(false),
        minimum_age: z.coerce
            .number()
            .min(0, i18n.t('experiences:experience_guest_requirements_step:minimum_age_required'))
            .nullable()
            .refine(value => value !== null && !isNaN(value), {
                message: i18n.t('experiences:experience_guest_requirements_step:minimum_age_required')
            }),
        activity_level: z.string({required_error: i18n.t('errors:field_required')}),
        skill_level: z.string({required_error: i18n.t('errors:field_required')}),
        guest_bring_something: z.boolean({
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        guest_bringing_items: z
            .array(
                z.object({
                    value: z.string()
                })
            )
            .optional()
            .nullable()
    })
    .superRefine((arg, context) => {
        if (arg.guest_bring_something) {
            if (!arg.guest_bringing_items || arg.guest_bringing_items.length == 0) {
                context.addIssue({
                    code: 'custom',
                    path: ['guest_bringing_items'],
                    message: i18n.t('services:step_other_details_about_guests:question4_error')
                })
            } else {
                arg.guest_bringing_items.forEach((item, index) => {
                    if (!item.value.trim()) {
                        context.addIssue({
                            code: 'custom',
                            path: ['guest_bringing_items', index, 'value'],
                            message: i18n.t('errors:field_required')
                        })
                    }
                })
            }
        }
    })

export type GuestRequirementsStepValidationSchema = z.infer<typeof GuestRequirementsStepValidationSchema>

export const ExperienceGuestRequirementsStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)

    const flagsCount = updatesRequiredSteps.guests.flatMap(field => field.flags).length
    const minimumAgeFlags = updatesRequiredSteps.guests.find(field => field.questionKeyName == 'minimum_age')?.flags
    const activityLevelFlags = updatesRequiredSteps.guests.find(field => field.questionKeyName == 'activity_level')
        ?.flags

    const skillLevelFlags = updatesRequiredSteps.guests.find(field => field.questionKeyName == 'skill_level')?.flags
    const whatBringFlags = updatesRequiredSteps.guests.find(field => field.questionKeyName == 'guest_bringing_items')
        ?.flags

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.availability,
        currentStep: StepKeyName.enum.guests,
        nextStep: StepKeyName.enum.accessibility,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })
    const defaultValues = makeExperiencesFormDefaultValues(experience).guests

    const form = useForm<GuestRequirementsStepValidationSchema>({
        resolver: zodResolver(GuestRequirementsStepValidationSchema),
        defaultValues: {
            ...defaultValues,
            guest_bringing_items: defaultValues?.guest_bringing_items?.map(value => ({value})) ?? [{value: ''}]
        }
    })

    const fieldArray = useFieldArray({control: form.control, name: 'guest_bringing_items'})
    const needBringAnythingWatch = useWatch({control: form.control, name: 'guest_bring_something'})

    const onSubmit = form.handleSubmit(data => {
        changeStep.handleChangeStep(false, {
            ...data,
            guest_bringing_items: data?.guest_bringing_items
                ? data.guest_bringing_items.map(({value}) => value).filter(value => value.trim() != '')
                : []
        })
    })
    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:experience_guest_requirements_step:title')}</Title>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('commons:x_items_improve', {count: flagsCount})}
                            paragraph={t('experiences:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={6} width="100%">
                    <Flexbox direction="column" gap={4} width="100%">
                        <Controller
                            control={form.control}
                            name="minimum_age"
                            render={({field}) => (
                                <DefaultSelector
                                    value={(field.value ?? '').toString()}
                                    array={Array.from({length: 19}, (_, index) => String(index + 3))}
                                    onChange={field.onChange}
                                    label={t('experiences:experience_guest_requirements_step:min_age_title')}
                                    placeholder={t(
                                        'experiences:experience_guest_requirements_step:min_age_placeholder'
                                    )}
                                    errorMessage={form.formState.errors.minimum_age?.message || undefined}
                                    ref={field.ref}
                                    flags={minimumAgeFlags}
                                />
                            )}
                        />

                        <Checkbox
                            id="parents_kids_under_2"
                            label={t('experiences:experience_guest_requirements_step:infant_allowed')}
                            {...form.register('parents_kids_under_2')}
                        />
                    </Flexbox>

                    <Divider direction="horizontal" />

                    <Controller
                        control={form.control}
                        name="activity_level"
                        render={({field}) => (
                            <DefaultSelector
                                value={(field.value ?? '').toString()}
                                array={activityLevelOptions}
                                labelPrefix={'experiences:experience_guest_requirements_step'}
                                onChange={field.onChange}
                                label={t('experiences:experience_guest_requirements_step:activity_level_label')}
                                note={t('experiences:experience_guest_requirements_step:activity_level_note')}
                                placeholder={t(
                                    'experiences:experience_guest_requirements_step:activity_level_placeholder'
                                )}
                                errorMessage={form.formState.errors.activity_level?.message || undefined}
                                ref={field.ref}
                                flags={activityLevelFlags}
                            />
                        )}
                    />

                    <Divider direction="horizontal" />

                    <Controller
                        control={form.control}
                        name="skill_level"
                        render={({field}) => (
                            <DefaultSelector
                                value={(field.value ?? '').toString()}
                                array={skillLevelOptions}
                                onChange={field.onChange}
                                labelPrefix={'experiences:experience_guest_requirements_step'}
                                label={t('experiences:experience_guest_requirements_step:skill_level_label')}
                                note={t('experiences:experience_guest_requirements_step:skill_level_note')}
                                placeholder={t(
                                    'experiences:experience_guest_requirements_step:skill_level_placeholder'
                                )}
                                errorMessage={form.formState.errors.skill_level?.message || undefined}
                                ref={field.ref}
                                flags={skillLevelFlags}
                            />
                        )}
                    />
                    <Divider direction="horizontal" />
                    <Flexbox direction={'column'} gap={4} width="100%">
                        <FieldTitle>
                            {t('experiences:experience_guest_requirements_step:bring_questions_title')}
                        </FieldTitle>
                        <StyledList>
                            <Controller
                                name="guest_bring_something"
                                control={form.control}
                                render={({field}) => (
                                    <>
                                        <AnswerItem
                                            type="radio"
                                            label={
                                                <Flexbox align="center" justify="center" gap={2}>
                                                    {t('commons:yes')}
                                                </Flexbox>
                                            }
                                            name="guest_bring_something"
                                            value="true"
                                            onChange={() => field.onChange(true)}
                                            checked={field.value === true}
                                        />
                                        <AnswerItem
                                            type="radio"
                                            label={
                                                <Flexbox align="center" justify="center" gap={2}>
                                                    {t('commons:no')}
                                                </Flexbox>
                                            }
                                            name="guest_bring_something"
                                            value="false"
                                            onChange={() => {
                                                field.onChange(false)
                                                form.resetField('guest_bringing_items', {defaultValue: [{value: ''}]})
                                            }}
                                            checked={field.value === false}
                                        />
                                    </>
                                )}
                            />
                        </StyledList>

                        {form.formState.errors.guest_bring_something && (
                            <InputHelpText error={form.formState.errors.guest_bring_something?.message} />
                        )}
                    </Flexbox>
                    {needBringAnythingWatch && (
                        <Flexbox direction="column" gap={4} align="stretch">
                            <Flexbox direction="column" gap={1.5} align="stretch">
                                <FieldTitle>
                                    {t('experiences:experience_guest_requirements_step:bring_questions_label')}
                                </FieldTitle>
                                <FieldDescription>
                                    {t('experiences:experience_guest_requirements_step:bring_questions_note')}
                                </FieldDescription>

                                <FlagsAlertText
                                    flags={whatBringFlags}
                                    title={t('experiences:experience_guest_requirements_step:bring_questions_label')}
                                />
                            </Flexbox>

                            {fieldArray.fields.map((field, index, array) => (
                                <StyledLinkField key={field.id}>
                                    <InputText
                                        inputSize="sm"
                                        type="text"
                                        label={t('experiences:experience_guest_requirements_step:item')}
                                        {...form.register(`guest_bringing_items.${index}.value`)}
                                    />
                                    <Button
                                        variant="ghost"
                                        shape="square"
                                        onClick={() => fieldArray.remove(index)}
                                        style={{
                                            visibility: array.length == 1 ? 'hidden' : 'visible',
                                            transition: 'none'
                                        }}
                                    >
                                        <Trash01Icon />
                                    </Button>
                                    {form.formState.errors.guest_bringing_items?.[index]?.value && (
                                        <InputHelpText
                                            error={form.formState.errors.guest_bringing_items[index]?.value?.message}
                                        />
                                    )}
                                </StyledLinkField>
                            ))}

                            <Button
                                variant="tertiary"
                                shape="text"
                                style={{width: 'max-content'}}
                                onClick={() => fieldArray.append({value: ''})}
                            >
                                <PlusIcon />
                                {t('experiences:experience_guest_requirements_step:add_item')}
                            </Button>

                            {form.formState.errors.guest_bringing_items?.root && (
                                <InputHelpText error={form.formState.errors.guest_bringing_items.root.message} />
                            )}
                        </Flexbox>
                    )}

                    <Divider direction="horizontal" />
                </Flexbox>
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('guests')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>

                {changeStep.hasNextStep ? (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button onClick={onSubmit}>
                        {t('commons:confirm')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
