import {useTranslation} from '@/translations/i18n.tsx'
import Avatar from '@components/ui/avatar/Avatar.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {logout} from '@/features/sso/http/ssoAuthActions'
import {useNavigate} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {useAsync} from '@hooks/useAsync.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {httpSetReferralIds} from '@/features/sso/http/auth.http'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {z} from 'zod'
import {CheckIcon} from '@components/ui/icon'
import {StyledCard, StyledContainer, StyledContainerSuccess, StyledContent} from '@pages/uid-login-confirm/style.ts'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {useState} from 'react'

export const UidLoginConfirm = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const user = useAuthStore(state => state.user)
    const continueAsync = useAsync()
    const [isSuccessView, setIsSuccessView] = useState(false)
    const {searchParams, setSearchParams} = useParsedSearchParams(z.object({lead: z.string().optional()}))

    const handleContinue = async () => {
        if (searchParams.lead) {
            try {
                await continueAsync.run(httpSetReferralIds({payload: {salesforce_id: searchParams.lead}}))
                setSearchParams({lead: undefined})
                setIsSuccessView(true)
            } catch (error) {
                errorHandler(error)
            }
        } else {
            setSearchParams({lead: undefined})
            setIsSuccessView(true)
        }
    }

    const changeProfile = async () => {
        await logout()
        navigate(
            {pathname: routes.UID_LOGIN.path, search: `?lead=${searchParams.lead}&isChangeProfile=true`},
            {replace: true}
        )
    }

    if (isSuccessView) {
        return (
            <StyledContainerSuccess>
                <span>
                    <CheckIcon size={42} />
                </span>
                <Flexbox direction={'column'} gap={2} align={'center'}>
                    <h1>{t('sso_login:profile_confirmed_title')}</h1>
                    <p>{t('sso_login:profile_confirmed_description')}</p>
                </Flexbox>
            </StyledContainerSuccess>
        )
    }

    return (
        <StyledContainer>
            <StyledContent>
                <Flexbox width="100%" direction="column" align="center" gap={6}>
                    <Flexbox width="100%" direction="column" align="center" gap={2}>
                        <h1>{t('uid_login_confirm:title')}</h1>
                        <p>{t('uid_login_confirm:description')}</p>
                    </Flexbox>
                    <StyledCard>
                        <h2>{t('uid_login_confirm:logged_as')}</h2>
                        <Flexbox direction="column" align="center" gap={2}>
                            <Avatar
                                name={`${user?.first_name} ${user?.last_name}`}
                                imageUrl={user?.picture_url ?? undefined}
                                size="xl"
                            />
                            <Flexbox direction="column" align="center">
                                <h3>
                                    {user?.first_name} {user?.last_name}
                                </h3>
                                <h4>{user?.email?.replace(/(?<=^.{4}).*(?=@)/g, '*'.repeat(4))}</h4>
                            </Flexbox>
                        </Flexbox>
                    </StyledCard>
                </Flexbox>
                <Flexbox width="100%" direction="column" align="center" gap={2.5}>
                    <Button fullWidth size="lg" onClick={handleContinue} disabled={continueAsync.isLoading}>
                        {t('commons:continue')}
                        {continueAsync.isLoading && <Spinner />}
                    </Button>
                    <Button size="lg" variant="tertiary" onClick={changeProfile} disabled={continueAsync.isLoading}>
                        {t('uid_login_confirm:change_profile')}
                    </Button>
                </Flexbox>
            </StyledContent>
        </StyledContainer>
    )
}
