import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    Paragraph,
    ButtonArea,
    HeadingGroup,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useEffect, useState} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {PlusIcon} from '@/components/ui/icon'
import {useTheme} from 'styled-components'
import {SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {Activity, ExperienceExtended, StepKeyName} from '../../types'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'
import {ActivityFormModal} from '../activity-form-modal/ActivityFormModal'
import {getExperiencesStepProgressPercentage, getUpdatesRequiredSteps} from '../../utils'
import {StyledButtonAreaCta} from './style'
import {Activities} from '../activities/Activities'
import {useChangeStep} from '../../hooks/useChangeStep'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {raise} from '@/utilities/helpers'

export const MAX_ACTIVITIES_TO_ADD = 10
export const ExperienceItineraryStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const {palette} = useTheme()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const [slidingActivityFormModalState, setSlidingActivityFormModalState] = useState<SlidingModalState>('closed')
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)
    const [selectedActivity, setSelectedActivity] = useState<null | Activity>(null)
    const flagsCount = updatesRequiredSteps.itinerary.flatMap(activity => activity.flatMap(field => field.flags)).length
    const isUpgrade = experience?.isUpgrade
    useEffect(() => {
        if (selectedActivity && slidingActivityFormModalState == 'closed') {
            setSelectedActivity(null)
        }
    }, [selectedActivity, slidingActivityFormModalState])

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.experience,
        currentStep: StepKeyName.enum.itinerary,
        nextStep: isUpgrade ? StepKeyName.enum.accessibility : StepKeyName.enum.pricing,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })
    const activities = experience?.itineraries ?? []
    const activitiesNeedUpdate = activities.some(
        activity => !activity.durationInMinutes || !activity.coverImage || !activity.title || !activity.description
    )

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:experience_itinerary_step:title')}</Title>
                    <Paragraph>
                        {t('experiences:experience_itinerary_step:paragraph', {count: MAX_ACTIVITIES_TO_ADD})}
                    </Paragraph>

                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('commons:x_items_improve', {count: flagsCount})}
                            paragraph={t('experiences:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>

                {experience.itineraries?.length ? (
                    experience.itineraries && (
                        <Activities
                            experienceId={experience.id}
                            activities={experience.itineraries}
                            openCreateActivityModal={() => {
                                setSlidingActivityFormModalState('open')
                            }}
                            onSelect={activity => {
                                setSelectedActivity(activity)
                                setSlidingActivityFormModalState('open')
                            }}
                        />
                    )
                ) : (
                    <ButtonArea
                        fullWidth
                        padding={7}
                        borderRadius="16px"
                        onClick={() => {
                            setSlidingActivityFormModalState('open')
                        }}
                    >
                        <Flexbox gap={1} align="center" direction="column">
                            <PlusIcon size={32} fill={palette.neutral[600]} />
                            <StyledButtonAreaCta>
                                {t('experiences:experience_itinerary_step:add_activity')}
                            </StyledButtonAreaCta>
                        </Flexbox>
                    </ButtonArea>
                )}
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('itinerary')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                {changeStep.hasNextStep ? (
                    <Button
                        disabled={
                            changeStep.isBackLoading ||
                            changeStep.isContinueLoading ||
                            !experience.itineraries ||
                            (!!experience.itineraries && experience.itineraries?.length < 1) ||
                            activitiesNeedUpdate
                        }
                        onClick={() => changeStep.handleChangeStep(false)}
                    >
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button
                        disabled={
                            changeStep.isBackLoading ||
                            changeStep.isContinueLoading ||
                            !experience.itineraries ||
                            (!!experience.itineraries && experience.itineraries?.length < 1) ||
                            activitiesNeedUpdate
                        }
                        onClick={() => changeStep.handleChangeStep(false)}
                    >
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}

            {slidingActivityFormModalState != 'closed' && (
                <ActivityFormModal
                    experienceId={experience.id}
                    slidingModalState={slidingActivityFormModalState}
                    setSlidingModalState={setSlidingActivityFormModalState}
                    gallery={experience.gallery?.images ?? []}
                    defaultValues={selectedActivity ?? undefined}
                    galleryId={experience?.gallery?.id ?? raise('galleryId is nullish')}
                    activityPosition={selectedActivity?.position ?? activities.length + 1}
                />
            )}
        </>
    )
}
