import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledVideo = styled.video(
    () => css`
        height: 120px;
        width: 120px;
        object-fit: contain;
        margin: 0 auto;
    `
)

export const StyledButton = styled(Button)(
    () => css`
        max-width: 800px;
    `
)

export const StyledTitle = styled.h1(
    ({theme: {typography}}) => css`
        ${typography.displayMd};
        font-weight: 500;
        text-align: center;
    `
)

export const StyledDescription = styled.p(
    ({theme: {typography}}) => css`
        ${typography.textXl};
        text-align: center;
    `
)

export const StyledParagraph = styled.div(
    ({theme: {typography, palette, spacing}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[900]};
        display: grid;
        grid-template-columns: auto 1fr;
        gap: ${spacing * 4}px;
        & span {
            display: flex;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: ${palette.neutral[100]};
        }
    `
)

export const StyledNote = styled.div(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        color: ${palette.neutral[900]};
    `
)
