import styled, {css} from 'styled-components'

export const StyledCheckboxLabel = styled.label(
    ({theme: {palette, spacing, typography}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing}px;
        ${typography.textSm};
        color: ${palette.neutral['900']};
        width: 100%;
        & span {
            color: ${palette.neutral['500']};
        }
    `
)
export const StyledHelpText = styled.span(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};
        color: ${palette.neutral['900']};
    `
)
