import {Dropzone} from '@/components/ui/dropzone/Dropzone'
import styled, {css} from 'styled-components'

export const StyledOfferingPhotoDropzone = styled(Dropzone)(
    ({theme: {palette}}) => css`
        width: 100%;
        height: 100%;
        border-radius: 16px;
        border: 2px dashed ${palette.neutral[300]};
        height: 140px;
    `
)

export const StyledOfferingPhotoLabel = styled.span(
    ({theme: {palette}}) => css`
        color: ${palette.neutral['900']};
        font-weight: 500;
    `
)

export const StyledPhotoUploaderFloatingItem = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
`

export const StyledPreviewImageWrapper = styled.div`
    position: relative;
    width: 100%;

    & img {
        width: 100%;
    }
`
