import styled, {css} from 'styled-components'

export const StyledListItem = styled.p(
    ({theme: {spacing}}) => css`
        margin-left: ${spacing * 1.5}px;
        &:before {
            content: '• ';
            margin-left: ${-spacing * 1.5}px;
        }
    `
)
