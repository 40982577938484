import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {parseAxiosPromise, raise} from '@utilities/helpers.ts'
import {httpGetUploadedVideo} from '@/features/experiences-host-video/queries/experiences-host-video.http'
import {captureException} from '@sentry/react'
import {ExperiencesHostVideoUploadRouteParams} from '@/features/experiences-host-video/types.ts'
import {UploadedMedia} from '@/features/host-submissions/services/types.ts'
import {z} from 'zod'

const UploadedVideosResponse = UploadedMedia.array()
type UploadedVideosResponse = z.infer<typeof UploadedVideosResponse>
interface UseUpdateVideosArgs {
    urlParams: ExperiencesHostVideoUploadRouteParams
    queryOptions?: Omit<UseQueryOptions<unknown, unknown, UploadedVideosResponse>, 'queryKey' | 'queryFn'>
}

export const useShowUploadedVideos = ({urlParams, queryOptions}: UseUpdateVideosArgs) =>
    useQuery<unknown, unknown, UploadedVideosResponse>({
        queryKey: [
            QUERY_KEYS.EXPERIENCES_HOST_VIDEO_UPLOADED_FILES,
            urlParams.hostCode,
            urlParams.hostPassword,
            urlParams.experiencesHostVideoId,
            urlParams.taskId,
            urlParams.videoSetId
        ],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetUploadedVideo({
                    urlParams: {...urlParams, videoSetId: urlParams.videoSetId || raise('videoSetId is undefined')}
                }),
                responseShape: UploadedVideosResponse,
                onZodError: captureException
            }),
        ...queryOptions
    })
