import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import toast from 'react-hot-toast'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {useUpdateStep} from '@/features/experiences-host-video/services/useUpdateStep.ts'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useTranslation} from '@/translations/i18n.tsx'
import {ExperiencesHostVideoProgressbar} from '@/features/experiences-host-video/components/experiences-host-video-progressbar/ExperiencesHostVideoProgressbar.tsx'
import {RequirementCard} from '@/features/experiences-host-video/components/requirement-card/RequirementCard.tsx'
import {Announcement01Icon, FaceWinkIcon, MusicNote01Icon, UserUp01Icon} from '@components/ui/icon'
import {Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'

export const VoiceoverGuidelines = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const updateStepMutation = useUpdateStep({
        params: routeParams,
        mutationOptions: {onError: () => toast.error(t('errors:default'))}
    })

    return (
        <>
            <ExperiencesHostVideoBody>
                <Flexbox direction={'column'} gap={2}>
                    <Title>{t('experiences_host_video:upload_voiceover_guidelines:title')}</Title>
                    <Paragraph>{t('experiences_host_video:upload_voiceover_guidelines:description')}</Paragraph>
                </Flexbox>

                <Flexbox direction={'column'} gap={6}>
                    <RequirementCard icon={<Announcement01Icon />}>
                        <h3>{t('experiences_host_video:upload_voiceover_guidelines:quiet_environment')}</h3>
                        <p>{t('experiences_host_video:upload_voiceover_guidelines:quiet_environment_description')}</p>
                    </RequirementCard>

                    <RequirementCard icon={<FaceWinkIcon />}>
                        <h3>{t('experiences_host_video:upload_voiceover_guidelines:avoid_touching_mic')}</h3>
                        <p>{t('experiences_host_video:upload_voiceover_guidelines:avoid_touching_mic_description')}</p>
                    </RequirementCard>

                    <RequirementCard icon={<MusicNote01Icon />}>
                        <h3>{t('experiences_host_video:upload_voiceover_guidelines:natural_voice')}</h3>
                        <p>{t('experiences_host_video:upload_voiceover_guidelines:natural_voice_description')}</p>
                    </RequirementCard>

                    <RequirementCard icon={<UserUp01Icon />}>
                        <h3>{t('experiences_host_video:upload_voiceover_guidelines:short_sweet')}</h3>
                        <p>{t('experiences_host_video:upload_voiceover_guidelines:short_sweet_description')}</p>
                    </RequirementCard>
                </Flexbox>
            </ExperiencesHostVideoBody>
            <ExperiencesHostVideoFooter>
                <Flexbox direction="column" gap={4.5} width="100%">
                    <ExperiencesHostVideoProgressbar />
                    <Button
                        onClick={() => updateStepMutation.mutate({status: 'upload', step: 'name-quick-intro'})}
                        disabled={updateStepMutation.isPending}
                        fullWidth
                    >
                        {t('commons:continue')}
                        {updateStepMutation.isPending && <Spinner />}
                    </Button>
                </Flexbox>
            </ExperiencesHostVideoFooter>
        </>
    )
}
