import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    StyledFriendOnboardingModalIntroStep,
    StyledFriendOnboardingModalIntroStepBody,
    StyledFriendOnboardingModalIntroStepFooter
} from '@/features/experiences-host-video/components/friend-onboarding-modal-intro-step/style.ts'
import {Dispatch, FC, SetStateAction} from 'react'
import inviteYourCrewImage from '@assets/images/experiences-host-video/inviteyourcrew.png'
import {FriendOnboardingModalStep} from '@/features/experiences-host-video/components/friend-onboarding-modal/FriendOnboardingModal.tsx'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useShowExperiencesHostVideoFriend} from '@/features/experiences-host-video/services/useShowExperiencesHostVideoFriend.ts'
import {Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'

export const FriendOnboardingModalIntroStep: FC<{setStep: Dispatch<SetStateAction<FriendOnboardingModalStep>>}> = ({
    setStep
}) => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const showExperiencesHostVideoFriendQuery = useShowExperiencesHostVideoFriend({urlParams: routeParams})

    return (
        <StyledFriendOnboardingModalIntroStep>
            <StyledFriendOnboardingModalIntroStepBody>
                <img src={inviteYourCrewImage} alt="step image" />
                <Flexbox direction="column" align="center" gap={2} width="100%">
                    <Title>
                        {t('experiences_host_video:friend_onboarding_modal:intro_title', {
                            name: showExperiencesHostVideoFriendQuery.data?.host?.first_name ?? ''
                        })}
                    </Title>
                    <Paragraph>{t('experiences_host_video:friend_onboarding_modal:intro_description')}</Paragraph>
                </Flexbox>
            </StyledFriendOnboardingModalIntroStepBody>
            <StyledFriendOnboardingModalIntroStepFooter>
                <Button fullWidth onClick={() => setStep('requirements')}>
                    {t('commons:get_started')}
                </Button>
            </StyledFriendOnboardingModalIntroStepFooter>
        </StyledFriendOnboardingModalIntroStep>
    )
}
