import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {parseAxiosPromise, raise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {httpUpdateOffering, HttpUpdateOfferingOptions} from '@/features/host-submissions/services/http/services.http'
import {z} from 'zod'

const UpdateOfferingResponse = z.object({
    id: z.number(),
    submission_id: z.number()
})
type UpdateOfferingResponse = z.infer<typeof UpdateOfferingResponse>

export const useUpdateOffering = ({
    serviceId,
    offeringId,
    options
}: {
    serviceId: number
    offeringId?: number
    options?: UseMutationOptions<
        UpdateOfferingResponse,
        unknown,
        Omit<HttpUpdateOfferingOptions['payload'], 'mutationFn'>
    >
}) => {
    return useMutation({
        ...options,
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpUpdateOffering({
                    urlParams: {
                        serviceId,
                        offeringId: offeringId ?? raise('offering id is null')
                    },
                    params: {response_type: 'regular'},
                    payload: payload
                }),
                responseShape: UpdateOfferingResponse,
                onZodError: captureException
            }),
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context)
        }
    })
}
