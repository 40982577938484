import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    FieldTitle,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '@/features/host-submissions/experiences/utils'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {z} from 'zod'
import {Controller, useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import InputText from '@/components/commons/input-text/InputText'
import {Divider} from '@/components/ui/divider/Divider'
import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import {StyledDescription, StyledFormWrapper, StyledList} from './style'
import {CountrySelect} from '../country-select/CountrySelect'
import {useChangeStep} from '../../hooks/useChangeStep'
import {ExperienceExtended, StepKeyName} from '../../types'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {InputContent} from '../atoms/Atoms'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'

export const AboutYourselfMoreInfoStepValidationSchema = z
    .object({
        residential_address: z.string().min(1, i18n.t('errors:field_required')),
        residential_address_extras: z.string().optional(),
        residential_country_region: z.string().min(1, {message: i18n.t('experiences:select_country')}),
        residential_city: z.string().min(1, i18n.t('errors:field_required')),
        residential_zip: z.string().min(1, i18n.t('errors:field_required')),
        residential_state: z.string().min(1, i18n.t('errors:field_required')),
        hosting_as_business: z.boolean({
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        business_name: z.string().optional().nullable()
    })
    .superRefine((arg, context) => {
        if (arg.hosting_as_business && !arg.business_name) {
            context.addIssue({
                code: 'custom',
                path: ['business_name'],
                message: i18n.t('errors:field_required')
            })
        }
    })
export type AboutYourselfMoreInfoStepValidationSchema = z.infer<typeof AboutYourselfMoreInfoStepValidationSchema>

export const ExperienceAboutYourselfMoreInfoStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)

    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)
    const flagsCount = updatesRequiredSteps.about_you_more_info.flatMap(field => field.flags).length
    const businessNameFlags = updatesRequiredSteps.about_you_more_info.find(
        field => field.questionKeyName == 'business_name'
    )?.flags
    const residentialCityFlags = updatesRequiredSteps.about_you_more_info.find(
        field => field.questionKeyName == 'residential_city'
    )?.flags
    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.about_you,
        currentStep: StepKeyName.enum.about_you_more_info,
        nextStep: StepKeyName.enum.location,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })

    const defaultValues = makeExperiencesFormDefaultValues(experience).about_you_more_info

    const form = useForm<AboutYourselfMoreInfoStepValidationSchema>({
        resolver: zodResolver(AboutYourselfMoreInfoStepValidationSchema),
        defaultValues
    })

    const onSubmit = form.handleSubmit(data => {
        changeStep.handleChangeStep(false, data)
    })

    const businessValueWatch = useWatch({
        control: form.control,
        name: 'hosting_as_business'
    })
    return (
        <>
            <Content>
                <Title>{t('experiences:experience_about_yourself_more_info_step:title')}</Title>
                <StyledFormWrapper direction="column" gap={4} width="100%">
                    <Flexbox direction="column" gap={1.5}>
                        <FieldTitle>
                            {t('experiences:experience_about_yourself_more_info_step:address_label')}
                        </FieldTitle>
                        <StyledDescription>
                            {t('experiences:experience_about_yourself_more_info_step:address_note')}
                        </StyledDescription>

                        {flagsCount >= 1 && (
                            <AlertBanner
                                title={t('commons:x_items_improve', {count: flagsCount})}
                                paragraph={t('experiences:update_required_paragraph')}
                            />
                        )}
                    </Flexbox>
                    <Controller
                        control={form.control}
                        name="residential_country_region"
                        render={({field, fieldState}) => (
                            <CountrySelect
                                value={field.value}
                                onChange={value => field.onChange(value)}
                                errorMessage={fieldState.error?.message}
                            />
                        )}
                    />
                    <InputText
                        type={'text'}
                        label={t('experiences:street_address')}
                        errorMessage={form.formState.errors.residential_address?.message}
                        {...form.register('residential_address')}
                    />
                    <InputText
                        type={'text'}
                        label={t('experiences:apartment')}
                        errorMessage={form.formState.errors.residential_address_extras?.message}
                        {...form.register('residential_address_extras')}
                    />
                    <InputContent direction="column" gap={1.5}>
                        <InputText
                            type={'text'}
                            label={t('experiences:city')}
                            errorMessage={form.formState.errors.residential_city?.message}
                            {...form.register('residential_city')}
                        />
                        <FlagsAlertText flags={residentialCityFlags} title={t('experiences:city')} />
                    </InputContent>

                    <InputText
                        type={'text'}
                        label={t('experiences:state')}
                        errorMessage={form.formState.errors.residential_state?.message}
                        {...form.register('residential_state')}
                    />
                    <InputText
                        type={'text'}
                        label={t('experiences:zip_code')}
                        errorMessage={form.formState.errors.residential_zip?.message}
                        {...form.register('residential_zip')}
                    />
                    <Divider direction="horizontal" startSpacing={2} endSpacing={2} />
                    <Flexbox direction="column" gap={1.5}>
                        <FieldTitle>
                            {t('experiences:experience_about_yourself_more_info_step:business_question_label')}
                        </FieldTitle>
                        <StyledDescription>
                            {t('experiences:experience_about_yourself_more_info_step:business_question_note')}
                        </StyledDescription>
                    </Flexbox>
                    <Flexbox direction="column" gap={4} align="stretch">
                        <StyledList>
                            <Controller
                                name="hosting_as_business"
                                control={form.control}
                                render={({field}) => (
                                    <>
                                        <AnswerItem
                                            type="radio"
                                            label={
                                                <Flexbox align="center" justify="center" gap={2}>
                                                    {t('commons:yes')}
                                                </Flexbox>
                                            }
                                            name="hosting_as_business"
                                            value="true"
                                            onChange={() => field.onChange(true)}
                                            checked={field.value === true}
                                        />
                                        <AnswerItem
                                            type="radio"
                                            label={
                                                <Flexbox align="center" justify="center" gap={2}>
                                                    {t('commons:no')}
                                                </Flexbox>
                                            }
                                            name="hosting_as_business"
                                            value="false"
                                            onChange={() => {
                                                field.onChange(false)
                                                form.resetField('business_name', {defaultValue: null})
                                            }}
                                            checked={field.value === false}
                                        />
                                    </>
                                )}
                            />
                        </StyledList>
                        {form.formState.errors.hosting_as_business && (
                            <InputHelpText error={form.formState.errors.hosting_as_business?.message} />
                        )}
                    </Flexbox>

                    {businessValueWatch && (
                        <InputContent direction="column" gap={1.5}>
                            <InputText
                                type={'text'}
                                label={t('experiences:experience_about_yourself_more_info_step:business_name_label')}
                                placeholder={t(
                                    'experiences:experience_about_yourself_more_info_step:business_name_placeholder'
                                )}
                                errorMessage={form.formState.errors.business_name?.message}
                                {...form.register('business_name')}
                            />

                            <FlagsAlertText
                                flags={businessNameFlags}
                                title={t('experiences:experience_about_yourself_more_info_step:business_name_label')}
                            />
                        </InputContent>
                    )}
                    <Divider direction="horizontal" startSpacing={2} endSpacing={8} />
                </StyledFormWrapper>
            </Content>

            <Footer progressPercentage={getExperiencesStepProgressPercentage('about_you_more_info')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                {changeStep.hasNextStep ? (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button onClick={onSubmit}>{t('commons:confirm')}</Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
