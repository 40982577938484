import {DefaultNamespace} from 'i18next'
import OtpInput from 'react-otp-input'
import {FC, ReactNode, useState} from 'react'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    StyledOtpInput,
    StyledOtpStepBody,
    StyledOtpStepHeader,
    StyledOtpStepWrapper
} from '@/features/otp-flow/components/style.ts'
import {useTheme} from 'styled-components'
import {useOtpStore} from '@/features/otp-flow/store/otpStore.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Spinner from '@components/ui/spinner-legacy/Spinner.tsx'
import {errorHandler} from '@utilities/helpers.ts'
import toast from 'react-hot-toast'
import {useServiceAcceptStore} from '@/features/service-acceptance-flow/store/ServiceAcceptStore.tsx'
import {NotifyChannels} from '@/features/service-acceptance-flow/types.ts'
import {useOtpValidationV2} from '@/features/otp-flow/queries/useOtpValidationV2'

interface OptPendingConfirmStepProps {
    type: NotifyChannels
    title: DefaultNamespace | string
    subtitle: DefaultNamespace | string
    helpText: DefaultNamespace | ReactNode
}

export const OtpPendingConfirmStep: FC<OptPendingConfirmStepProps> = ({type, title, subtitle, helpText}) => {
    const {t} = useTranslation()
    const {spacing} = useTheme()
    //otp store
    const coAlarmRequest = useOtpStore(state => state.coAlarmRequest)
    const isEditing = useOtpStore(state => state.isEditing)
    const otpChangeStep = useOtpStore(state => state.changeStep)
    const editedContact = useOtpStore(state => state.sendTo)
    const service = useOtpStore(state => state.service)
    const notifyToggleStepActive = useOtpStore(state => state.notifyToggleStepActive)
    //accepted store
    const updateServiceNotifyChannel = useServiceAcceptStore(state => state.updateServiceNotifyChannel)
    const [otp, setOtp] = useState('')
    //validate code mutation
    const {mutate: validateOtpCode, isPending} = useOtpValidationV2({
        onSuccess: () => {
            toast.success(t(`service_accept:common:code_verified_${type}`), {duration: 4500})
            updateServiceNotifyChannel(type, editedContact)
            otpChangeStep(notifyToggleStepActive ? 'channel_toggle' : 'base', false)
        },
        onError: error => errorHandler(error)
    })

    const onOptValueChange = (value: string) => {
        setOtp(value)
        if (value.length === 4) {
            if (coAlarmRequest && coAlarmRequest?.notification_preferences) {
                validateOtpCode({
                    urlParams: {
                        notifySettingsId: coAlarmRequest.notification_preferences.id
                    },
                    params: {
                        verification_code: Number(value),
                        service: type
                    }
                })
            } else if (service && service.notification_preferences) {
                validateOtpCode({
                    urlParams: {
                        notifySettingsId: service.notification_preferences.id
                    },
                    params: {
                        verification_code: Number(value),
                        service: type
                    }
                })
            } else {
                toast.error(t('errors:default'))
            }
        }
    }

    return (
        <StyledOtpStepWrapper direction={'column'} justify={'stretch'} gap={1}>
            <StyledOtpStepHeader justify={'space-between'} align={'center'}>
                <h2>{title}</h2>
                {isEditing && (
                    <Button
                        variant={'tertiary'}
                        size={'md'}
                        onClick={() => otpChangeStep(notifyToggleStepActive ? 'channel_toggle' : 'base', false)}
                    >
                        {t('commons:cancel')}
                    </Button>
                )}
            </StyledOtpStepHeader>
            <StyledOtpStepBody direction={'column'} gap={6}>
                <p>{subtitle}</p>
                <Flexbox align={'center'} gap={4}>
                    <OtpInput
                        containerStyle={{display: 'flex', gap: spacing * 2}}
                        skipDefaultStyles={true}
                        value={otp}
                        onChange={value => onOptValueChange(value)}
                        numInputs={4}
                        inputType={'number'}
                        renderInput={props => (
                            <StyledOtpInput
                                {...props}
                                //chrome on ios didn't fire the onPaste from the keyboard
                                onInput={event => onOptValueChange((event.target as HTMLInputElement).value)}
                                name="otp-code"
                                id={'otp-code'}
                                auto-complete="one-time-code"
                                disabled={isPending}
                            />
                        )}
                    />
                    {isPending && <Spinner size={26} />}
                </Flexbox>
                <p>{helpText}</p>
            </StyledOtpStepBody>
        </StyledOtpStepWrapper>
    )
}

OtpPendingConfirmStep.displayName = 'OtpPendingConfirmStep'
