import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {Content, FieldTitle} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC} from 'react'
import {zodResolver} from '@hookform/resolvers/zod'
import {useChangeStep} from '../../hooks/useChangeStep'
import {makeExperiencesFormDefaultValues} from '../../utils'
import {ExperienceExtended, FormFieldKeyName, StepKeyName, TransportingGuestsMode} from '../../types'
import {Controller, useWatch, useForm} from 'react-hook-form'
import {AnswerItem} from '@/components/commons/answer-item/AnswerItem'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {StyledList} from './style'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Divider} from '@/components/ui/divider/Divider'
import {z} from 'zod'
import {ExperienceLegalFooter, ExperienceLegalHeader} from './ExperienceLegalStep'

export const LegalNatureFormSchema = z
    .object({
        [FormFieldKeyName.enum.host_transports_guests]: z.boolean({
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        [FormFieldKeyName.enum.host_transporting_guests_method]: TransportingGuestsMode.array().nullable().optional(),
        [FormFieldKeyName.enum.host_serve_alcohol]: z.boolean().optional(),
        [FormFieldKeyName.enum.is_licensed_establishment]: z.boolean().optional().nullable(),
        [FormFieldKeyName.enum.host_serve_food]: z.boolean().optional(),
        [FormFieldKeyName.enum.prepared_in_restaurant]: z.boolean().optional().nullable(),
        [FormFieldKeyName.enum.host_discusses_poi_significance]: z.boolean().optional().nullable(),
        [FormFieldKeyName.enum.involves_national_park]: z.boolean({
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        }),
        [FormFieldKeyName.enum.involves_point_of_interest]: z.boolean({
            invalid_type_error: i18n.t('errors:field_required'),
            required_error: i18n.t('errors:field_required')
        })
    })
    .superRefine((arg, context) => {
        if (!!arg.involves_point_of_interest && typeof arg.host_discusses_poi_significance !== 'boolean') {
            context.addIssue({
                code: 'custom',
                path: ['host_discusses_poi_significance'],
                message: i18n.t('errors:field_required')
            })
        }
        if (!!arg.host_transports_guests && !arg.host_transporting_guests_method?.length) {
            context.addIssue({
                code: 'custom',
                path: ['host_transporting_guests_method'],
                message: i18n.t('errors:field_required')
            })
        }
    })

export type LegalNatureFormSchema = z.infer<typeof LegalNatureFormSchema>
export const NaturalLegal: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()

    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.accessibility,
        currentStep: StepKeyName.enum.legal,
        nextStep: StepKeyName.enum.terms
    })
    const defaultValues = makeExperiencesFormDefaultValues(experience).legal

    const form = useForm<LegalNatureFormSchema>({
        resolver: zodResolver(LegalNatureFormSchema),
        reValidateMode: 'onChange',
        defaultValues
    })

    const onSubmit = form.handleSubmit(data => {
        changeStep.handleChangeStep(false, data)
    })

    const [isTakeInterestingPointWatch, isTransportingGuestsWatch] = useWatch({
        control: form.control,
        name: ['involves_point_of_interest', 'host_transports_guests']
    })

    return (
        <>
            <Content>
                <ExperienceLegalHeader />

                {/* Is national park */}
                <Flexbox direction="column" gap={4} align="stretch">
                    <FieldTitle>{t('experiences:experience_legal_step:nature_question1_label')}</FieldTitle>
                    <StyledList>
                        <Controller
                            name="involves_national_park"
                            control={form.control}
                            render={({field}) => (
                                <>
                                    <AnswerItem
                                        type="radio"
                                        name="involves_national_park"
                                        value="true"
                                        label={
                                            <Flexbox align="center" justify="center" gap={2}>
                                                {t('commons:yes')}
                                            </Flexbox>
                                        }
                                        onChange={() => field.onChange(true)}
                                        checked={field.value === true}
                                    />
                                    <AnswerItem
                                        type="radio"
                                        name="involves_national_park"
                                        value="false"
                                        label={
                                            <Flexbox align="center" justify="center" gap={2}>
                                                {t('commons:no')}
                                            </Flexbox>
                                        }
                                        onChange={() => field.onChange(false)}
                                        checked={field.value === false}
                                    />
                                </>
                            )}
                        />
                    </StyledList>
                    {form.formState.errors.involves_national_park && (
                        <InputHelpText error={form.formState.errors.involves_national_park?.message} />
                    )}
                </Flexbox>

                <Divider direction="horizontal" />

                {/* Is take interesting point */}
                <Flexbox direction="column" gap={4} align="stretch">
                    <FieldTitle>{t('experiences:experience_legal_step:nature_question2_label')}</FieldTitle>
                    <StyledList>
                        <Controller
                            name="involves_point_of_interest"
                            control={form.control}
                            render={({field}) => (
                                <>
                                    <AnswerItem
                                        type="radio"
                                        name="involves_point_of_interest"
                                        value="true"
                                        label={
                                            <Flexbox align="center" justify="center" gap={2}>
                                                {t('commons:yes')}
                                            </Flexbox>
                                        }
                                        onChange={() => field.onChange(true)}
                                        checked={field.value === true}
                                    />
                                    <AnswerItem
                                        type="radio"
                                        name="involves_point_of_interest"
                                        value="false"
                                        label={
                                            <Flexbox align="center" justify="center" gap={2}>
                                                {t('commons:no')}
                                            </Flexbox>
                                        }
                                        onChange={() => {
                                            field.onChange(false)
                                            form.resetField('host_discusses_poi_significance', {
                                                defaultValue: null
                                            })
                                        }}
                                        checked={field.value === false}
                                    />
                                </>
                            )}
                        />
                    </StyledList>

                    {form.formState.errors.involves_point_of_interest && (
                        <InputHelpText error={form.formState.errors.involves_point_of_interest?.message} />
                    )}
                </Flexbox>

                {!!isTakeInterestingPointWatch && (
                    <Flexbox direction="column" gap={4} align="stretch">
                        <FieldTitle>{t('experiences:experience_legal_step:nature_question3_label')}</FieldTitle>
                        <StyledList>
                            <Controller
                                name="host_discusses_poi_significance"
                                control={form.control}
                                render={({field}) => (
                                    <>
                                        <AnswerItem
                                            type="radio"
                                            name="host_discusses_poi_significance"
                                            value="true"
                                            label={
                                                <Flexbox align="center" justify="center" gap={2}>
                                                    {t('commons:yes')}
                                                </Flexbox>
                                            }
                                            onChange={() => field.onChange(true)}
                                            checked={field.value == true}
                                        />
                                        <AnswerItem
                                            type="radio"
                                            name="host_discusses_poi_significance"
                                            value="false"
                                            label={
                                                <Flexbox align="center" justify="center" gap={2}>
                                                    {t('commons:no')}
                                                </Flexbox>
                                            }
                                            onChange={() => field.onChange(false)}
                                            checked={field.value == false}
                                        />
                                    </>
                                )}
                            />
                        </StyledList>

                        {form.formState.errors.host_discusses_poi_significance && (
                            <InputHelpText error={form.formState.errors.host_discusses_poi_significance?.message} />
                        )}
                    </Flexbox>
                )}
                <Divider direction="horizontal" />

                {/* Transport */}
                <Flexbox direction="column" gap={4} align="stretch">
                    <FieldTitle>{t('experiences:experience_legal_step:transporting_question1_label')}</FieldTitle>
                    <StyledList>
                        <Controller
                            name="host_transports_guests"
                            control={form.control}
                            render={({field}) => (
                                <>
                                    <AnswerItem
                                        type="radio"
                                        name="host_transports_guests"
                                        value="true"
                                        label={
                                            <Flexbox align="center" justify="center" gap={2}>
                                                {t('commons:yes')}
                                            </Flexbox>
                                        }
                                        onChange={() => field.onChange(true)}
                                        checked={field.value == true}
                                    />
                                    <AnswerItem
                                        type="radio"
                                        name="host_transports_guests"
                                        value="false"
                                        label={
                                            <Flexbox align="center" justify="center" gap={2}>
                                                {t('commons:no')}
                                            </Flexbox>
                                        }
                                        onChange={() => {
                                            field.onChange(false)
                                            form.resetField('host_transporting_guests_method', {defaultValue: []})
                                        }}
                                        checked={field.value == false}
                                    />
                                </>
                            )}
                        />
                    </StyledList>

                    {form.formState.errors.host_transports_guests && (
                        <InputHelpText error={form.formState.errors.host_transports_guests?.message} />
                    )}
                </Flexbox>

                {!!isTransportingGuestsWatch && (
                    <Flexbox direction="column" gap={4} align="stretch">
                        <FieldTitle>{t('experiences:experience_legal_step:transporting_question2_label')}</FieldTitle>
                        <StyledList>
                            <AnswerItem
                                type="checkbox"
                                value={'car'}
                                label={
                                    <Flexbox align="center" justify="center" gap={2}>
                                        {t('experiences:experience_legal_step:transporting_answers:car')}
                                    </Flexbox>
                                }
                                {...form.register('host_transporting_guests_method')}
                            />
                            <AnswerItem
                                type="checkbox"
                                value={'boat'}
                                label={
                                    <Flexbox align="center" justify="center" gap={2}>
                                        {t('experiences:experience_legal_step:transporting_answers:boat')}
                                    </Flexbox>
                                }
                                {...form.register('host_transporting_guests_method')}
                            />

                            <AnswerItem
                                type="checkbox"
                                value={'plane'}
                                label={
                                    <Flexbox align="center" justify="center" gap={2}>
                                        {t('experiences:experience_legal_step:transporting_answers:plane')}
                                    </Flexbox>
                                }
                                {...form.register('host_transporting_guests_method')}
                            />
                            <AnswerItem
                                type="checkbox"
                                value={'motorcycle'}
                                label={
                                    <Flexbox align="center" justify="center" gap={2}>
                                        {t('experiences:experience_legal_step:transporting_answers:motorcycle')}
                                    </Flexbox>
                                }
                                {...form.register('host_transporting_guests_method')}
                            />
                        </StyledList>

                        {form.formState.errors.host_transporting_guests_method && (
                            <InputHelpText error={form.formState.errors.host_transporting_guests_method?.message} />
                        )}
                    </Flexbox>
                )}
            </Content>

            <ExperienceLegalFooter
                onSubmit={onSubmit}
                onBack={() => changeStep.handleChangeStep(true)}
                isContinueLoading={changeStep.isContinueLoading}
                isBackLoading={changeStep.isBackLoading}
            />
        </>
    )
}
