import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps
} from '@/features/host-submissions/services/utils.tsx'
import {
    AlertBanner,
    Content,
    Footer,
    HeadingGroup,
    Paragraph,
    Title
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC} from 'react'
import {Service, UploadedMedia} from '@/features/host-submissions/services/types.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    StyledGalleryItemContainer,
    StyledGalleryItemImg,
    StyledGalleryGrid
} from '@/features/host-submissions/services/components/declined-gallery-photos/style'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {useUpdateStepToOfferings} from '@/features/host-submissions/services/queries/useUpdateStepToOfferings'

export const DeclinedGalleryPhotos: FC<{declinedPhoto: UploadedMedia[]; onContinue: () => void; service: Service}> = ({
    declinedPhoto,
    onContinue,
    service
}) => {
    const {t} = useTranslation()

    const updatesRequiredSteps = getUpdatesRequiredSteps(service)

    const updateStepToOfferingsMutation = useUpdateStepToOfferings({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })

    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'gallery', updatesRequiredSteps}).prev?.()
            : updateStepToOfferingsMutation
    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_gallery:title')}</Title>
                    <Paragraph>{t('services:step_gallery:paragraph')}</Paragraph>

                    <AlertBanner
                        title={t('services:gallery_improvement_title')}
                        paragraph={t('services:update_required_paragraph')}
                    />
                </HeadingGroup>

                <StyledGalleryGrid>
                    {declinedPhoto.map((item, index) => (
                        <Flexbox direction="column" gap={2} width="100%" height="100%" key={index}>
                            <StyledGalleryItemContainer>
                                <StyledGalleryItemImg src={item.url} alt={'img'} />
                            </StyledGalleryItemContainer>

                            <FlagsAlertText flags={item?.parent_flags} title={t('commons:photo')} />
                        </Flexbox>
                    ))}
                </StyledGalleryGrid>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('gallery')}>
                <Button
                    variant="tertiary"
                    disabled={prevStepMutation?.isPending}
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>

                <Button disabled={prevStepMutation?.isPending} onClick={onContinue}>
                    {t('services:step_gallery:upload_more_photo')}
                </Button>
            </Footer>
        </>
    )
}
