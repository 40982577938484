import {handleCloseSlidingModal, SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {StyledHeaderTitle, StyledList, StyledModalHeader, StyledSlidingModal, StyledTitle} from './style'
import {Button} from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon'
import {useTranslation} from '@/translations/i18n'
import {ModalBody} from '@/components/ui/modal-atoms/ModalAtoms'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {flagToDescription} from '@/features/host-submissions/services/utils'
import {FC, ReactNode} from 'react'
import {ParentFlag} from '@/features/host-submissions/types'
import {ExperiencesFlag} from '../../experiences/types'
import {experiencesFlagToDescription} from '../../experiences/utils'

interface FlagsModalProps {
    flags: ExperiencesFlag[] | ParentFlag[]
    feature: 'experiences' | 'services'
    title: ReactNode
    setSlidingModalState: (slidingModalState: SlidingModalState) => void
    slidingModalState: SlidingModalState
    detailLevel?: 'default' | 'offering_title' | 'offering_description'
}

export const FlagsModal: FC<FlagsModalProps> = ({
    flags,
    title,
    setSlidingModalState,
    slidingModalState,
    detailLevel = 'default',
    feature
}) => {
    const {t} = useTranslation()

    const getDescription = (flag: ParentFlag | ExperiencesFlag) => {
        if (feature === 'services') {
            return flagToDescription((flag as ParentFlag).key_name, detailLevel)
        } else {
            return experiencesFlagToDescription((flag as ExperiencesFlag).keyName)
        }
    }

    return (
        <StyledSlidingModal
            slidingModalState={slidingModalState}
            onOverlayClick={() => handleCloseSlidingModal(setSlidingModalState)}
        >
            <StyledModalHeader>
                <Button variant="ghost" shape="square" onClick={() => handleCloseSlidingModal(setSlidingModalState)}>
                    <XCloseIcon size={36} />
                </Button>
                <StyledHeaderTitle>{title}</StyledHeaderTitle>
            </StyledModalHeader>
            <ModalBody>
                <Flexbox direction="column" gap={4}>
                    <StyledTitle>{t('host_review:flag_alert_text')}</StyledTitle>
                    <StyledList>
                        {flags.map(flag => {
                            const description = getDescription(flag as ParentFlag)

                            return (
                                <li key={(flag as ParentFlag)?.key_name ?? (flag as ExperiencesFlag)?.keyName}>
                                    {description}
                                </li>
                            )
                        })}
                    </StyledList>
                </Flexbox>
            </ModalBody>
        </StyledSlidingModal>
    )
}
