import {ActionCard} from '@/features/service-actions/components/action-card/ActionCard.tsx'
import {CalendarMinus02Icon} from '@components/ui/icon'
import {TKey, useTranslation} from '@/translations/i18n.tsx'
import {Dispatch, FC, SetStateAction, useState} from 'react'
import {ServiceType} from '@/types/commons.ts'
import {Quote} from '@/features/service/types.ts'
import {httpClickAction} from '@/features/service-actions/http/actions.http'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'

const serviceTypeToParagraphTKey = {
    home: 'service_details:actions:block_calendar_date:paragraph:home',
    experience_photo: 'service_details:actions:block_calendar_date:paragraph:home',
    experience_video: 'service_details:actions:block_calendar_date:paragraph:experience_video',
    matterport: 'service_details:actions:block_calendar_date:paragraph:matterport',
    walkthrough: 'service_details:actions:block_calendar_date:paragraph:walkthrough'
} as const satisfies Record<ServiceType, TKey>

interface BlockCalendarDateActionProps {
    actionId: ServiceAction['id']
    isCompleted: boolean
    link?: string
    quoteId: Quote['id']
    serviceType: ServiceType
    setPendingActionsCount: Dispatch<SetStateAction<number>>
}

export const BlockCalendarDateAction: FC<BlockCalendarDateActionProps> = ({
    actionId,
    isCompleted,
    link,
    serviceType,
    setPendingActionsCount
}) => {
    const {t} = useTranslation()
    const [isClicked, setIsClicked] = useState(false)
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()

    const clickAction = () => {
        window.open(link, '_blank')
        void httpClickAction({...urlParams, actionId})
        setIsClicked(true)
        setPendingActionsCount(state => state - 1)
    }

    return (
        <ActionCard
            onClick={link ? clickAction : undefined}
            isCompleted={isClicked || isCompleted}
            icon={CalendarMinus02Icon}
            paragraph={t(serviceTypeToParagraphTKey[serviceType])}
            title={t('service_details:actions:block_calendar_date:title')}
        />
    )
}

BlockCalendarDateAction.displayName = 'BlockCalendarDateAction'
