import {FC, useState} from 'react'
import {ModalBody, ModalFooter, ModalHeader, ModalXCloseButton} from '@components/ui/modal-atoms/ModalAtoms.tsx'
import {Trans, useTranslation} from '@/translations/i18n.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useAsync} from '@hooks/useAsync.ts'
import {errorHandler} from '@utilities/helpers.ts'
import {
    httpAddPhoneNumber,
    httpResendVerificationCode,
    httpVerifyPhoneNumber
} from '@/features/host-submissions/http/hostSubmissions.http'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledModal,
    StyledParagraph,
    StyledPhoneInputWrapper,
    StyledTitle
} from '@/features/host-submissions/components/change-phone-modal/style'
import PhoneInput from 'react-phone-input-2'
import {Label} from '@components/ui/label/Label.tsx'
import {StyledOtpInput} from '@/features/otp-flow/components/style.ts'
import OtpInput from 'react-otp-input'
import {useTheme} from 'styled-components'
import toast from 'react-hot-toast'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

interface PhoneModalProps {
    onClose: () => void
    onSuccess: (phoneNumber: string) => void
}
export const ChangePhoneModal: FC<PhoneModalProps> = ({onClose, onSuccess}) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const [step, setStep] = useState<'enter' | 'confirm'>('enter')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const addPhoneNumberAsync = useAsync()
    const verifyPhoneNumberAsync = useAsync()
    const resendVerificationCodeAsync = useAsync()

    const onSubmit = async () => {
        try {
            await addPhoneNumberAsync.run(httpAddPhoneNumber({payload: {phone: phoneNumber}}))
            setStep('confirm')
        } catch (error) {
            errorHandler(error)
        }
    }

    const onConfirm = async () => {
        try {
            await verifyPhoneNumberAsync.run(
                httpVerifyPhoneNumber({payload: {verificationCode: Number(verificationCode)}})
            )
            onSuccess(phoneNumber)

            onClose()
        } catch (error) {
            errorHandler(error)
        }
    }

    const onResendCode = async () => {
        try {
            await resendVerificationCodeAsync.run(httpResendVerificationCode())
            toast.success(t('services:step_host_expertise_about_yourself:send_success'))
        } catch (error) {
            errorHandler(error)
        }
    }

    return (
        <StyledModal width={360} onOverlayClick={onClose}>
            <ModalHeader>
                <ModalXCloseButton onClick={onClose} />
                {t('services:step_host_expertise_about_yourself:phone_title')}
            </ModalHeader>
            <ModalBody>
                {step == 'confirm' ? (
                    <>
                        <Flexbox direction="column" gap={2}>
                            <StyledTitle>
                                {t('services:step_host_expertise_about_yourself:phone_modal_confirm_title')}
                            </StyledTitle>
                            <StyledParagraph>
                                <Trans
                                    i18nKey="services:step_host_expertise_about_yourself:send_again"
                                    components={{
                                        cta: (
                                            <Button
                                                variant="tertiary"
                                                shape="text"
                                                onClick={onResendCode}
                                                disabled={resendVerificationCodeAsync.isLoading}
                                                style={{display: 'inline-flex'}}
                                            />
                                        )
                                    }}
                                />
                            </StyledParagraph>
                        </Flexbox>
                        <OtpInput
                            containerStyle={{display: 'flex', gap: theme.spacing * 2}}
                            skipDefaultStyles
                            value={verificationCode}
                            onChange={setVerificationCode}
                            numInputs={4}
                            inputType={'number'}
                            renderInput={props => (
                                <StyledOtpInput
                                    {...props}
                                    //chrome on ios didn't fire the onPaste from the keyboard
                                    onInput={event => setVerificationCode((event.target as HTMLInputElement).value)}
                                    name="otp-code"
                                    id={'otp-code'}
                                    auto-complete="one-time-code"
                                    disabled={verifyPhoneNumberAsync.isLoading}
                                />
                            )}
                        />
                    </>
                ) : (
                    <>
                        <Flexbox direction="column" gap={2}>
                            <StyledTitle>
                                {t('services:step_host_expertise_about_yourself:phone_modal_enter_title')}
                            </StyledTitle>
                            <StyledParagraph>
                                {t('services:step_host_expertise_about_yourself:phone_modal_enter_description')}
                            </StyledParagraph>
                        </Flexbox>
                        <StyledPhoneInputWrapper>
                            <Label>{t('services:step_host_expertise_about_yourself:phone_title')}</Label>
                            <PhoneInput
                                countryCodeEditable={false}
                                enableAreaCodes={false}
                                enableTerritories={true}
                                country={'us'}
                                placeholder={t('services:step_host_expertise_about_yourself:phone_modal_enter_title')}
                                value={phoneNumber}
                                onChange={setPhoneNumber}
                            />
                        </StyledPhoneInputWrapper>
                    </>
                )}
            </ModalBody>
            <ModalFooter justify="space-between">
                <Button variant="tertiary" onClick={onClose}>
                    {t('commons:cancel')}
                </Button>
                {step == 'confirm' ? (
                    <Button onClick={onConfirm} disabled={!verificationCode || verifyPhoneNumberAsync.isLoading}>
                        {t('commons:confirm')}
                        {verifyPhoneNumberAsync.isLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button onClick={onSubmit} disabled={phoneNumber.length < 10 || addPhoneNumberAsync.isLoading}>
                        {t('commons:submit')}
                        {addPhoneNumberAsync.isLoading && <Spinner />}
                    </Button>
                )}
            </ModalFooter>
        </StyledModal>
    )
}
