import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {useEffect, useState} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Label} from '@/components/ui/label/Label'
import {Button} from '@/components/ui/button/Button'
import {PreviewImage} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'
import InputText from '@/components/commons/input-text/InputText'
import TextArea from '@/components/commons/textarea/TextArea'
import {SlidingModalState} from '@/components/commons/sliding-modal/SlidingModal'
import {Image01Icon} from '@/components/ui/icon'
import {useForm, FieldErrors} from 'react-hook-form'
import {z} from 'zod'
import {zodResolver} from '@hookform/resolvers/zod'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {UploadActivityPhotoModal} from '../upload-activity-photo-modal/UploadActivityPhotoModal'
import {GalleryItem} from '@/features/host-submissions/types'
import {StyledContent, StyledImagePlaceholderBox, StyledPreviewImageWrapper, StyledText} from './style'

const DESCRIPTION_MIN_LENGTH = 30
const ACTIVITY_TITLE_MAX_LENGTH = 20
const ACTIVITY_DESCRIPTION_MAX_LENGTH = 60

export const EditActivityValidationSchema = z.object({
    improvedTitle: z.string().min(1, {message: i18n.t('errors:field_required')}),
    improvedDescription: z.string({required_error: i18n.t('errors:field_required')}).min(DESCRIPTION_MIN_LENGTH, {
        message: i18n.t('errors:field_min_character', {count: DESCRIPTION_MIN_LENGTH})
    }),
    mainPhoto: GalleryItem.extend({
        src: z
            .string({required_error: i18n.t('errors:field_required')})
            .min(1, {message: i18n.t('errors:field_required')})
            .optional()
    })
        .nullable()
        .refine(data => !!data?.src, {
            message: i18n.t('errors:field_required'),
            path: ['src']
        }),
    id: z.number()
})
export type EditActivityValidationSchema = z.infer<typeof EditActivityValidationSchema>

interface EditSectionProps {
    title: string
    description: string
    mainPhoto: GalleryItem | null
    onEdit?: () => void
    onConfirm?: (data: EditActivityValidationSchema) => void
    disabled?: boolean
    experienceId: number
    galleryId: number
    id: number
    activityNumber: number
    errorMessages?: FieldErrors<EditActivityValidationSchema>
}
export const EditActivitySection = ({
    description,
    mainPhoto,
    title,
    disabled = false,
    id,
    onEdit,
    onConfirm,
    galleryId,
    activityNumber,
    experienceId,
    errorMessages
}: EditSectionProps) => {
    const {t} = useTranslation()
    const [isEditMode, setIsEditMode] = useState(false)
    const [slidingModalState, setSlidingModalState] = useState<SlidingModalState>('closed')

    const defaultValues = {
        improvedTitle: title,
        improvedDescription: description,
        mainPhoto: mainPhoto,
        id
    }

    const form = useForm<EditActivityValidationSchema>({
        resolver: zodResolver(EditActivityValidationSchema),
        defaultValues
    })

    useEffect(() => {
        form.reset(defaultValues)
    }, [title, description, mainPhoto?.src])

    const mainPhotoWatch = form.watch('mainPhoto')
    const titleWatch = form.watch('improvedTitle')
    const descriptionWatch = form.watch('improvedDescription')

    const onSubmit = form.handleSubmit(data => {
        onConfirm?.(data)
        setIsEditMode(false)
    })

    const changeMode = () => {
        if (isEditMode) {
            onSubmit()
        } else {
            setIsEditMode(true)
            onEdit?.()
        }
    }
    return (
        <StyledContent gap={4} width="100%" direction="column" $disabled={disabled && !isEditMode}>
            <Flexbox justify="space-between" gap={2} width="100%">
                <Label>{t('experiences:enhance_your_experience_step:activity_name', {count: activityNumber})}</Label>
                <Button disabled={disabled && !isEditMode} shape="text" variant="tertiary" onClick={changeMode}>
                    {t(isEditMode ? 'commons:done' : 'commons:edit')}
                </Button>
            </Flexbox>
            <Flexbox direction="column" gap={1.6}>
                <Flexbox align="center" gap={4}>
                    {mainPhotoWatch?.src ? (
                        <StyledPreviewImageWrapper>
                            <PreviewImage src={mainPhotoWatch.src} />
                        </StyledPreviewImageWrapper>
                    ) : (
                        <StyledImagePlaceholderBox>
                            <Image01Icon />
                        </StyledImagePlaceholderBox>
                    )}

                    {isEditMode && (
                        <Button variant="secondary" onClick={() => setSlidingModalState('open')}>
                            {t('commons:change_photo')}
                        </Button>
                    )}
                </Flexbox>
                <InputHelpText
                    error={form.formState.errors.mainPhoto?.src?.message ?? errorMessages?.mainPhoto?.src?.message}
                />
            </Flexbox>
            {isEditMode ? (
                <InputText
                    type={'text'}
                    label={t('experiences:enhance_your_experience_step:activity_title_label')}
                    maxLength={ACTIVITY_TITLE_MAX_LENGTH}
                    helpText={t('commons:x_characters_available', {
                        count: ACTIVITY_TITLE_MAX_LENGTH - titleWatch.length
                    })}
                    errorMessage={form.formState.errors.improvedTitle?.message}
                    {...form.register('improvedTitle')}
                />
            ) : (
                <Flexbox direction="column" gap={1.5} width="100%">
                    <Label>{t('experiences:enhance_your_experience_step:activity_title_label')}</Label>
                    <StyledText>{title}</StyledText>
                    <InputHelpText error={errorMessages?.improvedTitle?.message} />
                </Flexbox>
            )}

            {isEditMode ? (
                <TextArea
                    rows={3}
                    label={t('experiences:enhance_your_experience_step:activity_description_label')}
                    maxLength={ACTIVITY_DESCRIPTION_MAX_LENGTH}
                    helpText={t('commons:x_characters_available', {
                        count: ACTIVITY_DESCRIPTION_MAX_LENGTH - descriptionWatch.length
                    })}
                    errorMessage={form.formState.errors.improvedDescription?.message}
                    {...form.register('improvedDescription')}
                />
            ) : (
                <Flexbox direction="column" gap={1.5} width="100%">
                    <Label>{t('experiences:enhance_your_experience_step:activity_description_label')}</Label>
                    <StyledText>{description}</StyledText>
                    <InputHelpText error={errorMessages?.improvedDescription?.message} />
                </Flexbox>
            )}

            {slidingModalState != 'closed' && (
                <UploadActivityPhotoModal
                    slidingModalState={slidingModalState}
                    setSlidingModalState={setSlidingModalState}
                    title={t('experiences:enhance_your_experience_step:change_activity_photo', {count: activityNumber})}
                    setMainPhotoValue={(mainPhoto: GalleryItem | null) => form.setValue('mainPhoto', mainPhoto)}
                    activityMainPhoto={mainPhotoWatch}
                    experienceId={experienceId}
                    galleryId={galleryId}
                />
            )}
        </StyledContent>
    )
}
