import {StyledHeader, StyledLayout} from '@pages/experience/style.ts'
import {useEffect, useState} from 'react'
import {LangSelectionModal} from '@components/commons/lang-selection-modal/LangSelectionModal.tsx'
import {ChangeLangModalTrigger} from '@components/commons/change-lang-modal-trigger/ChangeLangModalTrigger.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {AlertCircleIcon, ChevronLeftIcon} from '@components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'
import {routes} from '@utilities/constants'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {raise} from '@utilities/helpers.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {SlidingModalState} from '@components/commons/sliding-modal/SlidingModal.tsx'
import {ExperiencesSupportModal} from '@/features/host-submissions/experiences/components/experiences-support-modal/ExperiencesSupportModal'
import {ExperienceDiscountsStep} from '@/features/host-submissions/experiences/components/experience-discounts-step/ExperienceDiscountsStep'
import {ExperienceAboutYourselfMoreInfoStep} from '@/features/host-submissions/experiences/components/experience-about-yourself-more-info-step/ExperienceAboutYourselfMoreInfoStep'
import {ExperiencePricingStep} from '@/features/host-submissions/experiences/components/experience-pricing-step/ExperiencePricingStep'
import {ExperienceStep} from '@/features/host-submissions/experiences/components/experience-step/ExperienceStep'
import {ExperienceLocationStep} from '@/features/host-submissions/experiences/components/experience-location-step/ExperienceLocationStep'
import {ExperienceExtended, StepKeyName} from '@/features/host-submissions/experiences/types'
import {ExperienceAboutYourselfStep} from '@/features/host-submissions/experiences/components/experience-about-yourself-step/ExperienceAboutYourselfStep'
import {ExperienceCategoryStep} from '@/features/host-submissions/experiences/components/experience-category-step/ExperienceCategoryStep'
import {ExperienceSettingsStep} from '@/features/host-submissions/experiences/components/experience-settings-step/ExperienceSettingsStep'
import {ExperienceAvailabilityStep} from '@/features/host-submissions/experiences/components/experience-availability-step/ExperienceAvailabilityStep'
import {ExperienceAccessibilityStep} from '@/features/host-submissions/experiences/components/experience-accessibility-step/ExperienceAccessibilityStep'
import {ExperienceGuestRequirementsStep} from '@/features/host-submissions/experiences/components/experience-guest-requirements-step/ExperienceGuestRequirementsStep'
import {ExperienceLegalStep} from '@/features/host-submissions/experiences/components/experience-legal-step/ExperienceLegalStep'
import {ExperiencesTermsStep} from '@/features/host-submissions/experiences/components/experiences-terms-step/ExperiencesTermsStep'
import {EnhanceYourExperienceStep} from '@/features/host-submissions/experiences/components/enhance-your-experience-step/EnhanceYourExperienceStep'
import {EnhanceAboutYouStep} from '@/features/host-submissions/experiences/components/enhance-about-you-step/EnhanceAboutYouStep'
import {EnhanceGalleryStep} from '@/features/host-submissions/experiences/components/enhance-gallery-step/EnhanceGalleryStep'
import {SuggestionsStep} from '@/features/host-submissions/experiences/components/suggestions-step/SuggestionsStep'
import {ApprovedStep} from '@/features/host-submissions/components/approved-step/ApprovedStep'
import {useExperience} from '@/features/host-submissions/experiences/queries/useExperience'
import {SentForReviewStep} from '@/features/host-submissions/components/sent-for-review-step/SentForReviewStep'
import {PendingIdVerificationStep} from '@/features/host-submissions/components/pending-id-verification-step/PendingIdVerificationStep'
import {GetStartedStep} from '@/features/host-submissions/experiences/components/get-started-step/GetStartedStep'
import {ExperienceItineraryStep} from '@/features/host-submissions/experiences/components/experience-itinerary-step/ExperienceItineraryStep'
import {ExperienceGalleryStep} from '@/features/host-submissions/experiences/components/experience-gallery-step/ExperienceGalleryStep'
import {DeclinedStep} from '@/features/host-submissions/services/components/declined-step/DeclinedStep'
import {Button} from '@/components/ui/button/Button'

export const Experience = () => {
    const {t} = useTranslation()
    const [isLanguageModalVisible, setIsLanguageModalVisible] = useState(false)
    const [supportSlidingModalState, setSupportSlidingModalState] = useState<SlidingModalState>('closed')
    const urlParams = useURLParsedParams(z.object({id: z.coerce.number()}))
    const experienceQuery = useExperience(urlParams.success ? urlParams.data.id : raise('Invalid urlParams'))

    useEffect(() => {
        scrollTo({top: 0, left: 0, behavior: 'instant'})
    }, [experienceQuery.data?.status, experienceQuery.data?.step])

    const getStepComponent = () => {
        if (experienceQuery.isPending) {
            return (
                <Flexbox align="center" justify="center">
                    <Spinner size={48} />
                </Flexbox>
            )
        }
        if (experienceQuery.isError) {
            return <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
        }

        if (experienceQuery.data.status == 'declined') {
            return <DeclinedStep title="experiences:step_declined:title" />
        }

        if (
            experienceQuery.data.status == 'approved' ||
            experienceQuery.data.status == 'pending_export' ||
            experienceQuery.data.status == 'pending_publishing' ||
            experienceQuery.data.status == 'publishing_enabled'
        ) {
            return <ApprovedStep description="experiences:step_approved:paragraph" />
        }

        if (experienceQuery.data.status == 'under_review') {
            return <SentForReviewStep />
        }
        if (experienceQuery.data.status == 'pending_id_verification') {
            return <PendingIdVerificationStep />
        }

        const stepComponents = {
            [StepKeyName.enum.home]: <GetStartedStep experience={experienceQuery.data} />,
            [StepKeyName.enum.about_you]: <ExperienceAboutYourselfStep experience={experienceQuery.data} />,
            [StepKeyName.enum.about_you_more_info]: (
                <ExperienceAboutYourselfMoreInfoStep experience={experienceQuery.data} />
            ),
            [StepKeyName.enum.location]: <ExperienceLocationStep experience={experienceQuery.data} />,
            [StepKeyName.enum.gallery]: <ExperienceGalleryStep experience={experienceQuery.data} />,
            [StepKeyName.enum.experience]: <ExperienceStep experience={experienceQuery.data} />,
            [StepKeyName.enum.itinerary]: <ExperienceItineraryStep experience={experienceQuery.data} />,
            [StepKeyName.enum.pricing]: <ExperiencePricingStep experience={experienceQuery.data} />,
            [StepKeyName.enum.discounts]: <ExperienceDiscountsStep experience={experienceQuery.data} />,
            [StepKeyName.enum.category]: (
                <ExperienceCategoryStep
                    experience={experienceQuery.data}
                    experienceCategory={experienceQuery.data.category}
                />
            ),
            [StepKeyName.enum.settings]: <ExperienceSettingsStep experience={experienceQuery.data} />,
            [StepKeyName.enum.availability]: <ExperienceAvailabilityStep experience={experienceQuery.data} />,
            [StepKeyName.enum.guests]: <ExperienceGuestRequirementsStep experience={experienceQuery.data} />,
            [StepKeyName.enum.accessibility]: <ExperienceAccessibilityStep experience={experienceQuery.data} />,
            [StepKeyName.enum.legal]: <ExperienceLegalStep experience={experienceQuery.data} />,
            [StepKeyName.enum.terms]: <ExperiencesTermsStep experience={experienceQuery.data} />,
            [StepKeyName.enum.suggestions]: <SuggestionsStep experience={experienceQuery.data} />,
            [StepKeyName.enum.enhance_your_experience]: <EnhanceYourExperienceStep experience={experienceQuery.data} />,
            [StepKeyName.enum.enhance_about_you]: <EnhanceAboutYouStep experience={experienceQuery.data} />,
            [StepKeyName.enum.enhance_gallery]: <EnhanceGalleryStep experience={experienceQuery.data} />
        } as const satisfies Record<NonNullable<ExperienceExtended['step']['keyName']>, React.ReactNode>

        return stepComponents[experienceQuery.data.step.keyName]
    }

    return (
        <StyledLayout>
            <StyledHeader>
                <ButtonLink to={routes.EXPERIENCES.path} size="sm" variant="tertiary">
                    <ChevronLeftIcon />
                    {t('experiences:title')}
                </ButtonLink>
                <Flexbox gap={2} align={'center'}>
                    <Button variant={'tertiary'} size={'sm'} onClick={() => setSupportSlidingModalState('open')}>
                        {t('commons:need_help')}
                    </Button>
                    {supportSlidingModalState != 'closed' && (
                        <ExperiencesSupportModal
                            slidingModalState={supportSlidingModalState}
                            setSlidingModalState={setSupportSlidingModalState}
                        />
                    )}
                    <ChangeLangModalTrigger showCallback={() => setIsLanguageModalVisible(true)} />
                    {isLanguageModalVisible && (
                        <LangSelectionModal onCloseCb={() => setIsLanguageModalVisible(false)} />
                    )}
                </Flexbox>
            </StyledHeader>
            {getStepComponent()}
        </StyledLayout>
    )
}
