import {useTranslation} from '@/translations/i18n.tsx'
import {Content, HeadingGroup, Footer} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC} from 'react'
import {errorHandler} from '@utilities/helpers.ts'
import {
    StyledButton,
    StyledVideo,
    StyledTitle,
    StyledParagraph,
    StyledDescription,
    StyledFooterText
} from '@/features/host-submissions/services/components/get-started-step/style.ts'
import Logo from '@/assets/videos/airbnb_logo.mp4'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useUpdateStepToHostExpertiseCareer} from '@/features/host-submissions/services/queries/useUpdateStepToHostExpertiseCareer'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const GetStartedStep: FC<{serviceId: number; expertiseId: number; isUpgrade?: boolean}> = ({
    serviceId,
    expertiseId,
    isUpgrade = false
}) => {
    const {t} = useTranslation()
    const updateStepToHostExpertiseCareerMutation = useUpdateStepToHostExpertiseCareer({
        serviceId,
        expertiseId,
        options: {onError: errorHandler}
    })

    return (
        <>
            <Content gap={0}>
                <StyledVideo src={Logo} autoPlay muted playsInline />
                <Flexbox direction="column" gap={6}>
                    <HeadingGroup direction="column" align="center" gap={4} width="100%">
                        <StyledTitle>
                            {t(
                                isUpgrade ? 'services:step_get_started:title_update' : 'services:step_get_started:title'
                            )}
                        </StyledTitle>
                        <StyledDescription>
                            {t(
                                isUpgrade
                                    ? 'services:step_get_started:description_update'
                                    : 'services:step_get_started:description'
                            )}
                        </StyledDescription>
                    </HeadingGroup>
                    <Flexbox direction="column" gap={4}>
                        <StyledParagraph>
                            <span>1</span>
                            <p>
                                {t(
                                    isUpgrade
                                        ? 'services:step_get_started:paragraph1_update'
                                        : 'services:step_get_started:paragraph1'
                                )}
                            </p>
                        </StyledParagraph>
                        <StyledParagraph>
                            <span>2</span>
                            <p>
                                {t(
                                    isUpgrade
                                        ? 'services:step_get_started:paragraph2_update'
                                        : 'services:step_get_started:paragraph2'
                                )}
                            </p>
                        </StyledParagraph>
                        <StyledParagraph>
                            <span>3</span>
                            <p>
                                {t(
                                    isUpgrade
                                        ? 'services:step_get_started:paragraph3_update'
                                        : 'services:step_get_started:paragraph3'
                                )}
                            </p>
                        </StyledParagraph>

                        {isUpgrade && (
                            <StyledParagraph>
                                <span>4</span>
                                {t('services:step_get_started:paragraph4_update')}
                            </StyledParagraph>
                        )}
                    </Flexbox>

                    <StyledFooterText>
                        {t(isUpgrade ? 'host_review:get_started_footer_update' : 'services:step_get_started:footer')}
                    </StyledFooterText>
                </Flexbox>
            </Content>
            <Footer>
                <Flexbox width={'100%'} justify={'center'}>
                    <StyledButton
                        fullWidth
                        size="lg"
                        disabled={updateStepToHostExpertiseCareerMutation.isPending}
                        onClick={() => updateStepToHostExpertiseCareerMutation.mutate({category: 'generic'})}
                    >
                        {t('commons:get_started')}
                        {updateStepToHostExpertiseCareerMutation.isPending && <Spinner />}
                    </StyledButton>
                </Flexbox>
            </Footer>
        </>
    )
}
