import {ForwardRefExoticComponent, ReactNode, RefAttributes} from 'react'
import {
    Activity,
    ActivityQuestionKeyName,
    Category,
    CategoryFormat,
    ExperienceExtended,
    ExperiencesFlag,
    FormFieldKeyName,
    QuestionKeyName,
    StepKeyName
} from '@/features/host-submissions/experiences/types'
import {
    AlertTriangleIcon,
    ArtIcon,
    BuildingsIcon,
    FoodIcon,
    GymIcon,
    IconProps,
    NatureIcon,
    WaveIcon
} from '@/components/ui/icon'
import i18n, {TKey, Trans} from '@/translations/i18n'
import {EditExperienceAnswers} from './http/experiences.http'
import countryToCurrency, {Countries} from 'country-to-currency'

const progressSteps = [
    StepKeyName.enum.category,
    StepKeyName.enum.about_you,
    StepKeyName.enum.about_you_more_info,
    StepKeyName.enum.location,
    StepKeyName.enum.gallery,
    StepKeyName.enum.experience,
    StepKeyName.enum.itinerary,
    StepKeyName.enum.pricing,
    StepKeyName.enum.discounts,
    StepKeyName.enum.settings,
    StepKeyName.enum.availability,
    StepKeyName.enum.guests,
    StepKeyName.enum.accessibility,
    StepKeyName.enum.legal,
    StepKeyName.enum.terms
] as const satisfies ReadonlyArray<NonNullable<ExperienceExtended['step']['keyName']>>

export const getExperiencesStepProgressPercentage = (stepName: NonNullable<ExperienceExtended['step']['keyName']>) => {
    const stepIndex = progressSteps.findIndex(progressStep => progressStep == stepName)
    return ((stepIndex + 1) / progressSteps.length) * 100
}
export const categoryToLabel = {
    [Category.enum.history_culture]: 'experiences:category:history_culture',
    [Category.enum.food_drink]: 'experiences:category:food_drink',
    [Category.enum.nature_outdoors]: 'experiences:category:nature_outdoors',
    [Category.enum.art_design]: 'experiences:category:art_design',
    [Category.enum.entertainment]: 'experiences:category:entertainment',
    [Category.enum.fitness_wellness]: 'experiences:category:fitness_wellness',
    [Category.enum.generic]: 'services:no_category'
} as const satisfies Record<Category, TKey>

export const categoryToIcon = {
    [Category.enum.history_culture]: BuildingsIcon,
    [Category.enum.food_drink]: FoodIcon,
    [Category.enum.nature_outdoors]: NatureIcon,
    [Category.enum.art_design]: ArtIcon,
    [Category.enum.entertainment]: WaveIcon,
    [Category.enum.fitness_wellness]: GymIcon,
    [Category.enum.generic]: AlertTriangleIcon
} as const satisfies Record<Category, ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>>

export const categoryFormatToLabel = {
    [CategoryFormat.enum.landmark_tour]: 'experiences:sub_category:landmark_tour',
    [CategoryFormat.enum.museum_tour]: 'experiences:sub_category:museum_tour',
    [CategoryFormat.enum.cultural_tour]: 'experiences:sub_category:cultural_tour',
    [CategoryFormat.enum.live_performances]: 'experiences:sub_category:live_performances',
    [CategoryFormat.enum.food_tour]: 'experiences:sub_category:food_tour',
    [CategoryFormat.enum.tasting]: 'experiences:sub_category:tastings',
    [CategoryFormat.enum.cooking_classes]: 'experiences:sub_category:food_drink_class',
    [CategoryFormat.enum.dining]: 'experiences:sub_category:dining',
    [CategoryFormat.enum.gallery_tour]: 'experiences:sub_category:gallery_tour',
    [CategoryFormat.enum.art_workshop]: 'experiences:sub_category:art_workshop',
    [CategoryFormat.enum.architecture_tour]: 'experiences:sub_category:architecture_tour',
    [CategoryFormat.enum.shopping_fashion]: 'experiences:sub_category:shopping_fashion',
    [CategoryFormat.enum.outdoor]: 'experiences:sub_category:nature_tours_hikes',
    [CategoryFormat.enum.water_sports]: 'experiences:sub_category:water_activities',
    [CategoryFormat.enum.flying]: 'experiences:sub_category:rides',
    [CategoryFormat.enum.wildlife]: 'experiences:sub_category:animal_experiences',
    [CategoryFormat.enum.concerts]: 'experiences:sub_category:music',
    [CategoryFormat.enum.theater_dance]: 'experiences:sub_category:theater_dance',
    [CategoryFormat.enum.nightlife]: 'experiences:sub_category:nightlife',
    [CategoryFormat.enum.games]: 'experiences:sub_category:games',
    [CategoryFormat.enum.workouts]: 'experiences:sub_category:sports_workouts',
    [CategoryFormat.enum.wellness]: 'experiences:sub_category:mindfulness',
    [CategoryFormat.enum.beauty]: 'experiences:sub_category:self_care',

    [CategoryFormat.enum.catering]: 'services:category_catering',
    [CategoryFormat.enum.chef]: 'services:category_chef',
    [CategoryFormat.enum.prepared_meals]: 'services:category_prepared_meals',
    [CategoryFormat.enum.photography]: 'services:category_photography',
    [CategoryFormat.enum.massage]: 'services:category_massage',
    [CategoryFormat.enum.hair_styling]: 'services:category_hair_styling',
    [CategoryFormat.enum.makeup]: 'services:category_makeup',
    [CategoryFormat.enum.nails]: 'services:category_nails',
    [CategoryFormat.enum.personal_training]: 'services:category_personal_training',
    [CategoryFormat.enum.spa_treatments]: 'services:category_spa_treatments'
} as const satisfies Record<CategoryFormat, TKey>

export const categoryToCategoryFormats = {
    [Category.enum.history_culture]: [
        CategoryFormat.enum.landmark_tour,
        CategoryFormat.enum.museum_tour,
        CategoryFormat.enum.cultural_tour,
        CategoryFormat.enum.live_performances
    ],
    [Category.enum.food_drink]: [
        CategoryFormat.enum.food_tour,
        CategoryFormat.enum.cooking_classes,
        CategoryFormat.enum.tasting,
        CategoryFormat.enum.dining,
        CategoryFormat.enum.catering, // services
        CategoryFormat.enum.chef, // services
        CategoryFormat.enum.prepared_meals // services
    ],
    [Category.enum.art_design]: [
        CategoryFormat.enum.gallery_tour,
        CategoryFormat.enum.art_workshop,
        CategoryFormat.enum.architecture_tour,
        CategoryFormat.enum.shopping_fashion,
        CategoryFormat.enum.photography // services
    ],
    [Category.enum.nature_outdoors]: [
        CategoryFormat.enum.outdoor,
        CategoryFormat.enum.water_sports,
        CategoryFormat.enum.flying,
        CategoryFormat.enum.wildlife
    ],
    [Category.enum.entertainment]: [
        CategoryFormat.enum.concerts,
        CategoryFormat.enum.theater_dance,
        CategoryFormat.enum.nightlife,
        CategoryFormat.enum.games
    ],
    [Category.enum.fitness_wellness]: [
        CategoryFormat.enum.massage, // services
        CategoryFormat.enum.hair_styling, // services
        CategoryFormat.enum.makeup, // services
        CategoryFormat.enum.nails, // services
        CategoryFormat.enum.personal_training, // services
        CategoryFormat.enum.spa_treatments, // services
        CategoryFormat.enum.workouts,
        CategoryFormat.enum.wellness,
        CategoryFormat.enum.beauty
    ],
    [Category.enum.generic]: []
} as const satisfies Record<Category, readonly CategoryFormat[]>

export type ExperienceFormDefaultValues = {
    [ExperienceFormSchemaKey in keyof EditExperienceAnswers]: {
        [Key in keyof EditExperienceAnswers[ExperienceFormSchemaKey]]:
            | EditExperienceAnswers[ExperienceFormSchemaKey][Key]
            | undefined
    }
}

export const makeExperiencesFormDefaultValues = (
    experience: ExperienceExtended
): Partial<ExperienceFormDefaultValues> => {
    const findAnswer = <TQuestionKeyName extends QuestionKeyName>(questionKeyName: TQuestionKeyName) =>
        experience.expertise.answers.find(answer => answer.questionKeyName == questionKeyName)?.answer as
            | Extract<ExperienceExtended['expertise']['answers'][number], {questionKeyName: TQuestionKeyName}>['answer']
            | undefined

    const country = (findAnswer(QuestionKeyName.enum.country_region) ?? 'US') as Countries

    return {
        [StepKeyName.enum.about_you]: {
            [FormFieldKeyName.enum.expertise_years]: String(findAnswer(QuestionKeyName.enum.expertise_years)),
            [FormFieldKeyName.enum.online_profile_links]: findAnswer(QuestionKeyName.enum.online_profile_links),
            [FormFieldKeyName.enum.host_experience_title]: findAnswer(QuestionKeyName.enum.host_experience_title) ?? '',
            [FormFieldKeyName.enum.host_expertise_description]:
                findAnswer(QuestionKeyName.enum.host_expertise_description) ?? '',
            [FormFieldKeyName.enum.host_first_name]:
                findAnswer(QuestionKeyName.enum.host_first_name) ?? experience.host.firstName,
            [FormFieldKeyName.enum.profile_photo]:
                findAnswer(QuestionKeyName.enum.profile_photo) ?? experience.host.profilePhotoUrl ?? undefined,
            [FormFieldKeyName.enum.host_recognition_title]:
                findAnswer(QuestionKeyName.enum.host_recognition_title) ?? '',
            [FormFieldKeyName.enum.host_recognition_subtitle]:
                findAnswer(QuestionKeyName.enum.host_recognition_subtitle) ?? ''
        },
        [StepKeyName.enum.about_you_more_info]: {
            [FormFieldKeyName.enum.residential_address]: findAnswer(QuestionKeyName.enum.residential_address) ?? '',
            [FormFieldKeyName.enum.residential_country_region]: findAnswer(
                QuestionKeyName.enum.residential_country_region
            ),
            [FormFieldKeyName.enum.residential_address_extras]:
                findAnswer(QuestionKeyName.enum.residential_address_extras) ?? '',
            [FormFieldKeyName.enum.residential_city]: findAnswer(QuestionKeyName.enum.residential_city) ?? '',
            [FormFieldKeyName.enum.residential_state]: findAnswer(QuestionKeyName.enum.residential_state) ?? '',
            [FormFieldKeyName.enum.residential_zip]: findAnswer(QuestionKeyName.enum.residential_zip) ?? '',
            [FormFieldKeyName.enum.hosting_as_business]: findAnswer(QuestionKeyName.enum.hosting_as_business),
            [FormFieldKeyName.enum.business_name]: findAnswer(QuestionKeyName.enum.business_name)
        },
        [StepKeyName.enum.location]: {
            [FormFieldKeyName.enum.country_region]: findAnswer(QuestionKeyName.enum.country_region) ?? '',
            [FormFieldKeyName.enum.location_address]: findAnswer(QuestionKeyName.enum.location_address) ?? '',
            [FormFieldKeyName.enum.location_address_extras]:
                findAnswer(QuestionKeyName.enum.location_address_extras) ?? '',
            [FormFieldKeyName.enum.location_city]: findAnswer(QuestionKeyName.enum.location_city) ?? '',
            [FormFieldKeyName.enum.location_state]: findAnswer(QuestionKeyName.enum.location_state) ?? '',
            [FormFieldKeyName.enum.location_zip]: findAnswer(QuestionKeyName.enum.location_zip) ?? '',
            [FormFieldKeyName.enum.meeting_point_name]: findAnswer(QuestionKeyName.enum.meeting_point_name) ?? '',
            [FormFieldKeyName.enum.meeting_point_description]:
                findAnswer(QuestionKeyName.enum.meeting_point_description) ?? ''
        },
        [StepKeyName.enum.experience]: {
            [FormFieldKeyName.enum.experience_title]: findAnswer(QuestionKeyName.enum.experience_title) ?? '',
            [FormFieldKeyName.enum.experience_description]:
                findAnswer(QuestionKeyName.enum.experience_description) ?? ''
        },
        [StepKeyName.enum.pricing]: {
            [FormFieldKeyName.enum.guest_individual_price]: findAnswer(QuestionKeyName.enum.guest_individual_price),
            [FormFieldKeyName.enum.group_minimum_price]: findAnswer(QuestionKeyName.enum.group_minimum_price),
            [FormFieldKeyName.enum.currency]: experience.currency ?? countryToCurrency[country],
            [FormFieldKeyName.enum.instance_private_groups]:
                findAnswer(QuestionKeyName.enum.instance_private_groups) ?? false,
            [FormFieldKeyName.enum.always_require_minimum_price]:
                findAnswer(QuestionKeyName.enum.always_require_minimum_price) ?? false
        },
        [StepKeyName.enum.discounts]: {
            [FormFieldKeyName.enum.new_listing_discount]:
                findAnswer(QuestionKeyName.enum.new_listing_discount) ?? false,
            [FormFieldKeyName.enum.early_bird_discount]: findAnswer(QuestionKeyName.enum.early_bird_discount) ?? false,
            [FormFieldKeyName.enum.discounts]: experience.discounts ?? []
        },
        [StepKeyName.enum.settings]: {
            [FormFieldKeyName.enum.maximum_public_group_size]:
                findAnswer(QuestionKeyName.enum.maximum_public_group_size) ?? null,
            [FormFieldKeyName.enum.maximum_private_group_size]:
                findAnswer(QuestionKeyName.enum.maximum_private_group_size) ?? null,
            [FormFieldKeyName.enum.main_language]: findAnswer(QuestionKeyName.enum.main_language) ?? 'en',
            [FormFieldKeyName.enum.additional_languages]: findAnswer(QuestionKeyName.enum.additional_languages) ?? []
        },
        [StepKeyName.enum.availability]: {
            [FormFieldKeyName.enum.unlisted_datetimes_allowed]:
                findAnswer(QuestionKeyName.enum.unlisted_datetimes_allowed) ?? false,
            [FormFieldKeyName.enum.experience_start_time]: findAnswer(QuestionKeyName.enum.experience_start_time),
            [FormFieldKeyName.enum.first_guests_cutoff_time]: findAnswer(QuestionKeyName.enum.first_guests_cutoff_time),
            [FormFieldKeyName.enum.additional_guests_cutoff_time]: findAnswer(
                QuestionKeyName.enum.additional_guests_cutoff_time
            ),
            [FormFieldKeyName.enum.cancellation_policy]: findAnswer(QuestionKeyName.enum.cancellation_policy)
        },
        [StepKeyName.enum.guests]: {
            [FormFieldKeyName.enum.minimum_age]: findAnswer(QuestionKeyName.enum.minimum_age) ?? null,
            [FormFieldKeyName.enum.parents_kids_under_2]: findAnswer(QuestionKeyName.enum.parents_kids_under_2),
            [FormFieldKeyName.enum.skill_level]: findAnswer(QuestionKeyName.enum.skill_level),
            [FormFieldKeyName.enum.activity_level]: findAnswer(QuestionKeyName.enum.activity_level),
            [FormFieldKeyName.enum.guest_bring_something]: findAnswer(QuestionKeyName.enum.guest_bring_something),
            [FormFieldKeyName.enum.guest_bringing_items]: findAnswer(QuestionKeyName.enum.guest_bringing_items)
        },
        [StepKeyName.enum.accessibility]: {
            [FormFieldKeyName.enum.accessibility_features]:
                findAnswer(QuestionKeyName.enum.accessibility_features) ?? [],
            [FormFieldKeyName.enum.accessibility_additional_information]:
                findAnswer(QuestionKeyName.enum.accessibility_additional_information) ?? '',
            [FormFieldKeyName.enum.accessibility_additional_qualifications]:
                findAnswer(QuestionKeyName.enum.accessibility_additional_qualifications) ?? ''
        },
        [StepKeyName.enum.legal]: {
            [FormFieldKeyName.enum.host_transports_guests]: findAnswer(QuestionKeyName.enum.host_transports_guests),
            [FormFieldKeyName.enum.host_transporting_guests_method]:
                findAnswer(QuestionKeyName.enum.host_transporting_guests_method) ?? [],
            [FormFieldKeyName.enum.host_serve_alcohol]: findAnswer(QuestionKeyName.enum.host_serve_alcohol),
            [FormFieldKeyName.enum.host_serve_food]: findAnswer(QuestionKeyName.enum.host_serve_food),
            [FormFieldKeyName.enum.prepared_in_restaurant]: findAnswer(QuestionKeyName.enum.prepared_in_restaurant),
            [FormFieldKeyName.enum.is_licensed_establishment]: findAnswer(
                QuestionKeyName.enum.is_licensed_establishment
            ),
            [FormFieldKeyName.enum.involves_national_park]: findAnswer(QuestionKeyName.enum.involves_national_park),
            [FormFieldKeyName.enum.involves_point_of_interest]: findAnswer(
                QuestionKeyName.enum.involves_point_of_interest
            ),
            [FormFieldKeyName.enum.host_discusses_poi_significance]: findAnswer(
                QuestionKeyName.enum.host_discusses_poi_significance
            )
        }
    }
}

export const hasRelevantFieldChanges = (
    data: ExperienceExtended['expertise']['answers'][] | Activity['answers'][],
    keys: string[]
) => {
    return data.some(item => item.some(q => keys.includes(q.questionKeyName) && q.answer != q?.previousAnswer))
}

export const getPreviousAnswerData = (experience: ExperienceExtended) => {
    const activityPreviousAnswerData = experience.itineraries
        ? experience.itineraries.map(activity =>
              activity.answers.reduce(
                  (acc, val) => {
                      if (val.questionKeyName == 'improved_title') {
                          return {...acc, improvedTitle: val.previousAnswer ?? val.answer}
                      }
                      if (val.questionKeyName == 'improved_description') {
                          return {...acc, improvedDescription: val.previousAnswer ?? val.answer}
                      }

                      if (val.questionKeyName === 'main_photo_url') {
                          // TODO we need send the id of the photo and not the url. if needed ask BE add previousAnswer ID
                          return {...acc, mainPhoto: activity?.coverImage?.id}
                      }
                      return acc
                  },
                  {
                      improvedTitle: '',
                      improvedDescription: '',
                      mainPhoto: activity?.coverImage?.id,
                      id: activity.id
                  }
              )
          )
        : []

    return experience.expertise.answers.reduce(
        (acc, val) => {
            if (val.questionKeyName == 'improved_experience_title') {
                return {...acc, improvedExperienceTitle: val.previousAnswer ?? val.answer}
            }
            if (val.questionKeyName == 'improved_experience_description') {
                return {...acc, improvedExperienceDescription: val.previousAnswer ?? val.answer}
            }
            if (val.questionKeyName == 'improved_host_experience_title') {
                return {...acc, improvedHostExperienceTitle: val.previousAnswer ?? val.answer}
            }
            if (val.questionKeyName == 'improved_host_expertise_description') {
                return {...acc, improvedHostExperienceDescription: val.previousAnswer ?? val.answer}
            }
            if (val.questionKeyName == 'improved_host_recognition_title') {
                return {...acc, improvedHostRecognitionTitle: val.previousAnswer ?? val.answer}
            }
            if (val.questionKeyName == 'improved_host_recognition_subtitle') {
                return {...acc, improvedHostRecognitionSubtitle: val.previousAnswer ?? val.answer}
            }
            return acc
        },
        {
            improvedExperienceTitle: '',
            improvedExperienceDescription: '',
            improvedHostExperienceTitle: '',
            improvedHostExperienceDescription: '',
            improvedHostRecognitionTitle: '',
            improvedHostRecognitionSubtitle: '',
            activities: activityPreviousAnswerData
        }
    )
}

type UpdatesRequiredStep = Array<{
    questionKeyName: ActivityQuestionKeyName | QuestionKeyName
    flags: ExperiencesFlag[]
}>

type UpdatesRequiredSteps = {
    [StepKeyName.enum.about_you]: UpdatesRequiredStep
    [StepKeyName.enum.about_you_more_info]: UpdatesRequiredStep
    [StepKeyName.enum.location]: UpdatesRequiredStep
    [StepKeyName.enum.experience]: UpdatesRequiredStep
    [StepKeyName.enum.gallery]: {
        flags: ExperiencesFlag[]
        images: {
            id: number
            flags: ExperiencesFlag[]
        }[]
    }
    [StepKeyName.enum.itinerary]: UpdatesRequiredStep[]
    [StepKeyName.enum.pricing]: UpdatesRequiredStep
    [StepKeyName.enum.discounts]: UpdatesRequiredStep
    [StepKeyName.enum.settings]: UpdatesRequiredStep
    [StepKeyName.enum.availability]: UpdatesRequiredStep
    [StepKeyName.enum.guests]: UpdatesRequiredStep
    [StepKeyName.enum.accessibility]: UpdatesRequiredStep
}
export const getUpdatesRequiredSteps = (experience: ExperienceExtended): UpdatesRequiredSteps => {
    const answers = experience.expertise.answers.reduce(
        (result: Omit<UpdatesRequiredSteps, 'gallery' | 'itinerary'>, value) => {
            if (value.parentFlags.length >= 1) {
                if (
                    [
                        'expertise_years',
                        'host_experience_title',
                        'host_expertise_description',
                        'online_profile_links',
                        'profile_photo'
                    ].includes(value.questionKeyName)
                ) {
                    return {
                        ...result,
                        [StepKeyName.enum.about_you]: [
                            ...result[StepKeyName.enum.about_you],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }
                if (['residential_city', 'business_name'].includes(value.questionKeyName)) {
                    return {
                        ...result,
                        [StepKeyName.enum.about_you_more_info]: [
                            ...result[StepKeyName.enum.about_you_more_info],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }
                if (['meeting_point_name', 'meeting_point_description'].includes(value.questionKeyName)) {
                    return {
                        ...result,
                        [StepKeyName.enum.location]: [
                            ...result[StepKeyName.enum.location],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }
                if (
                    [
                        'accessibility_features',
                        'accessibility_additional_information',
                        'accessibility_additional_qualifications'
                    ].includes(value.questionKeyName)
                ) {
                    return {
                        ...result,
                        [StepKeyName.enum.accessibility]: [
                            ...result[StepKeyName.enum.accessibility],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }
                if (['experience_title', 'experience_description'].includes(value.questionKeyName)) {
                    return {
                        ...result,
                        [StepKeyName.enum.experience]: [
                            ...result[StepKeyName.enum.experience],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }
                if (['guest_individual_price', 'group_minimum_price'].includes(value.questionKeyName)) {
                    return {
                        ...result,
                        [StepKeyName.enum.pricing]: [
                            ...result[StepKeyName.enum.pricing],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }
                if (['discounts'].includes(value.questionKeyName)) {
                    return {
                        ...result,
                        [StepKeyName.enum.discounts]: [
                            ...result[StepKeyName.enum.discounts],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }
                if (
                    [
                        'main_language',
                        'additional_languages',
                        'maximum_public_group_size',
                        'maximum_private_group_size'
                    ].includes(value.questionKeyName)
                ) {
                    return {
                        ...result,
                        [StepKeyName.enum.settings]: [
                            ...result[StepKeyName.enum.settings],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }
                if (
                    ['experience_start_time', 'first_guests_cutoff_time', 'additional_guests_cutoff_time'].includes(
                        value.questionKeyName
                    )
                ) {
                    return {
                        ...result,
                        [StepKeyName.enum.availability]: [
                            ...result[StepKeyName.enum.availability],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }

                if (['minimum_age', 'activity_level', 'skill_level', 'what_bring'].includes(value.questionKeyName)) {
                    return {
                        ...result,
                        [StepKeyName.enum.guests]: [
                            ...result[StepKeyName.enum.guests],
                            {questionKeyName: value.questionKeyName, flags: value.parentFlags}
                        ]
                    }
                }
                return result
            }
            return result
        },
        {
            about_you: [],
            about_you_more_info: [],
            location: [],
            experience: [],
            pricing: [],
            discounts: [],
            settings: [],
            availability: [],
            guests: [],
            accessibility: []
        }
    )

    const itineraries = experience.itineraries
        ? experience.itineraries.reduce((result: UpdatesRequiredSteps['itinerary'], itinerary) => {
              return [
                  ...result,
                  itinerary.answers.reduce((acc: UpdatesRequiredSteps['itinerary'][number], value) => {
                      if (value.parentFlags.length >= 1) {
                          return [...acc, {questionKeyName: value.questionKeyName, flags: value.parentFlags}]
                      }
                      return acc
                  }, [])
              ]
          }, [])
        : []

    const gallery = {
        flags: experience.gallery?.parentFlags ?? [],
        images:
            experience.gallery?.images.reduce((result: UpdatesRequiredSteps['gallery']['images'], value) => {
                if (value?.parentFlags && value.parentFlags.length >= 1) {
                    return [...result, {id: value.id, flags: value.parentFlags ?? []}]
                }
                return result
            }, []) ?? []
    }

    return {
        ...answers,
        [StepKeyName.enum.itinerary]: itineraries,
        [StepKeyName.enum.gallery]: gallery
    }
}
export const getPrevNextStep = ({
    updatesRequiredSteps,
    currentStep
}: {
    updatesRequiredSteps: UpdatesRequiredSteps
    currentStep: StepKeyName
}): {
    prev: keyof UpdatesRequiredSteps | 'suggestions' | null
    next: keyof UpdatesRequiredSteps | 'suggestions' | null
} => {
    const stepOrder = [
        StepKeyName.enum.about_you,
        StepKeyName.enum.about_you_more_info,
        StepKeyName.enum.location,
        StepKeyName.enum.gallery,
        StepKeyName.enum.experience,
        StepKeyName.enum.itinerary,
        StepKeyName.enum.pricing,
        StepKeyName.enum.discounts,
        StepKeyName.enum.settings,
        StepKeyName.enum.availability,
        StepKeyName.enum.guests,
        StepKeyName.enum.accessibility
    ]

    const flaggedSteps: (keyof UpdatesRequiredSteps | 'suggestions')[] = [
        'suggestions',
        ...stepOrder.filter(step => {
            const value = updatesRequiredSteps[step]
            if (!value) return false

            if (step === StepKeyName.enum.itinerary && Array.isArray(value)) {
                return value.some(v => Array.isArray(v) && v.length > 0)
            }
            if (step === StepKeyName.enum.gallery && typeof value === 'object' && 'flags' in value) {
                return value.flags.length > 0 || value.images.some(image => image.flags.length > 0)
            }
            return Array.isArray(value) && value.length > 0
        })
    ]

    const currentStepIndex = flaggedSteps.findIndex(step => step === currentStep)

    return {
        prev: flaggedSteps[currentStepIndex - 1] ?? null,
        next: flaggedSteps[currentStepIndex + 1] ?? null
    }
}

export const experiencesFlagToDescription = (flag: ExperiencesFlag['keyName']): ReactNode =>
    (
        ({
            spelling_grammar_or_formatting_issues: (
                <Trans
                    i18nKey="experiences:flags_key_name:spelling_grammatical_errors_title"
                    components={{ul: <ul />, li: <li />}}
                />
            ),
            not_representative_of_activity: i18n.t('experiences:flags_key_name:not_representative_activity'),
            not_descriptive_enough: i18n.t('experiences:flags_key_name:not_descriptive_enough'),
            spelling_or_grammatical_errors: i18n.t('experiences:flags_key_name:spelling_grammatical_errors'),
            more_details_needed: i18n.t('experiences:flags_key_name:more_details_needed'),
            lack_of_relevance_to_location: i18n.t('experiences:flags_key_name:lack_relevance_location'),
            lack_of_cultural_component: i18n.t('experiences:flags_key_name:lack_cultural_component'),
            experience_missing_or_unrelated_to_activity: i18n.t('experiences:flags_key_name:host_missing_unrelated'),
            insufficient_details: i18n.t('experiences:flags_key_name:description_insufficient_detail'),
            add_intro_activity: i18n.t('experiences:flags_key_name:description_add_intro'),
            add_final_activity: i18n.t('experiences:flags_key_name:description_add_finale'),
            merge_repetitive_activities: i18n.t('experiences:flags_key_name:merge_repetitive_activities'),
            separate_activities_long_duration: i18n.t('experiences:flags_key_name:description_separate_long'),
            photo_is_missing: i18n.t('experiences:flags_key_name:photo_missing'),
            photo_is_low_quality_or_irrelevant: i18n.t('experiences:flags_key_name:photo_low_quality_irrelevant'),
            photo_includes_multiple_people: i18n.t('experiences:flags_key_name:photo_multiple_people'),
            photo_is_a_passport_id_photo: i18n.t('experiences:flags_key_name:passport_or_logo_description'),
            photo_is_low_quality: (
                <Trans
                    i18nKey="experiences:flags_key_name:photo_low_quality"
                    components={{
                        br: <br />,
                        ul: <ul />,
                        li: <li />,
                        a: <a href="https://www.airbnb.ca/e/host-experiences-photo-guide-hpc?locale=en" />
                    }}
                />
            ),
            photos_do_not_match_activity_or_place_description: (
                <Trans
                    i18nKey="experiences:flags_key_name:photo_mismatch_activity_place"
                    components={{
                        a: <a href="https://www.airbnb.ca/e/host-experiences-photo-guide-hpc?locale=en" />
                    }}
                />
            ),
            photos_lack_variety: (
                <Trans
                    i18nKey="experiences:flags_key_name:photo_lack_variety"
                    components={{a: <a href="https://www.airbnb.ca/e/host-experiences-photo-guide-hpc?locale=en" />}}
                />
            ),
            photos_do_not_show_unposed_guest_interaction: (
                <Trans
                    i18nKey="experiences:flags_key_name:photo_no_guest_interaction"
                    components={{a: <a href="https://www.airbnb.ca/e/host-experiences-photo-guide-hpc?locale=en" />}}
                />
            ),
            photos_do_not_clearly_convey_who_the_host_is: (
                <Trans
                    i18nKey="experiences:flags_key_name:photo_no_host_identity"
                    components={{a: <a href="https://www.airbnb.ca/e/host-experiences-photo-guide-hpc?locale=en" />}}
                />
            ),
            photos_do_not_show_activity_highlights: (
                <Trans
                    i18nKey="experiences:flags_key_name:photo_no_activity_highlights"
                    components={{a: <a href="https://www.airbnb.ca/e/host-experiences-photo-guide-hpc?locale=en" />}}
                />
            ),
            photos_appear_to_be_screenshots: (
                <Trans
                    i18nKey="experiences:flags_key_name:photo_screenshot_stock"
                    components={{
                        br: <br />,
                        ul: <ul />,
                        li: <li />,
                        a: <a href="https://www.airbnb.ca/e/host-experiences-photo-guide-hpc?locale=en" />
                    }}
                />
            ),
            photos_show_a_policy_violation: (
                <Trans
                    i18nKey="experiences:flags_key_name:photo_policy_violation"
                    components={{
                        br: <br />,
                        ul: <ul />,
                        li: <li />,
                        a1: (
                            <a href="https://news.airbnb.com/introducing-airbnb-animal-experiences/#:~:text=Wild%20animals%3A%20there%20should%20be,be%20in%20captivity%20for%20entertainment" />
                        ),
                        a2: <a href="https://www.airbnb.ca/e/host-experiences-photo-guide-hpc?locale=en" />
                    }}
                />
            ),
            more_details_on_location: i18n.t('experiences:flags_key_name:place_more_details'),
            venue_is_not_suitable_for_activity: i18n.t('experiences:flags_key_name:place_unsuitable'),
            not_defined_or_too_many_options_shared: i18n.t('experiences:flags_key_name:place_not_defined'),
            lack_photo_variety: i18n.t('experiences:flags_key_name:lack_photo_variety'),
            requires_pro_photography: i18n.t('experiences:flags_key_name:requires_pro_photography')
        }) as const satisfies Record<typeof flag, ReactNode>
    )[flag]
