import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    Title,
    Footer,
    FieldTitle,
    FieldDescription,
    AlertBanner,
    HeadingGroup
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Divider} from '@/components/ui/divider/Divider'
import {z} from 'zod'
import {Checkbox} from '@/components/commons/checkbox/ChekBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Controller, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    getExperiencesStepProgressPercentage,
    getUpdatesRequiredSteps,
    makeExperiencesFormDefaultValues
} from '../../utils'
import {ExperienceExtended, StepKeyName} from '../../types'
import {Spinner} from '@/components/ui/spinner/Spinner'
import {useChangeStep} from '../../hooks/useChangeStep'
import {StyledAnswerItem} from './style'
import {hours} from '@/components/commons/time-picker/TimePicker'
import {DefaultSelector} from '../atoms/Atoms'
import {FlagsAlertText} from '@/features/host-submissions/components/flags-alert-text/FlagsAlertText'
import {ConfirmSubmitModal} from '../confirm-submit-modal/ConfirmSubmitModal'
import {CutoffTimeSelector} from '../cutoff-time-selector/CutoffTimeSelector'

const cutoffOptionsAdditionalGuests = [
    {value: '0_hours', label: i18n.t('experiences:availability_step:hours', {hours: 0, count: 0})},
    {value: '1_hour', label: i18n.t('experiences:availability_step:hours', {hours: 1, count: 1})},
    {value: '2_hours', label: i18n.t('experiences:availability_step:hours', {hours: 2, count: 2})},
    {value: '3_hours', label: i18n.t('experiences:availability_step:hours', {hours: 3, count: 3})},
    {value: '4_hours', label: i18n.t('experiences:availability_step:hours', {hours: 4, count: 4})},
    {value: '6_hours', label: i18n.t('experiences:availability_step:hours', {hours: 6, count: 6})},
    {value: '8_hours', label: i18n.t('experiences:availability_step:hours', {hours: 8, count: 8})},
    {value: '12_hours', label: i18n.t('experiences:availability_step:hours', {hours: 12, count: 12})},
    {value: '18_hours', label: i18n.t('experiences:availability_step:hours', {hours: 18, count: 18})},
    {value: '1_day', label: i18n.t('experiences:availability_step:days', {days: 1, count: 1})},
    {value: '2_days', label: i18n.t('experiences:availability_step:days', {days: 2, count: 2})},
    {value: '3_days', label: i18n.t('experiences:availability_step:days', {days: 3, count: 3})},
    {value: '4_days', label: i18n.t('experiences:availability_step:days', {days: 4, count: 4})},
    {value: '5_days', label: i18n.t('experiences:availability_step:days', {days: 5, count: 5})},
    {value: '6_days', label: i18n.t('experiences:availability_step:days', {days: 6, count: 6})},
    {value: '1_week', label: i18n.t('experiences:availability_step:weeks', {weeks: 1, count: 1})}
]

const cutoffOptionsFirstGuest = cutoffOptionsAdditionalGuests.filter(option => option.value !== '0_hours')

const AvailabilityStepValidationSchema = z.object({
    unlisted_datetimes_allowed: z.boolean().default(false),
    experience_start_time: z.string({required_error: i18n.t('errors:field_required')}),
    first_guests_cutoff_time: z.string({required_error: i18n.t('errors:field_required')}),
    additional_guests_cutoff_time: z.string({required_error: i18n.t('errors:field_required')}),
    cancellation_policy: z.enum(['7_days', '24_hours'], {
        invalid_type_error: i18n.t('errors:field_required'),
        required_error: i18n.t('errors:field_required')
    })
})

export type AvailabilityStepValidationSchema = z.infer<typeof AvailabilityStepValidationSchema>

export const ExperienceAvailabilityStep: FC<{
    experience: ExperienceExtended
}> = ({experience}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)

    const flagsCount = updatesRequiredSteps.availability.flatMap(field => field.flags).length
    const startTimeFlags = updatesRequiredSteps.availability.find(
        field => field.questionKeyName == 'experience_start_time'
    )?.flags
    const bookingCutoffTimeFlags = updatesRequiredSteps.availability.find(
        field => field.questionKeyName == 'first_guests_cutoff_time'
    )?.flags

    const newGuestCutoffTimeFlags = updatesRequiredSteps.availability.find(
        field => field.questionKeyName == 'additional_guests_cutoff_time'
    )?.flags
    const changeStep = useChangeStep({
        experience,
        previousStep: StepKeyName.enum.settings,
        currentStep: StepKeyName.enum.availability,
        nextStep: StepKeyName.enum.guests,
        openSubmitModal: () => setIsConfirmSubmitOpen(true)
    })
    const defaultValues = makeExperiencesFormDefaultValues(experience).availability
    const form = useForm<AvailabilityStepValidationSchema>({
        resolver: zodResolver(AvailabilityStepValidationSchema),
        reValidateMode: 'onChange',
        defaultValues
    })

    const onSubmit = form.handleSubmit(data => {
        changeStep.handleChangeStep(false, data)
    })
    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('experiences:availability_step:title')}</Title>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('commons:x_items_improve', {count: flagsCount})}
                            paragraph={t('experiences:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={6}>
                    <Flexbox direction="column" gap={4} width="100%">
                        <FieldTitle>{t('experiences:availability_step:question1_title')}</FieldTitle>
                        <FlagsAlertText
                            flags={startTimeFlags}
                            title={t('experiences:availability_step:question1_label')}
                        />
                        <Controller
                            control={form.control}
                            name="experience_start_time"
                            render={({field}) => (
                                <DefaultSelector
                                    array={hours}
                                    value={(field.value ?? '').toString()}
                                    onChange={field.onChange}
                                    label={t('experiences:availability_step:question1_label')}
                                    placeholder={t('experiences:availability_step:question1_placeholder')}
                                    errorMessage={form.formState.errors.experience_start_time?.message || undefined}
                                    ref={field.ref}
                                />
                            )}
                        />
                    </Flexbox>
                    <Divider direction="horizontal" />

                    <Controller
                        control={form.control}
                        name="first_guests_cutoff_time"
                        render={({field}) => (
                            <CutoffTimeSelector
                                array={cutoffOptionsFirstGuest}
                                value={(field.value ?? '').toString()}
                                onChange={field.onChange}
                                label={t('experiences:availability_step:question2_label')}
                                placeholder={t('experiences:availability_step:choose_time_placeholder')}
                                note={t('experiences:availability_step:question2_note')}
                                errorMessage={form.formState.errors.first_guests_cutoff_time?.message || undefined}
                                ref={field.ref}
                                flags={bookingCutoffTimeFlags}
                            />
                        )}
                    />

                    <Divider direction="horizontal" />

                    <Controller
                        control={form.control}
                        name="additional_guests_cutoff_time"
                        render={({field}) => (
                            <CutoffTimeSelector
                                array={cutoffOptionsAdditionalGuests}
                                value={(field.value ?? '').toString()}
                                onChange={field.onChange}
                                label={t('experiences:availability_step:question3_label')}
                                placeholder={t('experiences:availability_step:choose_time_placeholder')}
                                note={t('experiences:availability_step:question3_note')}
                                errorMessage={form.formState.errors.additional_guests_cutoff_time?.message || undefined}
                                ref={field.ref}
                                flags={newGuestCutoffTimeFlags}
                            />
                        )}
                    />

                    <Divider direction="horizontal" />

                    <Flexbox direction="column" gap={4}>
                        <Checkbox
                            id="unlisted_datetimes_allowed"
                            label={t('experiences:availability_step:question4_label')}
                            {...form.register('unlisted_datetimes_allowed')}
                        />
                    </Flexbox>
                    <Divider direction="horizontal" />
                    <Flexbox direction="column" gap={4} width="100%">
                        <Flexbox direction="column" gap={1.5}>
                            <FieldTitle>{t('experiences:availability_step:cancellation_policy')}</FieldTitle>
                            <FieldDescription>{t('commons:select_option')}</FieldDescription>
                        </Flexbox>

                        <StyledAnswerItem
                            type="radio"
                            label={<span>{t('experiences:availability_step:7_days')}</span>}
                            value={'7_days'}
                            {...form.register('cancellation_policy')}
                        />
                        <StyledAnswerItem
                            type="radio"
                            label={<span>{t('experiences:availability_step:24_hours')}</span>}
                            value={'24_hours'}
                            {...form.register('cancellation_policy')}
                        />
                        {form.formState.errors.cancellation_policy && (
                            <InputHelpText error={form.formState.errors.cancellation_policy.message} />
                        )}
                    </Flexbox>
                </Flexbox>
            </Content>
            <Footer progressPercentage={getExperiencesStepProgressPercentage('availability')}>
                <Button
                    variant="tertiary"
                    disabled={changeStep.isBackLoading || changeStep.isContinueLoading}
                    onClick={() => changeStep.handleChangeStep(true)}
                >
                    {t('commons:back')}
                    {changeStep.isBackLoading && <Spinner />}
                </Button>
                {changeStep.hasNextStep ? (
                    <Button disabled={changeStep.isBackLoading || changeStep.isContinueLoading} onClick={onSubmit}>
                        {t('commons:continue')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                ) : (
                    <Button onClick={onSubmit}>
                        {t('commons:confirm')}
                        {changeStep.isContinueLoading && <Spinner />}
                    </Button>
                )}
            </Footer>

            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal experienceId={experience.id} onClose={() => setIsConfirmSubmitOpen(false)} />
            )}
        </>
    )
}
