import {useMutation} from '@tanstack/react-query'
import {MUTATION_KEYS} from '@/queryClient'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'
import {httpServiceResourcesLog} from '@/features/service-resources/http/serviceResources.http'
import {errorHandler} from '@utilities/helpers.ts'

export const useServiceResourcesLog = () => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.SERVICE_RESOURCES_LOG],
        mutationFn: httpServiceResourcesLog,
        onError: (error: AxiosError<ErrorResponseData>) => errorHandler(error)
    })
}
