import {
    getPrevNextStepMutations,
    getStepProgressPercentage,
    getUpdatesRequiredSteps,
    retrieveAddressDataFromComponents
} from '@/features/host-submissions/services/utils.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import i18n, {useTranslation} from '@/translations/i18n.tsx'
import {
    Content,
    HeadingGroup,
    Paragraph,
    Footer,
    FieldDescription,
    FieldTitle,
    Title,
    AlertBanner
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {FC, useState} from 'react'
import {InputAddressAutocomplete} from '@/features/host-submissions/services/components/input-address-autocomplete/InputAddressAutocomplete'
import {Coordinates, Place, Service} from '@/features/host-submissions/services/types'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useForm, useWatch} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {z} from 'zod'
import {errorHandler} from '@/utilities/helpers'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {useUpdateLocation} from '@/features/host-submissions/services/queries/useUpdateLocation'
import {useUpdateStepToHostExpertiseAboutYourself} from '@/features/host-submissions/services/queries/useUpdateStepToHostExpertiseAboutYourself'
import {StyledLocationBox} from './style'
import {ConfirmSubmitModal} from '@/features/host-submissions/services/components/confirm-submit-modal/ConfirmSubmitModal.tsx'
import {FlagsAlertText} from '../../../components/flags-alert-text/FlagsAlertText'
import {ServiceAreaParser} from '@/features/host-submissions/services/components/service-location-step/ServiceAreaParser.tsx'
import {Spinner} from '@/components/ui/spinner/Spinner'

// types
const ServiceLocationStepSchema = z.object({
    address: z.string().min(1),
    city: z.string().min(1),
    country: z.string().min(1),
    latitude: z.string().min(1),
    longitude: z.string().min(1),
    zip_code: z.string().nullable(),
    state: z.string().min(1),
    places: z
        .array(Place, {required_error: i18n.t('services:step_service_location:select_one_area')})
        .min(1, {message: i18n.t('services:step_service_location:select_one_area')})
})
type ServiceLocationStepSchema = z.infer<typeof ServiceLocationStepSchema>

export const ServiceLocationStepTest: FC<{service: Service}> = ({service}) => {
    const {t} = useTranslation()
    const [isConfirmSubmitOpen, setIsConfirmSubmitOpen] = useState(false)
    const updatesRequiredSteps = getUpdatesRequiredSteps(service)
    const locationFlags = updatesRequiredSteps.serviceLocation.find(field => field.questionKeyName == 'location')?.flags
    const flagsCount = updatesRequiredSteps.serviceLocation.flatMap(field => field.flags).length
    const updateStepToHostExpertiseAboutYourselfMutation = useUpdateStepToHostExpertiseAboutYourself<true>({
        serviceId: service.id,
        expertiseId: service.expertise.id,
        options: {onError: errorHandler}
    })
    const updateLocationMutation = useUpdateLocation({serviceId: service.id, expertiseId: service.expertise.id})
    const prevStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'back'>({service, currentStep: 'serviceLocation', updatesRequiredSteps}).prev?.()
            : updateStepToHostExpertiseAboutYourselfMutation
    const nextStepMutation =
        service.status == 'update_required'
            ? getPrevNextStepMutations<'serviceLocation'>({
                  service,
                  currentStep: 'serviceLocation',
                  updatesRequiredSteps
              }).next?.()
            : updateLocationMutation
    const form = useForm<ServiceLocationStepSchema>({
        resolver: zodResolver(ServiceLocationStepSchema),
        defaultValues: {
            address: service.address ?? undefined,
            country: service.country ?? undefined,
            city: service.city ?? undefined,
            zip_code: service.zip_code ?? undefined,
            state: service.state ?? undefined,
            latitude: service.latitude ?? undefined,
            longitude: service.longitude ?? undefined,
            places: service.submission_places.map(place => ({
                placeId: place.place.airbnb_place_id,
                name: place.place.name
            }))
        }
    })

    // values
    const formattedAddress = useWatch({control: form.control, name: 'address'})
    const latitude = useWatch({control: form.control, name: 'latitude'})
    const longitude = useWatch({control: form.control, name: 'longitude'})
    const places = useWatch({control: form.control, name: 'places'})

    // actions
    const onLatLngFound = (coordinates: Coordinates | null) => {
        form.setValue('latitude', coordinates?.latitude ?? '')
        form.setValue('longitude', coordinates?.longitude ?? '')
        form.clearErrors(['address', 'city', 'country', 'state', 'zip_code', 'latitude', 'longitude'])
    }

    const onChangeAddress = (value: google.maps.GeocoderResult | null) => {
        if (!value) return
        const {city, country, zip_code, state} = retrieveAddressDataFromComponents(value.address_components)
        form.setValue('address', value.formatted_address, {shouldValidate: true})
        form.setValue('city', city)
        form.setValue('country', country)
        form.setValue('state', state)
        form.setValue('zip_code', zip_code)
    }

    const onSubmit = form.handleSubmit(
        formValues =>
            nextStepMutation?.mutate({
                ...formValues,
                places: formValues.places
            })
    )

    return (
        <>
            <Content>
                <HeadingGroup>
                    <Title>{t('services:step_service_location:title')}</Title>
                    <Paragraph>{t('services:step_service_location:paragraph')}</Paragraph>
                    {flagsCount >= 1 && (
                        <AlertBanner
                            title={t('services:service_location_improvement_title')}
                            paragraph={t('services:update_required_paragraph')}
                        />
                    )}
                </HeadingGroup>
                <Flexbox direction="column" gap={8} width="100%">
                    <InputAddressAutocomplete
                        placeholder={
                            formattedAddress
                                ? t('services:step_service_location:search_another_address_placeholder')
                                : t('services:step_service_location:search_address_placeholder')
                        }
                        onLatLngFound={onLatLngFound}
                        errorMessage={
                            form.formState.errors.address?.message
                                ? t('services:step_service_location:search_address_warning')
                                : ''
                        }
                        onChange={onChangeAddress}
                        predictionTypes={['address']}
                    />
                    {formattedAddress && latitude && longitude && (
                        <>
                            <Flexbox direction="column" gap={1.5} width="100%">
                                <StyledLocationBox direction="column" gap={1.5} width="100%">
                                    <FieldDescription>
                                        {t('services:step_service_location:offering_address')}
                                    </FieldDescription>
                                    <FieldTitle>{formattedAddress}</FieldTitle>
                                </StyledLocationBox>
                                <FieldDescription>
                                    {t('services:step_service_location:service_location_hint')}
                                </FieldDescription>
                                {Object.keys(form.formState.errors).filter(
                                    error => !['country_filter', 'places'].includes(error)
                                ).length > 0 && (
                                    <InputHelpText
                                        error={t('services:step_service_location:address_missing_data_error')}
                                    />
                                )}

                                <FlagsAlertText
                                    flags={locationFlags}
                                    title={t('services:step_service_location:offering_address')}
                                />
                            </Flexbox>

                            <Flexbox direction="column" gap={4} width={'100%'} align={'stretch'}>
                                <Flexbox direction="column" gap={1.5} align={'stretch'}>
                                    <Flexbox direction="column" gap={4} align={'stretch'}>
                                        <Flexbox direction="column" gap={1.5} align={'stretch'}>
                                            <FieldTitle>{t('services:step_service_location:country_title')}</FieldTitle>
                                            <FieldDescription>
                                                {t('services:step_service_location:area_note')}
                                            </FieldDescription>
                                        </Flexbox>
                                        <ServiceAreaParser
                                            onUpdateCb={areas => form.setValue('places', areas)}
                                            initialServiceArea={places}
                                        />
                                    </Flexbox>
                                    <FieldDescription>
                                        {t('services:step_service_location:service_area:description')}
                                    </FieldDescription>
                                    {form.formState.errors.places && (
                                        <InputHelpText error={t('services:step_service_location:select_one_area')} />
                                    )}
                                </Flexbox>
                            </Flexbox>
                        </>
                    )}
                </Flexbox>
            </Content>
            <Footer progressPercentage={getStepProgressPercentage('service_location')}>
                <Button
                    variant="tertiary"
                    disabled={prevStepMutation?.isPending || nextStepMutation?.isPending}
                    onClick={() => prevStepMutation?.mutate(undefined)}
                >
                    {t('commons:back')}
                    {prevStepMutation?.isPending && <Spinner />}
                </Button>
                {nextStepMutation ? (
                    <Button disabled={nextStepMutation.isPending || prevStepMutation?.isPending} onClick={onSubmit}>
                        {t('commons:continue')}
                        {nextStepMutation.isPending && <Spinner />}
                    </Button>
                ) : (
                    <Button disabled={prevStepMutation?.isPending} onClick={() => setIsConfirmSubmitOpen(true)}>
                        {t('commons:confirm')}
                    </Button>
                )}
            </Footer>
            {isConfirmSubmitOpen && (
                <ConfirmSubmitModal<'serviceLocation'>
                    serviceId={service.id}
                    expertiseId={service.expertise.id}
                    onClose={() => setIsConfirmSubmitOpen(false)}
                    payload={{...form.getValues()}}
                />
            )}
        </>
    )
}

ServiceLocationStepTest.displayName = 'ServiceLocationStepTest'
