import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {z} from 'zod'
import {Media} from '@/features/experiences-host-video/types.ts'
import {MUTATION_KEYS} from '@/queryClient.ts'
import {httpGetPresignBulkPhotos, HttpGetPresignBulkPhotosOptions} from '../http/experiences.http'

const BulkPresignedPhotosResponse = z.array(z.object({media: Media, presignedUrl: z.string().url()}))
export type BulkPresignedPhotosResponse = z.infer<typeof BulkPresignedPhotosResponse>
export const useBulkPresignedPhotos = ({
    urlParams,
    options
}: {
    urlParams: HttpGetPresignBulkPhotosOptions['urlParams']
    options?: UseMutationOptions<
        BulkPresignedPhotosResponse,
        unknown,
        Omit<HttpGetPresignBulkPhotosOptions['payload'], 'mutationFn'>
    >
}) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.EXPERIENCES_PHOTO_PRESIGNED, urlParams],
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpGetPresignBulkPhotos({urlParams, payload, params: {responseType: 'extended'}}),
                responseShape: BulkPresignedPhotosResponse,
                onZodError: captureException
            }),
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context)
        }
    })
}
