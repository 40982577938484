import axios from '@/axiosInstanceNode'
import {ResponseType} from '@/types/commons'
import {
    AccessibilityFeatures,
    Category,
    CategoryFormat,
    Country,
    ExperienceExtended,
    ExperienceSmall,
    FormFieldKeyName,
    StepKeyName,
    UpdateEnhanceStepPayload
} from '../types'
import {captureException} from '@sentry/react'
import {parseResponseData} from '@/utilities/helpers'
import {AboutYourselfStepValidationSchema} from '../components/experience-about-yourself-step/ExperienceAboutYourselfStep'
import {AboutYourselfMoreInfoStepValidationSchema} from '../components/experience-about-yourself-more-info-step/ExperienceAboutYourselfMoreInfoStep'
import {LocationStepValidationSchema} from '../components/experience-location-step/ExperienceLocationStep'
import {ExperienceStepValidationSchema} from '../components/experience-step/ExperienceStep'
import {PricingStepValidationSchema} from '../components/experience-pricing-step/ExperiencePricingStep'
import {DiscountsStepValidationSchema} from '../components/experience-discounts-step/ExperienceDiscountsStep'
import {ExperienceSettingsValidationSchema} from '../components/experience-settings-step/ExperienceSettingsStep'
import {AvailabilityStepValidationSchema} from '../components/experience-availability-step/ExperienceAvailabilityStep'
import {GuestRequirementsStepValidationSchema} from '../components/experience-guest-requirements-step/ExperienceGuestRequirementsStep'
import {LegalStepValidationSchema} from '../components/experience-legal-step/ExperienceLegalStep'
import {z} from 'zod'

export const httpCreateExperience = () => axios.post('experience/submissions')
export interface HttpGetExperiencesOptions {
    params: {
        responseType: ResponseType
        page: string | undefined
    }
}
export const HttpExperiencesResponse = z.object({
    data: ExperienceSmall.array(),
    meta: z.object({
        currentPage: z.number(),
        lastPage: z.number(),
        total: z.number()
    })
})

export const httpGetExperiences = async ({params}: HttpGetExperiencesOptions) => {
    const response = await axios.get(`experience/submissions`, {
        params
    })
    return parseResponseData({
        responseData: response.data,
        responseShape: HttpExperiencesResponse,
        onZodError: captureException
    })
}

interface HttpGetExperienceOptions {
    urlParams: {experienceId: number}
    params: {
        responseType: ResponseType
    }
}

export const httpGetExperience = async ({urlParams, params}: HttpGetExperienceOptions) => {
    const response = await axios.get(`experience/submissions/${urlParams.experienceId}`, {params})
    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceExtended,
        onZodError: captureException
    })
}

/* request support */
interface HttpSendExperiencesSupportRequestOptions {
    payload: {notes: string; submissionId?: number}
}
export const httpSendExperiencesSupportRequest = ({payload}: HttpSendExperiencesSupportRequestOptions) =>
    axios.post(`/experience/submissions/need-help`, payload)

export interface EditExperienceAnswers {
    [StepKeyName.enum.home]: undefined
    [StepKeyName.enum.category]: {
        [FormFieldKeyName.enum.categoryKeyName]: Category
        [FormFieldKeyName.enum.subCategoryKeyName]: CategoryFormat
    }
    [StepKeyName.enum.about_you]: Omit<AboutYourselfStepValidationSchema, 'online_profile_links'> & {
        online_profile_links: string[]
    }
    [StepKeyName.enum.about_you_more_info]: AboutYourselfMoreInfoStepValidationSchema
    [StepKeyName.enum.location]: LocationStepValidationSchema
    [StepKeyName.enum.gallery]: undefined
    [StepKeyName.enum.experience]: ExperienceStepValidationSchema
    [StepKeyName.enum.itinerary]: undefined
    [StepKeyName.enum.pricing]: PricingStepValidationSchema
    [StepKeyName.enum.discounts]: DiscountsStepValidationSchema
    [StepKeyName.enum.settings]: ExperienceSettingsValidationSchema
    [StepKeyName.enum.availability]: AvailabilityStepValidationSchema
    [StepKeyName.enum.guests]: Omit<GuestRequirementsStepValidationSchema, 'guest_bringing_items'> & {
        guest_bringing_items: string[]
    }
    [StepKeyName.enum.accessibility]: {
        accessibility_features: AccessibilityFeatures[]
        accessibility_additional_information?: string
        accessibility_additional_qualifications?: string
    }
    [StepKeyName.enum.legal]: LegalStepValidationSchema
    [StepKeyName.enum.terms]: undefined
    [StepKeyName.enum.suggestions]: undefined
    [StepKeyName.enum.enhance_your_experience]: undefined
    [StepKeyName.enum.enhance_about_you]: undefined
    [StepKeyName.enum.enhance_gallery]: undefined
}
type Answers<Step extends keyof EditExperienceAnswers> = EditExperienceAnswers[Step]

export interface httpEditExperienceOptions<Step extends keyof EditExperienceAnswers> {
    urlParams: {experienceId: number}
    params: {responseType: ResponseType}
    payload: Answers<Step>
}
export const httpUpdateStep = async <Step extends keyof EditExperienceAnswers>({
    urlParams,
    params,
    payload
}: httpEditExperienceOptions<Step>) => {
    const response = await axios.post(`/experience/submissions/${urlParams.experienceId}/partial-submit`, payload, {
        params
    })
    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceExtended,
        onZodError: captureException
    })
}

type StepEditKeyName =
    | Exclude<
          StepKeyName,
          'about_you' | 'about_you_more_info' | 'enhance_your_experience' | 'enhance_about_you' | 'enhance_gallery'
      >
    | 'about-you'
    | 'about-you-more-info'
    | 'enhance-your-experience'
    | 'enhance-about-you'
    | 'enhance-gallery'

/* Edit Step */
export interface HttpEditStepOptions {
    urlParams: {
        experienceId: number
        stepKeyName: StepEditKeyName
    }
    params: {responseType: ResponseType}
}
export const httpEditStep = async ({urlParams, params}: HttpEditStepOptions) => {
    const response = await axios.patch(
        `/experience/submissions/${urlParams.experienceId}/update-step-to/${urlParams.stepKeyName}`,
        undefined,
        {
            params
        }
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceExtended,
        onZodError: captureException
    })
}

export const HttpCountriesResponse = z.object({
    data: Country.array(),
    meta: z.object({
        currentPage: z.number(),
        lastPage: z.number(),
        total: z.number()
    })
})

export interface HttpGetCountriesOptions {
    params: {responseType: ResponseType; name?: string}
}

export const httpGetCountries = async ({params}: HttpGetCountriesOptions) => {
    const response = await axios.get(`/countries`, {params: {...params, limit: 300}})
    return parseResponseData({
        responseData: response.data,
        responseShape: HttpCountriesResponse,
        onZodError: captureException
    })
}

export interface HttpSubmitExperienceOptions {
    urlParams: {experienceId: number}
    params: {responseType: ResponseType}
    payload?: {isGeneralAttestationApproved: boolean; isTermsPoliciesApproved: boolean}
}
export const httpSubmitExperience = async ({urlParams, params, payload}: HttpSubmitExperienceOptions) => {
    const response = await axios.patch(`experience/submissions/${urlParams.experienceId}/submit-to-review`, payload, {
        params
    })
    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceExtended,
        onZodError: captureException
    })
}

export interface HttpDeleteActivityOptions {
    urlParams: {
        activityId: number
        experienceId: number
    }
}

export const httpDeleteActivity = ({urlParams}: HttpDeleteActivityOptions) =>
    axios.delete(`experience/submissions/${urlParams.experienceId}/itineraries/${urlParams.activityId}`)

export interface HttpAddActivityOptions {
    urlParams: {experienceId: number}
    params: {responseType: ResponseType}
    payload: {
        title: string
        description: string
        durationInMinutes: string
        coverImageId: number
        position: number
    }
}
export const httpAddActivity = async ({urlParams, params, payload}: HttpAddActivityOptions) => {
    const response = await axios.post(`experience/submissions/${urlParams.experienceId}/itineraries`, payload, {params})

    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceExtended,
        onZodError: captureException
    })
}

export interface HttpUpdateActivityOptions {
    urlParams: {experienceId: number; activityId: number}
    params: {responseType: ResponseType}
    payload: {
        title?: string
        description?: string
        durationInMinutes?: string
        coverImageId?: number
        position?: number
    }
}
export const httpUpdateActivity = async ({urlParams, params, payload}: HttpUpdateActivityOptions) => {
    const response = await axios.patch(
        `/experience/submissions/${urlParams.experienceId}/itineraries/${urlParams.activityId}`,
        payload,
        {
            params
        }
    )

    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceExtended,
        onZodError: captureException
    })
}

export interface HttpGetPresignBulkPhotosOptions {
    urlParams: {experienceId: number; galleryId: number}
    params: {responseType: ResponseType}
    payload: {medias: string[]}
}
export const httpGetPresignBulkPhotos = ({payload, urlParams, params}: HttpGetPresignBulkPhotosOptions) =>
    axios.post(
        `/experience/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/bulk/upload`,
        payload,
        {params}
    )

/*Confirm upload photo*/
interface HttpConfirmUploadPhotosOptions {
    params: {responseType: ResponseType}
    urlParams: {experienceId: number; galleryId: number; mediaId: number}
}
export const httpConfirmUploadPhoto = ({urlParams, params}: HttpConfirmUploadPhotosOptions) =>
    axios.patch(
        `/experience/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/${urlParams.mediaId}/confirm`,
        undefined,
        {params}
    )

export interface HttpDeletePhotosOptions {
    urlParams: {
        experienceId: number
        galleryId: number
    }
    params: {responseType: ResponseType; imageIds: number[]}
}
export const httpDeletePhotos = ({urlParams, params}: HttpDeletePhotosOptions) =>
    axios.delete(
        `/experience/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/bulk/delete`,
        {params}
    )

export interface HttpSetGalleryCoverOptions {
    urlParams: {
        experienceId: number
        galleryId: number
        imageId: number
    }
    payload: {isCover: boolean}
    params: {responseType: ResponseType}
}
export const httpSetGalleryCover = ({urlParams, params, payload}: HttpSetGalleryCoverOptions) =>
    axios.patch(
        `/experience/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/${urlParams.imageId}/cover`,
        payload,
        {params}
    )

interface HttpGetGalleryImagesOptions {
    urlParams: {experienceId: number; galleryId: number}
    params: {
        responseType: ResponseType
    }
}
export const httpGetGalleryImages = ({urlParams, params}: HttpGetGalleryImagesOptions) =>
    axios.get(`/experience/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images`, {params})

interface HttpGetAdditionalPhotosOptions {
    urlParams: {experienceId: number; galleryId: number}
    params: {
        responseType: ResponseType
    }
}
export const httpGetAdditionalPhotos = ({urlParams, params}: HttpGetAdditionalPhotosOptions) =>
    axios.get(
        `/experience/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/additional-photos`,
        {
            params
        }
    )

/*decline additional photo*/
export interface httpDeclineAdditionalPhotosOptions {
    urlParams: {experienceId: number; galleryId: number}
    params: {
        responseType: ResponseType
    }
    payload: {images: number[]}
}
export const httpDeclineAdditionalPhotos = ({urlParams, params, payload}: httpDeclineAdditionalPhotosOptions) =>
    axios.patch(
        `/experience/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/decline`,
        payload,
        {
            params
        }
    )

/*accept additional photo*/
export interface httpAcceptAdditionalPhotosOptions {
    urlParams: {experienceId: number; galleryId: number}
    params: {
        responseType: ResponseType
    }
    payload: {images: number[]}
}

export const httpAcceptAdditionalPhotos = ({urlParams, params, payload}: httpAcceptAdditionalPhotosOptions) =>
    axios.patch(
        `/experience/submissions/${urlParams.experienceId}/galleries/${urlParams.galleryId}/images/approval`,
        payload,
        {params}
    )

export interface httpHostApprovalUpdateQuestionsOptions {
    urlParams: {experienceId: number}
    params: {responseType: ResponseType}
    payload: UpdateEnhanceStepPayload
}

export const httpHostApprovalUpdateQuestions = async ({
    urlParams,
    params,
    payload
}: httpHostApprovalUpdateQuestionsOptions) => {
    const response = await axios.patch(
        `/experience/submissions/${urlParams.experienceId}/approval-required-update`,
        payload,
        {
            params
        }
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceExtended,
        onZodError: captureException
    })
}

export interface httpHostApprovalSubmitOptions {
    urlParams: {experienceId: number}
    params: {responseType: ResponseType}
}

export const httpHostApprovalSubmit = async ({urlParams, params}: httpHostApprovalSubmitOptions) => {
    const response = await axios.patch(
        `/experience/submissions/${urlParams.experienceId}/host-approval-submit`,
        undefined,
        {
            params
        }
    )
    return parseResponseData({
        responseData: response.data,
        responseShape: ExperienceExtended,
        onZodError: captureException
    })
}
