import Container from '@components/ui/container/Container.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {Helmet} from 'react-helmet'
import {AirbnbSSOWidget} from '@/features/sso/components/airbnb-sso-widget/AirbnbSSOWidget'
import {Camera01Icon} from 'src/components/ui/icon'
import {useTheme} from 'styled-components'
import {PhotoLandingStats} from '@/features/landing/components/photo-landing-stats/PhotoLandingStats.tsx'
import {PhotoMosaic} from '@/features/landing/components/photo-mosaic/PhotoMosaic.tsx'
import {ServiceSteps} from '@/features/landing/components/service-steps/ServiceSteps.tsx'
//assets images
import join1Img from '@/assets/images/photo-landing/join1.webp'
import join2Img from '@/assets/images/photo-landing/join2.webp'
import join3Img from '@/assets/images/photo-landing/join3.webp'
import join4Img from '@/assets/images/photo-landing/join4.webp'
import join5Img from '@/assets/images/photo-landing/join5.webp'
import acceptImg from '@assets/images/photo-landing/steps/sign_quote.svg'
import pickDatesImg from '@assets/images/photo-landing/steps/shoot_date.svg'
import havePhotoshootImg from '@assets/images/photo-landing/steps/meet_photographer.svg'
import reviewImg from '@assets/images/photo-landing/steps/review.svg'
import enjoyImg from '@assets/images/photo-landing/steps/enjoy.svg'
import {FooterNotes} from '@/features/service/components/footer-notes/FooterNotes.tsx'

export const ProPhotographyLanding = () => {
    const {t} = useTranslation()
    const {palette} = useTheme()

    return (
        <>
            <Container>
                <Helmet title={String(t('photoshoot_landing:page_title_meta'))} />
                <AirbnbSSOWidget
                    serviceIcon={<Camera01Icon size={64} fill={palette.neutral.black} />}
                    title={t('photoshoot_landing:sso:title')}
                    subtitle={t('photoshoot_landing:sso:subtitle')}
                />
            </Container>
            <PhotoLandingStats title={t('photoshoot_landing:stats:main_title')} />
            <Container>
                <PhotoMosaic
                    title={t('photoshoot_landing:join:title')}
                    subtitle={t('photoshoot_landing:join:subtitle')}
                    mainImage={join1Img}
                    sideImages={[join2Img, join3Img, join4Img, join5Img]}
                />
                <ServiceSteps
                    title={t('photoshoot_landing:service_steps:title')}
                    subtitle={t('photoshoot_landing:service_steps:subtitle')}
                    steps={[
                        {
                            title: t('photoshoot_landing:service_steps:label_1'),
                            subtitle: t('photoshoot_landing:service_steps:description_1'),
                            image: acceptImg
                        },
                        {
                            title: t('photoshoot_landing:service_steps:label_2'),
                            subtitle: t('photoshoot_landing:service_steps:description_2'),
                            image: pickDatesImg
                        },
                        {
                            title: t('photoshoot_landing:service_steps:label_3'),
                            subtitle: t('photoshoot_landing:service_steps:description_3'),
                            image: havePhotoshootImg
                        },
                        {
                            title: t('photoshoot_landing:service_steps:label_4'),
                            subtitle: t('photoshoot_landing:service_steps:description_4'),
                            image: reviewImg
                        },
                        {
                            title: t('photoshoot_landing:service_steps:label_5'),
                            subtitle: t('photoshoot_landing:service_steps:description_5'),
                            image: enjoyImg
                        }
                    ]}
                />
                <FooterNotes showSup2={true} />
            </Container>
        </>
    )
}

ProPhotographyLanding.displayName = 'PhotoshootLanding'
