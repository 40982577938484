import {errorHandler} from '@/utilities/helpers'
import {ExperienceExtended, StepKeyName} from '../types'
import {useState} from 'react'
import {useEditStep} from '../queries/useEditStep'
import {useEditExperience} from '../queries/useEditExperience'
import {httpEditExperienceOptions} from '../http/experiences.http'
import {getPrevNextStep, getUpdatesRequiredSteps} from '../utils'
import {generatePath, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants'

const stepToEditStepKeyName = (step: StepKeyName) => {
    if (step === 'about_you') return 'about-you'
    if (step === 'about_you_more_info') return 'about-you-more-info'
    if (step === 'enhance_your_experience') return 'enhance-your-experience'
    if (step === 'enhance_about_you') return 'enhance-about-you'
    if (step === 'enhance_gallery') return 'enhance-gallery'
    return step
}

export const useChangeStep = ({
    experience,
    previousStep,
    currentStep,
    nextStep,
    openSubmitModal
}: {
    experience: ExperienceExtended
    previousStep?: StepKeyName
    currentStep: StepKeyName
    nextStep?: StepKeyName
    openSubmitModal?: () => void
}) => {
    const navigate = useNavigate()
    const [isBackPending, setIsBackPending] = useState(false)
    const editStepMutation = useEditStep({
        experienceId: experience.id,
        options: {onError: errorHandler}
    })

    const editExperienceMutation = useEditExperience({
        step: currentStep,
        experienceId: experience.id,
        options: {
            onError: errorHandler,
            onSuccess: data => {
                if (data.isMigratedToService && data.serviceSubmissionId) {
                    navigate(
                        generatePath(routes.SERVICE.path, {
                            id: data.serviceSubmissionId
                        })
                    )
                } else if (nextStep && experience.status != 'update_required') {
                    editStepMutation.mutateAsync(stepToEditStepKeyName(nextStep))
                }
            }
        }
    })
    const isBackLoading = isBackPending && (editStepMutation.isPending || editExperienceMutation.isPending)
    const isContinueLoading = !isBackPending && (editStepMutation.isPending || editExperienceMutation.isPending)
    const updatesRequiredSteps = getUpdatesRequiredSteps(experience)

    const prevNextStep = getPrevNextStep({updatesRequiredSteps, currentStep})

    const handleChangeStepUpdateRequired = async (
        isBack?: boolean,
        payload?: httpEditExperienceOptions<StepKeyName>['payload']
    ) => {
        setIsBackPending(!!isBack)

        if (isBack && prevNextStep.prev) {
            await editStepMutation.mutateAsync(stepToEditStepKeyName(prevNextStep.prev))
        } else if (prevNextStep.next) {
            !!payload && (await editExperienceMutation.mutateAsync(payload))

            await editStepMutation.mutateAsync(stepToEditStepKeyName(prevNextStep.next))
        } else {
            !!payload && (await editExperienceMutation.mutateAsync(payload))
            openSubmitModal?.()
        }
        setIsBackPending(false)
    }

    const handleChangeStep = async (isBack?: boolean, payload?: httpEditExperienceOptions<StepKeyName>['payload']) => {
        setIsBackPending(!!isBack)

        if (isBack && previousStep) {
            await editStepMutation.mutateAsync(stepToEditStepKeyName(previousStep))
        } else if (nextStep) {
            payload
                ? await editExperienceMutation.mutateAsync(payload)
                : await editStepMutation.mutateAsync(stepToEditStepKeyName(nextStep))
        }
        setIsBackPending(false)
    }

    return {
        editStepMutation,
        editExperienceMutation,
        isBackLoading,
        isContinueLoading,
        handleChangeStep: experience.status == 'update_required' ? handleChangeStepUpdateRequired : handleChangeStep,
        hasNextStep: experience.status == 'update_required' ? !!prevNextStep.next : true
    }
}
