import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient.ts'
import {httpGetExperience} from '@/features/host-submissions/experiences/http/experiences.http'
import {ExperienceExtended} from '@/features/host-submissions/experiences/types'

export const useExperience = (
    experienceId: number,
    options?: Omit<UseQueryOptions<ExperienceExtended>, 'queryFn' | 'queryKey'>
) =>
    useQuery({
        ...options,
        queryKey: [QUERY_KEYS.EXPERIENCE, experienceId],
        queryFn: () =>
            httpGetExperience({
                urlParams: {experienceId},
                params: {responseType: 'extended'}
            })
    })
