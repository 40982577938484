import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    ExperiencesHostVideoBody,
    ExperiencesHostVideoFooter
} from '@/features/experiences-host-video/components/experiences-host-video-atoms/ExperiencesHostVideoAtoms.tsx'
import {useTranslation} from '@/translations/i18n.tsx'
import {ClockIcon} from '@components/ui/icon'
import {VoiceoverRecorder} from '@/features/experiences-host-video/components/voiceover-recorder/VoiceoverRecorder.tsx'
import {UsingYourAnswer} from '@/features/experiences-host-video/components/using-your-answer/UsingYourAnswer.tsx'
import {useExperiencesHostVideoRouteParams} from '@/features/experiences-host-video/hooks/useExperiencesHostVideoUrlParams.ts'
import {useShowExperiencesHostVideo} from '@/features/experiences-host-video/services/useShowExperiencesHostVideo.ts'
import {StyledHighlightsList} from '@/features/experiences-host-video/components/record-voiceover-highlights/style.ts'
import {useShowVoiceovers} from '@/features/experiences-host-video/services/useShowVoicovers.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {Paragraph, Title} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms'

export const RecordVoiceoverHighlights = () => {
    const {t} = useTranslation()
    const routeParams = useExperiencesHostVideoRouteParams()
    const showExperiencesHostVideoQuery = useShowExperiencesHostVideo({urlParams: routeParams})

    const answer =
        showExperiencesHostVideoQuery.data?.answers.find(
            answer => answer.question_answer.question.key_name === 'highlights'
        )?.notes || []

    const voiceoversQuery = useShowVoiceovers({
        urlParams: {...routeParams, videoSetId: showExperiencesHostVideoQuery.data?.videoset?.id || null},
        queryOptions: {enabled: !!showExperiencesHostVideoQuery.data?.videoset?.id}
    })
    const currentAudio = voiceoversQuery.data?.find(
        audio => audio.experience_task_status_id == showExperiencesHostVideoQuery.data?.status_id
    )

    return (
        <>
            {voiceoversQuery.isPending ? (
                <Flexbox width={'100%'} align={'center'} justify={'center'}>
                    <Spinner size={32} />
                </Flexbox>
            ) : (
                <>
                    <ExperiencesHostVideoBody gap={6}>
                        <Flexbox direction="column" gap={2}>
                            <Title>{t('experiences_host_video:record_voiceover_highlights:title')}</Title>
                            <Paragraph>{t('experiences_host_video:record_voiceover_highlights:description')}</Paragraph>
                        </Flexbox>
                        <Flexbox gap={2} align={'center'}>
                            <ClockIcon size={20} />
                            <p>{t('experiences_host_video:try_to_record_minimum_of_20_seconds')}</p>
                        </Flexbox>
                        {answer[0]?.notes && (
                            <UsingYourAnswer
                                label={t('experiences_host_video:record_voiceover_highlights:using_your_answer')}
                                answer={
                                    <StyledHighlightsList>
                                        {answer.map(value => (
                                            <li key={value.id}>{value.notes}</li>
                                        ))}{' '}
                                    </StyledHighlightsList>
                                }
                            />
                        )}
                    </ExperiencesHostVideoBody>
                    <ExperiencesHostVideoFooter>
                        <VoiceoverRecorder
                            uploadedAudio={currentAudio}
                            nextStatus={'completed'}
                            nextStep={'completed'}
                            prevStatus={'upload'}
                            prevStep={'unique-guests-can-do'}
                        />
                    </ExperiencesHostVideoFooter>
                </>
            )}
        </>
    )
}
