import {ChatbotSource, HistoryStep} from '@/features/chatbot/types.ts'
import {create} from 'zustand'
import {RefObject} from 'react'
import {HttpGetServiceResponse} from '@/features/service/http/service.http'

type ChatbotStoreState = {
    chatbotRef: RefObject<unknown> | null
    service: HttpGetServiceResponse | null
    chatHistory: HistoryStep[]
    sessionId: number | null
}

type ChatbotStoreActions = {
    setSessionId: (sessionId: number) => void
    setService: (service: HttpGetServiceResponse) => void
    setChatbotRef: (chatbotRef: RefObject<unknown>) => void
    addStepToHistory: ({id, source, text}: {id: string; source: ChatbotSource; text: string}) => void
    resetChatHistory: () => void
    resetService: () => void
}

export const useChatbotStore = create<ChatbotStoreState & ChatbotStoreActions>()(set => ({
    chatbotRef: null,
    service: null,
    sessionId: null,
    chatHistory: [],
    setSessionId: sessionId => set({sessionId: sessionId}),
    setChatbotRef: ref => set({chatbotRef: ref}),
    setService: service => set({service: service}),
    addStepToHistory: ({id, source, text}) =>
        set(state => {
            return {chatHistory: [...state.chatHistory, {id, message: `${source}: ${text}`}]}
        }),
    resetChatHistory: () => set({chatHistory: []}),
    resetService: () => set({service: null})
}))
