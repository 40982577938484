import {Label} from '@/components/ui/label/Label'
import {SelectItem, SelectList} from '@/components/ui/select-atoms/SelectAtoms'
import {useTranslation} from '@/translations/i18n'
import {SelectProvider} from '@ariakit/react'
import {
    StyledDurationSelectPopover,
    StyledDurationSelectPopoverWrapper,
    StyledSelectDurationSelectorTrigger
} from '@/features/host-submissions/experiences/components/activity-duration-selector/style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {forwardRef} from 'react'

interface ActivityDurationSelectorProps {
    errorMessage?: string
    onChange?: (value: string) => void
    value?: string
}

export const ActivityDurationSelector = forwardRef<HTMLSelectElement, ActivityDurationSelectorProps>(
    ({errorMessage, onChange, value}, ref) => {
        const {t} = useTranslation()

        return (
            <Flexbox direction="column" gap={1.5}>
                <SelectProvider value={value ?? ''} setValue={onChange}>
                    <Label>{t('experiences:experience_itinerary_step:create_activity_modal:duration_label')}</Label>
                    <StyledSelectDurationSelectorTrigger
                        fullWidth
                        placeholder={t(
                            'experiences:experience_itinerary_step:create_activity_modal:duration_placeholder'
                        )}
                        displayValue={value => t('commons:minutes', {minutes: value, count: parseInt(value as string)})}
                        $hasError={!!errorMessage}
                        ref={ref}
                    />
                    <StyledDurationSelectPopoverWrapper>
                        <StyledDurationSelectPopover portal={false}>
                            <SelectList>
                                {Array.from({length: 8}).map((_, index) => {
                                    const minutes = (index + 1) * 15
                                    return (
                                        <SelectItem key={index} value={minutes.toString()}>
                                            {t('commons:minutes', {minutes: minutes, count: minutes})}
                                        </SelectItem>
                                    )
                                })}
                            </SelectList>
                        </StyledDurationSelectPopover>
                    </StyledDurationSelectPopoverWrapper>
                </SelectProvider>
                {errorMessage && <InputHelpText error={errorMessage} />}
            </Flexbox>
        )
    }
)
