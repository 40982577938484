import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query'
import {parseAxiosPromise} from '@utilities/helpers.ts'
import {captureException} from '@sentry/react'
import {Service} from '@/features/host-submissions/services/types.ts'
import {httpUpdateEnhanceStep} from '@/features/host-submissions/services/http/services.http'
import {QUERY_KEYS} from '@/queryClient'
import {UploadedMedia} from '@/features/host-submissions/services/types'

export type UpdateEnhanceStepPayload = {
    host_experience_title?: string
    host_experience_description?: string
    host_education_title?: string
    host_education_description?: string
    host_career_highlight_title?: string
    host_career_highlight_description?: string
    service_title?: string
    service_description?: string
    photo?: UploadedMedia[]
    offerings?: {
        improved_title: string
        improved_description: string
        main_photo: string
        id: number
    }[]
}

export const useUpdateEnhanceStep = ({
    serviceId,
    options
}: {
    serviceId: number
    options?: Omit<UseMutationOptions<Service, unknown, UpdateEnhanceStepPayload>, 'mutationFn'>
}) => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: payload =>
            parseAxiosPromise({
                axiosPromise: httpUpdateEnhanceStep({
                    urlParams: {serviceId},
                    params: {response_type: 'extended'},
                    payload
                }),
                responseShape: Service,
                onZodError: captureException
            }),
        ...options,
        onSuccess: (data, variables, context) => {
            void queryClient.cancelQueries({queryKey: [QUERY_KEYS.SERVICE, serviceId]})
            void queryClient.setQueriesData({queryKey: [QUERY_KEYS.SERVICE, serviceId]}, data)
            options?.onSuccess?.(data, variables, context)
        }
    })
}
