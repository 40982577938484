import {AirbnbIcon, PassportIcon} from '@components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'
import {Paragraph} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Trans} from 'react-i18next'
import {StyledContent} from './style'

export const PendingIdVerificationStep = () => {
    const {t} = useTranslation()

    return (
        <StyledContent gap={6}>
            <PassportIcon />
            <Flexbox direction="column" align="center" gap={2}>
                <h3>{t('host_review:step_pending_id_verification:title')}</h3>
                <Paragraph>
                    <Trans
                        i18nKey={'host_review:step_pending_id_verification:paragraph'}
                        components={{
                            a: (
                                <a
                                    style={{textDecoration: 'underline', cursor: 'pointer'}}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.airbnb.com/help/article/1237"
                                />
                            )
                        }}
                    />
                </Paragraph>
            </Flexbox>
            <ButtonLink to="https://www.airbnb.com/verify" size="lg" fullWidth>
                <AirbnbIcon />
                {t('host_review:step_pending_id_verification:cta')}
            </ButtonLink>
            <small>{t('host_review:step_pending_id_verification:description')}</small>
        </StyledContent>
    )
}
