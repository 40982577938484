import {useMutation, UseMutationOptions} from '@tanstack/react-query'
import {
    httpUploadProfilePhoto,
    HttpUploadProfilePhotoOptions
} from '@/features/host-submissions/http/hostSubmissions.http'

export const useChangeProfilePhoto = ({
    hostId,
    options
}: {
    hostId: string
    options?: UseMutationOptions<{url: string}, unknown, Omit<HttpUploadProfilePhotoOptions['payload'], 'mutationFn'>>
}) => {
    return useMutation<{url: string}, unknown, HttpUploadProfilePhotoOptions['payload']>({
        mutationFn: payload =>
            httpUploadProfilePhoto({
                payload,
                urlParams: {hostId},
                params: {response_type: 'extended'}
            }),
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context)
        }
    })
}
