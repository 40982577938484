import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {Offering as OfferingType, OfferingTypes, Service} from '@/features/host-submissions/services/types'
import {
    ButtonArea,
    PreviewImage,
    FieldTitle,
    AlertText
} from '@/components/ui/multi-step-form-atoms/MultiStepFormAtoms.tsx'
import {
    StyledImagePlaceholderBox,
    StyledOfferingItem,
    StyledOfferingItemTextWrapper,
    StyledOfferingTitle,
    StyledOfferingType
} from './style'
import {Image01Icon, PlusIcon, Trash01Icon} from '@/components/ui/icon'
import {useTranslation} from '@/translations/i18n'
import {useMemo} from 'react'
import {Button} from '@/components/ui/button/Button'
import {Countries} from 'country-to-currency'
import {useDeleteOffering} from '@/features/host-submissions/services/queries/useDeleteOffering'
import {QUERY_KEYS, queryClient} from '@/queryClient'
import {Spinner} from '@components/ui/spinner/Spinner'
import {formatCurrency} from '@/utilities/helpers'
import {mandatoryOfferingTypes} from '../offerings-step/OfferingsStep'

export const Offerings = ({
    serviceId,
    offerings,
    openOfferingModal,
    onSelect,
    isOtherOfferings,
    hasAllRequiredType,
    offeringsLength
}: {
    serviceId: number
    offerings: Service['offerings']
    country: Countries
    openOfferingModal: () => void
    onSelect: (offering: OfferingType) => void
    isOtherOfferings: boolean
    hasAllRequiredType: boolean
    offeringsLength: number
}) => {
    const {t} = useTranslation()
    return (
        <Flexbox direction="column" gap={4} width="100%">
            {isOtherOfferings && !!offerings.length && (
                <StyledOfferingTitle>{t('services:step_offerings:type:other')}</StyledOfferingTitle>
            )}
            {offerings?.map(offering => (
                <Offering key={offering.id} offering={offering} serviceId={serviceId} onSelect={onSelect} />
            ))}

            {hasAllRequiredType && offeringsLength < 10 && isOtherOfferings && (
                <Button shape="text" variant="tertiary" onClick={openOfferingModal}>
                    <PlusIcon />
                    <span>{t('services:step_offerings:add_additional_offering')}</span>
                </Button>
            )}
        </Flexbox>
    )
}

const Offering = ({
    offering,
    serviceId,
    onSelect
}: {
    offering: OfferingType
    serviceId: number
    onSelect: (value: OfferingType) => void
}) => {
    const {t} = useTranslation()

    const deleteOfferingMutation = useDeleteOffering({
        offeringId: offering.id,
        serviceId,
        options: {
            onSuccess: () => {
                void queryClient.invalidateQueries({queryKey: [QUERY_KEYS.SERVICE, serviceId]})
            }
        }
    })

    const remappedOffering = useMemo(
        () =>
            offering.question_answer.reduce(
                (acc, val) => {
                    if (val.question_key_name == 'title') {
                        return {...acc, title: val.answer}
                    }
                    if (val.question_key_name == 'currency') {
                        return {...acc, currency: val.answer}
                    }
                    if (val.question_key_name == 'price_per_guest') {
                        return {...acc, price_per_guest: val.answer}
                    }
                    if (val.question_key_name == 'duration') {
                        return {...acc, duration: val.answer}
                    }
                    if (val.question_key_name == 'description') {
                        return {...acc, description: val.answer}
                    }
                    return acc
                },
                {
                    duration: '',
                    title: '',
                    description: '',
                    currency: '',
                    price_per_guest: ''
                }
            ),
        [offering]
    )

    const isOfferingTypeOther = offering.type == OfferingTypes.Enum.other
    const isOfferingTypeMandatory = mandatoryOfferingTypes.includes(offering.type)
    const offeringFlagsCount = offering.question_answer.flatMap(question => question.parent_flags).length
    const offeringNeedUpdate =
        !remappedOffering.title ||
        !remappedOffering.price_per_guest ||
        !remappedOffering.duration ||
        !remappedOffering.description ||
        !offering.meeting_point?.length ||
        !offering.main_photo_url

    return (
        <ButtonArea fullWidth borderRadius="12px" padding={4} onClick={() => onSelect(offering)}>
            <Flexbox width="100%">
                <StyledOfferingItem>
                    {offering.main_photo_url ? (
                        <PreviewImage src={offering.main_photo_url} />
                    ) : (
                        <StyledImagePlaceholderBox>
                            <Image01Icon />
                        </StyledImagePlaceholderBox>
                    )}
                    <Flexbox direction="column" width="100%" gap={0.5}>
                        {!isOfferingTypeOther && (
                            <StyledOfferingType>
                                {t(`services:step_offerings:type:${offering.type}`)}
                            </StyledOfferingType>
                        )}

                        <StyledOfferingItemTextWrapper direction="column" width="100%" gap={1}>
                            <FieldTitle>{remappedOffering.title}</FieldTitle>
                            <p>
                                {t('services:step_offerings:duration_price_per_guest', {
                                    duration: t('commons:minutes', {
                                        minutes: remappedOffering.duration,
                                        count: parseInt(remappedOffering.duration as string)
                                    }),
                                    price: remappedOffering.price_per_guest
                                        ? formatCurrency(
                                              parseFloat(remappedOffering.price_per_guest),
                                              remappedOffering.currency
                                          )
                                        : ''
                                })}
                            </p>
                            {offeringNeedUpdate && (
                                <AlertText text={t('experiences:experience_itinerary_step:missing_details')} />
                            )}
                            {offeringFlagsCount >= 1 && <AlertText text={t('commons:can_be_improved')} />}
                        </StyledOfferingItemTextWrapper>
                    </Flexbox>
                </StyledOfferingItem>
                {!isOfferingTypeMandatory && (
                    <Button
                        variant="ghost"
                        shape="text"
                        disabled={deleteOfferingMutation.isPending}
                        onClick={e => {
                            e.stopPropagation()
                            deleteOfferingMutation.mutate()
                        }}
                    >
                        {deleteOfferingMutation.isPending ? <Spinner /> : <Trash01Icon size={20} />}
                    </Button>
                )}
            </Flexbox>
        </ButtonArea>
    )
}
