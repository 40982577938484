import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {useShowExperiencesHostVideoRedirect} from '@/features/experiences-host-video/services/useShowExperiencesHostVideoRedirect.ts'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {raise} from '@utilities/helpers.ts'
import {useURLParsedParams} from '@hooks/useURLParsedParams.ts'
import {z} from 'zod'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import {AlertCircleIcon} from '@components/ui/icon'
import {useTranslation} from '@/translations/i18n.tsx'
import {generatePath, Navigate} from 'react-router-dom'
import {routes} from '@utilities/constants'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const ExperiencesHostVideoRedirect = () => {
    const {t} = useTranslation()
    const urlParams = useURLParsedParams(z.object({airbnbId: z.string().min(1)}))
    const hostCode = useAuthStore(store => store.user?.code)
    const hostPassword = useAuthStore(store => store.user?.password)
    const showExperiencesHostVideoRedirectQuery = useShowExperiencesHostVideoRedirect({
        urlParams: {
            hostCode,
            hostPassword,
            airbnbId: urlParams.success ? urlParams.data.airbnbId : raise('airbnbId is nullish')
        },
        queryOptions: {
            enabled: !!hostCode && !!hostPassword
        }
    })

    if (showExperiencesHostVideoRedirectQuery.isError) {
        return <ErrorBox icon={<AlertCircleIcon />} title={t('errors:default')} />
    }
    if (showExperiencesHostVideoRedirectQuery.isSuccess) {
        return (
            <Navigate
                to={generatePath(routes.EXPERIENCE_HOST_VIDEO.path, {
                    hostPassword,
                    hostCode,
                    experiencesHostVideoId: showExperiencesHostVideoRedirectQuery.data.experience_task.experience_id,
                    taskId: showExperiencesHostVideoRedirectQuery.data.experience_task.id
                })}
                replace
            />
        )
    }

    return (
        <Flexbox justify="center" align="center" style={{height: '100%'}}>
            <Spinner size={32} />
        </Flexbox>
    )
}
