import styled, {css} from 'styled-components'

export const StyledGalleryGrid = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: ${spacing * 4}px;
        ${mediaQueries.m} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    `
)

export const StyledGalleryItemImg = styled.img(
    () => css`
        width: 100%;
        height: 100%;
        object-fit: cover;
    `
)

export const StyledGalleryItemContainer = styled.div(
    ({theme: {palette, opacities}}) => css`
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        background: ${palette.neutral['600']}${opacities[30]};
        aspect-ratio: 1;
        width: 100%;
    `
)
